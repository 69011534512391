import styles from "./RulesGrid.module.css";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import Layout from "../../../../../components/layouts/Layout/Layout";
import { useCallback, useEffect, useMemo, useState } from "react";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import RuleBuilderModalContainer from "../../RuleGenerator/RuleBuilderModalContainer/RuleBuilderModalContainer";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import { useRulesColumnWidthStore } from "../../../../../globalStore/columnWidthStore";
import ColumnFilterInput, {
  resetRuleFilterSearchInput,
} from "../../ColumnFilterInput/ColumnFilterInput";
import {
  createMinimalContextMenu,
  insertEmptyStrOnPressBackspace,
  onFilterTextBoxChanged,
  removeTrailingZeros,
  suppressDeleteAndBackspaceKey,
} from "../../../../../utils";
import GetStartedButton from "../../../../../components/elements/GetStartedButton/GetStartedButton";
import {
  useOnBoardingStore,
  useUserStore,
} from "../../../../../globalStore/appStore";
import { MenuItem, Select, Tooltip } from "@mui/material";
import {
  getRuleSetFromRuleTemplates,
  ruleSets,
} from "../../../../rules/service/ruleSets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import RulesTemplateModal from "../../../../rules/components/RulesTemplateModal/RulesTemplateModal";
import NumRulesLimitInfo from "../../../../rules/components/NumRulesLimitInfo/NumRulesLimitInfo";
import { customWarningAlert } from "../../../../confirm/service/confirm";
import { PLAN } from "../../../../auth/service/auth";
import { showWarningPopupIfNoOthersShowing } from "../../../../../utils/uiUtils/popup";
import { KEYWORD_NA } from "../../../../../constants/keywords";
import Modal from "../../../../../components/elements/Modal/Modal";

const AddRulesButton = ({ onClick }) => {
  const { manuallyStepThroughTour } = useOnBoardingStore();
  return (
    <BasicButton
      color="#219ec9"
      hoverColor="#1f91b7"
      dataTestId="manage-rules-btn"
      onClick={() => {
        onClick();
        manuallyStepThroughTour(0);
      }}
      customStyle={{
        borderRadius: "10px",
      }}
    >
      Manage Rules
    </BasicButton>
  );
};

const RANDOM_200PX_STRING = "Loem ipsum dolor sit amet cons";

const RulesGrid = ({
  locationID,
  rulesData,
  isSaving,
  gridApi,
  setGridApiToParent,
  updateCustomRules,
  removeRule,
  updateData,
  ruleNames,
  allRulesData,
  rulesInRoster,
  ruleWarnings,
  showManageBtn = true,
  showWarningBox = true,
  customStyle,
  minimalContextMenu = false,
  setColumnApiToParent,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  exportToCsv,
  exportToExcel,
  columnApi,
  reformattedRules,
  isRulesWithExceptionEnabled,
  customTopComponent,
  getDataFromGrid,
  isScheduleView,
  toggleRulesTemplateModal,
  setSelectedRulesTemplate,
  showRulesTemplateModal = false,
  selectedRulesTemplate = null,
  initialRulesInBuilder = null,
  setInitialRulesInBuilder = null,
  clearDefaultNewRules,
  rosterName,
  isIndividualView = false,
}) => {
  const { plan } = useUserStore();
  const {
    weekdayColWidth,
    weekendColWidth,
    isWDayWEndSeparate,
    setWeekdayColWidth,
    setWeekendColWidth,
    setIsWDayWEndSeparate,
  } = useRulesColumnWidthStore();

  useEffect(() => {
    resetRuleFilterSearchInput();
  }, []);

  const isWarningPresent = ruleWarnings && ruleWarnings.length > 0;

  const [showBuildRule, setShowBuildRule] = useState(false);
  const toggleBuildRuleModal = useCallback(
    () => setShowBuildRule((prev) => !prev),
    []
  );

  const handleCloseAll = () => {
    toggleBuildRuleModal();
  };

  const showNoEmployeesAlert = async () => {
    if (rulesData.length === 0) {
      await customWarningAlert({
        title: "No employees found",
        descriptions: ["Please add employees before adding rules"],
      });
      return true;
    }
    return false;
  };

  const handleShowBuildRule = async () => {
    if (await showNoEmployeesAlert()) {
      return;
    }
    toggleBuildRuleModal();
  };

  const getContextMenuItems = (params) => {
    if (minimalContextMenu) {
      return createMinimalContextMenu();
    }
    let contextMenu = [
      {
        name: "Rule builder",
        action: function () {
          handleShowBuildRule();
        },
      },
      {
        name: "Remove selected rule",
        disabled:
          params.column === null ||
          !ruleNames.map(({ name }) => name).includes(params.column.colId),
        action: function () {
          if (
            params.column !== null &&
            ruleNames.map(({ name }) => name).includes(params.column.colId)
          ) {
            removeRule(params.column.colId);
          }
        },
      },
      "separator",
      "export",
      "copy",
      "copyWithHeaders",
    ];

    return contextMenu;
  };

  const ruleNameColumnDefs = useMemo(
    () =>
      ruleNames.map(({ name, formattedName }) => {
        return {
          cellClassRules: {
            "invalid-cell": (params) => {
              if (params.value === "" || params.value == null) {
                return true;
              }

              if (ruleWarnings) {
                const rulesWithInvalidInput = ruleWarnings.find(
                  ({ warningType }) => warningType === "invalid input"
                );
                if (rulesWithInvalidInput) {
                  for (const ruleInfo of rulesWithInvalidInput.extraInfo) {
                    if (
                      params.colDef.field === ruleInfo.ruleName &&
                      params.value === ruleInfo.ruleValue
                    ) {
                      switch (ruleInfo.correctType) {
                        case "integer":
                          if (
                            isNaN(parseInt(params.value)) ||
                            parseFloat(params.value) < 0 ||
                            !Number.isInteger(parseFloat(params.value))
                          ) {
                            return true;
                          }
                          return;
                        case "half_integer":
                          if (
                            isNaN(parseFloat(params.value)) ||
                            parseFloat(params.value) < 0 ||
                            parseFloat(params.value) % 0.5 !== 0
                          ) {
                            return true;
                          }
                          return;
                        default:
                          return;
                      }
                    }
                  }
                }
                const outOfRangeRules = ruleWarnings.find(
                  ({ warningType }) => warningType === "value out of range"
                );
                if (outOfRangeRules) {
                  for (const ruleInfo of outOfRangeRules.extraInfo) {
                    const violatingRules = ruleInfo.violatingRules;

                    const violatingRule = violatingRules.find(
                      ({ ruleName }) => params.colDef.field === ruleName
                    );

                    if (
                      violatingRule &&
                      violatingRule.ruleValue === parseFloat(params.value)
                    ) {
                      return true;
                    }
                  }
                }
              }

              return false;
            },
          },
          field: name,
          headerName: formattedName,
          width: weekdayColWidth ? weekdayColWidth : 200,
          suppressSizeToFit: true,
          valueSetter: (params) => {
            if (insertEmptyStrOnPressBackspace(params)) {
              return true;
            }
            if (params.newValue === undefined) {
              params.data[name] = "";
              return true;
            }
            const newStrValue = params.newValue.toString();
            if (newStrValue.toLowerCase() === KEYWORD_NA.toLowerCase()) {
              params.data[name] = KEYWORD_NA;
              return true;
            }
            const rule = reformattedRules.find(
              (item) => item.ruleColName === params.column.colId
            );

            if (isNaN(newStrValue.trim()) && rule.valueType !== "string") {
              showWarningPopupIfNoOthersShowing(
                "Invalid rule value",
                `Please enter a number or ${KEYWORD_NA}`
              );
              return false;
            }

            let value = newStrValue.trim();

            if (rule.valueType !== "string") {
              value = removeTrailingZeros(value);
            }

            params.data[name] = value;
            return true;
          },
        };
      }),
    [ruleNames, ruleWarnings, weekdayColWidth, reformattedRules]
  );

  const columnDefs = useMemo(
    () => [
      {
        field: "name",
        sortable: true,
        editable: false,
        suppressSizeToFit: true,
        width: 150,
        pinned: "left",
      },
      ...ruleNameColumnDefs,
    ],
    [ruleNameColumnDefs]
  );

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const getColumnQuickFilter = () => {
    return () => (
      <ColumnFilterInput
        columnApi={columnApi}
        ruleColNameDescriptionPairs={reformattedRules}
      />
    );
  };

  const customGridStyleStyle = customStyle ? customStyle.dataEntryTable : {};
  return (
    <>
      {showBuildRule && (
        <Modal isOpen={showBuildRule}>
          <RuleBuilderModalContainer
            updateCustomRules={updateCustomRules}
            handleClose={handleCloseAll}
            rules={allRulesData}
            rulesData={rulesData}
            rulesInRoster={rulesInRoster}
            isRulesWithExceptionEnabled={isRulesWithExceptionEnabled}
            initialRulesInBuilder={initialRulesInBuilder}
            clearDefaultNewRules={clearDefaultNewRules}
            plan={plan}
          />
        </Modal>
      )}
      {showRulesTemplateModal && (
        <RulesTemplateModal
          open={showRulesTemplateModal}
          selectedRulesTemplate={selectedRulesTemplate}
          setSelectedRulesTemplate={setSelectedRulesTemplate}
          onClose={toggleRulesTemplateModal}
          rules={allRulesData}
          isRulesWithExceptionEnabled={isRulesWithExceptionEnabled}
          toggleBuildRuleModal={toggleBuildRuleModal}
          setInitialRulesInBuilder={setInitialRulesInBuilder}
        />
      )}
      <Layout
        title={
          isScheduleView || isIndividualView ? "Rules" : `Rules - ${rosterName}`
        }
        headerNext={() => (
          <GetStartedButton url={"https://help.rosterlab.com/rules"} />
        )}
        headerRight={
          isScheduleView || !showManageBtn
            ? null
            : () => <AddRulesButton onClick={() => handleShowBuildRule()} />
        }
        isSubheading={customStyle ? customStyle.isSubheading : false}
      >
        <div className={styles.container}>
          <div className={styles.topLine}>
            <div className={styles.left}>{customTopComponent}</div>
            {isScheduleView && (
              <div className={styles.right}>
                {plan !== PLAN.AI && plan !== PLAN.COLLABORATOR && (
                  <RuleSetDropdown
                    existingRuleTemplates={rulesInRoster.map(
                      (rule) => rule.template
                    )}
                    numRules={ruleNames.length}
                    toggleRulesTemplateModal={toggleRulesTemplateModal}
                    setSelectedRulesTemplate={setSelectedRulesTemplate}
                    showNoEmployeesAlert={showNoEmployeesAlert}
                    plan={plan}
                  />
                )}
                {showManageBtn && isScheduleView && (
                  <AddRulesButton onClick={() => handleShowBuildRule()} />
                )}
              </div>
            )}
          </div>
          <ActionBar
            searchBarSettings={
              isIndividualView
                ? null
                : {
                    tableName: "rules",
                    onFilterInputChanged,
                  }
            }
            locationID={locationID}
            adjustWidthSettings={{
              longestStr: RANDOM_200PX_STRING,
              weekdayColWidth,
              weekendColWidth,
              isWDayWEndSeparate,
              setWeekdayColWidth,
              setWeekendColWidth,
              setIsWDayWEndSeparate,
              tableName: "rules",
              showWeekdayWeekendToggle: false,
              disableSetForAllPages: true,
            }}
            customComponents={[getColumnQuickFilter()]}
            exportSettings={{
              exportToCsv,
              exportToExcel,
            }}
          />
          <DataEntryTable
            customStyle={{
              height: `${window.innerHeight - 370}px`,
              minHeight: `500px`,
              ...customGridStyleStyle,
            }}
            columnDefs={columnDefs}
            rowData={rulesData}
            updateData={updateData}
            getContextMenuItems={getContextMenuItems}
            gridOptions={{
              onCellValueChanged: (params) => {
                if (triggerUndoRedoSnapshotCollection) {
                  triggerUndoRedoSnapshotCollection(params);
                }
              },
              suppressFieldDotNotation: true,
            }}
            setGridApiToParent={setGridApiToParent}
            suppressKeyboardEvent={suppressDeleteAndBackspaceKey}
            onCellKeyDown={(params) => {
              if (handleKeyDownForUndoRedo) {
                handleKeyDownForUndoRedo(params.event);
              }
            }}
            defaultColDef={{
              filterParams: { newRowsAction: "keep" },
            }}
            setColumnApiToParent={setColumnApiToParent}
            tableName="rules"
            getDataFromGrid={getDataFromGrid}
          />
          <p className={styles["saving-line"]}>
            {isSaving ? "saving..." : "saved"}
          </p>
          <p className={styles["option-keys"]}>
            Insert <span className={styles.highlight}>{KEYWORD_NA}</span> for
            the rules that are not applicable
          </p>
        </div>
        {isWarningPresent && showWarningBox && (
          <div className={styles["warning-wrapper"]}>
            <WarningDisplay
              title="Following issues were found:"
              displayedWarnings={getDisplayedWarningsInfo(ruleWarnings)}
            />
          </div>
        )}
      </Layout>
    </>
  );
};

const RuleSetDropdown = ({
  existingRuleTemplates,
  numRules,
  toggleRulesTemplateModal,
  setSelectedRulesTemplate,
  showNoEmployeesAlert,
  plan,
}) => {
  const ruleSet = getRuleSetFromRuleTemplates(existingRuleTemplates);

  return (
    <div className={styles.ruleSetDropdown}>
      {plan === PLAN.MID && <NumRulesLimitInfo numRules={numRules} />}
      <div>
        <p className={styles.ruleSetDropdownLabel}>Choose a rules template</p>
        <Select
          value={ruleSet ? ruleSet.name : "Custom"}
          onChange={async (event) => {
            if (await showNoEmployeesAlert()) {
              return;
            }
            const ruleSetName = event.target.value;
            setSelectedRulesTemplate(ruleSetName);
            toggleRulesTemplateModal();
          }}
          style={{
            height: "30px",
            width: "200px",
          }}
        >
          {ruleSets.map((ruleSet, index) => (
            <MenuItem key={index} value={ruleSet.name}>
              {ruleSet.name}
              <Tooltip
                title={
                  <p style={{ color: "white", fontSize: "16px" }}>
                    {ruleSet.description}
                  </p>
                }
              >
                <span>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    style={{ marginLeft: "8px" }}
                  />
                </span>
              </Tooltip>
            </MenuItem>
          ))}
          <MenuItem key={"custom"} value={"Custom"}>
            Custom
          </MenuItem>
        </Select>
      </div>
    </div>
  );
};

export default RulesGrid;
