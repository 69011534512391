import { forwardRef, useRef, useState } from "react";
import AdvSettingItem from "../SettingItem/AdvSettingItem";
import styles from "./SettingsTabContent.module.css";
import SettingItem from "../SettingItem/SettingItem";
import { LOCATION_SETTINGS_TYPE } from "../../../../constants/settings";
import LeaveSetting from "../SettingItem/LeaveSetting";
import PublicHolidaySetting from "../SettingItem/PublicHolidaySetting";
import ScheduleUploadDownload from "../../../scheduleView/components/ScheduleUploadDownload/ScheduleUploadDownload";
import KeywordInputSettingItem from "../SettingItem/KeywordInputSettingItem";
import { LONG_NAME_RDO } from "../../../../constants/keywords";

const SettingsFieldGroup = ({ title, children }) => {
  return (
    <div className={styles.fieldGroup}>
      <p className={styles.fieldGroupTitle}>{title}</p>
      <div className={styles.fields}>{children}</div>
    </div>
  );
};

const LocationNameEditor = ({ locationName, updateLocationName }) => {
  const [inputValue, setInputValue] = useState(locationName);
  const [isEditingName, setIsEditingName] = useState(false);
  const inputRef = useRef(null);

  return (
    <div className={styles.locationNameSetting}>
      <input
        ref={inputRef}
        className={styles.locationNameInput}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        readOnly={isEditingName ? false : true}
      />
      {isEditingName ? (
        <div className={styles.locationNameButtons}>
          <button
            className={styles.locationNameButton}
            onClick={() => {
              setIsEditingName(false);
              if (inputValue.trim() === locationName) {
                console.log("not changed");
                return;
              }
              const succeeded = updateLocationName(inputValue);
              if (!succeeded) {
                setInputValue(locationName);
              }
            }}
          >
            Save
          </button>
          <button
            className={styles.cancelLocationNameChangeButton}
            onClick={() => {
              setIsEditingName(false);
              setInputValue(locationName);
            }}
          >
            Cancel
          </button>
        </div>
      ) : (
        <button
          className={styles.locationNameButton}
          onClick={() => {
            inputRef.current.focus();
            setIsEditingName(true);
          }}
        >
          Edit name
        </button>
      )}
    </div>
  );
};

const SettingsTabContent = forwardRef(
  (
    {
      tabInfo,
      defaultNumDays,
      location,
      checkIsEnabled,
      getTargetSetting,
      getSettingToggler,
      updateFrontendSettings,
      customKeywordsData,
      leaveSettingValues,
      publicHolidayTimezone,
      updateCheckLeaveSetting,
      handleDeleteLocation,
      updateLocationName,
      isLocationDeletable,
    },
    ref
  ) => {
    const { tabId, tabLabel, groups } = tabInfo;

    const getGroupedSettings = (settings, updateCheckLeaveSetting) =>
      settings.map((setting) => {
        const isAdvanced = Boolean(setting.advancedInputFormats);
        const isEnabled = checkIsEnabled(setting);
        const toggleSettings = getSettingToggler(setting);

        if (isAdvanced) {
          const targetSetting = getTargetSetting(setting);
          return (
            <AdvSettingItem
              key={setting.name}
              setting={setting}
              isEnabled={isEnabled}
              toggleSettings={toggleSettings}
              updateFrontendSettings={updateFrontendSettings}
              targetSetting={targetSetting}
              defaultNumDays={defaultNumDays}
            />
          );
        }
        return (
          <SettingItem
            key={setting.name}
            setting={setting}
            toggleSettings={toggleSettings}
            isEnabled={isEnabled}
            updateCheckLeaveSetting={updateCheckLeaveSetting}
          />
        );
      });

    return (
      <div id={tabId} className={styles.settingsTabContent} ref={ref}>
        {tabLabel === LOCATION_SETTINGS_TYPE.general && (
          <SettingsFieldGroup title="Location name">
            <LocationNameEditor
              locationName={location.name}
              updateLocationName={updateLocationName}
            />
          </SettingsFieldGroup>
        )}
        {groups.map(({ groupId, groupLabel, settings }) => {
          return (
            <SettingsFieldGroup key={groupId} title={groupLabel}>
              {getGroupedSettings(settings, updateCheckLeaveSetting)}
              {groupId === "group-My Roster page display" && (
                <KeywordInputSettingItem
                  keywordLongname={LONG_NAME_RDO}
                  label="Name"
                  description="* Roster/Regular days off (RDO)"
                  note="Note: This is only for display purpose in the “My Roster” page. When put through days off for staff in request pages, please use “off” instead."
                  customKeywordsData={customKeywordsData}
                  leaveSettingValues={leaveSettingValues}
                  updateFrontendSettings={updateFrontendSettings}
                />
              )}
            </SettingsFieldGroup>
          );
        })}
        {tabLabel === LOCATION_SETTINGS_TYPE.leaveManagement && (
          <SettingsFieldGroup title="Location shift names">
            <LeaveSetting
              customKeywordsData={customKeywordsData}
              leaveSettingValues={leaveSettingValues}
              updateFrontendSettings={updateFrontendSettings}
            />
            <PublicHolidaySetting
              publicHolidayTimezone={publicHolidayTimezone}
              updateFrontendSettings={updateFrontendSettings}
            />
          </SettingsFieldGroup>
        )}
        {tabLabel === LOCATION_SETTINGS_TYPE.general && (
          <>
            {location.isScheduleView && (
              <SettingsFieldGroup title="Download rosters">
                <ScheduleUploadDownload location={location} />
              </SettingsFieldGroup>
            )}
            {isLocationDeletable && (
              <button
                className={styles.deleteLocationButton}
                onClick={handleDeleteLocation}
              >
                Delete this location
              </button>
            )}
          </>
        )}
      </div>
    );
  }
);

export default SettingsTabContent;
