import { useMemo } from "react";
import styles from "./PreferencesRecurringGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { preferenceCellClassRules } from "../PreferencesOverviewGrid/PreferencesOverviewGrid";
import RangeSelector from "../../RangeSelector/RangeSelector";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import ImportanceSelector from "../ImportanceSelector/ImportanceSelector";
import DropdownSingleSelector from "../../../../grid/components/DropdownSingleSelector/DropdownSingleSelector";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import {
  allocationDropdownOptionsValueSetter,
  convertShortIdsCellValueToEntityNames,
  createBasicContextMenu,
  DateTime,
  defaultFilterValueGetter,
  flatOptions,
  getDayNumFromColFieldName,
  getRequestOptions,
  isDayColumn,
  lockShiftsOnly,
  onFilterTextBoxChanged,
  preferencesValueFormatter,
  setCellRange,
  suppressEnterKey,
  unlockAllCells,
} from "../../../../../utils";
import { KEYWORD_OFF } from "../../../../../constants/keywords";
import {
  processPreferencesCellForClipboard,
  processAllocationsFromClipboard,
} from "../../../../../utils/agGridUtils/clipboard";
import {
  getColorCodedCells,
  getWeekendsCellStyle,
} from "../../../rosteredAllocations/service/styleGetters";
import { ColorCodingButton } from "../../../rosteredAllocations/components/RosterActionComponents/RosterActionComponents";

const NoGlobalEmployeesOverlay = `<span style="padding: 10px; border: 2px solid red; color: red; font-size: 15px">
  You have no employees set up for this location
  </span>`;

const NoEmployeesOverlay = `<span style="padding: 10px; border: 2px solid red; color: red; font-size: 15px">
  You have no employees set up for this roster
  </span>`;

const PreferencesRecurringGrid = ({
  locationID,
  startDate,
  employeesData,
  enumeratedTasks,
  enumeratedShiftTasks,
  isSaving,
  recurringColumns,
  setGridApiToParent,
  setColumnApiToParent,
  updateData,
  recurringWarnings,
  weekdayColWidth,
  weekendColWidth,
  updateRecurringSelection,
  recurringPeriodSelection,
  suffix,
  isGlobal,
  changePrefLevel,
  approveSpecialRequests,
  customStyle,
  title,
  isIndividualView = false,
  gridApi,
  showColAdjuster = true,
  exportToCsv,
  exportToExcel,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  shouldInsertOffOnPasteBlank,
  getDataFromGrid,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  areas,
  skills,
  showSkillsColumn = false,
  employeeNames,
  doesAreaFilterPass = null,
  isExternalFilterPresent = null,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
  colorCodes,
  toggleColorCodingModal,
}) => {
  const getContextMenuItems = (params) => {
    let contextMenu = [];

    if (params.column !== null && isDayColumn(params.column.colId)) {
      contextMenu = [
        {
          name: "Set critical",
          action: function () {
            setCellRange(params.api, lockShiftsOnly, "!");
          },
        },
        {
          name: "Set high",
          action: function () {
            setCellRange(params.api, lockShiftsOnly, "?");
          },
        },
        {
          name: "Set low",
          action: function () {
            setCellRange(params.api, unlockAllCells);
          },
        },
        "separator",
      ]
        .concat(contextMenu)
        .concat(createBasicContextMenu());
    } else {
      contextMenu = createBasicContextMenu();
    }

    return contextMenu;
  };

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const isWarningPresent = recurringWarnings && recurringWarnings.length > 0;

  const shiftOptions = useMemo(
    () =>
      getRequestOptions(
        [KEYWORD_OFF],
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        areas,
        enumeratedTasks,
        enumeratedShiftTasks,
        shortIdsToEntityNamesDicts,
        customKeywordsUtilObj
      ),
    [
      shifts,
      shiftGroups,
      tasks,
      subTasks,
      areas,
      enumeratedTasks,
      enumeratedShiftTasks,
      shortIdsToEntityNamesDicts,
      customKeywordsUtilObj,
    ]
  );

  const columnDefs = useMemo(() => {
    const employeesColDefs = [
      {
        field: "name",
        sortable: true,
        editable: false,
        width: 150,
        pinned: "left",
      },
    ];

    if (showSkillsColumn && skills) {
      employeesColDefs.push({
        field: "skills",
        sortable: true,
        editable: false,
        width: 100,
        pinned: "left",
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, skills),
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "skills", skills),
      });
    }
    return [
      {
        headerName: "Employees",
        groupId: "employeesGroup",
        children: employeesColDefs,
      },
      {
        headerName: "Recurring",
        groupId: "dayGroup",
        children: recurringColumns.map((colName) => {
          const offset = getDayNumFromColFieldName(colName);
          const DoW = new DateTime(
            DateTime.getPreviousMonday(new DateTime(startDate))
          )
            .addDays(offset)
            .getDayOfWeek("dd");
          return {
            headerName: DoW,
            headerClass: (params) => {
              const dayOfWeek = params.colDef.headerName;
              if (dayOfWeek === "Sa" || dayOfWeek === "Su") {
                return "header";
              }
            },
            editable: true,
            cellStyle: (params) => ({
              ...getWeekendsCellStyle(params),
              ...getColorCodedCells(
                params,
                colorCodes,
                shiftGroups,
                shortIdsToEntityNamesDicts,
                customKeywordsUtilObj
              ),
            }),
            suppressMenu: true,
            width:
              DoW === "Sa" || DoW === "Su" ? weekendColWidth : weekdayColWidth,
            field: colName,
            suppressSizeToFit: true,
            cellClassRules: preferenceCellClassRules(
              recurringWarnings,
              "invalid DaysRecurring input"
            ),
            cellEditor: "dropdownSingleSelector",
            cellEditorParams: {
              suffix,
              width: 100,
              options: shiftOptions,
            },
            cellEditorPopup: true,
            valueFormatter: (params) =>
              preferencesValueFormatter(
                params,
                [KEYWORD_OFF],
                shifts,
                shiftGroups,
                tasks,
                subTasks,
                areas,
                skills
              ),
            valueSetter: (params) =>
              allocationDropdownOptionsValueSetter(
                params,
                flatOptions(shiftOptions)
              ),
            suppressKeyboardEvent: suppressEnterKey,
          };
        }),
      },
    ];
  }, [
    recurringColumns,
    recurringWarnings,
    shiftOptions,
    startDate,
    suffix,
    weekdayColWidth,
    weekendColWidth,
    shiftGroups,
    shifts,
    subTasks,
    areas,
    tasks,
    skills,
    showSkillsColumn,
    colorCodes,
    customKeywordsUtilObj,
    shortIdsToEntityNamesDicts,
  ]);

  const customActionBarComponents = useMemo(() => {
    const components = [];
    if (!isIndividualView) {
      components.push(() => (
        <ImportanceSelector
          changePrefLevel={changePrefLevel}
          isGlobal={isGlobal}
        />
      ));
    }
    if (isGlobal) {
      components.push(() => (
        <button
          className={styles.approveStrongBtn}
          onClick={() => {
            approveSpecialRequests();
          }}
        >
          Approve all pending special preferences
        </button>
      ));
    }

    if (!isGlobal) {
      components.push(() => (
        <ColorCodingButton onClick={toggleColorCodingModal} />
      ));
    }
    return components;
  }, [
    toggleColorCodingModal,
    approveSpecialRequests,
    changePrefLevel,
    isGlobal,
    isIndividualView,
  ]);

  return (
    <Layout
      title={title ? title : "Recurring Patterns"}
      isSubheading={true}
      headerRight={() => {
        if (isIndividualView) {
          return (
            <div className={styles.headerRightWrapper}>
              <ImportanceSelector
                changePrefLevel={changePrefLevel}
                isGlobal={isGlobal}
              />
            </div>
          );
        }
        return (
          <RangeSelector
            updateSelection={updateRecurringSelection}
            selection={recurringPeriodSelection}
          />
        );
      }}
    >
      <div className={styles.container}>
        {showColAdjuster && (
          <ActionBar
            locationID={locationID}
            searchBarSettings={{
              tableName: "preferencesRecurring",
              onFilterInputChanged,
            }}
            customComponents={customActionBarComponents}
            exportSettings={{
              exportToCsv,
              exportToExcel,
            }}
          />
        )}
        <DataEntryTable
          customStyle={customStyle ? customStyle.dataEntryTable : {}}
          columnHoverHighlight={true}
          columnDefs={columnDefs}
          rowData={employeesData}
          updateData={updateData}
          getContextMenuItems={getContextMenuItems}
          onCellKeyDown={(params) => {
            if (handleKeyDownForUndoRedo) {
              handleKeyDownForUndoRedo(params.event);
            }
          }}
          gridOptions={{
            onCellValueChanged: (params) => {
              if (triggerUndoRedoSnapshotCollection) {
                triggerUndoRedoSnapshotCollection(params);
              }
            },
            suppressMultiRangeSelection: true,
          }}
          setGridApiToParent={setGridApiToParent}
          setColumnApiToParent={setColumnApiToParent}
          components={{
            dropdownSingleSelector: DropdownSingleSelector,
          }}
          overlayNoRowsTemplate={
            isGlobal ? NoGlobalEmployeesOverlay : NoEmployeesOverlay
          }
          tableName="preferences-recurring"
          processDataFromClipboard={(params) =>
            processAllocationsFromClipboard(
              params,
              shouldInsertOffOnPasteBlank,
              employeeNames,
              true
            )
          }
          context={{
            options: flatOptions(shiftOptions),
          }}
          getDataFromGrid={getDataFromGrid}
          processCellForClipboard={processPreferencesCellForClipboard}
          doesExternalFilterPass={doesAreaFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
        />
        {isGlobal ? (
          <div className={styles.solutionKey}>
            <div className={styles.specialKey}>
              Special preferences requiring approval
            </div>
          </div>
        ) : null}

        <p className={styles["saving-line"]}>
          {isSaving ? "saving..." : "saved"}
        </p>
      </div>
      {isWarningPresent && (
        <div className={styles["warning-wrapper"]}>
          <WarningDisplay
            title="Following issues were found:"
            displayedWarnings={getDisplayedWarningsInfo(recurringWarnings)}
            bottomText={
              "Please change the allocated fixed shift/ tasks or if they are correct, change the restrictions on the employee page."
            }
          />
        </div>
      )}
    </Layout>
  );
};

export default PreferencesRecurringGrid;
