import styles from "./SolutionGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import { useSolutionsColumnWidthStore } from "../../../../../globalStore/columnWidthStore";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import GoToRosterButton from "../GoToRosterButton/GoToRosterButton";
import { useMemo, useState } from "react";
import {
  getColorCodedCells,
  getDemandMinMaxCellStyles,
  getWeekendsCellStyle,
} from "../../../rosteredAllocations/service/styleGetters";
import {
  createBasicContextMenu,
  DateTime,
  getDatesColumnDefs,
  getDayNumFromColFieldName,
  getMergedOverviewAndRecurring,
  getMyRosterRowStyle,
  onFilterTextBoxChanged,
  suppressDeleteAndBackspaceKey,
} from "../../../../../utils";
import { preferenceOrFixedShiftMet } from "../../../service/preferencesAndFixedShifts";
import GetStartedButton from "../../../../../components/elements/GetStartedButton/GetStartedButton";
import WhyNotThisAllocationModal from "../WhyNotThisAllocation/WhyNotThisAllocation";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import CompareSolutionsToggle from "../CompareSolutionsToggle/CompareSolutionsToggle";
import { ShiftViewSwitcher } from "../../../../scheduleView/components/ScheduleRosterDataContainer/ScheduleRosterDataContainerUtils";
import RosterTableShiftView from "../../../rosteredAllocations/components/RosterTableShiftView/RosterTableShiftView";
import RerosteringToolTip from "../../../../../components/elements/RerosteringToolTip/RerosteringToolTip";
import { buildNamesToEntityShortIdsDicts } from "../../../service/rosterUtils";
import { convertAllocationInNameFormToShortIdForm } from "../../../../../utils/modelUtils/allocation";
import { KEYWORD_OFF } from "../../../../../constants/keywords";
import { frontendSettingsToSolverSettings } from "../../../../rosterGenerator/service/generateRoster";
import {
  getDayShiftCounts,
  getDayShiftHours,
  getShiftAndSkillCountsPerColumn,
  getShiftGroupHoursPerColumn,
} from "../../../../statistics/service/statsValueGetter";

const SolutionGrid = ({
  locationID,
  selectableSolutionExists,
  startDate,
  solutionData,
  failedEmployeeData,
  employees,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  subTasks,
  areas,
  skills,
  setGridApiToParent,
  solutionColumns,
  gridApi,
  getSolutionsList,
  getDeleteSolutionBtn,
  getPrintSolutionBtns,
  longestStr,
  selectedSolutionData,
  colorCodes,
  sendSolutionToMyRoster,
  exportToCsv,
  exportToExcel,
  customTopComponent = null,
  isScheduleView,
  roster,
  customKeywordsData,
  comparisonData,
  isShiftView,
  toggleShiftView,
  rules,
  location,
  periodStartDate,
  periodFinishDate,
  isMainStream,
  doesAreaFilterPass = null,
  isExternalFilterPresent = null,
  shortIdsToEntityNamesDicts,
  rosterName,
  pinnedBottomRowDataTemplate,
  employeesShiftHours,
  customKeywordsUtilObj,
  totalShiftsColGroups,
  totalShiftHoursColGroups,
  weekendsOffCountColDefs,
  preferencesCountColDefs,
  leaveCountColDefs,
  shiftDemands,
}) => {
  const { reservedShiftKeywords } = customKeywordsUtilObj;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const namesToEntityShortIdsDicts = buildNamesToEntityShortIdsDicts(
    areas,
    shifts,
    shiftGroups,
    tasks,
    subTasks,
    skills
  );

  const shiftViewFormattedEmployees = useMemo(() => {
    return solutionData.map((empData) => {
      const employee = employees.find((emp) => emp.id === empData.id);
      const publishedAllocationFormatData = [];
      const rosteredAllocationFormatData = [];
      for (let i = 0; i < roster.numDays; i++) {
        const allocation = empData[`d${i + 1}`];
        rosteredAllocationFormatData.push(allocation);
        publishedAllocationFormatData.push({
          date: new DateTime(location.startDate).addDays(i).toFormat("AWS"),
          publishedAllocation: allocation,
        });
      }

      return {
        ...employee,
        RosteredAllocations: rosteredAllocationFormatData,
        PublishedAllocations: publishedAllocationFormatData,
      };
    });
  }, [employees, solutionData, roster, location]);

  const statisticsColumnDefs = useMemo(() => {
    const colDefs = [];

    if (totalShiftsColGroups) {
      colDefs.push(totalShiftsColGroups);
    }

    if (totalShiftHoursColGroups) {
      colDefs.push(totalShiftHoursColGroups);
    }

    if (leaveCountColDefs) {
      colDefs.push(leaveCountColDefs);
    }

    if (weekendsOffCountColDefs) {
      colDefs.push(weekendsOffCountColDefs);
    }

    if (preferencesCountColDefs) {
      colDefs.push(preferencesCountColDefs);
    }

    return colDefs;
  }, [
    totalShiftsColGroups,
    totalShiftHoursColGroups,
    leaveCountColDefs,
    weekendsOffCountColDefs,
    preferencesCountColDefs,
  ]);

  const {
    weekdayColWidth,
    weekendColWidth,
    isWDayWEndSeparate,
    setWeekdayColWidth,
    setWeekendColWidth,
    setIsWDayWEndSeparate,
  } = useSolutionsColumnWidthStore();

  const getContextMenuItems = () => {
    return createBasicContextMenu();
  };

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const mergedFixedShifts = useMemo(
    () =>
      employees.map((employee) =>
        getMergedOverviewAndRecurring(
          employee["Allocations"],
          employee["AllocationsRecurring"],
          location.startDate,
          startDate,
          location.defaultNumDays
        )
      ),
    [employees, location, startDate]
  );

  const mergedPreferences = useMemo(
    () =>
      employees.map((employee) =>
        getMergedOverviewAndRecurring(
          employee["Days"],
          employee["DaysRecurring"],
          location.startDate,
          startDate,
          location.defaultNumDays
        )
      ),
    [employees, location, startDate]
  );

  const datesColumnDefs = useMemo(() => {
    let datesColumnDefs = getDatesColumnDefs(solutionColumns.length, startDate);
    const customDatesColumnDefs = datesColumnDefs.map(
      (weekColDefs, weekGroupIdx) => {
        const customWeekColDefs = weekColDefs.children.map(
          (dayColDefs, dayGroupIdx) => {
            const customDayColDefs = dayColDefs.children.map((colDef) => {
              const offset = getDayNumFromColFieldName(colDef.field);
              const DoW = new DateTime(startDate)
                .addDays(offset)
                .getDayOfWeek("dd");

              return {
                ...colDef,
                editable: false,
                cellClassRules: {
                  "met-fixed-shift": (params) => {
                    if (
                      params.node.rowPinned === "bottom" ||
                      params.data.id === "reserved_header"
                    )
                      return false;
                    const employee = employees.filter(
                      (emp) => emp.id === params.data.id
                    );

                    const d = parseInt(params.colDef.field.substring(1)) - 1;

                    const solutionFailed =
                      Object.entries(params.data).filter((val) =>
                        val[1].endsWith("*")
                      ).length > 0;

                    if (params.value === undefined) {
                      return false;
                    }

                    const shortId = convertAllocationInNameFormToShortIdForm(
                      params.value,
                      namesToEntityShortIdsDicts
                    );

                    return (
                      employee.length > 0 &&
                      !solutionFailed &&
                      preferenceOrFixedShiftMet(
                        mergedFixedShifts[employees.indexOf(employee[0])],
                        d,
                        shortId ? shortId : params.value,
                        areas,
                        shifts,
                        shiftGroups,
                        tasks,
                        taskBlocks,
                        employee[0].skills,
                        shortIdsToEntityNamesDicts,
                        customKeywordsUtilObj
                      )
                    );
                  },
                  "met-preference": (params) => {
                    if (
                      params.node.rowPinned === "bottom" ||
                      params.data.id === "reserved_header"
                    )
                      return false;
                    const employee = employees.filter(
                      (emp) => emp.id === params.data.id
                    );

                    const d = parseInt(params.colDef.field.substring(1)) - 1;

                    const notRelevant = ["name", "skills", "id"];
                    const filtered = Object.keys(params.data)
                      .filter((key) => !notRelevant.includes(key))
                      .reduce((obj, key) => {
                        obj[key] = params.data[key];
                        return obj;
                      }, {});

                    const solutionFailed =
                      Object.entries(filtered).filter((val) =>
                        val[1].endsWith("*")
                      ).length > 0;

                    if (params.value === undefined) {
                      return false;
                    }

                    return (
                      employee.length > 0 &&
                      !solutionFailed &&
                      preferenceOrFixedShiftMet(
                        mergedPreferences[employees.indexOf(employee[0])],
                        d,
                        params.value,
                        areas,
                        shifts,
                        shiftGroups,
                        tasks,
                        taskBlocks,
                        employee[0].skills,
                        shortIdsToEntityNamesDicts,
                        customKeywordsUtilObj
                      )
                    );
                  },
                  "failed-shift": (params) => {
                    if (
                      params.node.rowPinned === "bottom" ||
                      params.data.id === "reserved_header"
                    )
                      return false;
                    return params.value && params.value.endsWith("*");
                  },
                  "reroster-change": (params) => {
                    if (
                      params.node.rowPinned === "bottom" ||
                      params.data.id === "reserved_header"
                    )
                      return false;
                    if (!comparisonData) return false;

                    const employeeDifferences = comparisonData.filter(
                      (emp) => emp.id === params.data.id
                    )[0];

                    if (!employeeDifferences) return false;

                    const d = parseInt(params.colDef.field.substring(1)) - 1;

                    return employeeDifferences.days[d].isDifferent;
                  },
                },
                cellStyle: (params) => {
                  let style = getWeekendsCellStyle(params);
                  if (params.node.rowPinned === "bottom") {
                    return { ...style, ...getDemandMinMaxCellStyles(params) };
                  }
                  if (params.data.id === "reserved_header") {
                    return {};
                  }
                  const colorCodeParams = { ...params };
                  colorCodeParams.value =
                    convertAllocationInNameFormToShortIdForm(
                      params.value,
                      namesToEntityShortIdsDicts
                    ) || params.value;

                  style = {
                    ...style,
                    ...getColorCodedCells(
                      colorCodeParams,
                      colorCodes,
                      shiftGroups,
                      shortIdsToEntityNamesDicts,
                      customKeywordsUtilObj
                    ),
                  };
                  return style;
                },
                tooltipComponent: "tooltipComponent",
                tooltipComponentParams: {
                  comparisonData,
                },
                tooltipValueGetter: (params) =>
                  params.value ? params.value : KEYWORD_OFF,
                suppressMenu: true,
                suppressKeyboardEvent: suppressDeleteAndBackspaceKey,
                width:
                  DoW === "Sa" || DoW === "Su"
                    ? weekendColWidth
                    : weekdayColWidth,
                valueGetter: (params) => {
                  const originalVal = params.data[params.colDef.field];
                  if (
                    params.node.rowPinned === "bottom" &&
                    !params.data.id.startsWith("reserved")
                  ) {
                    if (params.data.id === "totalDayShiftCounts") {
                      return getDayShiftCounts(params, reservedShiftKeywords);
                    }
                    if (params.data.id === "totalDayShiftHours") {
                      return getDayShiftHours(
                        params,
                        reservedShiftKeywords,
                        employeesShiftHours,
                        shortIdsToEntityNamesDicts,
                        namesToEntityShortIdsDicts,
                        false
                      );
                    }
                    if (params.data.id.startsWith("shift-hours-")) {
                      return getShiftGroupHoursPerColumn(
                        params,
                        shiftGroups,
                        reservedShiftKeywords,
                        employeesShiftHours,
                        shortIdsToEntityNamesDicts,
                        namesToEntityShortIdsDicts,
                        false,
                        customKeywordsUtilObj
                      );
                    }
                    //shift-counts- or skill-counts or shift-skill-counts
                    return getShiftAndSkillCountsPerColumn(
                      params,
                      shortIdsToEntityNamesDicts,
                      namesToEntityShortIdsDicts,
                      false,
                      customKeywordsUtilObj
                    );
                  }
                  return originalVal;
                },
              };
            });
            return {
              ...dayColDefs,
              groupId: `dayGroupIdx_${weekGroupIdx}_${dayGroupIdx}`,
              children: customDayColDefs,
            };
          }
        );
        return {
          ...weekColDefs,
          groupId: `weekColGroup${weekGroupIdx}`,
          children: customWeekColDefs,
        };
      }
    );
    return customDatesColumnDefs;
  }, [
    employees,
    areas,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    colorCodes,
    solutionColumns.length,
    startDate,
    weekdayColWidth,
    weekendColWidth,
    comparisonData,
    mergedFixedShifts,
    mergedPreferences,
    namesToEntityShortIdsDicts,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj,
    employeesShiftHours,
    reservedShiftKeywords,
  ]);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Employees",
        groupId: "employeeGroup",
        children: [
          {
            field: "name",
            sortable: true,
            editable: false,
            width: 150,
            pinned: "left",
          },
        ],
      },
      ...datesColumnDefs,
      ...statisticsColumnDefs,
    ],
    [datesColumnDefs, statisticsColumnDefs]
  );

  const customMoreOptionComponents = getPrintSolutionBtns();

  const rerosterSolution = () =>
    comparisonData && <CompareSolutionsToggle allowSwitch={false} />;

  const getPageContent = () => {
    if (!selectableSolutionExists) {
      return (
        <div className={styles.noSolutionsContainer}>
          Please press &quot;Generate Roster&quot; to build a roster
        </div>
      );
    }
    if (isShiftView) {
      return (
        <RosterTableShiftView
          locationID={null}
          rosterID={null}
          isScheduleView={isScheduleView}
          demands={selectedSolutionData.demands}
          employees={shiftViewFormattedEmployees}
          numDays={roster.numDays}
          areas={areas}
          shifts={shifts}
          shiftGroups={shiftGroups}
          tasks={tasks}
          taskBlocks={taskBlocks}
          rules={rules}
          updateRosterTable={() => {}}
          startDate={startDate}
          locationStartDate={location.startDate}
          customKeywordsDict={customKeywordsUtilObj}
          predefinedShiftOptions={customKeywordsUtilObj.predefinedShiftOptions}
          toggleColorCodingModal={() => {}}
          colorCodes={colorCodes}
          readOnly={true}
          hiddenRows={[]}
          setHiddenShiftViewRows={() => {}}
          shouldDefaultViewIncludeToday={false}
          shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        />
      );
    }
    return (
      <>
        <ActionBar
          locationID={locationID}
          searchBarSettings={{
            tableName: "solutions",
            onFilterInputChanged,
          }}
          adjustWidthSettings={{
            longestStr,
            weekdayColWidth,
            weekendColWidth,
            isWDayWEndSeparate,
            setWeekdayColWidth,
            setWeekendColWidth,
            setIsWDayWEndSeparate,
            tableName: "solutions",
          }}
          customComponents={[
            getSolutionsList(),
            getDeleteSolutionBtn(),
            rerosterSolution,
          ]}
          exportSettings={{
            exportToCsv,
            exportToExcel,
          }}
          customMoreOptionComponents={
            customMoreOptionComponents ? customMoreOptionComponents : []
          }
        />
        <DataEntryTable
          customStyle={{
            height: `${
              window.innerHeight - 320 + pinnedBottomRowDataTemplate.length * 28
            }px`,
          }}
          columnDefs={columnDefs}
          rowData={solutionData}
          columnHoverHighlight={true}
          getContextMenuItems={getContextMenuItems}
          setGridApiToParent={setGridApiToParent}
          tooltipShowDelay={0}
          tooltipHideDelay={null}
          components={{
            tooltipComponent: RerosteringToolTip,
          }}
          defaultColDef={{
            colSpan: (params) => {
              if (
                params.data.id === "reserved_header" &&
                params.data["d1"] === "Total Counts of shifts by day"
              ) {
                return roster.numDays;
              }
              return 1;
            },
          }}
          tableName="solutions"
          doesExternalFilterPass={doesAreaFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
          pinnedBottomRowData={pinnedBottomRowDataTemplate}
          context={{
            shifts,
            shiftGroups,
            tasks,
            taskBlocks,
            skills,
            shiftDemands,
          }}
          getRowStyle={getMyRosterRowStyle}
        />
        <div className={styles.solutionKey}>
          {failedEmployeeData &&
          Object.keys(failedEmployeeData.issues).length > 0 ? (
            <div className={styles.failedShiftsKey}>
              Fixed shifts that can&apos;t be satisfied
            </div>
          ) : (
            <>
              <div className={styles.fixedShiftsKey}>
                Fulfilled Fixed Shifts
              </div>
              <div className={styles.preferencesKey}>Fulfilled Preferences</div>
            </>
          )}
        </div>
        {selectedSolutionData.comments && (
          <div>{selectedSolutionData.comments}</div>
        )}
      </>
    );
  };

  return (
    <Layout
      title={
        isScheduleView ? "Roster solution" : `Roster solution - ${rosterName}`
      }
      headerNext={() => (
        <GetStartedButton
          url={"https://help.rosterlab.com/ai-generate-solutions"}
        />
      )}
      headerRight={
        isScheduleView || !selectableSolutionExists
          ? null
          : () => (
              <GoToRosterButton
                selectedSolutionData={selectedSolutionData}
                employees={employees}
                handleSendSolution={sendSolutionToMyRoster}
              />
            )
      }
    >
      {modalIsOpen && (
        <WhyNotThisAllocationModal
          show={modalIsOpen}
          handleClose={() => setModalIsOpen(false)}
          employees={employees}
          shifts={shifts}
          tasks={tasks}
          taskBlocks={taskBlocks}
          numDays={roster.numDays}
          roster={roster}
          customKeywordsData={customKeywordsData}
          shiftGroups={shiftGroups}
          locationSettings={[
            ...location.settings,
            ...frontendSettingsToSolverSettings(location.frontendSettings),
          ]}
          areas={areas}
        />
      )}
      <div className={styles.container}>
        <div className={styles.topLine}>
          <div className={styles.left}>
            {customTopComponent}
            {selectableSolutionExists &&
              isScheduleView &&
              false && ( // I'm removing this as an option until it's fixed
                <ShiftViewSwitcher
                  isShiftView={isShiftView}
                  setShowShiftView={toggleShiftView}
                  updateDefaultPageView={false}
                />
              )}
          </div>
          {selectableSolutionExists && (
            <div className={styles.right}>
              <>
                {!isShiftView ? (
                  <BasicButton
                    color="#219ec9"
                    hoverColor="#1f91b7"
                    customStyle={{
                      borderRadius: "10px",
                    }}
                    onClick={() => setModalIsOpen(true)}
                  >
                    Why not this allocation?
                  </BasicButton>
                ) : (
                  <></>
                )}
                {!isShiftView && isScheduleView && (
                  <>
                    <GoToRosterButton
                      selectedSolutionData={selectedSolutionData}
                      employees={employees}
                      handleSendSolution={sendSolutionToMyRoster}
                      periodStartDate={periodStartDate}
                      periodFinishDate={periodFinishDate}
                      isMainStream={isMainStream}
                    />
                  </>
                )}
              </>
            </div>
          )}
        </div>
        {getPageContent()}
      </div>
    </Layout>
  );
};

export default SolutionGrid;
