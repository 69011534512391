import { DateTime } from "../../utils";
import { orderGlobalEmployees } from "../../features/locations/service/locationsUtil";
import {
  getCustomKeywordsDataFromLocation,
  interpretCustomKeywordsData,
} from "../../utils/queryUtils/locationDataGetters";
import { getActualNumDays } from "../../utils/queryUtils/monthViewUtils";
import {
  convertGlobalEmployeeToRosterInViewEmployee,
  getGlobalEmployeesWithinPeriod,
} from "../../utils/queryUtils/globalEmployeeDataGetters";
import { RosterDoesNotExistError } from "../../errors/errors";

export const getRosterInView = (location, globalEmployees, roster) => {
  if (!roster) {
    throw new RosterDoesNotExistError("Roster does not exist");
  }

  const isSnapshot = roster.isSnapshot;
  const rosterInView = isSnapshot
    ? createSnapshotRosterInView(location, roster)
    : createMainStreamRosterInView(location, roster, globalEmployees);
  return rosterInView;
};

const createMainStreamRosterInView = (location, roster, globalEmployees) => {
  const periodStartDate = roster.startDate;
  const numDays = getActualNumDays(
    periodStartDate,
    location.defaultNumDays || roster.numDays
  );
  const periodFinishDate = DateTime.addDaysToDate(
    periodStartDate,
    numDays - 1
  ).toFormat("AWS");

  const allDatesInSchedule = DateTime.getAllDateStringsBetween(
    periodStartDate,
    periodFinishDate
  );

  const { leaveCodes } = interpretCustomKeywordsData(
    getCustomKeywordsDataFromLocation(location)
  );

  const order = location.order;

  const orderedGlobalEmployees = orderGlobalEmployees(globalEmployees, order);
  const globalEmployeesWithinPeriod = getGlobalEmployeesWithinPeriod(
    orderedGlobalEmployees,
    periodStartDate,
    periodFinishDate
  );

  const employees = globalEmployeesWithinPeriod
    .map((globalEmployee) => {
      const rosterEmployee = roster.Employees.find(
        (rEmp) =>
          rEmp.id === globalEmployee.id ||
          rEmp.globalEmployeeID === globalEmployee.id
      );

      return convertGlobalEmployeeToRosterInViewEmployee(
        numDays,
        globalEmployee,
        rosterEmployee,
        allDatesInSchedule,
        leaveCodes
      );
    })
    .filter((employee) => employee !== null);

  return {
    ColorCodes: location.ColorCodes,
    CustomRules: location.CustomRules,
    Demands: location.Demands,
    Employees: employees,
    frontendSettings: location.frontendSettings,
    OpenShifts: location.OpenShifts,
    ShiftGroups: location.ShiftGroups,
    Shifts: location.Shifts,
    Skills: location.Skills,
    Solutions: roster.Solutions,
    Statistics: location.Statistics,
    TaskBlocks: location.TaskBlocks,
    Tasks: location.Tasks,
    Areas: location.Areas,
    completedOnboardingTasks: location.completedOnboardingTasks,
    id: roster.id,
    isPublished: roster.isPublished,
    isScheduleView: true,
    isSnapshot: false,
    locationID: location.id,
    name: null,
    numDays,
    order,
    plan: location.plan,
    settings: location.settings,
    shiftViewHiddenRows: location.shiftViewHiddenRows,
    startDate: periodStartDate,
  };
};

const createSnapshotRosterInView = (location, roster) => {
  const employees = roster.Employees.map((rosterEmployee) => {
    return {
      Allocations: rosterEmployee.Allocations,
      AllocationsRecurring: rosterEmployee.AllocationsRecurring,
      Days: rosterEmployee.Days,
      DaysRecurring: rosterEmployee.DaysRecurring,
      FTE: null,
      History: rosterEmployee.History,
      Preferences: null,
      PreferencesRecurring: null,
      PublishedAllocations: null,
      Requests: null,
      RosteredAllocations: rosterEmployee.RosteredAllocations,
      RuleValues: rosterEmployee.RuleValues,
      TimeEntries: null,
      email: null,
      externalID: rosterEmployee.externalID,
      finishDate: null,
      id: rosterEmployee.id,
      locationID: location.id,
      locationName: location.name,
      name: rosterEmployee.name,
      registrations: null,
      salary: null,
      shifts: rosterEmployee.shifts,
      skills: rosterEmployee.skills,
      startDate: null,
      areas: rosterEmployee.areas || "",
    };
  });
  return {
    ColorCodes: roster.ColorCodes,
    CustomRules: roster.CustomRules,
    Demands: roster.Demands,
    Employees: employees,
    frontendSettings: location.frontendSettings,
    OpenShifts: null,
    ShiftGroups: roster.ShiftGroups,
    Shifts: roster.Shifts,
    Skills: roster.Skills,
    Solutions: roster.Solutions,
    Statistics: roster.Statistics,
    TaskBlocks: roster.TaskBlocks,
    Tasks: roster.Tasks,
    Areas: roster.Areas,
    completedOnboardingTasks: null,
    id: roster.id,
    isPublished: roster.isPublished,
    isScheduleView: true,
    isSnapshot: true,
    locationID: location.id,
    name: roster.name,
    numDays: roster.numDays,
    order: null,
    plan: location.plan,
    settings: location.settings,
    shiftViewHiddenRows: location.shiftViewHiddenRows,
    startDate: roster.startDate,
  };
};
