import { useEffect, useMemo, useState } from "react";
import {
  DateTime,
  changePrefImportanceLevel,
  getAllPreferencesFromGrid,
  getDayColumns,
  getRecurringColumns,
  getRecurringPeriodLengthSelection,
  parseEmployeeModelToPreferencesGridFormat,
  removePendingSuffixedPreferencesFromEmployees,
} from "../../../../../utils";
import { hideColumnInGrid } from "../../../service/singleEmployeeViewUtils";
import IndPreferencesOverviewContainer from "../IndPreferencesOverviewContainer/IndPreferencesOverviewContainer";
import IndPreferencesRecurringContainer from "../IndPreferencesRecurringContainer/IndPreferencesRecurringContainer";

const IndPreferencesWrapper = ({
  selectedEmployee,
  numDays,
  enumeratedTasks,
  enumeratedShiftTasks,
  startDate,
  isSaving,
  warnings,
  isMainStream,
  customKeywordsUtilObj,
  updateEmployeeWithFields,
  locationID,
  location,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  areas,
  skills,
  isRoster,
  periodStartDate,
  periodFinishDate,
  shortIdsToEntityNamesDicts,
  colorCodes,
}) => {
  const [overviewGridApi, setOverviewGridApi] = useState(null);
  const [overviewColumnApi, setOverviewColumnApi] = useState(null);
  const [recurringGridApi, setRecurringGridApi] = useState(null);
  const [recurringColumnApi, setRecurringColumnApi] = useState(null);

  useEffect(() => {
    if (overviewColumnApi && recurringColumnApi) {
      hideColumnInGrid([overviewColumnApi, recurringColumnApi], ["name"]);
    }
  }, [overviewColumnApi, recurringColumnApi]);

  const employeesData = useMemo(() => {
    return parseEmployeeModelToPreferencesGridFormat(
      [selectedEmployee],
      numDays,
      isMainStream
    );
  }, [selectedEmployee, numDays, isMainStream]);

  const dayColumns = getDayColumns(numDays);

  const recurringPeriodSelection =
    getRecurringPeriodLengthSelection(employeesData);

  const recurringColumns = getRecurringColumns(
    recurringPeriodSelection === "week" ? 7 : 14
  );

  const updatePreferencesOverview = async (newEmployees) => {
    const updatedField = isMainStream ? "Preferences" : "Days";
    if (isRoster) {
      const newEmployeesWithoutPendingPreferences =
        removePendingSuffixedPreferencesFromEmployees(newEmployees);

      updateEmployeeWithFields(newEmployeesWithoutPendingPreferences[0], [
        updatedField,
      ]);
    } else {
      const updatedEmployees = newEmployees.map((employee) => {
        if (!isMainStream) {
          const Days = employee.Days;
          return {
            ...employee,
            Days,
          };
        }

        const existingGlobalPreferences = selectedEmployee.Preferences;
        const updatedPreferencesWithinRange = [];
        for (const idx in employee.Days) {
          const allocation = employee.Days[idx];
          if (!allocation) {
            continue;
          }
          const date = new DateTime(periodStartDate)
            .addDays(Number(idx))
            .toFormat("AWS");
          updatedPreferencesWithinRange.push({
            date,
            allocation,
          });
        }
        const preferencesBeforeCurrentPeriod = existingGlobalPreferences.filter(
          ({ date }) => new DateTime(date).isBefore(periodStartDate)
        );
        const preferencesAfterCurrentPeriod = existingGlobalPreferences.filter(
          ({ date }) => new DateTime(date).isAfter(periodFinishDate)
        );
        const updatedGlobalPreferences = [
          ...preferencesBeforeCurrentPeriod,
          ...updatedPreferencesWithinRange,
          ...preferencesAfterCurrentPeriod,
        ];
        return {
          id: employee.id,
          name: employee.name,
          Preferences: updatedGlobalPreferences,
        };
      });

      updateEmployeeWithFields(updatedEmployees[0], [updatedField]);
    }
  };

  const updatePreferencesRecurring = async (newEmployees) => {
    const updatedField = isMainStream
      ? "PreferencesRecurring"
      : "DaysRecurring";

    if (isRoster) {
      const newEmployeesWithoutPendingPreferences =
        removePendingSuffixedPreferencesFromEmployees(newEmployees);

      updateEmployeeWithFields(newEmployeesWithoutPendingPreferences[0], [
        updatedField,
      ]);
    } else {
      const updatedEmployees = newEmployees.map((employee) => {
        if (!isMainStream) {
          return {
            ...employee,
            DaysRecurring: employee.DaysRecurring,
          };
        }
        return {
          id: employee.id,
          name: employee.name,
          PreferencesRecurring: employee.DaysRecurring,
        };
      });

      const updatedField = isMainStream
        ? "PreferencesRecurring"
        : "DaysRecurring";
      updateEmployeeWithFields(updatedEmployees[0], [updatedField]);
    }
  };

  const getDataFromGrid = (gridApi) => {
    let employeesInGrid;
    try {
      employeesInGrid = getAllPreferencesFromGrid(
        gridApi,
        dayColumns,
        recurringColumns
      );
    } catch (error) {
      console.error(error);
      return null;
    }
    return employeesInGrid;
  };

  const changePrefLevel = async (level, isOverview) => {
    if (isOverview) {
      changePrefImportanceLevel(overviewGridApi, level);
      const newEmployees = getDataFromGrid(overviewGridApi);
      await updatePreferencesOverview(newEmployees);
      return;
    }
    changePrefImportanceLevel(recurringGridApi, level);
    const newEmployees = getDataFromGrid(overviewGridApi);
    await updatePreferencesRecurring(newEmployees);
  };

  const finishDateDate = new DateTime(startDate).getDate();
  finishDateDate.setDate(finishDateDate.getDate() + numDays - 1);

  return (
    <>
      <IndPreferencesOverviewContainer
        startDate={startDate}
        enumeratedTasks={enumeratedTasks}
        enumeratedShiftTasks={enumeratedShiftTasks}
        employeesData={employeesData}
        dayColumns={dayColumns}
        updateData={updatePreferencesOverview}
        setGridApiToParent={setOverviewGridApi}
        setColumnApiToParent={setOverviewColumnApi}
        isSaving={isSaving}
        warnings={warnings}
        changePrefLevel={(level) => changePrefLevel(level, true)}
        locationID={locationID}
        getDataFromGrid={getDataFromGrid}
        shifts={shifts}
        shiftGroups={shiftGroups}
        tasks={tasks}
        subTasks={subTasks}
        areas={areas}
        skills={skills}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        customKeywordsUtilObj={customKeywordsUtilObj}
        colorCodes={colorCodes}
      />
      <IndPreferencesRecurringContainer
        startDate={startDate}
        location={location}
        numDays={numDays}
        enumeratedTasks={enumeratedTasks}
        enumeratedShiftTasks={enumeratedShiftTasks}
        isSaving={isSaving}
        employeesData={employeesData}
        recurringColumns={recurringColumns}
        updateData={updatePreferencesRecurring}
        setGridApiToParent={setRecurringGridApi}
        warnings={warnings}
        setColumnApiToParent={setRecurringColumnApi}
        changePrefLevel={(level) => changePrefLevel(level, false)}
        locationID={locationID}
        getDataFromGrid={getDataFromGrid}
        shifts={shifts}
        shiftGroups={shiftGroups}
        tasks={tasks}
        subTasks={subTasks}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        areas={areas}
        skills={skills}
        customKeywordsUtilObj={customKeywordsUtilObj}
        colorCodes={colorCodes}
      />
    </>
  );
};

export default IndPreferencesWrapper;
