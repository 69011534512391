import { useCallback, useMemo, useState } from "react";
import {
  getAllRulesFromGrid,
  parseRuleModelToRulesGridFormat,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import RulesGrid from "../../../rosterProblems/components/Rules/RulesGrid/RulesGrid";
import { gatherRulesData, reformatRules } from "../../../rules/service/rules";
import { getRuleNamesFromCustomRules } from "../../../rules/service/ruleFunctions";
import {
  getEmployeesAndCustomRulesAfterRulesDelete,
  getEmployeesAndCustomRulesAfterRulesUpdate,
} from "../../../../utils/queryUtils/sharedModelDataGetters";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import TableSchedulePeriodSwitcher from "../TableSchedulePeriodSwitcher/TableSchedulePeriodSwitcher";
import { customWarningAlert } from "../../../confirm/service/confirm";
import { checkRulesWithExceptionEnabled } from "../../../locations/service/globalSettings";
import { useWarningsStore } from "../../../../globalStore/warningsStore";
import { useUserStore } from "../../../../globalStore/appStore";
import { PLAN } from "../../../auth/service/auth";
import NotAccessibleView from "../../../../components/elements/NotAccessibleView/NotAccessibleView";

const ScheduleRulesDataContainer = ({
  location,
  locationID,
  periodStartDate,
  periodFinishDate,
  periodNum,
  isRoster,
  rosterID,
  globalEmployees,
}) => {
  const { plan } = useUserStore();
  const {
    gridApi,
    setGridApi,
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    createRosterModelForMainStreamInDifferentPeriod,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });

  const { warnings } = useWarningsStore();

  const [showRulesTemplateModal, setShowRulesTemplateModal] = useState(false);
  const [selectedRulesTemplate, setSelectedRulesTemplate] = useState("Custom");
  const [initialRulesInBuilder, setInitialRulesInBuilder] = useState(null);

  const clearDefaultNewRules = useCallback(() => {
    setInitialRulesInBuilder(null);
  }, []);

  const toggleRulesTemplateModal = () =>
    setShowRulesTemplateModal((prev) => !prev);

  const {
    name,
    employees,
    rules,
    shifts,
    shiftGroups,
    tasks,
    frontendSettings,
    numDays,
    isSnapshot,
  } = fields;

  const isMainStream = !isRoster && !isSnapshot;

  const [columnApi, setColumnApi] = useState();

  const isRulesWithExceptionEnabled = useMemo(() => {
    return checkRulesWithExceptionEnabled(frontendSettings);
  }, [frontendSettings]);

  const rulesData = useMemo(
    () => parseRuleModelToRulesGridFormat(employees, rules, false),
    [employees, rules]
  );

  const ruleWarnings = useMemo(() => {
    return warnings ? warnings.CustomRules : null;
  }, [warnings]);

  const updateCustomRules = async (ruleData, updatedRuleData) => {
    const updatedRules = [...updatedRuleData, ...ruleData];
    const { updatedEmployees, updatedCustomRules } =
      getEmployeesAndCustomRulesAfterRulesUpdate(
        employees,
        updatedRules,
        false
      );

    const updatedRoster = {
      ...roster,
      Employees: updatedEmployees,
      CustomRules: updatedCustomRules,
    };

    updateFields(["Employees", "CustomRules"], updatedRoster, roster, [
      "RuleValues",
    ]);
  };

  const updateRules = async (newEmployees) => {
    const updatedEmployees = employees.map((employee) => {
      const updatedEmployee = newEmployees.find(
        (emp) => emp.id === employee.id
      );
      if (!updatedEmployee) {
        return employee;
      }
      return {
        ...employee,
        RuleValues: updatedEmployee.RuleValues,
      };
    });

    const updatedRoster = { ...roster, Employees: updatedEmployees };
    updateFields(["Employees"], updatedRoster, roster, ["RuleValues"]);
  };

  const removeRuleColumn = async (ruleName) => {
    const response = await customWarningAlert({
      title: "Removing rule",
      descriptions: ["Are you sure to remove '" + ruleName + "' rule?"],
      okLabel: "Continue",
      cancelLabel: "Cancel",
      showCancelBtn: true,
    });
    if (!response) {
      return;
    }
    const { updatedEmployees, updatedCustomRules } =
      getEmployeesAndCustomRulesAfterRulesDelete(
        employees,
        rules,
        ruleName,
        false
      );

    const updatedRoster = {
      ...roster,
      Employees: updatedEmployees,
      CustomRules: updatedCustomRules,
    };
    updateFields(["Employees", "CustomRules"], updatedRoster, roster, [
      "RuleValues",
    ]);
  };

  const ruleNames = useMemo(
    () => getRuleNamesFromCustomRules(rules, shifts, shiftGroups, tasks),
    [rules, shifts, shiftGroups, tasks]
  );

  const allRulesData = gatherRulesData({
    id: roster.id,
    Shifts: shifts,
    ShiftGroups: shiftGroups,
    Tasks: tasks,
  });

  const ruleColNameDescriptionPairs = useMemo(() => {
    return reformatRules(allRulesData).map((item) => ({
      ruleColName: item.subruleName,
      description: item.description,
      valueType: item.valueType,
    }));
  }, [allRulesData]);

  const getDataFromGrid = (gridApi) => {
    return getAllRulesFromGrid(
      gridApi,
      ruleNames.map(({ name }) => name)
    );
  };

  const TopControllerComponent = (
    <div>
      {isMainStream && (
        <TableSchedulePeriodSwitcher
          location={location}
          periodStartDate={periodStartDate}
          periodFinishDate={periodFinishDate}
          globalEmployees={globalEmployees}
          numDays={numDays}
          isSaving={isSaving}
          periodNum={periodNum}
          pageUrlSlug={"rules"}
          customContainerStyle={{}}
          createRosterModelForMainStreamInDifferentPeriod={
            createRosterModelForMainStreamInDifferentPeriod
          }
        />
      )}
    </div>
  );

  if (plan === PLAN.COORDINATOR) {
    return <NotAccessibleView />;
  }

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <GridActionHandler
        gridApi={gridApi}
        addNewItemToDB={() => {}}
        updateItemsToDB={updateRules}
        duplicateItemsToDB={() => {}}
        removeItemsFromDB={() => {}}
        getDataFromGrid={getDataFromGrid}
        getToBeDeletedItems={() => {}}
        parseSelectedRowsToDuplicableInfo={() => {}}
        undoRedoParams={{
          tableName: null,
          getCustomGridSnapshot: () =>
            getAllRulesFromGrid(
              gridApi,
              ruleNames.map(({ name }) => name)
            ),
        }}
      >
        <RulesGrid
          locationID={locationID}
          rulesData={rulesData}
          isSaving={isSaving}
          gridApi={gridApi}
          setGridApiToParent={setGridApi}
          updateCustomRules={updateCustomRules}
          removeRule={removeRuleColumn}
          ruleNames={ruleNames}
          allRulesData={allRulesData}
          rulesInRoster={rules}
          ruleWarnings={ruleWarnings}
          setColumnApiToParent={setColumnApi}
          columnApi={columnApi}
          isRulesWithExceptionEnabled={isRulesWithExceptionEnabled}
          reformattedRules={ruleColNameDescriptionPairs}
          customTopComponent={TopControllerComponent}
          isScheduleView={!isRoster}
          toggleRulesTemplateModal={toggleRulesTemplateModal}
          setSelectedRulesTemplate={setSelectedRulesTemplate}
          showRulesTemplateModal={showRulesTemplateModal}
          selectedRulesTemplate={selectedRulesTemplate}
          setInitialRulesInBuilder={setInitialRulesInBuilder}
          clearDefaultNewRules={clearDefaultNewRules}
          initialRulesInBuilder={initialRulesInBuilder}
          rosterName={name}
        />
      </GridActionHandler>
    </>
  );
};

export default ScheduleRulesDataContainer;
