import { Link } from "react-router-dom";
import { DndProvider, useDrop, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Paper } from "@mui/material";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import styles from "./RosterTableShiftView.module.css";
import {
  SHIFT_VIEW_ANY_LABEL,
  buildGrid,
  getCellDemandValuesInfo,
  transformDemands,
} from "./GridBuildingUtils";
import { changeGrid, detectGridChange } from "./GridChangingUtils";
import { findEntityByShortId } from "../../../service/rosterUtils";
import {
  arraysHaveSameContent,
  countConsecutive,
  DateTime,
  extractDayNumberFromDayString,
  getDistanceBetweenElementLeftAndContainerRight,
  getIds,
  getIndicesOfModifiedElements,
  getLongestStrInPx,
  getNames,
  hasCommonItems,
  parseGlobalEmployeeModelToScheduleRosterGridFormat,
  removeDuplicateStrInArr,
  sortEmployeesById,
  strToArrCommaSeparated,
  twoArraysAreEqual,
} from "../../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import useDragScroll from "./useDragScroll";
import ShiftViewActionBar from "../ShiftViewActionBar/ShiftViewActionBar";
import ShiftViewEmployeeListDropdown from "../ShiftViewEmployeeListDropdown/ShiftViewEmployeeListDropdown";
import {
  getColorCodeForRow,
  getDaysArrayInView,
  getDemandFromIndex,
  isDemandCellFillable,
} from "./ShiftViewUtil";
import ShiftViewGridHeader from "../ShiftViewGridHeader/ShiftViewGridHeader";
import { useToggleShow } from "../../../../../hooks/useToggleShow";
import { customConfirmAlert } from "../../../../confirm/service/confirm";
import { getInvalidCellsFromRulesWithOutGridDeps } from "../../../../rules/service/invalidCellsGetter";
import { DeleteAllocationButton, Notes, ReadOnlyNotes } from "../Notes/Notes";
import { isDark } from "../../service/styleGetters";
import { getEmployeeNoteOnDay } from "../../../../../utils/queryUtils/globalEmployeeDataGetters";
import {
  getLocationAreaFiltersSettings,
  useSettingsModelQuery,
} from "../../../../../hooks/modelQueryHooks/useSettingsModelQuery";
import { convertAllocationInShortIdFormToNameForm } from "../../../../../utils/modelUtils/allocation";
import { KEYWORD_ALL } from "../../../../../constants/keywords";

function RosterTableShiftView({
  locationID,
  rosterID,
  isMainStream,
  demands,
  employees,
  taskBlocks,
  numDays,
  updateRosterTable,
  tasks,
  rules,
  areas,
  shifts,
  shiftGroups,
  startDate,
  locationStartDate,
  customKeywordsDict,
  predefinedShiftOptions,
  toggleColorCodingModal,
  colorCodes,
  readOnly,
  locationDefaultNumDays,
  hiddenRows,
  updateShiftViewHiddenRows,
  shouldDefaultViewIncludeToday = true,
  employeesAllocationNotes = null,
  updateNote,
  forceUpdateOnStartDateChange = false,
  shortIdsToEntityNamesDicts,
}) {
  const { settings } = useSettingsModelQuery();
  const areaFilter = useMemo(
    () => getLocationAreaFiltersSettings(settings, locationID),
    [settings, locationID]
  );

  const isFilteredByArea =
    !areaFilter.includes(KEYWORD_ALL) && areaFilter.length > 0;

  const filteredDemands = useMemo(
    () =>
      isFilteredByArea
        ? demands.filter((demand) => {
            if (!demand.areas) {
              return false;
            }

            const demandAreas = strToArrCommaSeparated(demand.areas);
            return hasCommonItems(demandAreas, areaFilter);
          })
        : demands,
    [demands, areaFilter, isFilteredByArea]
  );

  const prevFilteredDemands = useRef(filteredDemands);

  const isGridInitialized = useRef(false);

  const [gridInfo, setGridInfo] = useState({
    grid: null,
    columnData: null,
    rowData: null,
    demandsGrid: null,
    dayGrids: null,
  });

  const { grid, columnData, rowData, demandsGrid } = gridInfo;

  const employeesDataInMyRosterGridFormat =
    parseGlobalEmployeeModelToScheduleRosterGridFormat(employees);

  const invalidCellListWithReasons = readOnly
    ? []
    : getInvalidCellsFromRulesWithOutGridDeps(
        rules,
        areas,
        shifts,
        shiftGroups,
        tasks,
        taskBlocks,
        employees,
        numDays,
        predefinedShiftOptions,
        customKeywordsDict,
        employeesDataInMyRosterGridFormat,
        shortIdsToEntityNamesDicts
      );

  const transformedDemands = useMemo(
    () =>
      transformDemands(
        areas,
        filteredDemands,
        tasks,
        taskBlocks,
        shifts,
        shiftGroups
      ),
    [areas, filteredDemands, tasks, taskBlocks, shifts, shiftGroups]
  );

  // Load grid data only once based on current field values
  useEffect(() => {
    const prevFilteredDemandsShortIds = getIds(prevFilteredDemands.current);
    const filteredDemandsShortIds = getIds(filteredDemands);
    const hasFilteredDemandsChanged = !arraysHaveSameContent(
      prevFilteredDemandsShortIds,
      filteredDemandsShortIds
    );

    prevFilteredDemands.current = filteredDemands;

    if (transformedDemands.length === 0) {
      return;
    }

    if (
      !hasFilteredDemandsChanged &&
      isGridInitialized.current &&
      !forceUpdateOnStartDateChange
    ) {
      return;
    }

    const gridInfo = buildGrid(
      numDays,
      employees,
      transformedDemands,
      taskBlocks,
      shiftGroups,
      customKeywordsDict,
      null,
      null
    );
    setGridInfo(gridInfo);
    isGridInitialized.current = true;
  }, [
    startDate,
    customKeywordsDict,
    employees,
    numDays,
    shiftGroups,
    taskBlocks,
    transformedDemands,
    forceUpdateOnStartDateChange,
    filteredDemands,
  ]);

  if (transformedDemands.length === 0) {
    return (
      <div className={styles.alertContainer}>
        <p>No demands with shift(s)/task(s)</p>
        <Link
          className={styles.redirectLink}
          to={
            isMainStream
              ? {
                  pathname: `/locations/schedule-view/demands`,
                  search: `?location-id=${locationID}&date=${startDate}`,
                }
              : {
                  pathname: `/roster/demands`,
                  search: `?roster-id=${rosterID}`,
                }
          }
        >
          Add demands
        </Link>
      </div>
    );
  }

  if (!grid || !columnData || !rowData || !demandsGrid) {
    return <div>Loading</div>;
  }

  return (
    <div className={styles.outerContainer}>
      {!readOnly && (
        <ShiftViewActionBar
          toggleColorCodingModal={toggleColorCodingModal}
          rowNames={rowData}
          updateShiftViewHiddenRows={updateShiftViewHiddenRows}
          hiddenRows={hiddenRows}
          rosterID={rosterID}
          areas={areas}
          shifts={shifts}
          tasks={tasks}
          shiftGroups={shiftGroups}
        />
      )}
      {/* tabIndex={0} is for listening to keydown event for undo/redo feature */}
      <div tabIndex={0}>
        <RosterGridShiftView
          numDays={numDays}
          employees={employees}
          modifyRosteredAllocations={updateRosterTable}
          grid={grid}
          columnData={columnData}
          rowData={rowData}
          demandsGrid={demandsGrid}
          demands={transformedDemands}
          tasks={tasks}
          taskBlocks={taskBlocks}
          areas={areas}
          shifts={shifts}
          startDate={startDate}
          shiftGroups={shiftGroups}
          customKeywordsDict={customKeywordsDict}
          colorCodes={colorCodes}
          invalidCellListWithReasons={invalidCellListWithReasons}
          hiddenRows={hiddenRows}
          locationStartDate={locationStartDate}
          readOnly={readOnly}
          locationDefaultNumDays={locationDefaultNumDays}
          setGridInfo={setGridInfo}
          cachedDayGrids={gridInfo.dayGrids}
          shouldDefaultViewIncludeToday={shouldDefaultViewIncludeToday}
          employeesAllocationNotes={employeesAllocationNotes}
          updateNote={updateNote}
          shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
          isFilteredByArea={isFilteredByArea}
        />
      </div>
    </div>
  );
}

const getInitialIntervalNumber = (
  shouldDefaultViewIncludeToday,
  startDate,
  numDays
) => {
  if (shouldDefaultViewIncludeToday) {
    const today = new DateTime();
    const endDate = new DateTime(startDate).addDays(numDays - 1);

    if (!today.isDateBetweenTwoDates(startDate, endDate, true, true)) {
      return 1;
    }

    const offset = DateTime.getDifferenceInDays(startDate, today);
    const numInterval = Math.floor(offset / 7) + 1;

    return numInterval;
  }

  return 1;
};

function RosterGridShiftView({
  numDays,
  employees,
  modifyRosteredAllocations,
  grid,
  columnData,
  rowData,
  demands,
  tasks,
  taskBlocks,
  shifts,
  areas,
  demandsGrid,
  startDate,
  locationStartDate,
  shiftGroups,
  customKeywordsDict,
  colorCodes,
  invalidCellListWithReasons,
  hiddenRows,
  readOnly,
  locationDefaultNumDays,
  setGridInfo,
  cachedDayGrids,
  shouldDefaultViewIncludeToday,
  employeesAllocationNotes = null,
  updateNote,
  shortIdsToEntityNamesDicts,
  isFilteredByArea,
}) {
  const areasConsecutiveNumbersList = isFilteredByArea
    ? countConsecutive(
        rowData.map((data) => {
          let [areaPart, rest] = data.split(":");
          if (!rest) {
            areaPart = null;
          }
          return areaPart;
        })
      )
    : Array(rowData.length).fill(0);

  const numWeeks = Math.ceil(numDays / 7.0);

  const [numWeeksPerView, setNumWeeksPerView] = useState(1);
  const [numIntervalInView, setNumIntervalInView] = useState(
    getInitialIntervalNumber(shouldDefaultViewIncludeToday, startDate, numDays)
  );
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredColum, setHoveredColumn] = useState(null);

  const updateNumWeeksPerView = (value) => {
    if (value === numWeeksPerView) {
      return;
    }
    if (value === 1 || value === 2) {
      setNumWeeksPerView(value);
      const maxIntervalNum = Math.ceil(numWeeks / value);

      let updatedNumIntervalInView = maxIntervalNum;
      if (value === 2) {
        updatedNumIntervalInView = Math.ceil(numIntervalInView / 2.0);
      } else {
        updatedNumIntervalInView = 2 * numIntervalInView - 1;
      }
      setNumIntervalInView(
        updatedNumIntervalInView > maxIntervalNum
          ? maxIntervalNum
          : updatedNumIntervalInView
      );
    }
  };

  const [daysArray, setDaysArray] = useState(() => {
    return getDaysArrayInView(numDays, numWeeksPerView, numIntervalInView);
  });

  const switchNumIntervalInView = (shouldGoForward) => {
    const maxIntervalNum = Math.ceil(numWeeks / numWeeksPerView);

    let currentNumIntervalInView = numIntervalInView;
    if (shouldGoForward) {
      currentNumIntervalInView++;
    } else {
      currentNumIntervalInView--;
    }

    if (
      0 < currentNumIntervalInView &&
      currentNumIntervalInView <= maxIntervalNum
    ) {
      setNumIntervalInView(currentNumIntervalInView);
    }
  };

  useEffect(() => {
    const daysArrayInView = getDaysArrayInView(
      numDays,
      numWeeksPerView,
      numIntervalInView
    );
    setDaysArray(daysArrayInView);
  }, [numIntervalInView, numWeeksPerView, numDays]);

  const [selectedDayIndex, setSelectedDayIndex] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedBlockIndex, setSelectedBlockIndex] = useState(null);

  const getGridAfterUpdate = useCallback(
    (originalEmployees, updatedEmployee) => {
      const employeesAfterUpdate = originalEmployees.map((emp) => {
        if (emp.id === updatedEmployee.id) {
          return updatedEmployee;
        }
        return emp;
      });

      const targetEmployeeBeforeUpdate = originalEmployees.find(
        (emp) => emp.id === updatedEmployee.id
      );

      const updatedDayIndices = getIndicesOfModifiedElements(
        targetEmployeeBeforeUpdate.RosteredAllocations,
        updatedEmployee.RosteredAllocations
      );

      const { grid, columnData, rowData, demandsGrid, dayGrids } = buildGrid(
        numDays,
        employeesAfterUpdate,
        demands,
        taskBlocks,
        shiftGroups,
        customKeywordsDict,
        cachedDayGrids,
        updatedDayIndices
      );

      return {
        grid,
        columnData,
        rowData,
        demandsGrid,
        dayGrids,
      };
    },
    [
      customKeywordsDict,
      demands,
      numDays,
      shiftGroups,
      taskBlocks,
      cachedDayGrids,
    ]
  );

  const confirmGridUpdate = useCallback(
    async (employee, gridBefore, gridAfter, exceptions, hiddenRows) => {
      const gridDifferences = detectGridChange(
        employee.id,
        gridBefore,
        gridAfter,
        exceptions,
        hiddenRows
      );

      if (gridDifferences.length === 0) {
        return true;
      }

      const messages = [];
      const dateMap = new Map();

      // Group by date
      for (const difference of gridDifferences) {
        const { rowName, dayIndex, isAllocationRemoved } = difference;

        const date = new DateTime(startDate)
          .addDays(dayIndex)
          .toFormat("DOW day");

        if (!dateMap.has(date)) {
          dateMap.set(date, { added: [], removed: [] });
        }

        if (isAllocationRemoved) {
          dateMap.get(date).removed.push(rowName);
        } else {
          dateMap.get(date).added.push(rowName);
        }
      }

      for (const [date, { added, removed }] of dateMap.entries()) {
        const addedEntityNames = added.map((shortIdForm) =>
          convertAllocationInShortIdFormToNameForm(
            shortIdForm,
            shortIdsToEntityNamesDicts
          )
        );
        const removedEntityNames = removed.map((shortIdForm) =>
          convertAllocationInShortIdFormToNameForm(
            shortIdForm,
            shortIdsToEntityNamesDicts
          )
        );

        if (removedEntityNames.length > 0) {
          messages.push(
            <div key={`removed-${date}`}>
              <b>
                With this change, on day {date}, {employee.name} will no longer
                be doing:
              </b>
              {removedEntityNames
                .map((x, idx) => <span key={idx}> &quot;{x}&quot;</span>)
                .reduce((prev, curr) => [prev, ", ", curr])}
            </div>
          );
        }
        messages.push(
          <React.Fragment key="break">
            <br />
          </React.Fragment>
        );
        if (addedEntityNames.length > 0) {
          messages.push(
            <div key={`added-${date}`}>
              <b>
                With this change, on day {date}, {employee.name} will be doing:
              </b>
              {addedEntityNames
                .map((x, idx) => <span key={idx}> &quot;{x}&quot;</span>)
                .reduce((prev, curr) => [prev, ", ", curr])}
            </div>
          );
        }
      }

      const response = await customConfirmAlert({
        title: "Are you sure?",
        descriptions: [messages],
      });

      return response;
    },
    [startDate, shortIdsToEntityNamesDicts]
  );

  const handleDrop = useCallback(
    async (
      employeeID,
      fromDay,
      fromRowIndex,
      fromBlockIndex,
      fromRowName,
      toDay,
      toRowIndex,
      toBlockIndex,
      toRowName
    ) => {
      const employee = employees.find((employee) => employee.id === employeeID);
      const newRosteredAllocation = [...employee.RosteredAllocations];

      const toDemandCellIsFillable = isDemandCellFillable(
        demandsGrid,
        toRowName,
        toBlockIndex
      );

      if (!toDemandCellIsFillable) {
        return;
      }

      if (fromDay !== toDay) {
        const data1 = changeGrid(
          "remove",
          employee.RosteredAllocations[fromDay],
          getDemandFromIndex(
            rowData[fromRowIndex],
            fromBlockIndex,
            demandsGrid
          ),
          demands,
          taskBlocks,
          areas,
          shifts,
          shiftGroups,
          employee.skills,
          customKeywordsDict,
          null,
          shortIdsToEntityNamesDicts
        );
        if (data1 !== null) {
          newRosteredAllocation[fromDay] = data1;
        }

        const data2 = changeGrid(
          "add",
          employee.RosteredAllocations[toDay],
          getDemandFromIndex(rowData[toRowIndex], toBlockIndex, demandsGrid),
          demands,
          taskBlocks,
          areas,
          shifts,
          shiftGroups,
          employee.skills,
          customKeywordsDict,
          null,
          shortIdsToEntityNamesDicts
        );
        if (data2 !== null) {
          newRosteredAllocation[toDay] = data2;
        }
      } else {
        const data2 = changeGrid(
          "swap",
          employee.RosteredAllocations[toDay],
          getDemandFromIndex(rowData[toRowIndex], toBlockIndex, demandsGrid),
          demands,
          taskBlocks,
          areas,
          shifts,
          shiftGroups,
          employee.skills,
          customKeywordsDict,
          getDemandFromIndex(
            rowData[fromRowIndex],
            fromBlockIndex,
            demandsGrid
          ),
          shortIdsToEntityNamesDicts
        );
        if (data2 !== null) {
          newRosteredAllocation[toDay] = data2;
        }
      }

      const updatedEmployee = {
        ...employee,
        RosteredAllocations: newRosteredAllocation,
      };

      const gridInfoAfter = getGridAfterUpdate(employees, updatedEmployee);
      const { grid: gridAfter } = gridInfoAfter;

      const detectGridChangeExceptions = [
        {
          dayIndex: fromDay,
          rowName: fromRowName,
          blockIndex: fromBlockIndex,
        },
        {
          dayIndex: toDay,
          rowName: toRowName,
          blockIndex: toBlockIndex,
        },
      ];

      const shouldProceed = await confirmGridUpdate(
        employee,
        grid,
        gridAfter,
        detectGridChangeExceptions,
        hiddenRows
      );
      if (!shouldProceed) {
        return;
      }

      setGridInfo(gridInfoAfter);

      const updatedAllocations = [
        {
          id: employee.id,
          RosteredAllocations: newRosteredAllocation,
        },
      ];

      if (
        twoArraysAreEqual(employee.RosteredAllocations, newRosteredAllocation)
      ) {
        return;
      }

      modifyRosteredAllocations(updatedAllocations);
    },
    [
      hiddenRows,
      demands,
      demandsGrid,
      employees,
      modifyRosteredAllocations,
      shifts,
      taskBlocks,
      customKeywordsDict,
      rowData,
      shiftGroups,
      grid,
      confirmGridUpdate,
      getGridAfterUpdate,
      setGridInfo,
      areas,
      shortIdsToEntityNamesDicts,
    ]
  );

  const handleEmployeeSelection = async (employeeID, isSelected, rowName) => {
    const employee = employees.filter(
      (employee) => employee.id === employeeID
    )[0];

    const newRosteredAllocation = [...employee.RosteredAllocations];

    const type = isSelected ? "add" : "remove"; // But i'm not sure if "remove" operation is valid if it is performed alone

    const updatedAllocation = changeGrid(
      type,
      employee.RosteredAllocations[selectedDayIndex],
      getDemandFromIndex(
        rowData[selectedRowIndex],
        selectedBlockIndex,
        demandsGrid
      ),
      demands,
      taskBlocks,
      areas,
      shifts,
      shiftGroups,
      employee.skills,
      customKeywordsDict,
      null,
      shortIdsToEntityNamesDicts
    );

    if (updatedAllocation != null)
      newRosteredAllocation[selectedDayIndex] = updatedAllocation;

    if (
      twoArraysAreEqual(employee.RosteredAllocations, newRosteredAllocation)
    ) {
      return;
    }

    const updatedEmployee = {
      ...employee,
      RosteredAllocations: newRosteredAllocation,
    };
    const gridInfoAfter = getGridAfterUpdate(employees, updatedEmployee);
    const { grid: gridAfter } = gridInfoAfter;

    const detectGridChangeExceptions = [
      {
        dayIndex: selectedDayIndex,
        rowName: rowName,
        blockIndex: selectedBlockIndex,
      },
    ];

    const shouldProceed = await confirmGridUpdate(
      employee,
      grid,
      gridAfter,
      detectGridChangeExceptions,
      hiddenRows
    );

    if (!shouldProceed) {
      return;
    }

    setGridInfo(gridInfoAfter);

    const updatedAllocations = [
      {
        id: employee.id,
        RosteredAllocations: newRosteredAllocation,
      },
    ];

    modifyRosteredAllocations(updatedAllocations);
  };

  const handleOpenDropdown = (dayIndex, blockIndex, rowIndex) => {
    setSelectedDayIndex(dayIndex);
    setSelectedBlockIndex(blockIndex);
    setSelectedRowIndex(rowIndex);
  };

  const handleCloseDropdown = () => {
    setSelectedDayIndex(null);
    setSelectedRowIndex(null);
    setSelectedBlockIndex(null);
  };

  const handleDeleteAllocation = async (
    employeeID,
    dayIndex,
    rowIndex,
    blockIndex,
    rowName
  ) => {
    const employee = employees.filter(
      (employee) => employee.id === employeeID
    )[0];
    const newRosteredAllocation = [...employee.RosteredAllocations];
    const data = changeGrid(
      "remove",
      employee.RosteredAllocations[dayIndex],
      getDemandFromIndex(rowData[rowIndex], blockIndex, demandsGrid),
      demands,
      taskBlocks,
      areas,
      shifts,
      shiftGroups,
      employee.skills,
      customKeywordsDict,
      null,
      shortIdsToEntityNamesDicts
    );

    if (data !== null) {
      newRosteredAllocation[dayIndex] = data;
    }

    const updatedEmployee = {
      ...employee,
      RosteredAllocations: newRosteredAllocation,
    };
    const gridInfoAfter = getGridAfterUpdate(employees, updatedEmployee);
    const { grid: gridAfter } = gridInfoAfter;

    const detectGridChangeExceptions = [
      {
        dayIndex: dayIndex,
        rowName: rowName,
        blockIndex: blockIndex,
      },
    ];
    const shouldProceed = await confirmGridUpdate(
      employee,
      grid,
      gridAfter,
      detectGridChangeExceptions,
      hiddenRows
    );

    if (!shouldProceed) {
      return;
    }

    setGridInfo(gridInfoAfter);

    const updatedAllocations = [
      {
        id: employee.id,
        RosteredAllocations: newRosteredAllocation,
      },
    ];

    modifyRosteredAllocations(updatedAllocations);
  };

  const isSingleColumn = columnData.length === 1;
  const containerRef = useRef(null);

  useDragScroll(containerRef, {
    scrollIncrement: 0.4,
    thresholdTop: 130,
    thresholdRight: 100,
    thresholdBottom: 100,
    thresholdLeft: 300,
  });

  const employeesAllocatedInCurrentView = useMemo(() => {
    const rows = [];
    const displayedDayIndices = daysArray.map(
      (day) => extractDayNumberFromDayString(day) - 1
    );

    for (const key in grid) {
      const rowWithVisibleColumns = grid[key].filter((day, idx) =>
        displayedDayIndices.includes(idx)
      );
      rows.push(rowWithVisibleColumns);
    }
    const flattenRows = removeDuplicateStrInArr(rows.flat(Infinity));
    const displayedEmployees = flattenRows
      .map((employeeID) =>
        employees.find((employee) => employee.id === employeeID)
      )
      .filter((employee) => employee !== undefined);

    return displayedEmployees;
  }, [grid, daysArray, employees]);

  const displayedEmployeeNames = useMemo(
    () => getNames(employeesAllocatedInCurrentView),
    [employeesAllocatedInCurrentView]
  );

  const { minBlockWidth, minFullColumnWidth } = getCellBlockMinWidthInPx(
    columnData,
    displayedEmployeeNames
  );

  return (
    <div
      ref={containerRef}
      className={`${styles.container} ${styles.borderRight} ${
        styles.borderLeft
      } ${styles.borderBottom} ${readOnly && styles.borderTop}`}
    >
      <ShiftViewGridHeader
        areas={areas}
        taskBlocks={taskBlocks}
        columnData={columnData}
        daysArray={daysArray}
        startDate={startDate}
        isSingleColumn={isSingleColumn}
        switchNumIntervalInView={switchNumIntervalInView}
        numIntervalInView={numIntervalInView}
        numWeeksPerView={numWeeksPerView}
        updateNumWeeksPerView={updateNumWeeksPerView}
        minBlockWidth={minBlockWidth}
        minFullColumnWidth={minFullColumnWidth}
        totalNumWeeks={numWeeks}
      />
      <div className={styles.content}>
        <DndProvider backend={HTML5Backend}>
          <div>
            {rowData.map((rowName, rowIndex) => {
              const isHidden = hiddenRows.includes(rowName);
              const row = grid[rowName];
              return (
                <div
                  key={rowIndex}
                  className={
                    styles.borderBottom + " " + (isHidden ? styles.hidden : "")
                  }
                >
                  <AllocationRow
                    key={rowIndex}
                    row={row}
                    columnData={columnData}
                    rowName={rowData[rowIndex]}
                    employees={employees}
                    onDrop={handleDrop}
                    handleOpenDropdown={handleOpenDropdown}
                    handleCloseDropdown={handleCloseDropdown}
                    daysArray={daysArray}
                    rowIndex={rowIndex}
                    isSingleColumn={isSingleColumn}
                    selectedDayIndex={selectedDayIndex}
                    selectedRowIndex={selectedRowIndex}
                    selectedBlockIndex={selectedBlockIndex}
                    handleEmployeeSelection={handleEmployeeSelection}
                    demandsGrid={demandsGrid}
                    handleDeleteAllocation={handleDeleteAllocation}
                    hoveredRow={hoveredRow}
                    hoveredColum={hoveredColum}
                    setHoveredRow={setHoveredRow}
                    setHoveredColumn={setHoveredColumn}
                    colorCodes={colorCodes}
                    areas={areas}
                    shifts={shifts}
                    tasks={tasks}
                    shiftGroups={shiftGroups}
                    customKeywordsDict={customKeywordsDict}
                    invalidCellListWithReasons={invalidCellListWithReasons}
                    minBlockWidth={minBlockWidth}
                    minFullColumnWidth={minFullColumnWidth}
                    scrollContainerRef={containerRef}
                    numDays={numDays}
                    locationStartDate={locationStartDate}
                    startDate={startDate}
                    readOnly={readOnly}
                    locationDefaultNumDays={locationDefaultNumDays}
                    employeesAllocationNotes={employeesAllocationNotes}
                    updateNote={updateNote}
                    shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
                    areasConsecutiveNumber={
                      areasConsecutiveNumbersList[rowIndex]
                    }
                  />
                </div>
              );
            })}
            <LeaveRow
              areas={areas}
              daysArray={daysArray}
              employees={employees}
              customKeywordsDict={customKeywordsDict}
              minBlockWidth={minBlockWidth}
              minFullColumnWidth={minFullColumnWidth}
              columnData={columnData}
              startDate={startDate}
              colorCodes={colorCodes}
            />
          </div>
        </DndProvider>
      </div>
    </div>
  );
}

const LeaveRow = ({
  areas,
  daysArray,
  employees,
  customKeywordsDict,
  minBlockWidth,
  minFullColumnWidth,
  columnData,
  startDate,
  colorCodes,
}) => {
  const { annualLeaveKeyword } = customKeywordsDict;

  const colorCode = colorCodes.find(
    ({ shift }) => shift === annualLeaveKeyword
  )?.color;

  const employeeLeaveList = useMemo(
    () =>
      daysArray.map((day) => {
        const dayIndex = extractDayNumberFromDayString(day) - 1;
        const employeesOnLeave = [];
        for (const employee of employees) {
          const rosteredAllocations = employee.RosteredAllocations;
          if (rosteredAllocations[dayIndex] === annualLeaveKeyword) {
            employeesOnLeave.push(employee);
          }
        }

        return { dayIndex, employeesOnLeave };
      }),
    [employees, annualLeaveKeyword, daysArray]
  );

  const employeeIdsInEachDay = employeeLeaveList.map(({ employeesOnLeave }) =>
    employeesOnLeave.map(({ id }) => id)
  );

  const employeesFrequencyOrder =
    getEmployeesOrderedByFrequency(employeeIdsInEachDay);

  return (
    <div
      className={`${styles.row} ${styles.borderBottom}`}
      style={{
        ...(colorCode && { backgroundColor: colorCode }),
      }}
    >
      <div
        className={`${styles.fixedBlock} ${styles.borderRight} ${styles.whiteBackground} ${styles.rowMinHeight} ${styles.centerContent} ${styles.rowNameLabel} ${styles.boldText}`}
        style={{
          width: areas.length > 0 ? "301px" : "151px",
          backgroundColor: colorCode || "white",
          color: colorCode && isDark(colorCode) ? "white" : null,
        }}
      >
        <span>{annualLeaveKeyword} (Annual Leave)</span>
      </div>

      {employeeLeaveList.map(({ dayIndex, employeesOnLeave }) => {
        const date = new DateTime(startDate)
          .addDays(Number(dayIndex))
          .toFormat("AWS");

        const sortedEmployeeLeaveList = sortEmployeesById(
          employeesOnLeave,
          employeesFrequencyOrder
        );

        return (
          <div
            key={dayIndex}
            style={{
              ...(columnData.length === 1 && {
                minWidth: `${minFullColumnWidth}px`,
              }),
              width: `${(columnData.length - 1) * minBlockWidth}px`,
            }}
            className={`${styles.borderRight} ${styles.rowMinHeight} ${styles.employeeBoxes}`}
          >
            {sortedEmployeeLeaveList.map((employee) => (
              <EmployeeBox
                key={employee.id + " " + dayIndex}
                employeeID={employee.id}
                employeeName={employee.name}
                dayIndex={dayIndex}
                rowIndex={null}
                blockIndex={null}
                handleDeleteAllocation={() => {}}
                employeeRuleViolation={null}
                rowName={null}
                scrollContainerRef={null}
                readOnly={true}
                date={date}
                employeesAllocationNotes={[]}
                updateNote={() => {}}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

const AllocationRow = ({
  daysArray,
  employees,
  onDrop,
  handleOpenDropdown,
  handleCloseDropdown,
  columnData,
  row,
  rowName,
  rowIndex,
  isSingleColumn,
  selectedDayIndex,
  selectedRowIndex,
  selectedBlockIndex,
  handleEmployeeSelection,
  demandsGrid,
  handleDeleteAllocation,
  hoveredRow,
  hoveredColum,
  setHoveredRow,
  setHoveredColumn,
  colorCodes,
  areas,
  shifts,
  tasks,
  shiftGroups,
  customKeywordsDict,
  invalidCellListWithReasons,
  minBlockWidth,
  minFullColumnWidth,
  scrollContainerRef,
  numDays,
  locationStartDate,
  startDate,
  readOnly,
  locationDefaultNumDays,
  employeesAllocationNotes = null,
  updateNote,
  shortIdsToEntityNamesDicts,
  areasConsecutiveNumber,
}) => {
  const colorCode = getColorCodeForRow(
    rowName,
    colorCodes,
    shifts,
    shiftGroups,
    tasks,
    shortIdsToEntityNamesDicts,
    customKeywordsDict
  );

  const displayedRowName = getDisplayedRowName(
    rowName,
    areas,
    shifts,
    tasks,
    shiftGroups
  );

  return (
    <div
      className={`${styles.row}`}
      style={{
        ...(colorCode && { backgroundColor: colorCode }),
      }}
    >
      <div
        className={`${styles.rowName} ${styles.fixedBlock} ${
          styles.centerContent
        } ${styles.centerText} ${styles.borderRight} ${styles.boldText} ${
          areasConsecutiveNumber > 0 ? styles.fixedBlockHigherZIndex : ""
        }`}
        style={{
          backgroundColor: colorCode || "white",
          color: colorCode && isDark(colorCode) ? "white" : null,
        }}
      >
        {areas.length > 0 && (
          <div
            style={{ width: "150px", height: "100%" }}
            className={`${styles.borderRight} ${styles.rowMinHeight} ${styles.centerContent} ${styles.areaLabelContainer}`}
          >
            {areasConsecutiveNumber > 0 ? (
              <div
                className={styles.areaLabel}
                style={{
                  height: `${(areasConsecutiveNumber + 1) * 100}%`,
                }}
              >
                {displayedRowName.areaLabel}
              </div>
            ) : (
              displayedRowName.areaLabel
            )}
          </div>
        )}
        <span
          className={`${styles.rowMinHeight} ${styles.centerContent} ${styles.rowNameLabel}`}
          style={{ width: "150px", height: "100%" }}
        >
          {displayedRowName.allocationLabel}
        </span>
      </div>
      {daysArray.map((day, daysArrayIndex) => {
        const dayIndex = extractDayNumberFromDayString(day) - 1;
        const isFirstColumnAnyColumn = columnData[0] === SHIFT_VIEW_ANY_LABEL;
        const isFirstColumnDemandCellFillable = isDemandCellFillable(
          demandsGrid,
          rowName,
          0
        );

        const isMergedDay =
          isFirstColumnAnyColumn && isFirstColumnDemandCellFillable;

        return (
          <div key={dayIndex} className={`${styles.fullColumnWidth}`}>
            {isMergedDay ? (
              <div
                style={{
                  minWidth: `${minFullColumnWidth}px`,
                  width: `${(columnData.length - 1) * minBlockWidth}px`,
                  height: "100%",
                }}
              >
                <DayColumn
                  key={`${day} ${columnData[0]} ${0}`}
                  dayIndex={dayIndex}
                  rowName={rowName}
                  daysArrayIndex={daysArrayIndex}
                  column={columnData[0]}
                  row={row}
                  employees={employees}
                  handleOpenDropdown={handleOpenDropdown}
                  handleCloseDropdown={handleCloseDropdown}
                  onDrop={onDrop}
                  rowIndex={rowIndex}
                  blockIndex={0}
                  isSingleColumn={true}
                  selectedDayIndex={selectedDayIndex}
                  selectedRowIndex={selectedRowIndex}
                  selectedBlockIndex={selectedBlockIndex}
                  handleEmployeeSelection={handleEmployeeSelection}
                  demandsGrid={demandsGrid}
                  handleDeleteAllocation={handleDeleteAllocation}
                  hoveredRow={hoveredRow}
                  hoveredColum={hoveredColum}
                  setHoveredRow={setHoveredRow}
                  setHoveredColumn={setHoveredColumn}
                  customKeywordsDict={customKeywordsDict}
                  demandCellIsFillable={true}
                  invalidCellListWithReasons={invalidCellListWithReasons}
                  isMergedDay={isMergedDay}
                  minBlockWidth={minBlockWidth}
                  scrollContainerRef={scrollContainerRef}
                  numDays={numDays}
                  locationStartDate={locationStartDate}
                  startDate={startDate}
                  readOnly={readOnly}
                  locationDefaultNumDays={locationDefaultNumDays}
                  employeesAllocationNotes={employeesAllocationNotes}
                  updateNote={updateNote}
                  shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
                  displayedRowName={displayedRowName}
                />
              </div>
            ) : (
              <div
                className={`${styles.fullColumnWidth} ${styles.blockColumnsContainer} ${styles.blockCellsContainer}`}
              >
                {columnData.map((column, columnIndex) => {
                  const blockIndex = columnIndex;
                  const demandCellIsFillable = isDemandCellFillable(
                    demandsGrid,
                    rowName,
                    blockIndex
                  );

                  return (
                    <DayColumn
                      key={`${day} ${column} ${columnIndex}`}
                      dayIndex={dayIndex}
                      row={row}
                      rowName={rowName}
                      column={column}
                      employees={employees}
                      handleOpenDropdown={handleOpenDropdown}
                      handleCloseDropdown={handleCloseDropdown}
                      onDrop={onDrop}
                      rowIndex={rowIndex}
                      blockIndex={blockIndex}
                      isSingleColumn={isSingleColumn}
                      selectedDayIndex={selectedDayIndex}
                      selectedRowIndex={selectedRowIndex}
                      selectedBlockIndex={selectedBlockIndex}
                      handleEmployeeSelection={handleEmployeeSelection}
                      handleDeleteAllocation={handleDeleteAllocation}
                      hoveredRow={hoveredRow}
                      hoveredColum={hoveredColum}
                      setHoveredRow={setHoveredRow}
                      setHoveredColumn={setHoveredColumn}
                      customKeywordsDict={customKeywordsDict}
                      demandCellIsFillable={demandCellIsFillable}
                      invalidCellListWithReasons={invalidCellListWithReasons}
                      isMergedDay={isMergedDay}
                      minBlockWidth={minBlockWidth}
                      scrollContainerRef={scrollContainerRef}
                      demandsGrid={demandsGrid}
                      numDays={numDays}
                      locationStartDate={locationStartDate}
                      startDate={startDate}
                      readOnly={readOnly}
                      locationDefaultNumDays={locationDefaultNumDays}
                      employeesAllocationNotes={employeesAllocationNotes}
                      updateNote={updateNote}
                      shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
                      displayedRowName={displayedRowName}
                    />
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const DayColumn = ({
  dayIndex,
  onDrop,
  handleOpenDropdown,
  handleCloseDropdown,
  row,
  rowName,
  column,
  rowIndex,
  blockIndex,
  employees,
  isSingleColumn,
  selectedDayIndex,
  selectedRowIndex,
  selectedBlockIndex,
  handleEmployeeSelection,
  handleDeleteAllocation,
  hoveredRow,
  hoveredColum,
  setHoveredRow,
  setHoveredColumn,
  customKeywordsDict,
  demandCellIsFillable,
  invalidCellListWithReasons,
  isMergedDay,
  minBlockWidth,
  scrollContainerRef,
  demandsGrid,
  numDays,
  locationStartDate,
  startDate,
  readOnly,
  locationDefaultNumDays,
  employeesAllocationNotes = null,
  updateNote,
  shortIdsToEntityNamesDicts,
  displayedRowName,
}) => {
  const { minDemandValue, maxDemandValue } = getCellDemandValuesInfo(
    demandsGrid,
    rowName,
    dayIndex,
    blockIndex,
    numDays,
    locationStartDate,
    startDate,
    locationDefaultNumDays
  );

  const date = new DateTime(startDate)
    .addDays(Number(dayIndex))
    .toFormat("AWS");

  const shouldHideColumn = column === SHIFT_VIEW_ANY_LABEL && !isMergedDay; // "any" column is not needed unless it is implicitly expanded
  const [, drop] = useDrop({
    accept: "EMPLOYEE",
    drop: (item) => {
      onDrop(
        item.employeeID,
        item.dayIndex,
        item.rowIndex,
        item.blockIndex,
        item.rowName,
        dayIndex,
        rowIndex,
        blockIndex,
        rowName
      );
    },
  });

  const allocatedEmployeeIDs = row[dayIndex][blockIndex];

  const employeeIdsInEachDay = row.map((data) =>
    removeDuplicateStrInArr(data.flat())
  );
  const employeesFrequencyOrder =
    getEmployeesOrderedByFrequency(employeeIdsInEachDay);

  const allocatedEmployeeIDsInOrder = employeesFrequencyOrder.filter((id) =>
    allocatedEmployeeIDs.includes(id)
  );

  const updateHoveredRowColumn = (row, column) => {
    setHoveredRow(row);
    setHoveredColumn(column);
  };

  const cellButtonRef = useRef(null);

  return (
    <div
      id={`dayIndex_${dayIndex}-blockIndex_${blockIndex}-rowIndex_${rowIndex}`}
      className={`${styles.blockColumnWidth}
      ${styles.cell}
      ${!isSingleColumn && styles.blockColumnMaxWidth}
      ${!demandCellIsFillable && styles.unfillable}
      ${(hoveredRow === rowIndex || hoveredColum === dayIndex) && styles.shaded}
      ${
        hoveredRow === rowIndex &&
        hoveredColum === dayIndex &&
        styles.hoveredCell
      }
      ${styles.borderRight}
      ${shouldHideColumn && styles.hidden}
      `}
      ref={drop}
      onMouseOver={() => updateHoveredRowColumn(rowIndex, dayIndex)}
      onMouseOut={() => updateHoveredRowColumn(null, null)}
      style={{
        minWidth: `${minBlockWidth}px`,
      }}
    >
      {allocatedEmployeeIDs.length > 0 && (
        <div className={`${styles.employeeBoxes}`}>
          {allocatedEmployeeIDsInOrder.map((employeeID) => {
            const employee = employees.find(
              (employee) => employee.id === employeeID
            );

            const employeeRuleViolation = invalidCellListWithReasons.find(
              (violation) => {
                if (violation.employeeId === employeeID) {
                  const [, problematicDay] = violation.coords;
                  if (problematicDay - 1 === dayIndex) {
                    return true;
                  }
                }
                return false;
              }
            );

            return (
              <EmployeeBox
                key={employeeID + " " + dayIndex}
                employeeID={employeeID}
                employeeName={employee.name}
                dayIndex={dayIndex}
                rowIndex={rowIndex}
                blockIndex={blockIndex}
                handleDeleteAllocation={handleDeleteAllocation}
                employeeRuleViolation={employeeRuleViolation}
                rowName={rowName}
                scrollContainerRef={scrollContainerRef}
                readOnly={readOnly}
                date={date}
                employeesAllocationNotes={employeesAllocationNotes}
                updateNote={updateNote}
              />
            );
          })}
        </div>
      )}
      {demandCellIsFillable && (
        <div className={`${styles.cellButtonContainer}`}>
          {readOnly ? (
            <div className={styles.cellButtonPlaceHolder}></div>
          ) : (
            <button
              ref={cellButtonRef}
              onClick={() => handleOpenDropdown(dayIndex, blockIndex, rowIndex)}
              className={styles.cellButton}
            >
              <FontAwesomeIcon
                icon={allocatedEmployeeIDs.length > 0 ? faUserPlus : faPlus}
              />
            </button>
          )}
          {!readOnly && (
            <DemandsIndicator
              employeeNum={allocatedEmployeeIDs.length}
              minValue={minDemandValue === -1 ? -Infinity : minDemandValue}
              maxValue={maxDemandValue === -1 ? Infinity : maxDemandValue}
              scrollContainerRef={scrollContainerRef}
              displayedRowName={displayedRowName}
            />
          )}
          {dayIndex === selectedDayIndex &&
            blockIndex === selectedBlockIndex &&
            rowIndex === selectedRowIndex &&
            cellButtonRef.current && (
              <ShiftViewEmployeeListDropdown
                containerRef={cellButtonRef}
                employees={employees}
                handleCloseDropdown={handleCloseDropdown}
                handleEmployeeSelection={handleEmployeeSelection}
                allocatedEmployeeIDs={allocatedEmployeeIDs}
                dayIndex={dayIndex}
                customKeywordsDict={customKeywordsDict}
                rowName={rowName}
                shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
              />
            )}
        </div>
      )}
    </div>
  );
};

const EmployeeBox = ({
  employeeID,
  employeeName,
  dayIndex,
  rowIndex,
  blockIndex,
  handleDeleteAllocation,
  employeeRuleViolation,
  rowName,
  scrollContainerRef,
  readOnly,
  date,
  employeesAllocationNotes = null,
  updateNote,
}) => {
  const noteOnDay = useMemo(
    () => getEmployeeNoteOnDay(employeeID, employeesAllocationNotes, date),
    [employeeID, employeesAllocationNotes, date]
  );

  const {
    isOpen,
    toggleDropdown,
    selectorRef: dropdownTriggerRef,
    selectorTriggerRef: dropdownRef,
  } = useToggleShow();

  const employeeBoxRef = useRef(null);
  const [violationMessageOffset, setViolationMessageOffset] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const [, drag] = useDrag({
    type: "EMPLOYEE",
    item: {
      employeeID,
      dayIndex,
      rowIndex,
      blockIndex,
      rowName,
    },
  });

  let violationColor = null;
  let message = null;
  if (employeeRuleViolation) {
    const { importance } = employeeRuleViolation;
    if (importance === "necessary") {
      violationColor = "tomato";
    } else {
      violationColor = "rgb(295, 99, 71, 0.5)";
    }
    message = employeeRuleViolation.description;
  }

  return (
    <div
      className={`${styles.employeeBox} ${
        !readOnly && styles.employeeBoxClickable
      }`}
      ref={employeeBoxRef}
      onMouseEnter={() => {
        if (!scrollContainerRef) {
          return;
        }
        setIsHovered(true);
        const distanceBetweenEmployeeBoxAndContainerRight =
          getDistanceBetweenElementLeftAndContainerRight(
            employeeBoxRef.current,
            scrollContainerRef.current
          );
        const offset = 320 - distanceBetweenEmployeeBoxAndContainerRight;
        setViolationMessageOffset(offset > 0 ? offset : 0);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      {!readOnly && (
        <button
          className={styles.employeeBoxMenu}
          onClick={toggleDropdown}
          ref={dropdownTriggerRef}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>
      )}
      <Paper
        ref={readOnly ? null : drag}
        style={{
          overflow: "hidden",
        }}
      >
        <div
          className={`${styles.employeeName} ${
            !readOnly && styles.hoverableEmployeeName
          } ${styles.centerText}`}
        >
          {employeeName}
        </div>
        {noteOnDay && (
          <div className={styles.noteSummaryContainer}>
            <div className={styles.noteSummaryTag} />
            <span className={styles.noteSummary}>{noteOnDay}</span>
          </div>
        )}
      </Paper>
      {isOpen && (
        <div className={styles.employeeBoxMenuModal} ref={dropdownRef}>
          <Notes
            employeeID={employeeID}
            date={date}
            note={noteOnDay}
            saveNote={updateNote}
          />
          <DeleteAllocationButton
            handleDeleteAllocation={() =>
              handleDeleteAllocation(
                employeeID,
                dayIndex,
                rowIndex,
                blockIndex,
                rowName
              )
            }
          />
        </div>
      )}
      {employeeRuleViolation && (
        <div
          className={styles.violationIndicator}
          style={{
            backgroundColor: violationColor,
          }}
        />
      )}
      {message && (
        <div
          className={styles.violationMessage}
          style={{
            left: `-${violationMessageOffset}px`,
          }}
        >
          {message}
        </div>
      )}
      {isHovered && noteOnDay && readOnly && (
        <div className={styles.readOnlyNotes}>
          <ReadOnlyNotes note={noteOnDay} />
        </div>
      )}
    </div>
  );
};

const DemandsIndicator = ({
  employeeNum,
  minValue,
  maxValue,
  scrollContainerRef,
  displayedRowName,
}) => {
  const { areaLabel, allocationLabel } = displayedRowName;
  const fullAllocationLabel = `${areaLabel}${
    areaLabel ? ` ${allocationLabel}` : allocationLabel
  }`;

  const [messageOffset, setMessageOffset] = useState(null);
  const { isOpen, toggleDropdown, selectorTriggerRef, selectorRef } =
    useToggleShow();

  useEffect(() => {
    if (!selectorTriggerRef.current && !isOpen) {
      return;
    }
    const distanceBetweenMessageBoxLeftAndContainerRight =
      getDistanceBetweenElementLeftAndContainerRight(
        selectorTriggerRef.current,
        scrollContainerRef.current
      );

    const offset = 420 - distanceBetweenMessageBoxLeftAndContainerRight;
    setMessageOffset(offset > 0 ? offset : 0);
  }, [selectorTriggerRef, scrollContainerRef, isOpen]);

  let doesMeetDemand = false;
  let label = "";
  let staffNumOffset = 0;

  if (minValue !== null && employeeNum < minValue) {
    label = `${employeeNum}/${minValue}`;
    staffNumOffset = employeeNum - minValue;
  } else if (maxValue !== null && employeeNum > maxValue) {
    label = `${employeeNum}/${maxValue}`;
    staffNumOffset = employeeNum - maxValue;
  } else {
    doesMeetDemand = true;
    label = employeeNum;
  }

  const color = doesMeetDemand ? "#48a03d" : "#FF5F5F";
  return (
    <div className={styles.demandsIndicator}>
      <button
        ref={selectorTriggerRef}
        className={styles.demandsIndicatorButton}
        style={{
          border: `2px solid ${color}`,
        }}
        onClick={toggleDropdown}
      >
        <div className={styles.circle} style={{ backgroundColor: color }} />
        {label}
      </button>
      {staffNumOffset !== 0 && isOpen && (
        <div
          ref={selectorRef}
          className={styles.message}
          style={{
            border: `1px solid ${color}`,
            left: `-${messageOffset}px`,
          }}
        >
          <p>
            You are{" "}
            <span
              className={styles.emph}
              style={{
                color,
              }}
            >
              {staffNumOffset > 0 ? "overstaffed" : "understaffed"} (
              {staffNumOffset > 0 ? "+" : ""}
              {staffNumOffset})
            </span>{" "}
            during {fullAllocationLabel}
          </p>
        </div>
      )}
    </div>
  );
};

function getCellBlockMinWidthInPx(blockColumnData, displayedEmployeeNames) {
  const longestBlockNameWidth = getLongestStrInPx(blockColumnData);
  const longestEmployeeNameWidth = getLongestStrInPx(displayedEmployeeNames);

  const baseWidth =
    longestBlockNameWidth > longestEmployeeNameWidth
      ? longestBlockNameWidth
      : longestEmployeeNameWidth;

  const resultingBlockWidth = baseWidth > 30 ? baseWidth + 50 : 80;
  const maxBlockWidth = 180;
  const resultingBlockWidthWithLimit =
    resultingBlockWidth > maxBlockWidth ? maxBlockWidth : resultingBlockWidth;
  const minFullColumnWidth = 150;

  const widths = {
    minBlockWidth: resultingBlockWidthWithLimit,
    minFullColumnWidth:
      resultingBlockWidthWithLimit > minFullColumnWidth
        ? resultingBlockWidthWithLimit
        : minFullColumnWidth,
  };

  return widths;
}

export function getDisplayedRowName(
  rowName,
  areas,
  shifts,
  tasks,
  shiftGroups
) {
  let rowLabel = rowName;
  const [areaShortId, rest] = rowLabel.split(":");
  if (rest) {
    rowLabel = rest;
  }

  const [shiftShortIds, taskShortIds] = rowLabel.split("||");

  let areaNames = [];
  let shiftNames = [];
  let taskNames = [];

  if (areaShortId) {
    strToArrCommaSeparated(areaShortId).forEach((shortId) => {
      const targetArea = findEntityByShortId(areas, shortId.trim());
      if (targetArea) {
        areaNames.push(targetArea.name);
      }
    });
  }

  if (shiftShortIds) {
    strToArrCommaSeparated(shiftShortIds).forEach((shortId) => {
      const targetShift = shifts.find(
        ({ shortId: id }) => id === shortId.trim()
      );
      const targetShiftGroup = shiftGroups.find(
        ({ shortId: id }) => id === shortId.trim()
      );
      const targetTask = tasks.find(({ shortId: id }) => id === shortId.trim());

      if (targetShift) {
        shiftNames.push(targetShift.name);
      }
      if (targetShiftGroup) {
        shiftNames.push(targetShiftGroup.name);
      }
      if (targetTask) {
        shiftNames.push(targetTask.name);
      }
    });
  }

  if (taskShortIds) {
    strToArrCommaSeparated(taskShortIds).forEach((shortId) => {
      const targetTask = tasks.find(({ shortId: id }) => id === shortId.trim());
      if (targetTask) {
        taskNames.push(targetTask.name);
      }
    });
  }

  const areaLabel = areaNames.join(", ");

  // Join all the shift and task names with commas and spaces, and handle the || separator if task names exist
  let allocationLabel = shiftNames.join(", ");
  if (taskNames.length > 0) {
    allocationLabel += ` || ${taskNames.join(", ")}`;
  }

  return { areaLabel, allocationLabel };
}

function getEmployeesOrderedByFrequency(employeeIdsInEachDay) {
  const allIds = employeeIdsInEachDay.flat();
  const frequencyMap = allIds.reduce((acc, id) => {
    acc[id] = (acc[id] || 0) + 1;
    return acc;
  }, {});

  return Object.entries(frequencyMap)
    .sort(([, countA], [, countB]) => countB - countA)
    .map(([id]) => id);
}

export default RosterTableShiftView;
