import _ from "lodash";
import { DateTime } from "../../dataTypesUtils/DateTime";

export const parseGlobalEmployeeGridDataToModelFormat = (employees) => {
  return employees.map((emp) => {
    return {
      FTE: Number(emp.FTE),
      email: emp.email,
      finishDate: emp.finishDate,
      id: emp.id,
      name: emp.name,
      salary: Number(emp.salary),
      shifts: emp.shifts,
      skills: emp.skills,
      startDate: emp.startDate,
    };
  });
};

export const parseDemandGridDataToModelFormat = (demands) => {
  const demandsInModelForm = [];
  for (const demand of demands) {
    const values = [];
    for (const key in demand) {
      const num = Number(key);
      if (Number.isInteger(num)) {
        const demandVal = _.isString(demand[key])
          ? demand[key].trim()
          : demand[key];
        if (demandVal === null || demandVal === "" || demandVal === undefined) {
          values[num - 1] = undefined;
        } else {
          values[num - 1] = Number(demandVal);
        }
      }
    }

    const convertedDemand = {
      finishTime: DateTime.getFormattedTime(demand.finishTime, "AWS"),
      id: demand.id,
      importance: demand.importance,
      shifts: demand.shifts,
      skills: demand.skills,
      startTime: DateTime.getFormattedTime(demand.startTime, "AWS"),
      type: demand.type,
      values,
      tasks: demand.tasks,
      areas: demand.areas,
      defaultPHValue: demand.publicHoliday,
      conditionalGroup: demand.conditionalGroup,
    };
    demandsInModelForm.push(convertedDemand);
  }
  return demandsInModelForm;
};

export const parseHistoryGridDataToModelFormat = (history) => {
  const historyInModelForm = [];
  for (const hist of history) {
    let valuesInReverseOrder = [];
    for (const key in hist) {
      if (key !== "id" && key !== "name") {
        const num = Number(key.slice(2));
        const histVal = hist[key];
        valuesInReverseOrder[num - 1] = histVal;
      }
    }

    const values = valuesInReverseOrder.reverse();
    const convertedDemand = {
      id: hist.id,
      name: hist.name,
      History: values,
    };
    historyInModelForm.push(convertedDemand);
  }
  return historyInModelForm;
};

export const parseFixedShiftsGridDataToModelFormat = (fixedShifts) => {
  const fixedShiftsInModelForm = [];

  for (const fixedShift of fixedShifts) {
    let allocations = [];
    let allocationsRecurring = [];

    for (const key in fixedShift) {
      if (key !== "id" && key !== "name") {
        const allocationVal = fixedShift[key];
        const num = Number(key.slice(1));
        if (key.startsWith("d")) {
          allocations[num - 1] = allocationVal;
        }
        if (key.startsWith("r")) {
          allocationsRecurring[num - 1] = allocationVal;
        }
      }
    }
    const convertedFixedShifts = {
      id: fixedShift.id,
      name: fixedShift.name,
      Allocations: allocations,
      AllocationsRecurring: allocationsRecurring,
    };
    fixedShiftsInModelForm.push(convertedFixedShifts);
  }
  return fixedShiftsInModelForm;
};

export const parsePreferencesGridDataToModelFormat = (preferences) => {
  const preferencesInModelForm = [];

  for (const preference of preferences) {
    let days = [];
    let daysRecurring = [];

    for (const key in preference) {
      if (key !== "id" && key !== "name") {
        const allocationVal = preference[key];
        const num = Number(key.slice(1));
        if (key.startsWith("d")) {
          days[num - 1] = allocationVal;
        }
        if (key.startsWith("r")) {
          daysRecurring[num - 1] = allocationVal;
        }
      }
    }
    const convertedPreferences = {
      id: preference.id,
      name: preference.name,
      Days: days,
      DaysRecurring: daysRecurring,
    };
    preferencesInModelForm.push(convertedPreferences);
  }
  return preferencesInModelForm;
};

export const parseGlobalPreferencesGridDataToModelFormat = (
  preferences,
  startDate
) => {
  const preferencesInModelForm = [];

  for (const preference of preferences) {
    let days = [];
    let daysRecurring = [];

    for (const key in preference) {
      if (key !== "id" && key !== "name") {
        const allocationVal = preference[key];
        const num = Number(key.slice(1));
        if (key.startsWith("d")) {
          days[num - 1] = allocationVal;
        }
        if (key.startsWith("r")) {
          daysRecurring[num - 1] = allocationVal;
        }
      }
    }

    const daysInGlobalPreferencesFormat = days.map((day, idx) => {
      return {
        date: new DateTime(startDate).addDays(idx).toFormat("AWS"),
        allocation: day,
      };
    });

    const daysInGlobalPreferencesFormatWithoutEmptyAllo =
      daysInGlobalPreferencesFormat.filter((day) => day.allocation !== "");

    const convertedPreferences = {
      id: preference.id,
      name: preference.name,
      Preferences: daysInGlobalPreferencesFormatWithoutEmptyAllo,
      PreferencesRecurring: daysRecurring,
    };
    preferencesInModelForm.push(convertedPreferences);
  }
  return preferencesInModelForm;
};

export const parseRosterTableGridDataToModelFormat = (rosterTableData) => {
  const rosterTableDataInModelForm = [];

  for (const employee of rosterTableData) {
    const RosteredAllocations = [];

    for (const key in employee) {
      if (key.startsWith("d")) {
        const allocationVal = employee[key];
        const num = Number(key.slice(1));
        RosteredAllocations[num - 1] = allocationVal;
      }
    }
    const convertedRosterEmployee = {
      id: employee.id,
      name: employee.name,
      skills: employee.skills,
      RosteredAllocations,
    };
    rosterTableDataInModelForm.push(convertedRosterEmployee);
  }

  return rosterTableDataInModelForm;
};
