import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BasicButton from "../BasicButton/BasicButton";
import styles from "./ModalViewer.module.css";

const ModalViewer = ({
  children,
  isShowing,
  hide,
  invokeHideAfterButtonClick = true,
  title,
  onclickOkay,
  firstBtnLabel = "OK",
  secondBtnLabel = "Cancel",
  onFirstBtnClick = null,
  onSecondBtnClick = null,
  backgroundColor = "white",
  modalWidth = "800px",
  noSecondButton = false,
  themeColor = null,
  buttonWidth = null,
  showCloseIcon = true,
  setOverflow = true,
}) => {
  if (isShowing) {
    return (
      <div className={styles.container}>
        <div
          style={{
            backgroundColor: backgroundColor,
            width: modalWidth,
            ...(setOverflow && { overflowY: "auto" }),
          }}
          className={styles.modal}
        >
          {showCloseIcon && (
            <button className={styles.closeBtn} onClick={hide}>
              <FontAwesomeIcon
                icon={faXmark}
                className={styles.closeIcon}
                style={{
                  ...(themeColor && { color: themeColor }),
                }}
              />
            </button>
          )}
          <h2
            className={styles.title}
            style={{
              ...(themeColor && { color: themeColor }),
            }}
          >
            {title}
          </h2>
          {children}
          <div className={styles["lower-btns-container"]}>
            <BasicButton
              color={themeColor || "#219ec9"}
              hoverColor={themeColor || "#1f91b7"}
              onClick={async () => {
                if (onFirstBtnClick) {
                  await onFirstBtnClick();
                } else {
                  await onclickOkay();
                }
                if (invokeHideAfterButtonClick) {
                  hide();
                }
              }}
              customStyle={{
                borderRadius: "5px",
                fontSize: "20px",
                ...(buttonWidth && { width: buttonWidth }),
              }}
            >
              {firstBtnLabel ? firstBtnLabel : "OK"}
            </BasicButton>
            {!noSecondButton && (
              <BasicButton
                borderColor={themeColor || "#219ec9"}
                hoverColor={themeColor || "#219ec9"}
                onClick={() => {
                  if (onSecondBtnClick) {
                    onSecondBtnClick();
                  }
                  if (invokeHideAfterButtonClick) {
                    hide();
                  }
                }}
                customStyle={{
                  borderRadius: "5px",
                  fontSize: "20px",
                  ...(buttonWidth && { width: buttonWidth }),
                }}
              >
                {secondBtnLabel ? secondBtnLabel : "Cancel"}
              </BasicButton>
            )}
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default ModalViewer;
