import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./GlobalFixedShiftsContainer.module.css";
import withHeader from "../../../../components/layouts/hoc/withHeader/withHeader";
import { useUserStore } from "../../../../globalStore/appStore";
import GetStartedButton from "../../../../components/elements/GetStartedButton/GetStartedButton";
import {
  getFieldsFromLocation,
  interpretCustomKeywordsData,
} from "../../../../utils/queryUtils/locationDataGetters";
import CollapsibleDateRangePicker from "../../../../components/elements/CollapsibleDateRangePicker/CollapsibleDateRangePicker";
import {
  DateTime,
  deepCopyObject,
  duplicateArr,
  getAllGlobalAllocationsFromGrid,
  getDayColumns,
  getIds,
  getLongestAllocationStringInRowData,
  getRecurringColumns,
  getRecurringPeriodLengthSelection,
  removeDuplicateStrInArr,
  sortByDateField,
  twoArraysAreEqual,
} from "../../../../utils";
import {
  detectViewRange,
  handlePageChange,
  onViewRangeChangeBuilder,
} from "../../service/calendar";
import { VIEW_RANGE } from "../../constants/calendarView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import FixedShiftsOverviewGrid from "../../../rosterProblems/components/FixedShifts/FixedShiftsOverviewGrid/FixedShiftsOverviewGrid";
import FixedShiftsRecurringGrid from "../../../rosterProblems/components/FixedShifts/FixedShiftsRecurringGrid/FixedShiftsRecurringGrid";
import { getRosterInView } from "../../../../hooks/modelQueryHooks/useScheduleQuery";
import { getAllRostersWithinDates } from "../../../store/service/shared/sharedStoreApi";
import { useGlobalFixedShiftsColumnWidthStore } from "../../../../globalStore/columnWidthStore";
import { createRosterModelForMainStreamScheduleView } from "../../../../utils/queryUtils/locationQuery";
import { getRosterStartAndFinishDate } from "../../../../utils/queryUtils/rosterDataGetters";
import { updateRosterModel } from "../../../../utils/queryUtils/rosterQuery";
import { useQueryClient } from "@tanstack/react-query";
import {
  getUpdatedLeaveDatesFromGlobalFixedShifts,
  modifyRequests,
} from "../../../../utils/queryUtils/globalEmployeeDataGetters";
import { TABLE_NAMES } from "../../../../constants";
import { getActualNumDays } from "../../../../utils/queryUtils/monthViewUtils";
import { useGetPeriodFromUrl } from "../../../../hooks/useGetPeriodFromUrl";
import { getQueryClient } from "../../../../hooks/modelQueryHooks/queryClientStore";
import { useLocationMutation } from "../../../../hooks/modelQueryHooks/useLocationMutation";
import { PLAN } from "../../../auth/service/auth";
import NotAccessibleView from "../../../../components/elements/NotAccessibleView/NotAccessibleView";
import {
  generateWarningMessages,
  getLeaveDeletionWarnings,
  showLeaveDeletionConfirmation,
} from "../../../scheduleView/components/ScheduleFixedShiftsWrapper/ScheduleFixedShiftsWrapper";
import { useAreaFilter } from "../../../../hooks/useAreaFilter";
import AreaFilter from "../../../../components/elements/AreaFilter/AreaFilter";
import { buildShortIdsToEntityNamesDicts } from "../../../rosterProblems/service/rosterUtils";
import { getInferredPeriodStartAndFinishDate } from "../../../scheduleView/service/scheduleViewRoster";

async function fetchRosterModels(
  locationID,
  numDays,
  startDateString,
  finishDateString
) {
  const rosters = await getAllRostersWithinDates(
    locationID,
    startDateString,
    finishDateString,
    numDays
  );

  const orderedRosters = deepCopyObject(rosters);
  sortByDateField(orderedRosters, "startDate", true);

  return orderedRosters;
}

function getEmployeesFixedShiftsData(
  location,
  globalEmployees,
  rosters,
  startDate,
  finishDate
) {
  const rostersInView = rosters.map((roster) =>
    getRosterInView(location, globalEmployees, roster)
  );

  const dateStrings = DateTime.getAllDateStringsBetween(startDate, finishDate);
  const dateAllocations = dateStrings.map((date) => ({
    date,
    allocation: null,
  }));

  const initialTableData = {};
  for (const employee of globalEmployees) {
    initialTableData[employee.id] = {
      name: employee.name,
      Allocations: dateAllocations,
      AllocationsRecurring: employee.AllocationsRecurring
        ? employee.AllocationsRecurring
        : Array(7).fill(""),
    };
  }

  const tableData = rostersInView.reduce((accumulator, currentRoster) => {
    const { startDate: rosterStartDate, Employees } = currentRoster;
    Employees.forEach((employee) => {
      const { id: employeeId, globalEmployeeID, Allocations } = employee;
      let globalEmployee = globalEmployees.find(
        (emp) => emp.id === globalEmployeeID
      );

      if (!globalEmployee) {
        globalEmployee = globalEmployees.find((emp) => emp.id === employeeId);
      }

      const numDays = Allocations.length;

      const allocations = [];
      for (let i = 0; i < numDays; i++) {
        const date = new DateTime(rosterStartDate).addDays(i);

        if (
          date.isSameOrAfter(dateStrings[0]) &&
          date.isSameOrBefore(dateStrings[dateStrings.length - 1])
        ) {
          allocations.push({
            date: date.toFormat("AWS"),
            allocation: Allocations[i],
          });
        }
      }

      const accumulatedEmployee = accumulator[globalEmployee.id];
      const employeeCopy = deepCopyObject(accumulatedEmployee);
      accumulatedEmployee.AllocationsRecurring =
        globalEmployee.AllocationsRecurring;

      if (allocations.length !== 0) {
        const firstDateOccuranceIdx = accumulatedEmployee.Allocations.findIndex(
          (allo) => allo.date === allocations[0].date
        );

        employeeCopy.Allocations.splice(
          firstDateOccuranceIdx,
          allocations.length,
          ...allocations
        );

        accumulatedEmployee.Allocations = employeeCopy.Allocations;
      }
    });

    return accumulator;
  }, initialTableData);

  return globalEmployees.map((employee) => ({
    id: employee.id,
    skills: employee.skills,
    areas: employee.areas || "",
    startDate: employee.startDate,
    finishDate: employee.finishDate,
    ...tableData[employee.id],
  }));
}

function parseEmployeesFixedShiftsDataToGridFormat(employeesFixedShiftsData) {
  return employeesFixedShiftsData.map((employeeData) => {
    const {
      id,
      name,
      skills,
      areas,
      startDate,
      finishDate,
      Allocations,
      AllocationsRecurring,
    } = employeeData;

    const data = {
      id,
      name,
      skills,
      areas,
      startDate,
      finishDate,
    };

    Allocations.forEach((allo, idx) => {
      data[`d${idx + 1}`] = allo.allocation ? allo.allocation : "";
    });

    AllocationsRecurring.forEach((allo, idx) => {
      data[`r${idx + 1}`] = allo ? allo : "";
    });
    return data;
  });
}

/**
 * This function detects updated Allocations and AllocationsRecurring
 * @param {*} prevEmployeesData
 * @param {*} updatedEmployeesData
 */
function compareEmployeesDataBeforeAndAfterUpdate(
  prevEmployeesData,
  updatedEmployeesData
) {
  return prevEmployeesData.map((prevEmployee) => {
    const {
      id,
      Allocations: prevAllocations,
      AllocationsRecurring: prevAllocationsRecurring,
    } = prevEmployee;
    const updatedEmployee = updatedEmployeesData.find(
      (employee) => employee.id === id
    );
    const {
      Allocations: updatedAllocations,
      AllocationsRecurring: updatedAllocationsRecurring,
    } = updatedEmployee;

    // Detect Updated Allocations
    const editedAllocations = updatedAllocations.filter(
      (updatedAllocation, idx) => {
        const prevAllocation = prevAllocations[idx];
        if (!updatedAllocation.allocation && !prevAllocation.allocation) {
          return false;
        }

        return updatedAllocation.allocation !== prevAllocation.allocation;
      }
    );

    // Detect Updated Allocations Recurring
    const hasRecurringAllocationsEdited = !twoArraysAreEqual(
      prevAllocationsRecurring,
      updatedAllocationsRecurring
    );

    return {
      employeeID: id,
      editedAllocations:
        editedAllocations.length > 0 ? editedAllocations : null,
      editedAllocationsRecurring: hasRecurringAllocationsEdited
        ? updatedAllocationsRecurring
        : null,
    };
  });
}

function getUpdatedAllocationDates(editedEmployeesData) {
  let editedDates = [];
  editedEmployeesData.forEach(({ editedAllocations }) => {
    if (editedAllocations) {
      const dates = removeDuplicateStrInArr([
        ...editedAllocations.map(({ date }) => date),
        ...editedDates,
      ]);
      editedDates.push(...dates);
      editedDates = dates;
    }
  });
  return editedDates;
}

function getRostersToUpdate(editedEmployeesData, rosters, globalEmployees) {
  const rostersToBeUpdated = [];

  const editedDates = getUpdatedAllocationDates(editedEmployeesData);

  editedDates.forEach((date) => {
    const belongingRoster = rosters.find((roster) => {
      const { startDate: rosterStartDate, finishDate: rosterEndDate } =
        getRosterStartAndFinishDate(roster);

      const isDateInRoster = new DateTime(date).isDateBetweenTwoDates(
        rosterStartDate,
        rosterEndDate,
        true,
        true
      );
      return isDateInRoster;
    });

    const rostersToUpdateStartDates = rostersToBeUpdated.map(
      (r) => r.startDate
    );

    if (belongingRoster) {
      if (!rostersToUpdateStartDates.includes(belongingRoster.startDate)) {
        rostersToBeUpdated.push(belongingRoster);
      }
    }
  });

  const rosterUpdateMapper = (roster) => {
    const numDays = roster.numDays;
    const { startDate: rosterStartDate, finishDate: rosterEndDate } =
      getRosterStartAndFinishDate(roster);

    const rosterEmployees = deepCopyObject(roster.Employees);

    // Handle non-existing Roster Employee (when such Global Employee exists)
    editedEmployeesData.forEach(({ employeeID }) => {
      const rosterEmployeeIDs = getIds(rosterEmployees);

      if (!rosterEmployeeIDs.includes(employeeID)) {
        const targetGlobalEmployee = globalEmployees.find(
          (emp) => emp.id === employeeID
        );

        if (targetGlobalEmployee) {
          const { startDate: empStartDate, finishDate: empFinishDate } =
            targetGlobalEmployee;
          const isInRoster = DateTime.isOverlapingDateRangePair(
            rosterStartDate,
            rosterEndDate,
            empStartDate,
            empFinishDate
          );

          if (isInRoster) {
            rosterEmployees.push({
              id: targetGlobalEmployee.id,
              globalEmployeeID: targetGlobalEmployee.id,
              Allocations: Array(numDays).fill(""),
              History: Array(14).fill(""),
              name: targetGlobalEmployee.name,
            });
          }
        }
      }
    });

    const updatedEmployees = rosterEmployees.map((employee) => {
      const editedEmployee = editedEmployeesData.find(
        (data) => data.employeeID === employee.id
      );

      if (!editedEmployee) {
        return employee;
      }

      const { editedAllocations } = editedEmployee;

      if (!editedAllocations) {
        return employee;
      }

      for (const { date, allocation } of editedAllocations) {
        const editedDate = new DateTime(date);
        if (
          editedDate.isAfter(rosterEndDate) ||
          editedDate.isBefore(rosterStartDate)
        ) {
          continue;
        }
        const allocationIndex = DateTime.getDifferenceInDays(
          rosterStartDate,
          date
        );

        employee.Allocations[allocationIndex] = allocation;
      }

      return employee;
    });

    return {
      ...roster,
      Employees: updatedEmployees,
    };
  };

  const updatedRosters = rostersToBeUpdated.map(rosterUpdateMapper);
  return updatedRosters;
}

// Create roster model if needed
const rosterStartDatesCache = {}; // a temporal bs solution for preventing race-condition calling `loadRosterModelsForDateRange` which causes creation of multiple rosters with same startDate
async function loadRosterModelsForDateRange(
  startDate,
  finishDate,
  location,
  globalEmployees,
  existingRosters
) {
  if (!rosterStartDatesCache[location.id]) {
    rosterStartDatesCache[location.id] = [];
  }

  const startDatesCache = rosterStartDatesCache[location.id];

  const numDays = getActualNumDays(startDate, location.defaultNumDays);

  // Re-load rosters in date range just in case it's out of date. But maybe we don't need this if we are sure that `location` always holds the up-to-date rosters
  const fetchedRostersInDateRange = await fetchRosterModels(
    location.id,
    numDays,
    startDate,
    finishDate
  );

  const rosters = existingRosters.map((r) => {
    const fetchedRoster = fetchedRostersInDateRange.find(
      ({ startDate }) => startDate === r.startDate
    );
    return fetchedRoster || r;
  });

  const rosterStartDates = rosters.map(({ startDate }) => startDate);

  rosterStartDates.forEach((date) => {
    if (!startDatesCache.includes(date)) {
      startDatesCache.push(date);
    }
  });

  const allDatesInRange = DateTime.getAllDateStringsBetween(
    startDate,
    finishDate
  );

  const rosterStartDatesToCreate = [];

  allDatesInRange.forEach((date) => {
    const { periodStartDate } = getInferredPeriodStartAndFinishDate(
      location.startDate,
      date,
      location.defaultNumDays
    );

    if (
      !rosterStartDates.includes(periodStartDate) &&
      !rosterStartDatesToCreate.includes(periodStartDate) &&
      !startDatesCache.includes(periodStartDate)
    ) {
      rosterStartDatesToCreate.push(periodStartDate);
    }
  });

  rosterStartDatesToCreate.forEach((date) => {
    if (!startDatesCache.includes(date)) {
      startDatesCache.push(date);
    }
  });

  const createdRosterModels = [];
  for (const newRosterStartDate of rosterStartDatesToCreate) {
    const rosterNumDays = getActualNumDays(
      newRosterStartDate,
      location.defaultNumDays
    );
    const newRoster = await createRosterModelForMainStreamScheduleView(
      location,
      globalEmployees,
      rosterNumDays,
      newRosterStartDate
    );
    createdRosterModels.push(newRoster);
  }

  const allRosters = [...rosters, ...createdRosterModels];
  sortByDateField(allRosters, "startDate", true);

  if (createdRosterModels.length > 0) {
    const queryClient = getQueryClient();
    queryClient.setQueryData(["location", location.id], {
      ...location,
      Rosters: {
        items: allRosters,
      },
    });
  }

  const rostersInDateRange = [
    ...fetchedRostersInDateRange,
    ...createdRosterModels,
  ];

  return rostersInDateRange;
}

const GlobalFixedShiftsContainer = ({
  locationID,
  customKeywordsData,
  globalEmployees,
  location,
}) => {
  const queryClient = useQueryClient();
  const { updateGlobalEmployees, isMutationLoading: isSaving } =
    useLocationMutation(location);

  const { startDate, finishDate } = useGetPeriodFromUrl(
    DateTime.getFirstDateOfCurrentMonth().toFormat("AWS"),
    DateTime.getLastDateOfCurrentMonth().toFormat("AWS")
  );

  const rosters = useMemo(
    () => (location.Rosters ? location.Rosters.items : []),
    [location]
  );

  const [rostersInDateRange, setRostersInDateRange] = useState(null);

  useEffect(() => {
    if (location && globalEmployees) {
      loadRosterModelsForDateRange(
        startDate,
        finishDate,
        location,
        globalEmployees,
        rosters
      ).then(setRostersInDateRange);
    }
  }, [location, globalEmployees, startDate, finishDate, rosters]);

  const isFirstOverviewHeaderRender = useRef(true);

  const history = useHistory();
  const { user, isPaidPlan, plan } = useUserStore();

  const [viewRange, setViewRange] = useState(VIEW_RANGE.month);

  const [overviewGridApi, setOverviewGridApi] = useState(null);
  const [recurringGridApi, setRecurringGridApi] = useState(null);

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([overviewGridApi, recurringGridApi], location.id);

  const {
    name,
    frontendSettings,
    globalShifts: shifts,
    globalSkills: skills,
    globalShiftGroups: shiftGroups,
    globalTasks: tasks,
    globalAreas: areas,
    colorCodes,
    subTasks,
    enumeratedTasks,
    enumeratedShiftTasks,
  } = useMemo(() => getFieldsFromLocation(location), [location]);

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  useEffect(() => {
    if (overviewGridApi) {
      if (isFirstOverviewHeaderRender.current) {
        isFirstOverviewHeaderRender.current = false;
        return;
      }
      setTimeout(() => {
        overviewGridApi.refreshHeader();
      }, 10);
    }
  }, [startDate, overviewGridApi]);

  useEffect(() => {
    setViewRange(detectViewRange(startDate, finishDate));
  }, [startDate, finishDate]);

  const getEmployeesData = useCallback(() => {
    const employeesFixedShiftsData = getEmployeesFixedShiftsData(
      location,
      globalEmployees,
      rostersInDateRange ? rostersInDateRange : [],
      startDate,
      finishDate
    );

    return parseEmployeesFixedShiftsDataToGridFormat(employeesFixedShiftsData);
  }, [finishDate, globalEmployees, location, rostersInDateRange, startDate]);

  const employeesData = useMemo(() => getEmployeesData(), [getEmployeesData]);

  const displayedNumDays =
    DateTime.getDifferenceInDays(
      new DateTime(startDate),
      new DateTime(finishDate)
    ) + 1;

  const longestStr = getLongestAllocationStringInRowData(employeesData, [
    "id",
    "name",
  ]);

  const dayColumns = useMemo(
    () => getDayColumns(displayedNumDays),
    [displayedNumDays]
  );

  const recurringPeriodSelection =
    getRecurringPeriodLengthSelection(employeesData);
  const recurringColumns = getRecurringColumns(
    recurringPeriodSelection === "week" ? 7 : 14
  );

  const shouldInsertOffOnPasteBlank = frontendSettings.find(
    (item) => item.name === "pasting blank inserts off"
  )
    ? true
    : false;

  const {
    weekdayColWidth,
    weekendColWidth,
    isWDayWEndSeparate,
    setWeekdayColWidth,
    setWeekendColWidth,
    setIsWDayWEndSeparate,
  } = useGlobalFixedShiftsColumnWidthStore();

  const customKeywordsUtilObj = interpretCustomKeywordsData(customKeywordsData);
  const { predefinedShiftOptions, annualLeaveKeyword, studyKeyword } =
    customKeywordsUtilObj;

  const updateDateQueryParam = (startDate, finishDate) => {
    history.push({
      pathname: "/locations/fixedshifts",
      search: `?location-id=${locationID}&start-date=${startDate}&finish-date=${finishDate}`,
    });
  };

  const onViewRangeChange = (e) => {
    onViewRangeChangeBuilder(
      e.target.value,
      startDate,
      VIEW_RANGE,
      updateDateQueryParam
    );
  };

  const getDataFromGrid = () => {
    const overviewGridData = getAllGlobalAllocationsFromGrid(
      overviewGridApi,
      dayColumns,
      recurringColumns,
      startDate,
      false
    );
    const recurringGridData = getAllGlobalAllocationsFromGrid(
      recurringGridApi,
      dayColumns,
      recurringColumns,
      startDate,
      false
    );

    return overviewGridData.map(({ FixedShifts, id, name }) => {
      const Allocations = FixedShifts.map(({ allocation, date }) => ({
        date,
        allocation: !allocation ? "" : allocation,
      }));
      const { FixedShiftsRecurring } = recurringGridData.find(
        (data) => data.id === id
      );

      return {
        Allocations,
        AllocationsRecurring: FixedShiftsRecurring,
        id: id,
        name: name,
      };
    });
  };

  const updateFixedShifts = async (updatedEmployeesData) => {
    const prevEmployeesData = getEmployeesFixedShiftsData(
      location,
      globalEmployees,
      rostersInDateRange,
      startDate,
      finishDate
    );

    const editedEmployeesData = compareEmployeesDataBeforeAndAfterUpdate(
      prevEmployeesData,
      updatedEmployeesData
    );

    const updatedLeaveDates = getUpdatedLeaveDatesFromGlobalFixedShifts(
      prevEmployeesData,
      updatedEmployeesData,
      annualLeaveKeyword,
      studyKeyword
    );

    // Check for alert
    const leaveDeletionWarnings = getLeaveDeletionWarnings(
      updatedLeaveDates,
      globalEmployees
    );

    if (leaveDeletionWarnings.length > 0) {
      const warningMessages = generateWarningMessages(leaveDeletionWarnings);
      const response = await showLeaveDeletionConfirmation(warningMessages);

      if (!response) {
        // Revert grid to previous state
        const previousEmployees = getEmployeesData();
        overviewGridApi.applyTransaction({
          update: previousEmployees,
        });
        return;
      }
    }

    /*
     * Update Roster Employee (Allocation)
     */
    const rostersToUpdate = getRostersToUpdate(
      editedEmployeesData,
      rostersInDateRange,
      globalEmployees
    );

    const rostersAfterUpdate = rostersInDateRange.map((roster) => {
      const targetRoster = rostersToUpdate.find((r) => r.id === roster.id);
      if (targetRoster) {
        return targetRoster;
      }
      return roster;
    });

    sortByDateField(rostersAfterUpdate, "startDate", true);

    /*
     * Update Global Employee (AllocationRecurring & Requests)
     */
    const updatedGlobalEmployees = [];
    // Get updated AllocationsRecurring
    for (const editedEmployee of editedEmployeesData) {
      const { employeeID, editedAllocationsRecurring } = editedEmployee;
      if (!editedAllocationsRecurring) {
        continue;
      }
      const globalEmployee = globalEmployees.find(
        (employee) => employee.id === employeeID
      );
      updatedGlobalEmployees.push({
        ...globalEmployee,
        AllocationsRecurring: editedAllocationsRecurring,
      });
    }

    //  Get updated Requests
    const modifiedEmployeeRequests = modifyRequests(
      user,
      globalEmployees,
      updatedLeaveDates,
      startDate,
      finishDate
    );

    modifiedEmployeeRequests.forEach((employeeData) => {
      const employeeIndex = updatedGlobalEmployees.findIndex(
        (emp) => emp.id === employeeData.id
      );
      if (employeeIndex === -1) {
        const globalEmployee = globalEmployees.find(
          (employee) => employee.id === employeeData.id
        );

        // globalEmployee's Request is different to employeeData.Requests
        if (
          JSON.stringify(globalEmployee.Requests) !==
          JSON.stringify(employeeData.Requests)
        ) {
          updatedGlobalEmployees.push({
            ...globalEmployee,
            Requests: employeeData.Requests,
          });
        }
      } else {
        updatedGlobalEmployees[employeeIndex].Requests = employeeData.Requests;
      }
    });

    // Update Rosters if needed
    const rosterUpdatePromises = rostersToUpdate.map(({ id, Employees }) => {
      return updateRosterModel(id, {
        Employees,
      });
    });

    await Promise.all([...rosterUpdatePromises]);

    // Post processing:
    // If any roster(s) is updated, make sure `roster` state have the latest data
    if (rostersToUpdate.length > 0) {
      setRostersInDateRange(rostersAfterUpdate);
    }

    // If any global employee(s) is updated, make sure the tanstack query has the latest info
    if (updatedGlobalEmployees.length > 0) {
      const allGlobalEmployees = globalEmployees.map((employee) => {
        const updateGlobalEmployee = updatedGlobalEmployees.find(
          (emp) => emp.id === employee.id
        );
        if (updateGlobalEmployee) {
          return updateGlobalEmployee;
        }
        return employee;
      });

      const updatedGlobalEmployeesFields = updatedGlobalEmployees.map(
        (employee) => ({
          id: employee.id,
          AllocationsRecurring: employee.AllocationsRecurring,
          Requests: employee.Requests,
        })
      );

      const allRostersAfterUpdate = rosters.map((roster) => {
        const updatedRoster = rostersAfterUpdate.find(
          ({ startDate }) => startDate === roster.startDate
        );
        return updatedRoster || roster;
      });

      const updatedLocation = {
        ...location,
        Employees: {
          items: allGlobalEmployees,
        },
        Rosters: {
          items: allRostersAfterUpdate,
        },
      };

      updateGlobalEmployees(
        updatedGlobalEmployeesFields,
        ["AllocationsRecurring", "Requests"],
        updatedLocation
      );
    }

    if (rostersToUpdate.length > 0) {
      rostersToUpdate.forEach((updatedRoster) => {
        const queryKey = ["roster", updatedRoster.id];
        const previousRosterData = queryClient.getQueryData(queryKey);
        if (previousRosterData) {
          queryClient.setQueryData(queryKey, () => {
            return {
              ...previousRosterData,
              Employees: updatedRoster.Employees,
            };
          });
        }
      });
    }
  };

  const updateRecurringSelection = async (selection) => {
    const dataInGrid = getDataFromGrid();
    let updatedData;

    if (selection === "fortnight") {
      updatedData = dataInGrid.map((rowData) => ({
        ...rowData,
        AllocationsRecurring: duplicateArr(rowData.AllocationsRecurring, 2),
      }));
    } else {
      updatedData = dataInGrid.map((rowData) => ({
        ...rowData,
        AllocationsRecurring: rowData.AllocationsRecurring.slice(0, 7),
      }));
    }
    updateFixedShifts(updatedData);
  };

  if ([PLAN.COORDINATOR, PLAN.COLLABORATOR].includes(plan)) {
    return <NotAccessibleView />;
  }

  if (!isPaidPlan) {
    return (
      <div>
        <h1>Unlock Fixed Shifts with RosterLab AI plan!</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles["inner-container"]}>
        <div className={styles.header}>
          <h1 className={styles.title} data-testid="heading">
            Fixed Shifts - {name}
          </h1>
          <GetStartedButton
            url={
              "https://help.rosterlab.com/fixed-shifts-leave-requests-and-preferences"
            }
          />
        </div>
        <div className={styles["top"]}>
          <div className={styles["top-left"]}>
            <div className={styles.areaFilterContainer}>
              <AreaFilter
                areas={areas}
                onAreaFilterChanged={onAreaFilterChanged}
                onMenuClose={saveAreaFilter}
                defaultValue={initialAreaFilterValue}
              />
            </div>
            <div className={styles["date-picker-wrapper"]}>
              <CollapsibleDateRangePicker
                startDate={startDate}
                finishDate={finishDate}
                urlUpdater={updateDateQueryParam}
              />
            </div>
            <span className={styles.viewLabel}>View: </span>
            <button
              className={styles["date-arrow"]}
              onClick={() => {
                handlePageChange(
                  "decrement",
                  startDate,
                  finishDate,
                  viewRange,
                  updateDateQueryParam
                );
              }}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </button>
            <select
              className={styles["range-selection"]}
              name="range"
              id="range"
              value={viewRange}
              onChange={onViewRangeChange}
            >
              <option value={VIEW_RANGE.month}>Month</option>
              <option value={VIEW_RANGE.fortnight}>2 Weeks</option>
              <option value={VIEW_RANGE.week}>Week</option>
              <option value={VIEW_RANGE.custom}>Custom</option>
            </select>
            <button
              className={styles["date-arrow"]}
              onClick={() => {
                handlePageChange(
                  "increment",
                  startDate,
                  finishDate,
                  viewRange,
                  updateDateQueryParam
                );
              }}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </button>
          </div>
          <div className={styles["top-right"]}></div>
        </div>
        <GridActionHandler
          gridApi={overviewGridApi}
          addNewItemToDB={() => {}}
          updateItemsToDB={updateFixedShifts}
          duplicateItemsToDB={() => {}}
          removeItemsFromDB={() => {}}
          getDataFromGrid={getDataFromGrid}
          getToBeDeletedItems={() => {}}
          parseSelectedRowsToDuplicableInfo={() => {}}
          undoRedoParams={{
            tableName: TABLE_NAMES.GLOBAL_FIXED_SHIFTS,
            getCustomGridSnapshot: () => getDataFromGrid(),
          }}
          disableUndoRedo={true}
        >
          <FixedShiftsOverviewGrid
            locationID={locationID}
            startDate={startDate}
            isSaving={isSaving}
            employeesData={employeesData}
            dayColumns={dayColumns}
            setGridApiToParent={setOverviewGridApi}
            setColumnApiToParent={() => {}}
            overviewWarnings={null}
            shouldSpecifyWeeksInColHeader={false}
            weekdayColWidth={weekdayColWidth}
            weekendColWidth={weekendColWidth}
            isWDayWEndSeparate={isWDayWEndSeparate}
            setWeekdayColWidth={setWeekdayColWidth}
            setWeekendColWidth={setWeekendColWidth}
            setIsWDayWEndSeparate={setIsWDayWEndSeparate}
            enumeratedTasks={enumeratedTasks}
            enumeratedShiftTasks={enumeratedShiftTasks}
            gridApi={overviewGridApi}
            longestStr={longestStr}
            predefinedKeywords={predefinedShiftOptions}
            shouldInsertOffOnPasteBlank={shouldInsertOffOnPasteBlank}
            isScheduleView={true}
            showManageBtn={false}
            areas={areas}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            subTasks={subTasks}
            showSkillsColumn={true}
            skills={skills}
            doesAreaFilterPass={doesAreaFilterPass}
            isExternalFilterPresent={isExternalFilterPresent}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            customKeywordsUtilObj={customKeywordsUtilObj}
            isGlobal={true}
            colorCodes={colorCodes}
          />
        </GridActionHandler>
        <GridActionHandler
          gridApi={recurringGridApi}
          addNewItemToDB={() => {}}
          updateItemsToDB={updateFixedShifts}
          duplicateItemsToDB={() => {}}
          removeItemsFromDB={() => {}}
          getDataFromGrid={getDataFromGrid}
          getToBeDeletedItems={() => {}}
          parseSelectedRowsToDuplicableInfo={() => {}}
          undoRedoParams={{
            tableName: TABLE_NAMES.GLOBAL_FIXED_SHIFTS,
            getCustomGridSnapshot: () => getDataFromGrid(),
          }}
          disableUndoRedo={true}
        >
          <FixedShiftsRecurringGrid
            locationID={locationID}
            startDate={location.startDate}
            isSaving={isSaving}
            employeesData={employeesData}
            enumeratedTasks={enumeratedTasks}
            enumeratedShiftTasks={enumeratedShiftTasks}
            recurringColumns={recurringColumns}
            setGridApiToParent={setRecurringGridApi}
            recurringWarnings={null}
            weekdayColWidth={weekdayColWidth}
            weekendColWidth={weekendColWidth}
            recurringPeriodSelection={recurringPeriodSelection}
            gridApi={recurringGridApi}
            updateRecurringSelection={updateRecurringSelection}
            predefinedKeywords={predefinedShiftOptions}
            shouldInsertOffOnPasteBlank={shouldInsertOffOnPasteBlank}
            areas={areas}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            subTasks={subTasks}
            showSkillsColumn={true}
            skills={skills}
            doesAreaFilterPass={doesAreaFilterPass}
            isExternalFilterPresent={isExternalFilterPresent}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            customKeywordsUtilObj={customKeywordsUtilObj}
            isGlobal={true}
            colorCodes={colorCodes}
          />
        </GridActionHandler>
      </div>
    </div>
  );
};

export const GlobalFixedShiftsContainerNoHeader = GlobalFixedShiftsContainer;

export default withHeader(GlobalFixedShiftsContainer);
