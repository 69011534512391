import styles from "./LiveCalendarDataContainer.module.css";
import logo from "../../../../../assets/images/logo_coloured.png";
import { useHistory } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useRouteQuery } from "../../../../../hooks/useRouteQuery";
import {
  DateTime,
  getNames,
  getShortIds,
  parseEmployeeModelToLiveCalendarFormat,
  parseEmployeeModelToLiveShiftCalendarFormat,
} from "../../../../../utils";
import { inferRosterStartDate } from "../../../../scheduleView/service/scheduleViewRoster";
import SchedulePeriodSwitcher from "../../../../scheduleView/components/SchedulePeriodSwitcher/SchedulePeriodSwitcher";
import { useLiveCalendarNoAuth } from "../../../hooks/useLiveCalendar";
import LiveCalendarGrid from "../LiveCalendarGrid/LiveCalendarGrid";
import LiveShiftCalendarGrid from "../LiveShiftCalendarGrid/LiveShiftCalendarGrid";
import { deobfuscateString } from "../../../../shareRostser/components/ShareRosterModal/ShareRosterModal";
import {
  getCustomKeywordsDataFromFrontendSettings,
  interpretCustomKeywordsData,
} from "../../../../../utils/queryUtils/locationDataGetters.ts";
import LiveShiftViewCalendarGrid from "../LiveShiftViewCalendarGrid/LiveShiftViewCalendarGrid";
import { getActualNumDays } from "../../../../../utils/queryUtils/monthViewUtils";
import { getEmployeesAllocationNotes } from "../../../../../utils/queryUtils/globalEmployeeDataGetters";
import TwoOptionsSwitcher from "../../../../../components/elements/TwoOptionsSwitcher/TwoOptionsSwitcher";
import AreaFilter from "../../../../../components/elements/AreaFilter/AreaFilter";
import { useAreaFilter } from "../../../../../hooks/useAreaFilter";
import { KEYWORD_ALL } from "../../../../../constants/keywords";
import { DEFAULT_FRONTEND_SETTINGS } from "../../../../../constants";
import { getShiftHoursForEmployees } from "../../../../rules/service/rules";
import { getMinMaxRuleValuesForStatsCol } from "../../../../rules/service/ruleFunctions";
import { hasSetting } from "../../../../../utils/settingsUtils/settingsUtils";

const VIEW = Object.freeze({
  employeeView: "employee",
  shiftView: "shift-view",
  customShiftView: "shift",
});

const LiveCalendarDataContainer = () => {
  const [liveCalendarGridApi, setLiveCalendarGridApi] = useState(null);
  const [liveShiftCalendarGridApi, setLiveShiftCalendarGridApi] =
    useState(null);

  let query = useRouteQuery();
  const locationID = query.get("id");
  const rawShiftNames = query.get("s");
  const view = query.get("v");
  const unpublishedEmployeesVisibility = query.get("u");
  const history = useHistory();

  const shouldIncludeUnpublishedEmployees =
    unpublishedEmployeesVisibility === "true";

  const switchView = (view) => {
    if (!Object.values(VIEW).includes(view)) {
      return;
    }

    const newUrl = `/liveschedule?id=${locationID}&s=${rawShiftNames}&v=${view}`;
    history.push(newUrl);
  };

  const {
    employees,
    skills,
    shifts,
    shiftGroups,
    colorCodes,
    locationName,
    tasks,
    taskBlocks,
    subTasks,
    areas,
    numDays: locationNumDays,
    startDate,
    frontendSettings,
    rules,
    demands,
    shiftViewHiddenRows,
    isLoading,
    shortIdsToEntityNamesDicts,
    statistics,
    namesToEntityShortIdsDicts,
  } = useLiveCalendarNoAuth(locationID);

  const [use14Days, setUse14Days] = useState(true);

  const numDays = locationNumDays > 300 && use14Days ? 14 : locationNumDays;

  const customKeywordsUtilObj = useMemo(() => {
    return interpretCustomKeywordsData(
      getCustomKeywordsDataFromFrontendSettings(
        frontendSettings.length > 0
          ? frontendSettings
          : DEFAULT_FRONTEND_SETTINGS
      )
    );
  }, [frontendSettings]);

  const isStatisticsEnabled = hasSetting(
    frontendSettings,
    "enableStatisticsInEmployeeApp"
  );

  const { leaveKeywords: shortNames } = customKeywordsUtilObj;

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([liveCalendarGridApi], locationID);

  const ruleStatsMinMaxValues = useMemo(() => {
    return getMinMaxRuleValuesForStatsCol(
      rules,
      employees,
      getNames(shifts),
      getNames(shiftGroups)
    );
  }, [rules, employees, shifts, shiftGroups]);

  const employeesShiftHours = useMemo(
    () =>
      getShiftHoursForEmployees(
        employees,
        rules,
        shifts,
        shiftGroups,
        customKeywordsUtilObj
      ),
    [employees, rules, shifts, shiftGroups, customKeywordsUtilObj]
  );

  const employeesAllocationNotes = useMemo(
    () => getEmployeesAllocationNotes(employees, false),
    [employees]
  );

  const filteredShifts = useMemo(() => {
    if (rawShiftNames === KEYWORD_ALL) {
      const allocations = [...getShortIds(shifts), ...getShortIds(shiftGroups)];
      if (shortNames) {
        allocations.push(...shortNames);
      }
      return allocations;
    }
    return deobfuscateString(rawShiftNames).split(",");
  }, [rawShiftNames, shortNames, shifts, shiftGroups]);

  const [employeesData, setEmployeesData] = useState([]);
  const [shiftsData, setShiftsData] = useState([]);

  const [schedulePeriodNum, setSchedulePeriodNum] = useState(0);

  const scheduleStartDate = useMemo(() => {
    if (startDate) {
      const inferredStartDate = new DateTime(
        inferRosterStartDate(startDate, new Date(), numDays)
      );

      if (numDays === -1) {
        return new DateTime(inferredStartDate)
          .addMonths(schedulePeriodNum)
          .getDate();
      } else {
        return inferredStartDate.addDays(schedulePeriodNum * numDays).getDate();
      }
    }
  }, [startDate, numDays, schedulePeriodNum]);

  const scheduleFinishDate = useMemo(() => {
    if (!scheduleStartDate) {
      return null;
    }

    if (numDays === -1) {
      return new DateTime(scheduleStartDate)
        .addMonths(1)
        .subtractDays(1)
        .getDate();
    } else {
      return DateTime.addDaysToDate(scheduleStartDate, numDays - 1).toFormat(
        "AWS"
      );
    }
  }, [scheduleStartDate, numDays]);

  const actualNumDays = getActualNumDays(scheduleStartDate, numDays);

  useEffect(() => {
    if (!employees) {
      return;
    }

    setEmployeesData(
      parseEmployeeModelToLiveCalendarFormat(
        employees,
        scheduleStartDate,
        actualNumDays,
        filteredShifts,
        shiftGroups,
        customKeywordsUtilObj,
        shortIdsToEntityNamesDicts,
        shouldIncludeUnpublishedEmployees
      )
    );
    setShiftsData(
      parseEmployeeModelToLiveShiftCalendarFormat(
        employees,
        scheduleStartDate,
        actualNumDays,
        filteredShifts,
        shiftGroups,
        customKeywordsUtilObj,
        shortIdsToEntityNamesDicts
      )
    );
  }, [
    employees,
    scheduleStartDate,
    actualNumDays,
    filteredShifts,
    areas,
    shifts,
    tasks,
    taskBlocks,
    shiftGroups,
    customKeywordsUtilObj,
    shortIdsToEntityNamesDicts,
    shouldIncludeUnpublishedEmployees,
  ]);

  const noEmployeesWithAllocatedShifts = employeesData.length === 0;

  const schedulePeriodSwitcher = (
    <SchedulePeriodSwitcher
      startDate={scheduleStartDate}
      finishDate={scheduleFinishDate}
      onForward={() => {
        setSchedulePeriodNum(schedulePeriodNum + 1);
      }}
      onBackward={() => setSchedulePeriodNum(schedulePeriodNum - 1)}
      periodNum={schedulePeriodNum}
      dropdownItems={[
        {
          label: "Previous schedule period",
          onClick: () => setSchedulePeriodNum(-1),
        },
        {
          label: "Current schedule period",
          onClick: () => setSchedulePeriodNum(0),
        },
        {
          label: "Next schedule period + 1",
          onClick: () => setSchedulePeriodNum(1),
        },
        {
          label: "Next schedule period + 2",
          onClick: () => setSchedulePeriodNum(2),
        },
        {
          label: "Next schedule period + 3",
          onClick: () => setSchedulePeriodNum(3),
        },
      ]}
    />
  );

  const getContent = () => {
    switch (view) {
      case VIEW.shiftView:
        return (
          <LiveShiftViewCalendarGrid
            locationID={locationID}
            employees={employees}
            areas={areas}
            shifts={shifts}
            shiftGroups={shiftGroups}
            demands={demands}
            tasks={tasks}
            taskBlocks={taskBlocks}
            rules={rules}
            scheduleStartDate={scheduleStartDate}
            scheduleFinishDate={scheduleFinishDate}
            startDate={startDate}
            colorCodes={colorCodes}
            customKeywordsUtilObj={customKeywordsUtilObj}
            noEmployeesWithAllocatedShifts={noEmployeesWithAllocatedShifts}
            hiddenRows={shiftViewHiddenRows}
            filteredShifts={filteredShifts}
            numDays={actualNumDays}
            employeesAllocationNotes={employeesAllocationNotes}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
          />
        );
      case VIEW.customShiftView:
        return (
          <LiveShiftCalendarGrid
            shiftsData={shiftsData}
            numDays={actualNumDays}
            startDate={scheduleStartDate}
            areas={areas}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            subTasks={subTasks}
            gridApi={liveShiftCalendarGridApi}
            setGridApi={setLiveShiftCalendarGridApi}
            doesAreaFilterPass={doesAreaFilterPass}
            isExternalFilterPresent={isExternalFilterPresent}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
          />
        );
      default:
        return (
          <LiveCalendarGrid
            locationID={locationID}
            employeesData={employeesData}
            employees={employees}
            colorCodes={colorCodes}
            shortNames={shortNames}
            numDays={actualNumDays}
            startDate={scheduleStartDate}
            employeesAllocationNotes={employeesAllocationNotes}
            gridApi={liveCalendarGridApi}
            setGridApi={setLiveCalendarGridApi}
            doesAreaFilterPass={doesAreaFilterPass}
            isExternalFilterPresent={isExternalFilterPresent}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            areas={areas}
            skills={skills}
            shiftGroups={shiftGroups}
            shifts={shifts}
            tasks={tasks}
            taskBlocks={taskBlocks}
            customKeywordsUtilObj={customKeywordsUtilObj}
            statistics={statistics}
            namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
            employeesShiftHours={employeesShiftHours}
            ruleStatsMinMaxValues={ruleStatsMinMaxValues}
            isStatisticsEnabled={isStatisticsEnabled}
            schedulePeriodNum={schedulePeriodNum}
          />
        );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <img src={logo} className={styles.logo} alt="RosterLab Logo" />
          <h1 className={styles.title}>{locationName}</h1>
        </div>
      </div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className={styles.tableTop}>
            {view !== VIEW.customShiftView && (
              <AreaFilter
                areas={areas}
                onAreaFilterChanged={onAreaFilterChanged}
                onMenuClose={saveAreaFilter}
                defaultValue={initialAreaFilterValue}
              />
            )}
            <div>{schedulePeriodSwitcher}</div>
            {[VIEW.employeeView, VIEW.shiftView].includes(view) && (
              <TwoOptionsSwitcher
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  let destinationView = VIEW.employeeView;
                  if (isChecked) {
                    destinationView = VIEW.shiftView;
                  }
                  switchView(destinationView);
                }}
                isChecked={view === VIEW.shiftView}
                uncheckedLabel="Employee View"
                checkedLabel="Shift View"
              />
            )}
            {locationNumDays > 300 && (
              <div className={styles.checkboxContainer}>
                View 14 days per period:
                <input
                  type="checkbox"
                  checked={use14Days}
                  onChange={(e) => setUse14Days(e.target.checked)}
                  className={styles.checkboxInput}
                />
              </div>
            )}
          </div>
          {getContent()}
        </>
      )}
    </div>
  );
};

export default LiveCalendarDataContainer;
