import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./RosterTable.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import { useRosterAllocationsColumnWidthStore } from "../../../../../globalStore/columnWidthStore";
import useModal from "../../../../../hooks/useModal";
import ModalViewer from "../../../../../components/elements/Modal/ModalViewer";
import ShiftStaffingLevelModal from "../ShiftStaffingLevelModal/ShiftStaffingLevelModal";
import { ColorCodingButton } from "../RosterActionComponents/RosterActionComponents";
import {
  getWeekFromDayColFieldName,
  getMinAndMaxDemandValuesForStaffingLevelGrid,
  getStaffingLevelModalDurationValue,
} from "../../../service/rosterUtils";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import RosterAddRestrictionModal from "../RosterAddRestrictionModal/RosterAddRestrictionModal";
import {
  getShiftAndSkillShortIdFromCountStatsRowId,
  getStatsColumns,
} from "../../service/statsColumns";
import RosterGrid from "../RosterGrid/RosterGrid";
import ColorCodingModal from "../../../../colorCoding/components/ColorCodingModal/ColorCodingModal";
import PrintableSolutionButton from "../../../components/Solutions/PrintableSolution/PrintableSolution";
import {
  createModestContextMenu,
  DateTime,
  getDayColumns,
  getShiftStartAndFinishTime,
  getShortIds,
  onFilterTextBoxChanged,
  redrawBottomPinnedRows,
} from "../../../../../utils";
import { rosterAllocationsDataToPrintable } from "../../service/rosteredAllocationsUtil";
import { findMinMaxDemandsPairByShiftName } from "../../../../statistics/service/statsValueGetter";
import RosterTableShiftView from "../RosterTableShiftView/RosterTableShiftView";
import PublishedCellColorToggler from "../../../../allRosters/components/PublishedCellColorToggler/PublishedCellColorToggler";
import GetStartedButton from "../../../../../components/elements/GetStartedButton/GetStartedButton";
import { PLAN } from "../../../../auth/service/auth";
import { useUserStore } from "../../../../../globalStore/appStore";
import { KEYWORD_NA } from "../../../../../constants/keywords";
import AllocationCellAreaToggler from "../../../../allRosters/components/AllocationCellAreaToggler/AllocationCellAreaToggler";
import { hasSetting } from "../../../../../utils/settingsUtils/settingsUtils";
import Modal from "../../../../../components/elements/Modal/Modal";

const cellRangeToCoords = (cellRanges) => {
  if (cellRanges.length === 0) return [];
  const coords = [];
  let pattern = /d[0-9]+/;

  cellRanges[0].columns.forEach((column) => {
    if (pattern.test(column.colId)) {
      coords.push([
        cellRanges[0].startRow.rowIndex,
        parseInt(column.colId.substring(1)),
      ]);
    }
  });
  return coords;
};

const getColWidthsFromTableSettings = (
  numDays,
  startDate,
  weekdayColWidth,
  weekendColWidth
) => {
  const widths = {};
  getDayColumns(numDays).forEach((colName) => {
    const dow = new DateTime(startDate)
      .addDays(colName.substring(1) - 1)
      .getDayOfWeek();
    if (dow === 0 || dow === 6) {
      widths[colName] = weekendColWidth;
    } else {
      widths[colName] = weekdayColWidth;
    }
  });
  return widths;
};

const RosterTable = ({
  location,
  startDate,
  finishDate,
  rosterName,
  numDays,
  rosterID,
  employeesData,
  shiftNames,
  taskNames,
  enumeratedTasks,
  subtaskNames,
  skills,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  subTasks,
  areas,
  demands,
  rules,
  updateCustomRulesAndEmployees,
  employees,
  isSaving,
  setGridApiToParent,
  updateData,
  longestStr,
  gridApi,
  pinnedBottomRowDataTemplate,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  reorderEmployeesData,
  exportToCsv,
  exportToExcel,
  setColumnApiToParent,
  shiftDemands,
  allocationOptions,
  updateDemands,
  removeDemands,
  isUniqueShiftPopupOpen,
  toggleUniqueShiftPopup,
  showWarningBox = true,
  rosterWarnings,
  statistics,
  preferencesInfo,
  fixedShiftsInfo,
  ruleStatsMinMaxValues,
  invalidCellListWithReasons,
  predefinedShiftOptions,
  uncountableShiftKeywords,
  colorCodes,
  updateColorCodes,
  addShiftGroup,
  updateStatistics,
  employeesShiftHours,
  customTopComponent = null,
  redirectToShiftsPage,
  publishedOnlyAllocations = null,
  requestAllocations = null,
  isPublished = false,
  locationID,
  isMainStream = false,
  showPublishedCellColor = true,
  setShowPublishedCellColor,
  customKeywordsUtilObj,
  openShiftsData,
  openOpenShiftsModal,
  openShifts,
  deleteOpenShift,
  getDataFromGrid,
  employeeDatesWithAcceptedOpenShiftAllocations = null,
  onToolPanelVisibleChanged = null,
  updateRosterTable,
  isShiftView,
  shiftViewHiddenRows,
  updateShiftViewHiddenRows,
  setWizardOpen,
  updateNote,
  employeesAllocationNotes = null,
  isExternalFilterPresent = null,
  doesExternalFilterPass = null,
  shortIdsToEntityNamesDicts,
  namesToEntityShortIdsDicts,
  toggleHideAreaSetting,
  frontendSettings,
  myRosterSettings,
}) => {
  const { displayAreaInAllocationCell } = myRosterSettings;
  const isAreaEnabled = hasSetting(frontendSettings, "locationAreas");

  const { plan } = useUserStore();

  const isTodayIncludedInCurrentPeriod = DateTime.checkTodayIsIncludedInPeriod(
    startDate,
    numDays
  );

  const { annualLeaveKeyword, leaveKeywords, reservedShiftKeywords } =
    customKeywordsUtilObj;
  const [cellClicked, setCellClicked] = useState(null);
  const {
    weekdayColWidth,
    weekendColWidth,
    isWDayWEndSeparate,
    setWeekdayColWidth,
    setWeekendColWidth,
    setIsWDayWEndSeparate,
  } = useRosterAllocationsColumnWidthStore();
  const selectedCoordinates = useRef([]);

  const [isGridFullScreen, setIsGridFullScreen] = useState(false);
  const handleRangeSelectionChanged = (params) => {
    const range = params.api.getCellRanges();
    if (range.length === 0 || range[0].endRow.rowPinned === "bottom") {
      selectedCoordinates.current = [];
      return;
    }
    selectedCoordinates.current = cellRangeToCoords(range);
  };

  const [colWidths, setColWidths] = useState(() => {
    return getColWidthsFromTableSettings(
      numDays,
      startDate,
      weekdayColWidth,
      weekendColWidth
    );
  });

  /**
   * This resolves problem where if you click many employee statistics button very rapidly, cell contents UI breaks unexpectedly.
   * I couldn't find any nicer way to resolve this :(
   */
  useEffect(() => {
    if (gridApi) {
      gridApi.refreshCells();
    }
  }, [statistics, gridApi]);

  useEffect(() => {
    setColWidths(
      getColWidthsFromTableSettings(
        numDays,
        startDate,
        weekdayColWidth,
        weekendColWidth
      )
    );
  }, [weekendColWidth, weekdayColWidth, numDays, startDate]);

  const {
    isShowing: isRestrictionModalOpen,
    toggle: toggleRestrictionModal,
    modalProps: restrictionModalProps,
  } = useModal();

  const {
    isShowing: isStaffingLevelModalOpen,
    toggle: toggleStaffingLevelModal,
    modalProps: staffingLevelModalProps,
  } = useModal();
  const { isShowing: isColorCodingModalOpen, toggle: toggleColorCodingModal } =
    useModal();

  useEffect(() => {
    if (gridApi) {
      setTimeout(() => {
        redrawBottomPinnedRows(gridApi);
      }, 500);
    }
  }, [gridApi]);

  const getContextMenuItems = () => createModestContextMenu();
  const handleUIColDrag = (params) => {
    if ((params.source === "uiColumnDragged") & params.finished) {
      const resizedColumns = params.columns;
      const colWidthPairs = {};
      resizedColumns.forEach((col) => {
        if (col.colDef.field) {
          colWidthPairs[col.colDef.field] = col.actualWidth;
        }
      });

      setColWidths((prev) => ({
        ...prev,
        ...colWidthPairs,
      }));
    }
  };

  const minCounts = ruleStatsMinMaxValues.minCounts;
  const maxCounts = ruleStatsMinMaxValues.maxCounts;

  const customActionBarComponents = useMemo(() => {
    const components = [
      () => <ColorCodingButton onClick={toggleColorCodingModal} />,
    ];
    if (isMainStream) {
      components.push(() => (
        <PublishedCellColorToggler
          showPublishedCellColor={showPublishedCellColor}
          setShowPublishedCellColor={setShowPublishedCellColor}
          labelStyle={{
            color: "#219ec9",
          }}
        />
      ));
    }

    if (isAreaEnabled) {
      components.push(() => (
        <AllocationCellAreaToggler
          onToggle={toggleHideAreaSetting}
          isChecked={displayAreaInAllocationCell}
          labelStyle={{
            color: "#219ec9",
          }}
        />
      ));
    }

    return components;
  }, [
    toggleColorCodingModal,
    setShowPublishedCellColor,
    showPublishedCellColor,
    isMainStream,
    displayAreaInAllocationCell,
    toggleHideAreaSetting,
    isAreaEnabled,
  ]);

  const toggleExpandGrid = () => {
    setIsGridFullScreen((prev) => !prev);
  };

  const onFilterInputChanged = useCallback(
    (inputTagID) => {
      onFilterTextBoxChanged(gridApi, inputTagID);
    },
    [gridApi]
  );

  const isWarningPresent = rosterWarnings && rosterWarnings.length > 0;
  const printableData = rosterAllocationsDataToPrintable(
    employeesData,
    numDays,
    shortIdsToEntityNamesDicts,
    displayAreaInAllocationCell
  );

  const onShiftCountCellClick = (params) => {
    const rowId = params.data.id;

    const { shiftShortId, skillShortId } =
      getShiftAndSkillShortIdFromCountStatsRowId(rowId);

    const cellValue = params.colDef.field;
    const selectedWeek = getWeekFromDayColFieldName(cellValue);
    if (selectedWeek === null) return;

    const refreshRosterGrid = () => redrawBottomPinnedRows(params.api);

    const { shiftDemands, shifts, skills, shiftGroups, demands } =
      params.context;

    const shift = [...shifts, ...shiftGroups].find(
      ({ shortId }) => shortId === shiftShortId
    );
    const skill = skills.find(({ shortId }) => shortId === skillShortId);

    const shiftGroupShortIds = getShortIds(shiftGroups);

    const isShiftGroup = shiftGroupShortIds.includes(shiftShortId);

    let shiftStartTime = null;
    let shiftFinishTime = null;

    if (!isShiftGroup) {
      const { startTime, finishTime } = getShiftStartAndFinishTime(
        shifts,
        shiftShortId
      );
      shiftStartTime = startTime;
      shiftFinishTime = finishTime;
    }

    const shiftDemandsMinMaxPair = findMinMaxDemandsPairByShiftName(
      shiftDemands,
      shiftShortId,
      skillShortId,
      "shiftShortId",
      "skillShortId"
    );

    const demandStartTime = shiftDemandsMinMaxPair.demandStartTime;
    const demandFinishTime = shiftDemandsMinMaxPair.demandFinishTime;

    const duration = isShiftGroup
      ? null
      : getStaffingLevelModalDurationValue(
          shiftDemandsMinMaxPair,
          shiftStartTime,
          shiftFinishTime
        );

    const { minDemandValues, maxDemandValues } =
      getMinAndMaxDemandValuesForStaffingLevelGrid(
        shiftDemandsMinMaxPair,
        numDays
      );

    toggleStaffingLevelModal({
      demands,
      demandStartTime,
      demandFinishTime,
      shiftStartTime,
      shiftFinishTime,
      duration: duration,
      toggleModal: toggleStaffingLevelModal,
      minDemandValues,
      maxDemandValues,
      selectedWeek,
      refreshRosterGrid,
      isShiftGroup,
      shift,
      skill,
    });
  };

  const {
    totalShiftsColGroups,
    totalShiftHoursColGroups,
    weekendsOffCountColDefs,
    preferencesCountColDefs,
    leaveCountColDefs,
  } = useMemo(
    () =>
      getStatsColumns(
        startDate,
        numDays,
        shifts,
        shiftGroups,
        ruleStatsMinMaxValues,
        colWidths,
        uncountableShiftKeywords,
        employeesShiftHours,
        employees,
        tasks,
        taskBlocks,
        customKeywordsUtilObj,
        shortIdsToEntityNamesDicts,
        namesToEntityShortIdsDicts,
        true,
        statistics
      ),
    [
      startDate,
      numDays,
      shiftGroups,
      ruleStatsMinMaxValues,
      colWidths,
      uncountableShiftKeywords,
      employeesShiftHours,
      employees,
      shifts,
      tasks,
      taskBlocks,
      customKeywordsUtilObj,
      shortIdsToEntityNamesDicts,
      statistics,
      namesToEntityShortIdsDicts,
    ]
  );

  return (
    <Layout
      title={rosterName}
      isGridFullScreen={isGridFullScreen}
      hideHeader={false}
    >
      {!isGridFullScreen && (
        <>
          {isMainStream && (
            <GetStartedButton
              url="https://help.rosterlab.com/"
              noPaddingBottom={true}
              setWizardOpen={setWizardOpen}
            />
          )}
        </>
      )}
      <Modal isOpen={isUniqueShiftPopupOpen}>
        <ModalViewer
          isShowing={true}
          title="Before you go ahead..."
          firstBtnLabel="Got it"
          secondBtnLabel="Edit shifts details"
          onFirstBtnClick={() => {}}
          onSecondBtnClick={() => redirectToShiftsPage()}
          hide={toggleUniqueShiftPopup}
        >
          <p>
            All the unique shift types have been created. The default hours for
            shifts are from 9.00 AM to 5.30 PM, 8.5 hours with a 30 mins break
            included.
          </p>
          <br />
          <p>
            If you wish to change the start & end time for your different
            shifts, please click &quot;edit shifts details&quot; button to set
            the details. This will impact the hours counts statistics that
            you&apos;re selecting.
          </p>
        </ModalViewer>
      </Modal>
      {isColorCodingModalOpen && (
        <Modal isOpen={isColorCodingModalOpen}>
          <ColorCodingModal
            handleClose={toggleColorCodingModal}
            shifts={shifts}
            shiftGroups={shiftGroups}
            colorCodes={colorCodes}
            updateColorCodes={updateColorCodes}
            customKeywordsUtilObj={customKeywordsUtilObj}
            namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
          />
        </Modal>
      )}
      {isStaffingLevelModalOpen && (
        <Modal isOpen={isStaffingLevelModalOpen}>
          {isStaffingLevelModalOpen && (
            <ShiftStaffingLevelModal
              {...staffingLevelModalProps}
              startDate={startDate}
              numDays={numDays}
              rosterStartDate={startDate}
              rosterFinishDate={finishDate}
              locationStartDate={location.startDate}
              updateDemands={updateDemands}
              shifts={shifts}
              shiftGroups={shiftGroups}
              removeDemands={removeDemands}
              reservedShiftKeywords={reservedShiftKeywords}
              locationDefaultNumDays={location.defaultNumDays}
            />
          )}
        </Modal>
      )}
      {cellClicked && isRestrictionModalOpen && (
        <Modal isOpen={isRestrictionModalOpen}>
          <RosterAddRestrictionModal
            key={cellClicked[0]}
            isShowing={isRestrictionModalOpen}
            hide={toggleRestrictionModal}
            employees={employees}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            updateCustomRulesWithDominance={(ruleData) =>
              updateCustomRulesWithDominance(
                rules,
                employees,
                ruleData,
                updateCustomRulesAndEmployees
              )
            }
            rosterID={rosterID}
            cellClicked={cellClicked}
            minCounts={minCounts}
            maxCounts={maxCounts}
            setCellClicked={setCellClicked}
            restrictionModalProps={restrictionModalProps}
            onClickOkay={() => {}}
          />
        </Modal>
      )}

      <div className={styles.container}>
        {!isGridFullScreen && customTopComponent}
        {isShiftView ? (
          <>
            <RosterTableShiftView
              locationID={locationID}
              rosterID={rosterID}
              isMainStream={isMainStream}
              demands={demands}
              employees={employees}
              numDays={numDays}
              areas={areas}
              shifts={shifts}
              shiftGroups={shiftGroups}
              tasks={tasks}
              taskBlocks={taskBlocks}
              subTasks={subTasks}
              rules={rules}
              updateRosterTable={updateRosterTable}
              startDate={startDate}
              locationStartDate={location.startDate}
              customKeywordsDict={customKeywordsUtilObj}
              predefinedShiftOptions={predefinedShiftOptions}
              toggleColorCodingModal={toggleColorCodingModal}
              colorCodes={colorCodes}
              readOnly={false}
              locationDefaultNumDays={location.defaultNumDays}
              hiddenRows={shiftViewHiddenRows}
              updateShiftViewHiddenRows={updateShiftViewHiddenRows}
              shouldDefaultViewIncludeToday={isTodayIncludedInCurrentPeriod}
              employeesAllocationNotes={employeesAllocationNotes}
              updateNote={updateNote}
              shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            />
          </>
        ) : (
          <>
            <ActionBar
              locationID={locationID}
              searchBarSettings={{
                tableName: "roster",
                onFilterInputChanged,
              }}
              adjustWidthSettings={{
                longestStr,
                weekdayColWidth,
                weekendColWidth,
                isWDayWEndSeparate,
                setWeekdayColWidth,
                setWeekendColWidth,
                setIsWDayWEndSeparate,
                tableName: "roster",
              }}
              duplicateSelectedSettings={null}
              deleteSelectedSettings={null}
              exportSettings={{
                exportToCsv,
                exportToExcel,
              }}
              customComponents={customActionBarComponents}
              customMoreOptionComponents={[
                () => (
                  <PrintableSolutionButton
                    solutionData={printableData}
                    startDate={startDate}
                    rosterName={rosterName}
                    numDays={numDays}
                    shiftGroups={shiftGroups}
                    size={"A4"}
                    leaveKeywords={leaveKeywords}
                    shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
                    customKeywordsUtilObj={customKeywordsUtilObj}
                    colorCodes={colorCodes}
                    namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
                  />
                ),
                () => (
                  <PrintableSolutionButton
                    solutionData={printableData}
                    startDate={startDate}
                    rosterName={rosterName}
                    numDays={numDays}
                    shiftGroups={shiftGroups}
                    size={"A3"}
                    leaveKeywords={leaveKeywords}
                    shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
                    customKeywordsUtilObj={customKeywordsUtilObj}
                    colorCodes={colorCodes}
                    namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
                  />
                ),
              ]}
            />
            <RosterGrid
              numDays={numDays}
              employeesData={employeesData}
              updateData={updateData}
              handleKeyDownForUndoRedo={handleKeyDownForUndoRedo}
              triggerUndoRedoSnapshotCollection={
                triggerUndoRedoSnapshotCollection
              }
              onShiftCountCellClick={onShiftCountCellClick}
              toggleRestrictionModal={toggleRestrictionModal}
              setCellClicked={setCellClicked}
              setGridApiToParent={setGridApiToParent}
              toggleExpandGrid={toggleExpandGrid}
              pinnedBottomRowDataTemplate={pinnedBottomRowDataTemplate}
              isSaving={isSaving}
              context={{
                location,
                areas,
                shifts,
                shiftGroups,
                tasks,
                taskBlocks,
                subTasks,
                demands,
                taskNames,
                enumeratedTasks,
                subtaskNames,
                rosterID,
                invalidCellListWithReasons,
                shiftDemands,
                preferencesInfo,
                fixedShiftsInfo,
                statistics,
                skills,
                isMainStream,
                locationID,
                isPublished,
                reorderEmployeesData,
                addShiftGroup,
                updateStatistics,
                startDate,
              }}
              setColumnApiToParent={setColumnApiToParent}
              startDate={startDate}
              invalidCellListWithReasons={invalidCellListWithReasons}
              preferencesInfo={preferencesInfo}
              fixedShiftsInfo={fixedShiftsInfo}
              shiftGroups={shiftGroups}
              shiftNames={shiftNames}
              allocationOptions={allocationOptions}
              totalShiftsColGroups={totalShiftsColGroups}
              totalShiftHoursColGroups={totalShiftHoursColGroups}
              leaveCountColDefs={leaveCountColDefs}
              weekendsOffCountColDefs={weekendsOffCountColDefs}
              preferencesCountColDefs={preferencesCountColDefs}
              getContextMenuItems={getContextMenuItems}
              colWidths={colWidths}
              handleUIColDrag={handleUIColDrag}
              handleRangeSelectionChanged={handleRangeSelectionChanged}
              areas={areas}
              skills={skills}
              shifts={shifts}
              tasks={tasks}
              taskBlocks={taskBlocks}
              subTasks={subTasks}
              predefinedShiftOptions={predefinedShiftOptions}
              uncountableShiftKeywords={uncountableShiftKeywords}
              colorCodes={colorCodes}
              employeesShiftHours={employeesShiftHours}
              publishedOnlyAllocations={publishedOnlyAllocations}
              requestAllocations={requestAllocations}
              isMainStream={isMainStream}
              annualLeaveKeyword={annualLeaveKeyword}
              showPublishedCellColor={showPublishedCellColor}
              openShiftsData={openShiftsData}
              openOpenShiftsModal={openOpenShiftsModal}
              openShifts={openShifts}
              deleteOpenShift={deleteOpenShift}
              getDataFromGrid={getDataFromGrid}
              employeeDatesWithAcceptedOpenShiftAllocations={
                employeeDatesWithAcceptedOpenShiftAllocations
              }
              onToolPanelVisibleChanged={onToolPanelVisibleChanged}
              showSideBar={
                ![PLAN.COORDINATOR, PLAN.COLLABORATOR].includes(plan)
              }
              customGridHeight={
                isGridFullScreen
                  ? "calc(100vh - 70px)"
                  : `${
                      window.innerHeight -
                      320 +
                      pinnedBottomRowDataTemplate.length * 28
                    }px`
              }
              rosterWarnings={rosterWarnings}
              updateNote={updateNote}
              employeesAllocationNotes={employeesAllocationNotes}
              isExternalFilterPresent={isExternalFilterPresent}
              doesExternalFilterPass={doesExternalFilterPass}
              shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
              namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
              customKeywordsUtilObj={customKeywordsUtilObj}
              myRosterSettings={myRosterSettings}
            />
            <p className={styles["saving-line"]}>
              {isSaving ? "saving..." : "saved"}
            </p>
            {!isGridFullScreen && (
              <div className={styles.keys}>
                <div className={styles.fixedShiftsKey}>
                  Fulfilled Fixed Shifts
                </div>
                <div className={styles.preferenceKey}>Fulfilled Preference</div>
                <div className={styles.softFailKey}>Recommendation</div>
                <div className={styles.hardFailKey}>Critical Warning</div>
              </div>
            )}
          </>
        )}
      </div>
      {isWarningPresent && showWarningBox && (
        <div className={styles["warning-wrapper"]}>
          <WarningDisplay
            title="Following issues were found:"
            displayedWarnings={getDisplayedWarningsInfo(rosterWarnings)}
          />
        </div>
      )}
    </Layout>
  );
};

export default RosterTable;

const updateCustomRulesWithDominance = async (
  originalCustomRules,
  employees,
  ruleData,
  updateCustomRulesAndEmployees
) => {
  let newCustomRules = [...originalCustomRules];

  const newEmployees = employees.map((emp) => ({
    RuleValues: emp.RuleValues,
    id: emp.id,
  }));

  ruleData.forEach((rule) => {
    const ruleInd = newCustomRules.findIndex(
      (customRule) => customRule.template === rule.template
    );

    if (ruleInd === -1) {
      newCustomRules.push({
        name: rule.ruleName,
        template: rule.template,
      });
    }

    for (let e = 0; e < newEmployees.length; e++) {
      if (ruleInd === -1) {
        if (rule.employeeInds.includes(e))
          newEmployees[e].RuleValues.push(rule.value);
        else {
          newEmployees[e].RuleValues.push(KEYWORD_NA);
        }
      } else if (rule.employeeInds.includes(e)) {
        newEmployees[e].RuleValues[ruleInd] = rule.value.toString();
      }
    }
  });

  updateCustomRulesAndEmployees(newCustomRules, newEmployees);
};
