import styles from "./HistoryGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import { useMemo } from "react";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { useHistoryColumnWidthStore } from "../../../../../globalStore/columnWidthStore";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import DropdownSingleSelector from "../../../../grid/components/DropdownSingleSelector/DropdownSingleSelector";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import HistoryLocked from "../../../../upgradePlan/components/HistoryLocked/HistoryLocked";
import {
  allocationDropdownOptionsValueSetter,
  createBasicContextMenu,
  DateTime,
  flatOptions,
  getDayNumFromColFieldName,
  getFilledMonthsArray,
  getHistoryOptions,
  onFilterTextBoxChanged,
  suppressEnterKey,
} from "../../../../../utils";
import GetStartedButton from "../../../../../components/elements/GetStartedButton/GetStartedButton";
import { useUserStore } from "../../../../../globalStore/appStore";
import { convertAllocationInShortIdFormToNameForm } from "../../../../../utils/modelUtils/allocation";
import {
  getColorCodedCells,
  getWeekendsCellStyle,
} from "../../../rosteredAllocations/service/styleGetters";
import { processAllocationsFromClipboard } from "../../../../../utils/agGridUtils/clipboard";
import { ColorCodingButton } from "../../../rosteredAllocations/components/RosterActionComponents/RosterActionComponents";
import useModal from "../../../../../hooks/useModal";
import ColorCodingModal from "../../../../colorCoding/components/ColorCodingModal/ColorCodingModal";
import Modal from "../../../../../components/elements/Modal/Modal";

const HistoryGrid = ({
  locationID,
  startDate,
  employeesData,
  enumeratedTasks,
  isSaving,
  setGridApiToParent,
  updateData,
  historyColumns,
  historyWarnings,
  longestStr,
  gridApi,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  exportToCsv,
  exportToExcel,
  predefinedKeywords,
  isScheduleView = false,
  customTopComponent,
  shouldInsertOffOnPasteBlank,
  getDataFromGrid,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  areas,
  employeeNames,
  doesAreaFilterPass = null,
  isExternalFilterPresent = null,
  shortIdsToEntityNamesDicts,
  rosterName,
  colorCodes,
  customKeywordsUtilObj,
  namesToEntityShortIdsDicts,
  updateColorCodes,
}) => {
  const { isPaidPlan } = useUserStore();
  const { isShowing: isColorCodingModalOpen, toggle: toggleColorCodingModal } =
    useModal();

  const {
    weekdayColWidth,
    weekendColWidth,
    isWDayWEndSeparate,
    setWeekdayColWidth,
    setWeekendColWidth,
    setIsWDayWEndSeparate,
  } = useHistoryColumnWidthStore();

  const getContextMenuItems = () => {
    return createBasicContextMenu();
  };

  const isWarningPresent = historyWarnings && historyWarnings.length > 0;

  const shiftOptions = useMemo(
    () =>
      getHistoryOptions(
        predefinedKeywords,
        shifts,
        tasks,
        subTasks,
        areas,
        enumeratedTasks,
        shortIdsToEntityNamesDicts
      ),
    [
      areas,
      shifts,
      tasks,
      subTasks,
      enumeratedTasks,
      predefinedKeywords,
      shortIdsToEntityNamesDicts,
    ]
  );

  const datesColumnDefs = useMemo(() => {
    const monthDays = getFilledMonthsArray(historyColumns, startDate);
    const monthColDefs = monthDays.map((month, monthIdx) => {
      return {
        headerName: month.name,
        groupId: `monthGroup${monthIdx}`,
        children: month.days.map((colName, dayIdx) => {
          const offset = getDayNumFromColFieldName(colName) + 1;
          const DoW = new DateTime(startDate)
            .addDays(offset)
            .getDayOfWeek("ddd");
          return {
            headerName: new DateTime(startDate).getDay(offset),
            groupId: `dayGroup_${monthIdx}_${dayIdx}`,
            children: [
              {
                headerName: DoW,
                field: colName,
                cellEditor: "dropdownSingleSelector",
                cellEditorParams: {
                  width: 100,
                  options: shiftOptions,
                },
                cellEditorPopup: true,
                width:
                  DoW === "Sat" || DoW === "Sun"
                    ? weekendColWidth
                    : weekdayColWidth,
                suppressMenu: true,
                cellClassRules: {
                  "invalid-cell": (params) => {
                    if (historyWarnings) {
                      const historyWarning = historyWarnings.find(
                        ({ warningType }) =>
                          warningType === "invalid History input"
                      );
                      if (
                        historyWarning &&
                        historyWarning.values.includes(params.value)
                      ) {
                        return true;
                      }
                    }
                  },
                },
                headerClass: (params) => {
                  const headerName = params.colDef.headerName;
                  const dayOfWeek = headerName.substring(0, 2);
                  if (dayOfWeek === "Sa" || dayOfWeek === "Su") {
                    return "header";
                  }
                },
                cellStyle: (params) => ({
                  ...getWeekendsCellStyle(params),
                  ...getColorCodedCells(
                    params,
                    colorCodes,
                    shiftGroups,
                    shortIdsToEntityNamesDicts,
                    customKeywordsUtilObj
                  ),
                }),
                valueSetter: (params) =>
                  allocationDropdownOptionsValueSetter(
                    params,
                    flatOptions(shiftOptions),
                    false
                  ),
                suppressKeyboardEvent: suppressEnterKey,
                valueFormatter: (params) => {
                  const value = params.value;
                  if (predefinedKeywords.includes(value)) {
                    return value;
                  }
                  const nameCell = convertAllocationInShortIdFormToNameForm(
                    value,
                    shortIdsToEntityNamesDicts
                  );
                  return nameCell || value;
                },
              },
            ],
          };
        }),
      };
    });

    return monthColDefs;
  }, [
    historyColumns,
    startDate,
    weekdayColWidth,
    weekendColWidth,
    historyWarnings,
    shiftOptions,
    predefinedKeywords,
    shortIdsToEntityNamesDicts,
    colorCodes,
    customKeywordsUtilObj,
    shiftGroups,
  ]);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Employees",
        groupId: "employeeGroup",
        children: [
          {
            field: "name",
            sortable: true,
            editable: false,
            suppressSizeToFit: true,
            width: 150,
            pinned: "left",
          },
        ],
      },
      ...datesColumnDefs,
    ],
    [datesColumnDefs]
  );

  const customActionBarComponents = useMemo(() => {
    const components = [
      () => <ColorCodingButton onClick={toggleColorCodingModal} />,
    ];
    return components;
  }, [toggleColorCodingModal]);

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  if (!isPaidPlan) {
    return <HistoryLocked />;
  }

  return (
    <>
      {isColorCodingModalOpen && (
        <Modal isOpen={isColorCodingModalOpen}>
          <ColorCodingModal
            handleClose={toggleColorCodingModal}
            shifts={shifts}
            shiftGroups={shiftGroups}
            colorCodes={colorCodes}
            updateColorCodes={updateColorCodes}
            customKeywordsUtilObj={customKeywordsUtilObj}
            namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
          />
        </Modal>
      )}
      <Layout
        title={isScheduleView ? "History" : `History - ${rosterName}`}
        headerNext={() => (
          <GetStartedButton url={"https://help.rosterlab.com/history"} />
        )}
      >
        <div className={styles["above-grid"]}>
          <span className={styles.subtitle}>
            Note that blank cells do not represent days off but instead are not
            considered by the AI when calculating rules. Please write
            &quot;off&quot; to represent an historical day off.
          </span>
        </div>
        <div className={styles.container}>
          {customTopComponent}
          <ActionBar
            locationID={locationID}
            searchBarSettings={{
              tableName: "history",
              onFilterInputChanged,
            }}
            adjustWidthSettings={{
              longestStr,
              weekdayColWidth,
              weekendColWidth,
              isWDayWEndSeparate,
              setWeekdayColWidth,
              setWeekendColWidth,
              setIsWDayWEndSeparate,
              tableName: "history",
            }}
            customComponents={customActionBarComponents}
            exportSettings={{
              exportToCsv,
              exportToExcel,
            }}
          />
          <DataEntryTable
            columnHoverHighlight={true}
            columnDefs={columnDefs}
            rowData={employeesData}
            updateData={updateData}
            getContextMenuItems={getContextMenuItems}
            processDataFromClipboard={(params) => {
              return processAllocationsFromClipboard(
                params,
                shouldInsertOffOnPasteBlank,
                employeeNames,
                true
              );
            }}
            context={{
              options: flatOptions(shiftOptions),
            }}
            onCellKeyDown={(params) => {
              if (handleKeyDownForUndoRedo) {
                handleKeyDownForUndoRedo(params.event);
              }
            }}
            gridOptions={{
              onCellValueChanged: (params) => {
                if (triggerUndoRedoSnapshotCollection) {
                  triggerUndoRedoSnapshotCollection(params);
                }
              },
            }}
            setGridApiToParent={setGridApiToParent}
            components={{
              dropdownSingleSelector: DropdownSingleSelector,
            }}
            tableName="history"
            getDataFromGrid={getDataFromGrid}
            customStyle={{
              height: `${window.innerHeight - 370}px`,
              minHeight: `500px`,
            }}
            doesExternalFilterPass={doesAreaFilterPass}
            isExternalFilterPresent={isExternalFilterPresent}
          />
          <p className={styles["saving-line"]}>
            {isSaving ? "saving..." : "saved"}
          </p>
        </div>
        {isWarningPresent && (
          <div className={styles["warning-wrapper"]}>
            <WarningDisplay
              title="Following issues were found:"
              displayedWarnings={getDisplayedWarningsInfo(historyWarnings)}
              bottomText={
                isScheduleView
                  ? "You can replace the invalid or one-off shifts with NA or other existing shifts from the shift/shift groups page."
                  : ""
              }
            />
          </div>
        )}
      </Layout>
    </>
  );
};

export default HistoryGrid;
