import { useEffect, useMemo, useState } from "react";
import {
  getAllRulesFromGrid,
  parseRuleModelToRulesGridFormat,
} from "../../../../../utils";
import RulesGrid from "../../../components/Rules/RulesGrid/RulesGrid";
import {
  gatherRulesData,
  reformatRules,
} from "../../../../rules/service/rules";
import { hideColumnInGrid } from "../../../service/singleEmployeeViewUtils";
import { checkRulesWithExceptionEnabled } from "../../../../locations/service/globalSettings";
import { getRuleNamesFromCustomRules } from "../../../../rules/service/ruleFunctions";

const IndRulesContainer = ({
  rules,
  selectedEmployee,
  isSaving,
  updateCustomRules,
  removeRule,
  location,
  warnings,
  roster,
  updateEmployeeWithFields,
  locationID,
  shifts,
  shiftGroups,
  tasks,
  isScheduleView,
}) => {
  const [columnApi, setColumnApi] = useState(null);

  useEffect(() => {
    if (columnApi) {
      hideColumnInGrid([columnApi], ["name"]);
    }
  }, [columnApi]);

  const isRulesWithExceptionEnabled = useMemo(
    () => checkRulesWithExceptionEnabled(location.frontendSettings),
    [location]
  );

  const allRulesData = useMemo(() => gatherRulesData(roster), [roster]);
  const ruleColNameDescriptionPairs = useMemo(() => {
    return reformatRules(allRulesData).map((item) => ({
      ruleColName: item.subruleName,
      description: item.description,
    }));
  }, [allRulesData]);

  const ruleNames = useMemo(
    () => getRuleNamesFromCustomRules(rules, shifts, shiftGroups, tasks),
    [rules, shifts, shiftGroups, tasks]
  );

  const rulesData = useMemo(
    () => parseRuleModelToRulesGridFormat([selectedEmployee], rules, false),
    [selectedEmployee, rules]
  );

  const ruleWarnings = useMemo(() => {
    return warnings ? warnings.CustomRules : null;
  }, [warnings]);

  const getDataFromGrid = (gridApi) => {
    return getAllRulesFromGrid(
      gridApi,
      ruleNames.map(({ name }) => name)
    );
  };

  // If global, implement RuleValues => ruleValues
  const updateData = (newEmployee) => {
    updateEmployeeWithFields(newEmployee[0], ["RuleValues"]);
  };

  return (
    <RulesGrid
      rulesData={rulesData}
      isSaving={isSaving}
      setGridApiToParent={() => {}}
      updateCustomRules={updateCustomRules}
      removeRule={removeRule}
      updateData={updateData}
      ruleNames={ruleNames}
      allRulesData={allRulesData}
      rulesInRoster={rules}
      ruleWarnings={ruleWarnings}
      showManageBtn={false}
      showWarningBox={false}
      customStyle={{
        isSubheading: true,
        dataEntryTable: {
          height: "80px",
          minHeight: "80px",
        },
      }}
      minimalContextMenu={true}
      setColumnApiToParent={setColumnApi}
      isEditable={false}
      columnApi={columnApi}
      reformattedRules={ruleColNameDescriptionPairs}
      isRulesWithExceptionEnabled={isRulesWithExceptionEnabled}
      locationID={locationID}
      getDataFromGrid={getDataFromGrid}
      isIndividualView={true}
      isScheduleView={isScheduleView}
    />
  );
};

export default IndRulesContainer;
