import { useMemo, useState } from "react";
import { TABLE_NAMES } from "../../../../constants";
import { usePreferencesColumnWidthStore } from "../../../../globalStore/columnWidthStore";
import {
  changePrefImportanceLevel,
  convertEmployeeOverviewDataToIndexForm,
  convertManageRequestValuesToAllocations,
  DateTime,
  deepCopyObject,
  duplicateArr,
  getAllPreferencesFromGrid,
  getDayColumns,
  getLongestAllocationStringInRowData,
  getNames,
  getRecurringColumns,
  getRecurringPeriodLengthSelection,
  parseEmployeeModelToPreferencesGridFormat,
  removePendingSuffixedPreferencesFromEmployees,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import PreferencesOverviewGrid from "../../../rosterProblems/components/Preferences/PreferencesOverviewGrid/PreferencesOverviewGrid";
import RequestManagerContainer from "../../../rosterProblems/components/RequestManager/RequestManagerContainer/RequestManagerContainer";
import PreferencesRecurringGrid from "../../../rosterProblems/components/Preferences/PreferencesRecurringGrid/PreferencesRecurringGrid";
import styles from "./SchedulePreferencesWrapper.module.css";
import useModal from "../../../../hooks/useModal";
import { updateEmployeesWithOldEmployeeData } from "../../../../utils/queryUtils/sharedModelDataGetters";
import useStandardDataContainer, {
  getAllStandardUpdateFunctions,
} from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import { interpretCustomKeywordsData } from "../../../../utils/queryUtils/locationDataGetters";
import LoadingPage from "../../../loading/components/LoadingPage/LoadingPage";
import GetStartedButton from "../../../../components/elements/GetStartedButton/GetStartedButton";
import TableSchedulePeriodSwitcher from "../TableSchedulePeriodSwitcher/TableSchedulePeriodSwitcher";
import { getActualInitialStartDate } from "../../../../utils/queryUtils/monthViewUtils";
import { useWarningsStore } from "../../../../globalStore/warningsStore";
import { useUserStore } from "../../../../globalStore/appStore";
import { PLAN } from "../../../auth/service/auth";
import NotAccessibleView from "../../../../components/elements/NotAccessibleView/NotAccessibleView";
import { useAreaFilter } from "../../../../hooks/useAreaFilter";
import AreaFilter from "../../../../components/elements/AreaFilter/AreaFilter";
import {
  buildNamesToEntityShortIdsDicts,
  buildShortIdsToEntityNamesDicts,
} from "../../../rosterProblems/service/rosterUtils";
import { KEYWORD_OFF } from "../../../../constants/keywords";
import Modal from "../../../../components/elements/Modal/Modal";
import ColorCodingModal from "../../../colorCoding/components/ColorCodingModal/ColorCodingModal";

const SchedulePreferencesWrapper = ({
  location,
  locationID,
  periodStartDate,
  periodFinishDate,
  periodNum,
  isRoster,
  rosterID,
  customKeywordsData,
  globalEmployees,
}) => {
  const { plan } = useUserStore();

  const { isShowing: isRequestManagerOpen, toggle: toggleRequestManager } =
    useModal();
  const { isShowing: isColorCodingModalOpen, toggle: toggleColorCodingModal } =
    useModal();

  const [overviewGridApi, setOverviewGridApi] = useState(null);
  const [recurringGridApi, setRecurringGridApi] = useState(null);

  const {
    fields,
    roster,
    isQueryLoading,
    isSaving,
    updateFields,
    createRosterModelForMainStreamInDifferentPeriod,
  } = useStandardDataContainer({
    isScheduleView: !isRoster,
    locationID,
    rosterID,
  });

  const { warnings } = useWarningsStore();

  const {
    name,
    employees,
    skills,
    tasks,
    shifts,
    shiftGroups,
    areas,
    colorCodes,
    numDays,
    startDate: rosterStartDate,
    frontendSettings,
    subTasks,
    enumeratedTasks,
    enumeratedShiftTasks,
    isSnapshot,
  } = fields;

  const { updateColorCodes } = getAllStandardUpdateFunctions(
    deepCopyObject(roster),
    updateFields
  );

  const namesToEntityShortIdsDicts = useMemo(
    () =>
      buildNamesToEntityShortIdsDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const isMainStream = !isRoster && !isSnapshot;
  const employeeNames = useMemo(() => getNames(employees), [employees]);

  const shouldInsertOffOnPasteBlank = frontendSettings.find(
    (item) => item.name === "pasting blank inserts off"
  )
    ? true
    : false;

  let startDate = periodStartDate;
  let finishDate = periodFinishDate;
  if (!isMainStream) {
    startDate = rosterStartDate;
    finishDate = new DateTime(startDate).addDays(numDays - 1).toFormat("AWS");
  }

  const applyEmployeeUpdates = (updatedEmployees, updatedField) => {
    const updatedRoster = {
      ...roster,
      Employees: updateEmployeesWithOldEmployeeData(
        employees,
        updatedEmployees
      ),
    };
    updateFields(["Employees"], updatedRoster, roster, [updatedField]);
  };

  const {
    weekdayColWidth,
    weekendColWidth,
    isWDayWEndSeparate,
    setWeekdayColWidth,
    setWeekendColWidth,
    setIsWDayWEndSeparate,
  } = usePreferencesColumnWidthStore();

  const overviewWarnings = useMemo(() => {
    return warnings ? warnings.Days : null;
  }, [warnings]);

  const recurringWarnings = useMemo(() => {
    return warnings ? warnings.DaysRecurring : null;
  }, [warnings]);

  const customKeywordsUtilObj = interpretCustomKeywordsData(customKeywordsData);

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([overviewGridApi, recurringGridApi], locationID);

  const employeesData = useMemo(() => {
    return parseEmployeeModelToPreferencesGridFormat(employees, numDays, true);
  }, [employees, numDays]);

  const dayColumns = getDayColumns(numDays);

  const recurringPeriodSelection =
    getRecurringPeriodLengthSelection(employeesData);

  const recurringColumns = getRecurringColumns(
    recurringPeriodSelection === "week" ? 7 : 14
  );

  const getPreferencesInGrid = (gridApi) => {
    let employeesInGrid;
    try {
      employeesInGrid = getAllPreferencesFromGrid(
        gridApi,
        dayColumns,
        recurringColumns
      );
    } catch (error) {
      console.error(error);
      return null;
    }

    return employeesInGrid;
  };

  const applyRequests = async (requests) => {
    const employeesData = getPreferencesInGrid(overviewGridApi);
    const newEmployees = convertManageRequestValuesToAllocations(
      requests,
      employeesData,
      "Days",
      startDate,
      finishDate
    );

    await updatePreferencesOverview(newEmployees);
    toggleRequestManager();
  };

  const updatePreferencesOverview = async (newEmployees) => {
    if (isRoster) {
      const newEmployeesWithoutPendingPreferences =
        removePendingSuffixedPreferencesFromEmployees(newEmployees);
      applyEmployeeUpdates(newEmployeesWithoutPendingPreferences, "Days");
    } else {
      const updatedEmployees = newEmployees.map((employee) => {
        if (!isMainStream) {
          const Days = employee.Days;
          return {
            ...employee,
            Days,
          };
        }

        const targetEmployee = employees.find(({ id }) => employee.id === id);
        const existingGlobalPreferences = targetEmployee.Preferences;

        const updatedPreferencesWithinRange = [];

        for (const idx in employee.Days) {
          const allocation = employee.Days[idx];
          if (!allocation) {
            continue;
          }
          const date = new DateTime(periodStartDate)
            .addDays(Number(idx))
            .toFormat("AWS");

          updatedPreferencesWithinRange.push({
            date,
            allocation,
          });
        }

        const preferencesBeforeCurrentPeriod = existingGlobalPreferences.filter(
          ({ date }) => new DateTime(date).isBefore(periodStartDate)
        );
        const preferencesAfterCurrentPeriod = existingGlobalPreferences.filter(
          ({ date }) => new DateTime(date).isAfter(finishDate)
        );

        const updatedGlobalPreferences = [
          ...preferencesBeforeCurrentPeriod,
          ...updatedPreferencesWithinRange,
          ...preferencesAfterCurrentPeriod,
        ];

        return {
          id: employee.id,
          name: employee.name,
          Preferences: updatedGlobalPreferences,
        };
      });

      const updatedField = isMainStream ? "Preferences" : "Days";

      applyEmployeeUpdates(updatedEmployees, updatedField);
    }
  };

  const updatePreferencesRecurring = async (newEmployees) => {
    if (isRoster) {
      const newEmployeesWithoutPendingPreferences =
        removePendingSuffixedPreferencesFromEmployees(newEmployees);
      applyEmployeeUpdates(
        newEmployeesWithoutPendingPreferences,
        "DaysRecurring"
      );
    } else {
      const updatedEmployees = newEmployees.map((employee) => {
        if (!isMainStream) {
          return {
            ...employee,
            DaysRecurring: employee.DaysRecurring,
          };
        }
        return {
          id: employee.id,
          name: employee.name,
          PreferencesRecurring: employee.DaysRecurring,
        };
      });

      const updatedField = isMainStream
        ? "PreferencesRecurring"
        : "DaysRecurring";
      applyEmployeeUpdates(updatedEmployees, updatedField);
    }
  };

  const getDataFromGrid = () => {
    const updatedEmployeesData = getPreferencesInGrid(overviewGridApi);
    const newEmployees = [];
    for (let i = 0; i < updatedEmployeesData.length; i++) {
      newEmployees.push({
        ...updatedEmployeesData[i],
      });
    }
    return newEmployees;
  };

  const longestStr = getLongestAllocationStringInRowData(employeesData, [
    "id",
    "name",
  ]);

  // level: "normal", "high", "critical"
  const changePrefLevel = async (level, isOverview) => {
    if (isOverview) {
      changePrefImportanceLevel(overviewGridApi, level);
      const newEmployees = getDataFromGrid();
      await updatePreferencesOverview(newEmployees);
      return;
    }
    changePrefImportanceLevel(recurringGridApi, level);
    const newEmployees = getDataFromGrid();
    await updatePreferencesRecurring(newEmployees);
  };

  const updateRecurringSelection = async (selection) => {
    const key = isMainStream ? "PreferencesRecurring" : "DaysRecurring";

    const dataInGrid = getPreferencesInGrid(recurringGridApi);
    let recurringData;

    if (selection === "fortnight") {
      recurringData = dataInGrid.map((rowData) => ({
        ...rowData,
        [key]: duplicateArr(rowData.DaysRecurring, 2),
      }));
    } else {
      recurringData = dataInGrid.map((rowData) => ({
        ...rowData,
        [key]: rowData.DaysRecurring.slice(0, 7),
      }));
    }

    applyEmployeeUpdates(recurringData, key);
  };

  const TopControllerComponent = (
    <>
      <div className={styles.topComponents}>
        <AreaFilter
          areas={areas}
          onAreaFilterChanged={onAreaFilterChanged}
          onMenuClose={saveAreaFilter}
          defaultValue={initialAreaFilterValue}
        />
        {isMainStream && (
          <TableSchedulePeriodSwitcher
            location={location}
            periodStartDate={periodStartDate}
            periodFinishDate={periodFinishDate}
            globalEmployees={globalEmployees}
            numDays={numDays}
            isSaving={isSaving}
            periodNum={periodNum}
            pageUrlSlug={"preferences"}
            customContainerStyle={{}}
            createRosterModelForMainStreamInDifferentPeriod={
              createRosterModelForMainStreamInDifferentPeriod
            }
          />
        )}
      </div>
    </>
  );

  if (plan === PLAN.COORDINATOR) {
    return <NotAccessibleView />;
  }

  if (isQueryLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className={styles.wrapper}>
        {isRequestManagerOpen && (
          <RequestManagerContainer
            toggleRequestManager={toggleRequestManager}
            employees={employees}
            startDate={startDate}
            finishDate={finishDate}
            applyRequests={applyRequests}
            overviewDataWithIndex={convertEmployeeOverviewDataToIndexForm(
              getPreferencesInGrid(overviewGridApi),
              "Days"
            )}
            type="preferences"
            enumeratedTasks={enumeratedTasks}
            enumeratedShiftTasks={enumeratedShiftTasks}
            areas={areas}
            skills={skills}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            subTasks={subTasks}
            predefinedKeywords={[KEYWORD_OFF]}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            customKeywordsUtilObj={customKeywordsUtilObj}
          />
        )}
        <div className={styles.header}>
          <h1 className={styles.title} data-testid="heading">
            Preferences
            {isRoster && `- ${name}`}
          </h1>
          <GetStartedButton
            url={
              "https://help.rosterlab.com/fixed-shifts-leave-requests-and-preferences"
            }
          />
        </div>
        <GridActionHandler
          gridApi={overviewGridApi}
          addNewItemToDB={() => {}}
          updateItemsToDB={updatePreferencesOverview}
          duplicateItemsToDB={() => {}}
          removeItemsFromDB={() => {}}
          getDataFromGrid={getDataFromGrid}
          getToBeDeletedItems={() => {}}
          parseSelectedRowsToDuplicableInfo={() => {}}
          undoRedoParams={{
            tableName: TABLE_NAMES.ROSTER_PREFERENCES,
            getCustomGridSnapshot: null,
          }}
        >
          <PreferencesOverviewGrid
            locationID={locationID}
            startDate={startDate}
            employeesData={employeesData}
            enumeratedTasks={enumeratedTasks}
            enumeratedShiftTasks={enumeratedShiftTasks}
            dayColumns={dayColumns}
            setGridApiToParent={setOverviewGridApi}
            setColumnApiToParent={() => {}}
            toggleRequestManager={toggleRequestManager}
            isSaving={isSaving}
            shouldSpecifyWeeksInColHeader={true}
            overviewWarnings={overviewWarnings}
            weekdayColWidth={weekdayColWidth}
            weekendColWidth={weekendColWidth}
            isWDayWEndSeparate={isWDayWEndSeparate}
            setWeekdayColWidth={setWeekdayColWidth}
            setWeekendColWidth={setWeekendColWidth}
            setIsWDayWEndSeparate={setIsWDayWEndSeparate}
            isGlobal={false} //  should it be false?
            longestStr={longestStr}
            changePrefLevel={(level) => changePrefLevel(level, true)}
            gridApi={overviewGridApi}
            customTopComponent={TopControllerComponent}
            shouldInsertOffOnPasteBlank={shouldInsertOffOnPasteBlank}
            isScheduleView={!isRoster}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            subTasks={subTasks}
            areas={areas}
            skills={skills}
            employeeNames={employeeNames}
            doesAreaFilterPass={doesAreaFilterPass}
            isExternalFilterPresent={isExternalFilterPresent}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            customKeywordsUtilObj={customKeywordsUtilObj}
            colorCodes={colorCodes}
            toggleColorCodingModal={toggleColorCodingModal}
          />
        </GridActionHandler>
        <div className={styles.separator}></div>
        {startDate && (
          <GridActionHandler
            gridApi={recurringGridApi}
            addNewItemToDB={() => {}}
            updateItemsToDB={updatePreferencesRecurring}
            duplicateItemsToDB={() => {}}
            removeItemsFromDB={() => {}}
            getDataFromGrid={getDataFromGrid}
            getToBeDeletedItems={() => {}}
            parseSelectedRowsToDuplicableInfo={() => {}}
            undoRedoParams={{
              tableName: TABLE_NAMES.ROSTER_PREFERENCES,
              getCustomGridSnapshot: null,
            }}
          >
            <PreferencesRecurringGrid
              locationID={locationID}
              isSaving={isSaving}
              startDate={getActualInitialStartDate(
                location.isScheduleView ? location.startDate : startDate,
                location.isScheduleView ? location.defaultNumDays : numDays,
                location.isScheduleView
              )}
              enumeratedTasks={enumeratedTasks}
              enumeratedShiftTasks={enumeratedShiftTasks}
              employeesData={employeesData}
              recurringColumns={recurringColumns}
              setGridApiToParent={setRecurringGridApi}
              recurringWarnings={recurringWarnings}
              weekdayColWidth={weekdayColWidth}
              weekendColWidth={weekendColWidth}
              recurringPeriodSelection={recurringPeriodSelection}
              updateRecurringSelection={updateRecurringSelection}
              changePrefLevel={(level) => changePrefLevel(level, false)}
              gridApi={recurringGridApi}
              shouldInsertOffOnPasteBlank={shouldInsertOffOnPasteBlank}
              shifts={shifts}
              shiftGroups={shiftGroups}
              tasks={tasks}
              subTasks={subTasks}
              areas={areas}
              skills={skills}
              employeeNames={employeeNames}
              doesAreaFilterPass={doesAreaFilterPass}
              isExternalFilterPresent={isExternalFilterPresent}
              shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
              customKeywordsUtilObj={customKeywordsUtilObj}
              colorCodes={colorCodes}
              toggleColorCodingModal={toggleColorCodingModal}
            />
          </GridActionHandler>
        )}
      </div>
      {isColorCodingModalOpen && (
        <Modal isOpen={isColorCodingModalOpen}>
          <ColorCodingModal
            handleClose={toggleColorCodingModal}
            shifts={shifts}
            shiftGroups={shiftGroups}
            colorCodes={colorCodes}
            updateColorCodes={updateColorCodes}
            customKeywordsUtilObj={customKeywordsUtilObj}
            namesToEntityShortIdsDicts={namesToEntityShortIdsDicts}
          />
        </Modal>
      )}
    </>
  );
};

export default SchedulePreferencesWrapper;
