import { v4 as uuidv4 } from "uuid";
import {
  DEFAULT_CUSTOM_KEYWORDS,
  getDefaultDisplayedStatistics,
} from "../../constants";
import {
  deepCopyObject,
  generateUniqueKioskCode,
} from "../generalUtils/general";
import { getRosterModelById } from "./rosterQuery";
import { DateTime } from "../dataTypesUtils/DateTime";
import { removeUnmutableModelFields } from "./sharedModelDataGetters";
import { sortByDateField } from "../generalUtils/sort";
import { PLAN } from "../../features/auth/service/auth";
import {
  getCombinedShiftSubtasks,
  getCombinedSubtasks,
  getSubtasks,
} from "../../features/rosterProblems/service/rosterUtils";
import { getDefaultNumDays } from "../../hooks/useCreateLocation";
import { getActualNumDays } from "./monthViewUtils";
import {
  arraysHaveSameContent,
  removeDuplicateStrInArr,
  strToArrCommaSeparated,
} from "../generalUtils/array";
import { getDatesWhereOpenShiftIsPendingAndPublishedAllocationExists } from "../modelUtils/generalModelUtil";
import { sendOpenShiftsNotifications } from "../../features/notifications/service/notifications";
import { customConfirmAlert } from "../../features/confirm/service/confirm";
import { recordStatsInHubspot } from "../hubspotUtils/record";
import {
  KEYWORD_ALL,
  KEYWORD_NA,
  KEYWORD_NO_TASK,
  KEYWORD_OFF,
  KEYWORD_ON,
  KEYWORD_STUDY,
  LONG_NAME_ANNUAL_LEAVE,
  LONG_NAME_RDO,
} from "../../constants/keywords";

export type GlobalEmployeeSchema = {
  id: string;
  externalID: string;
  email: string;
  name: string;
  locationID: string;
  locationName: string;
  ruleValues: string[];
  skills: string;
  shifts: string;
  Requests: any[];
  Preferences: any[];
  PreferencesRecurring: string[];
  PublishedAllocations: any[];
  DaysRecurring: string[];
  AllocationsRecurring: string[];
  TimeEntries: any[];
  FTE: number;
  salary: number;
  startDate: string;
  finishDate: string;
  registrations: string[];
  code?: string;
  areas?: string;
};

export function getNewRoster(rosterInfo) {
  const {
    location,
    name,
    rosteringPeriod,
    startDate,
    isSnapshot,
    snapshotStartDate,
  } = rosterInfo;

  const numDays = getActualNumDays(
    startDate,
    getDefaultNumDays(rosteringPeriod)
  );
  const onShiftGroupExists = location.ShiftGroups.find(
    (group) => group.name === KEYWORD_ON
  );
  const shiftGroups = onShiftGroupExists
    ? location.ShiftGroups
    : [
        {
          id: uuidv4(),
          name: KEYWORD_ON,
          shifts: KEYWORD_OFF,
          inversed: true,
          tasks: "",
          skills: "",
          skillsInversed: false,
          adminUseOnly: false,
          shortId: KEYWORD_ON,
          areas: "",
        },
        ...location.ShiftGroups,
      ];

  return {
    id: uuidv4(),
    locationID: location.id,
    name,
    numDays,
    startDate,
    Employees: [],
    Demands: location.Demands,
    Shifts: location.Shifts,
    ShiftGroups: shiftGroups,
    CustomRules: location.CustomRules,
    RuleExceptions: [],
    Skills: location.Skills,
    Tasks: location.Tasks,
    TaskBlocks: [],
    Areas: [],
    Statistics: getDefaultDisplayedStatistics(),
    ColorCodes: [],
    isPublished: null,
    isSnapshot,
    snapshotStartDate,
  };
}

export function getFieldsFromLocation(location) {
  const shifts = location ? location.Shifts : [];
  const tasks = location ? location.Tasks : [];
  const taskBlocks = location ? location.TaskBlocks : [];
  const subTasks = getSubtasks(tasks, taskBlocks);
  const enumeratedTasks = getCombinedSubtasks(tasks, taskBlocks);
  const enumeratedShiftTasks = getCombinedShiftSubtasks(
    shifts,
    tasks,
    taskBlocks,
    enumeratedTasks,
    subTasks
  );

  return {
    name: location ? location.name : null,
    locationID: location ? location.id : null,
    isScheduleView: location ? location.isScheduleView : null,
    colorCodes: location ? location.ColorCodes : [],
    globalSkills: location ? location.Skills : [],
    globalTasks: tasks,
    globalTaskBlocks: taskBlocks,
    globalShifts: location ? location.Shifts : [],
    globalShiftGroups: location ? location.ShiftGroups : [],
    globalDemands: location ? location.Demands : [],
    globalRules: location ? location.CustomRules : [],
    globalFrontendSettings: location ? location.frontendSettings : [],
    globalAreas: location ? location.Areas || [] : [],
    order: location ? location.order : [],
    frontendSettings: location ? location.frontendSettings : [],
    settings: location ? location.settings : [],
    defaultNumDays: location ? location.defaultNumDays : null,
    startDate: location ? location.startDate : null,
    employees:
      location && location.Employees && location.Employees.items
        ? location.Employees.items.filter((employee) => !employee._deleted)
        : [],
    openShifts: location && location.OpenShifts ? location.OpenShifts : [],
    subTasks: subTasks,
    enumeratedTasks,
    enumeratedShiftTasks,
  };
}

export function getCustomKeywordsDataFromLocation(location) {
  const frontendSettings = location.frontendSettings;
  return getCustomKeywordsDataFromFrontendSettings(frontendSettings);
}

export function getCustomKeywordsDataFromFrontendSettings(frontendSettings) {
  const customKeywordsSettingsName = "annualLeaveKeyword";
  const leaveCodeSetting = frontendSettings.find(
    (setting) => setting.name === customKeywordsSettingsName
  );

  const leaveCodes = leaveCodeSetting.values.map((codeMap) => {
    const [shortname, longname] = codeMap.split(";");
    return { shortname, longname };
  });

  const customKeywords = {
    ...deepCopyObject(DEFAULT_CUSTOM_KEYWORDS),
    leaveCodes,
  };

  const leaveKeywords = customKeywords.leaveCodes
    .filter((code) => code.longname !== LONG_NAME_RDO)
    .map((code) => code.shortname);

  const predefinedShiftOptions = [...leaveKeywords, KEYWORD_OFF, KEYWORD_NA];

  const customKeywordsDict = {
    leaveKeywords,
    dayOffKeyword: KEYWORD_OFF,
    notApplicableKeyword: KEYWORD_NA,
    allKeyword: KEYWORD_ALL,
    predefinedShiftOptions,
  };

  return { customKeywords, customKeywordsDict };
}

export function getUpdatedLocation(location, updatedFields) {
  return {
    ...location,
    ...updatedFields,
  };
}

/**
 * Creates a utility object which interprets customKeywordsData
 */
export function interpretCustomKeywordsData(customKeywordsData) {
  const { customKeywords, customKeywordsDict } = customKeywordsData;
  const { leaveCodes } = customKeywords;
  const { leaveKeywords } = customKeywordsDict;

  const studyKeyword = KEYWORD_STUDY;
  const noTaskKeyowrd = KEYWORD_NO_TASK;

  const annualLeaveCode = customKeywords.leaveCodes.find(
    (code) => code.longname === LONG_NAME_ANNUAL_LEAVE
  );

  const rdoCode = customKeywords.leaveCodes.find(
    (code) => code.longname === LONG_NAME_RDO
  );

  const annualLeaveKeyword = annualLeaveCode
    ? annualLeaveCode.shortname
    : DEFAULT_CUSTOM_KEYWORDS.annualLeave;

  const rdoKeyword = rdoCode
    ? rdoCode.shortname
    : DEFAULT_CUSTOM_KEYWORDS.rosteredDayOff;

  const leaveLongNames = customKeywords.leaveCodes.map((code) => code.longname);

  const predefinedShiftOptions = [...leaveKeywords, KEYWORD_OFF, KEYWORD_NA];

  const reservedShiftKeywords = [
    rdoKeyword,
    annualLeaveKeyword,
    KEYWORD_OFF,
    KEYWORD_NA,
    KEYWORD_ALL,
    noTaskKeyowrd,
    studyKeyword,
  ];

  const reservedKeywords = [KEYWORD_OFF, KEYWORD_NA, studyKeyword, KEYWORD_ALL];

  const reservedShiftsGridData = [
    {
      autoAssigned: "N/A",
      finishTime: "N/A",
      fulfilsDemand: "N/A",
      id: "reserved_shift_leave_AL",
      description: "(Pre-defined Annual leave)",
      name: annualLeaveKeyword,
      skill: "N/A",
      startTime: "N/A",
    },
    {
      autoAssigned: "N/A",
      finishTime: "N/A",
      fulfilsDemand: "N/A",
      id: "reserved_shift_off",
      description: "(Pre-defined Day Off)",
      name: KEYWORD_OFF,
      skill: "N/A",
      startTime: "N/A",
    },
    {
      autoAssigned: "N/A",
      finishTime: "N/A",
      fulfilsDemand: "N/A",
      id: "reserved_shift_ai",
      description: "(Pre-defined Not Available)",
      name: KEYWORD_NA,
      skill: "N/A",
      startTime: "N/A",
    },
  ];

  if (predefinedShiftOptions.includes(KEYWORD_STUDY)) {
    reservedShiftsGridData.push({
      autoAssigned: "N/A",
      finishTime: "N/A",
      fulfilsDemand: "N/A",
      id: "reserved_shift_study",
      description: "(Pre-defined Study Leave)",
      name: studyKeyword,
      skill: "N/A",
      startTime: "N/A",
    });
  }

  return {
    leaveKeywords,
    leaveLongNames,
    allKeyword: KEYWORD_ALL,
    dayOffKeyword: KEYWORD_OFF,
    annualLeaveKeyword,
    rdoKeyword,
    studyKeyword,
    reservedKeywords,
    notApplicableKeyword: KEYWORD_NA,
    leaveCodes,
    predefinedShiftOptions,
    reservedShiftKeywords,
    reservedShiftsGridData,
  };
}

export async function getDuplicatedRoster(rosterID, location, options) {
  const {
    rosterName,
    startDate,
    numDays,
    dataToCarryOver,
    historyUsage,
    shouldCopyRosteredAllocations,
  } = options;

  const roster = await getRosterModelById(rosterID);
  const name = rosterName === "" ? `copy of ${roster.name}` : rosterName;

  // Modify Demands
  const demands = deepCopyObject(roster.Demands);
  const WEEK = 7;
  const FORTNIGHT = 14;

  for (const demand of demands) {
    let newDemandValues;
    if (demand.values.length !== WEEK && demand.values.length !== FORTNIGHT) {
      if (numDays > demand.values.length) {
        newDemandValues = Array(numDays).fill(1);
        for (const idx in demand.values) {
          newDemandValues[idx] = demand.values[idx];
        }
        demand.values = newDemandValues;
      } else {
        demand.values = demand.values.slice(0, numDays);
      }
    }
  }

  // Modify Employees
  const employees = deepCopyObject(roster.Employees);
  for (const employee of employees) {
    const allocations = [...employee.Allocations];
    const days = [...employee.Days];
    const beforeCopyNumDays = allocations.length;

    if (numDays > beforeCopyNumDays) {
      const newAllocations = Array(numDays).fill("");
      const newDays = Array(numDays).fill("");
      for (const idx in allocations) {
        newAllocations[idx] = allocations[idx];
      }
      for (const idx in days) {
        newDays[idx] = days[idx];
      }
      employee.Allocations = newAllocations;
      employee.Days = newDays;
    } else {
      employee.Allocations = employee.Allocations.slice(0, numDays);
      employee.Days = employee.Days.slice(0, numDays);
    }

    if (historyUsage !== "history") {
      employee.History = Array(14).fill("");
    }
  }

  for (const property in dataToCarryOver) {
    if (!dataToCarryOver[property]) {
      switch (property) {
        case "overallFixedRequests":
          for (const emp of employees) {
            emp.Allocations = Array(emp.Allocations.length).fill("");
          }
          break;
        case "recurringFixedRequests":
          for (const emp of employees) {
            emp.AllocationsRecurring = Array(
              emp.AllocationsRecurring.length
            ).fill("");
          }
          break;
        case "overallPreferences":
          for (const emp of employees) {
            emp.Days = Array(emp.Days.length).fill("");
          }
          break;
        case "recurringPreferences":
          for (const emp of employees) {
            emp.DaysRecurring = Array(emp.DaysRecurring.length).fill("");
          }
          break;
        default:
          break;
      }
    }
  }

  if (historyUsage === "roster") {
    for (const emp of employees) {
      if (emp.RosteredAllocations.filter((alloc) => alloc !== "").length > 0) {
        const last14days = emp.RosteredAllocations.slice(-14);
        for (let i = 0; i < 14; i++) {
          emp.History[i] = last14days[i];
          if (emp.History[i] === "" || emp.History[i] === "-") {
            emp.History[i] = KEYWORD_OFF;
          }
        }
      }
    }
  }

  if (!shouldCopyRosteredAllocations) {
    for (const emp of employees) {
      for (let i = 0; i < numDays; i++) {
        emp.RosteredAllocations[i] = "";
      }
    }
  }

  const duplicatedRoster = {
    ...roster,
    id: uuidv4(),
    name,
    numDays,
    startDate: new DateTime(startDate).toFormat("AWS"),
    Employees: employees,
    Demands: demands,
  };

  delete duplicatedRoster.Solutions;

  return removeUnmutableModelFields(duplicatedRoster);
}

export function getOpenShiftTemplate(options) {
  const { date, shift, skills, task, area, number, selectedEmployeeIDs } =
    options;

  const employeeStates = selectedEmployeeIDs.map((employeeID) => ({
    employeeID,
    state: "not-asked",
    isTicked: true,
  }));
  return {
    id: uuidv4(),
    date,
    shift,
    skills,
    task,
    area,
    number,
    publishedNumber: null,
    isPublished: false,
    employeeStates,
  };
}

export function getNewGlobalEmployeesTemplate(options): GlobalEmployeeSchema[] {
  const {
    locationID,
    locationName,
    numItems,
    name,
    nextNumberedSuffix,
    startDate,
    fields = {},
  } = options;

  const defaultGlobalEmployeeInfo = {
    locationID,
    locationName,
    externalID: "",
    email: "",
    ruleValues: [],
    skills: "",
    shifts: KEYWORD_ALL,
    areas: KEYWORD_ALL,
    Requests: [],
    Preferences: [],
    PreferencesRecurring: Array(7).fill(""),
    PublishedAllocations: [],
    TimeEntries: [],
    FTE: 0.0,
    salary: 0.0,
    startDate: startDate ? startDate : "1970-01-01",
    finishDate: "2038-01-01",
    DaysRecurring: null,
    AllocationsRecurring: null,
    registrations: null,
    ...fields,
  };

  const registeredKioskCodes = [];

  const newEntities = Array(numItems)
    .fill(null)
    .map((_, idx) => {
      const entityName = nextNumberedSuffix
        ? `${name} ${nextNumberedSuffix + idx}`
        : name;

      const kioskCode = generateUniqueKioskCode(registeredKioskCodes);
      registeredKioskCodes.push(kioskCode);

      return {
        ...defaultGlobalEmployeeInfo,
        id: uuidv4(),
        name: entityName,
        code: kioskCode,
      };
    });

  return newEntities;
}

export const getRosterIdByPeriodStartDate = (location, periodStartDate) => {
  if (!location) {
    return null;
  }

  const roster = location.Rosters.items.find(
    (roster) => roster.startDate === periodStartDate && !roster.isSnapshot
  );

  if (!roster) {
    return null;
  }
  return roster.id;
};

export const getOpenShiftStatusInfo = (openShift) => {
  const publishedNumber = openShift.publishedNumber;
  const numInvited = openShift.employeeStates.length;
  const numDeclined = openShift.employeeStates.filter(
    (state) => state.state === "declined"
  ).length;
  const numPending = openShift.employeeStates.filter(
    (state) => state.state === "pending"
  ).length;
  const numAccepted = openShift.employeeStates.filter(
    (state) => state.state === "accepted"
  ).length;

  return {
    publishedNumber,
    numInvited,
    numDeclined,
    numPending,
    numAccepted,
  };
};

export const getMyOpenShifts = (
  openShifts,
  employee,
  skills,
  tasks,
  shifts,
  areas
) => {
  const applicableConditions = ["pending", "accepted", "declined"];
  if (!employee) {
    return [];
  }
  const employeeID = employee.id;
  const applicableOpenShifts = openShifts.filter((openShift) => {
    let isMyOpenShift = false;
    const myState = openShift.employeeStates.find(
      (openShift) => openShift.employeeID === employeeID
    );

    if (!myState) {
      return false;
    }

    const { publishedNumber, numAccepted } = getOpenShiftStatusInfo(openShift);
    if (!(publishedNumber > numAccepted) && myState.state !== "accepted") {
      return false;
    }

    if (!myState) {
      return false;
    }

    if (applicableConditions.includes(myState.state)) {
      isMyOpenShift = true;
    }
    return isMyOpenShift;
  });

  return applicableOpenShifts.map((openShift) => {
    const {
      shift: shiftValue,
      task: taskValue,
      skills: skillsValue,
      area: areaValue,
    } = openShift;
    const targetShift = shifts.find(({ shortId }) => shiftValue === shortId);
    const targetTask = tasks.find(({ shortId }) => taskValue === shortId);
    const targetArea = areas.find(({ shortId }) => areaValue === shortId);

    const displayedShift = targetShift ? targetShift.name : shiftValue;
    const displayedTask = targetTask ? targetTask.name : taskValue;
    const displayedSkills = strToArrCommaSeparated(skillsValue)
      .map((skillValue) => {
        const targetSkill = skills.find(
          ({ shortId }) => shortId === skillValue
        );
        return targetSkill ? targetSkill.name : skillValue;
      })
      .join(", ");
    const displayedArea = targetArea ? targetArea.name : areaValue;

    let shiftTimeRange = "";
    if (targetShift && targetShift.startTime && targetShift.finishTime) {
      const startTime = DateTime.getFormattedTime(
        targetShift.startTime,
        "12hr"
      );
      const finishTime = DateTime.getFormattedTime(
        targetShift.finishTime,
        "12hr"
      );

      shiftTimeRange = `${startTime} - ${finishTime}`;
    }

    return {
      ...openShift,
      displayedShift,
      displayedTask,
      displayedSkills,
      displayedArea,
      shiftTimeRange,
    };
  });
};

/**
 * - Check if employee can take open shift on a specific date when "publish" is executed
 * @param date aws date
 * @param publishedAllocations globalEmployee.PublishedALlocations
 */
export function canEmployeeTakeOpenShift(date, publishedAllocations) {
  const targetAllocation = publishedAllocations.find(
    (allocation) => allocation.date === date
  );
  if (!targetAllocation) {
    return true;
  }

  if (targetAllocation.draftAllocation) {
    return false;
  }

  if (
    targetAllocation.draftAllocation == null &&
    targetAllocation.publishedAllocation
  ) {
    return false;
  }

  return true;
}

export function separateAcceptedOpenShiftsAsEntity(openShifts) {
  const resultingOpenShifts = [];

  for (const openShift of openShifts) {
    const employeeStates = openShift.employeeStates;
    const number = openShift.number;
    const publishedNumber = openShift.publishedNumber;

    const acceptedStates = [];
    const unacceptedStates = [];

    employeeStates.forEach((employeeState) => {
      if (employeeState.state === "accepted") {
        acceptedStates.push(employeeState);
      } else {
        unacceptedStates.push(employeeState);
      }
    });

    acceptedStates.forEach((employeeState) => {
      resultingOpenShifts.push({
        ...openShift,
        displayedInfo: {
          isAccepted: true,
          number: 1,
          publishedNumber: 1,
          employeeStates: [employeeState],
        },
      });
    });

    const untakenOpenShiftsNum = number - acceptedStates.length;
    const untakenOpenShiftsPublishedNum =
      publishedNumber - acceptedStates.length;

    if (untakenOpenShiftsNum > 0) {
      resultingOpenShifts.push({
        ...openShift,
        displayedInfo: {
          isAccepted: false,
          number: untakenOpenShiftsNum,
          publishedNumber: openShift.isPublished
            ? untakenOpenShiftsPublishedNum
            : null,
          employeeStates: unacceptedStates,
        },
      });
    }
  }

  return resultingOpenShifts;
}

export function filterPastOpenShifts(openShifts) {
  const today = new DateTime(new Date());
  return openShifts.filter((openShift) => {
    if (today.isAfter(openShift.date)) {
      return false;
    }
    return true;
  });
}

export function getLocationPlan(location) {
  const plan = location.plan;
  return plan ? plan : PLAN.AI;
}

export function checkCheckLeaveSettingEnabled(frontendSettings) {
  const checkLeaveSetting = frontendSettings.find(
    (setting) => setting.name === "checkLeave"
  );
  if (!checkLeaveSetting) {
    return true;
  }

  if (checkLeaveSetting.values.includes("false")) {
    return false;
  }

  return true;
}

export async function publishOpenShifts(
  location,
  globalEmployees,
  publishStartDate,
  finishDate,
  shifts,
  tasks
) {
  const openShifts = location.OpenShifts;

  // Check if employee already have pending open shift for a published shift
  const conflictingPublishedAllocationDatesInfoDueToPendingOpenShift =
    getDatesWhereOpenShiftIsPendingAndPublishedAllocationExists(
      globalEmployees,
      openShifts
    );

  const openShiftsWithinRange = [];
  const openShiftsOutOfRange = [];

  openShifts.forEach((openShift) => {
    const openShiftDate = new DateTime(openShift.date);
    const isWithinRange = openShiftDate.isDateBetweenTwoDates(
      publishStartDate,
      finishDate,
      true,
      true
    );
    if (isWithinRange) {
      openShiftsWithinRange.push(openShift);
    } else {
      openShiftsOutOfRange.push(openShift);
    }
  });

  const notInvitedDueToClash = [];
  const newInvitations = [];

  openShiftsWithinRange.forEach((openShift) => {
    const { date, employeeStates, isPublished } = openShift;
    employeeStates.forEach((state) => {
      const targetEmployee = globalEmployees.find(
        (employee) => employee.id === state.employeeID
      );
      if (targetEmployee && state.state === "not-asked" && !isPublished) {
        if (
          canEmployeeTakeOpenShift(date, targetEmployee.PublishedAllocations)
        ) {
          const { task, shift } = openShift;
          const targetShift = shifts.find((s) => s.shortId === shift);
          const targetTask = tasks.find((t) => t.shortId === task);
          const shiftName = targetShift ? targetShift.name : "";
          const taskName = targetTask ? targetTask.name : "";
          newInvitations.push({
            date,
            employee: targetEmployee,
            openShift,
            taskName: task === KEYWORD_NO_TASK ? KEYWORD_NO_TASK : taskName,
            shiftName,
          });
        } else {
          notInvitedDueToClash.push({
            date,
            employee: targetEmployee,
            openShift,
          });
        }
      }
    });
  });

  const updatedOpenShiftsWithinRange = openShiftsWithinRange.map(
    (openShift) => {
      const { date, employeeStates } = openShift;
      const updatedEmployeeStates = employeeStates
        .map((state) => {
          const targetEmployee = globalEmployees.find(
            (employee) => employee.id === state.employeeID
          );

          if (!targetEmployee) {
            return null;
          }

          const publishedAllocations = targetEmployee.PublishedAllocations;
          if (
            state.state === "not-asked" &&
            canEmployeeTakeOpenShift(date, publishedAllocations)
          ) {
            return {
              ...state,
              state: "pending",
            };
          }
          return state;
        })
        .filter((state) => state !== null);

      return {
        ...openShift,
        publishedNumber: openShift.number,
        employeeStates: updatedEmployeeStates,
        isPublished: true,
      };
    }
  );

  let updatedOpenShifts = [
    ...openShiftsOutOfRange,
    ...updatedOpenShiftsWithinRange,
  ];

  // Delete open shift states (pending) if the employee got a published shift on that day
  for (const info of conflictingPublishedAllocationDatesInfoDueToPendingOpenShift) {
    const employeeID = info.employeeID;
    const toBeDeletedStateDates = info.conflictingDates;

    updatedOpenShifts = updatedOpenShifts.map((openShift) => {
      if (!toBeDeletedStateDates.includes(openShift.date)) {
        return openShift;
      }

      const updatedEmployeeStates = openShift.employeeStates.filter(
        (state) => state.employeeID !== employeeID
      );
      return {
        ...openShift,
        employeeStates: updatedEmployeeStates,
      };
    });
  }

  sortByDateField(updatedOpenShifts, "date", true);

  if (newInvitations.length > 0) {
    await sendOpenShiftsNotifications(location, newInvitations);
  }

  if (notInvitedDueToClash.length > 0) {
    customConfirmAlert({
      title: "Open Shifts Invites Sent",
      descriptions: [
        "Some employees were not invited to an open shift as they already have a shift on that day.",
      ],
      hideCancel: true,
    });
  }

  return updatedOpenShifts;
}

export function addCompletedOnboardingTasks(location, taskIDs) {
  const updatedLocation = deepCopyObject(location);
  const completedOnboardingTasks = location.completedOnboardingTasks || [];

  if (taskIDs.length === 0) {
    return updatedLocation;
  }

  const updatedCompletedTasks = removeDuplicateStrInArr([
    ...completedOnboardingTasks,
    ...taskIDs,
  ]);

  if (arraysHaveSameContent(completedOnboardingTasks, updatedCompletedTasks)) {
    return updatedLocation;
  }

  updatedLocation.completedOnboardingTasks = updatedCompletedTasks;
  recordStatsInHubspot(updatedCompletedTasks, completedOnboardingTasks);

  return updatedLocation;
}
