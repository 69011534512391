import _ from "lodash";
import { DateTime } from "../utils/dataTypesUtils/DateTime";
import { PLAN } from "../features/auth/service/auth";
import { LONG_NAME_ANNUAL_LEAVE, LONG_NAME_RDO } from "./keywords";

export const DEFAULT_DEMANDS_START_TIME = DateTime.getFormattedTime(
  "9:00",
  "AWS"
);
export const DEFAULT_DEMANDS_FINISH_TIME = DateTime.getFormattedTime(
  "17:00",
  "AWS"
);

const DEFAULT_ROSTER_STATISTICS = Object.freeze({
  employeeShiftCountsToShow: [],
  employeeSkillCountsToShow: [],
  employeeHoursToShow: [],
  dayShiftSkillToggleDisplayed: [],
  dayHoursToShow: [],
  dayShiftCountsToShow: [],
  daySkillCountsToShow: [],
  dayShiftSkillCountsToShow: [],
  leaveCountsToShow: [],
  otherSettings: [],
});

export const getDefaultDisplayedStatistics = () => {
  return _.clone(DEFAULT_ROSTER_STATISTICS);
};

export const DEFAULT_LOCATION_NAME = "New Location";

export const DEFAULT_CUSTOM_KEYWORDS = Object.freeze({
  leaveCodes: [
    { shortname: "AL", longname: LONG_NAME_ANNUAL_LEAVE },
    { shortname: "RDO", longname: LONG_NAME_RDO },
  ],
  annualLeave: "AL",
  rosteredDayOff: "RDO",
  leaveKeywords: ["AL", "RDO"],
});

export const DEFAULT_LEAVE_KEYWORD_SETTING = {
  name: "annualLeaveKeyword",
  values: [
    `${DEFAULT_CUSTOM_KEYWORDS.annualLeave};${LONG_NAME_ANNUAL_LEAVE}`,
    `${DEFAULT_CUSTOM_KEYWORDS.rosteredDayOff};${LONG_NAME_RDO}`,
  ],
};

export const DEFAULT_CHECK_LEAVE_SETTING = {
  name: "checkLeave",
  values: ["true"],
};

const DEFAULT_DEMAND_SETTING = {
  name: "shiftTaskOnlyDemands",
};

export const DEFAULT_FRONTEND_SETTINGS = [
  DEFAULT_LEAVE_KEYWORD_SETTING,
  DEFAULT_CHECK_LEAVE_SETTING,
];

export const getDefaultFrontendSettings = (plan) => {
  if (plan === PLAN.LITE || plan === PLAN.MID)
    return [...DEFAULT_FRONTEND_SETTINGS, DEFAULT_DEMAND_SETTING];

  return DEFAULT_FRONTEND_SETTINGS;
};
