import { Capacitor } from "@capacitor/core";
import {
  getUserEmail,
  getUserGroups,
  PLAN,
} from "../../features/auth/service/auth";

export const isProduction =
  location.hostname === "app.rosterlab.com" ||
  (location.hostname === "localhost" && Capacitor.isNativePlatform());

export const canAccessOnboardingWizard = (plan) => {
  return !isProduction || plan === PLAN.MID;
};

export const canAccessExportDemands = (email) => {
  return !isProduction || email.endsWith("@rosterlab.com");
};

export const canAccessConditionalDemands = (email) => {
  return (
    !isProduction ||
    email.endsWith("@rosterlab.com") ||
    doesListContainEmail(email, ["roster@peticare.com.hk"])
  );
};

export const canOpenOnboardingWizard = () => {
  return !isProduction;
};

export const canAccessLongFormFailExplanation = () => {
  return !isProduction;
};

export const canAccessReportGenerator = (email) => {
  return doesListContainEmail(email, [
    "MargaretT@kensingtonhospital.co.nz",
    "kimbines@kensingtonhospital.co.nz",
    "AlexS@kensingtonhospital.co.nz",
    "SandyB@kensingtonhospital.co.nz",
    "rosters@kensingtonhospital.co.nz",
    "daniel@rosterlab.com",
    "isaac@rosterlab.com",
  ]);
};

export const canSeeMobileAdminPage = (user) => {
  const groups = getUserGroups(user);

  if (groups.includes("494d00cc-45c8-4a60-92d8-6bf9e656064b_coordinator"))
    return true;
  if (getUserEmail(user) === "roster@peticare.com.hk") return true;

  return !isProduction;
};

export const canAccessExtendedRosterPeriods = (email) => {
  return doesListContainEmail(email, [
    "jason@rosterlab.com",
    "daniel@rosterlab.com",
    "isaac@rosterlab.com",
    "sunny@rosterlab.com",
  ]);
};

export const canAccessRerostering = (plan) => {
  return plan === PLAN.AI || plan === PLAN.COLLABORATOR;
};

export const canAccessSurvey = (email) => {
  return doesListContainEmail(email, surveyData.emails);
};

export const getFormData = (isProductionForm) => {
  if (isProductionForm) return surveyData.productionForm;
  else return surveyData.testForm;
};

const doesListContainEmail = (email, listOfEmails) => {
  const lowerCaseEmail = email.toLowerCase();

  return listOfEmails.some(
    (listEmail) => listEmail.toLowerCase() === lowerCaseEmail
  );
};

const surveyData = {
  emails: [
    "jason@rosterlab.com",
    "bokopa8898@tsderp.com",
    "daniellge+notificationtest@gmail.com",
    "Lisa.Sicely@wdhb.org.nz",
    "natasha.burney@wdhb.org.nz",
    "jade.kingi@wdhb.org.nz",
    "sophia.cullen-quigley@wdhb.org.nz",
    "sylvan.pather@wdhb.org.nz",
    "sasha.mackie@wdhb.org.nz",
    "linda.wood-bodley@wdhb.org.nz",
    "courtney.head@wdhb.org.nz",
    "russell.spencer@wdhb.org.nz",
    "brylee.noble@wdhb.org.nz",
    "mike.petersen@wdhb.org.nz",
    "christine.raue@wdhb.org.nz",
    "sarah.hunt@wdhb.org.nz",
    "alison.howroyd@wdhb.org.nz",
    "olivia.smith@wdhb.org.nz",
    "donna.strachan@wdhb.org.nz",
    "julia.gregory22@gmail.com",
  ],
  productionForm: {
    baseUrl:
      "https://docs.google.com/forms/d/e/1FAIpQLScQOqEGU2GViEYoDV3v9YUsHGwY3-uJ-iO1nLjPYjCkPxeQyg/viewform?usp=pp_url",
    entries: [
      { key: "name", entry: "1963416442" },
      { key: "email", entry: "939775348" },
      { key: "shift-date", entry: "1381475002" },
      { key: "shift-name", entry: "1169687637" },
      { key: "is-preference-met", entry: "1178433834" },
      { key: "preference-name", entry: "1329615700" },
    ],
  },
  testForm: {
    baseUrl:
      "https://docs.google.com/forms/d/e/1FAIpQLSeKwnOqN_WW0iv50k2cusRcxYzmNZqmdV2MXKzOtH3LlxrkKw/viewform?usp=pp_url",
    entries: [
      { key: "name", entry: "1818900932" },
      { key: "email", entry: "2113537453" },
      { key: "shift-date", entry: "1949899509" },
      { key: "shift-name", entry: "1265281507" },
      { key: "is-preference-met", entry: "662931274" },
      { key: "preference-name", entry: "2026296394" },
    ],
  },
};
