import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { configAggrid } from "./config/aggrid";
import { createRoot } from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { getQueryClient } from "./hooks/modelQueryHooks/queryClientStore";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { getAmplifyEnvironment } from "./utils/flagsUtils/flags";
import { Capacitor } from "@capacitor/core";
import { configureAutoTrack } from "aws-amplify/analytics";

// NOTE: this must run before anything else that modifies awsconfig
export const awsEnvironment = getAmplifyEnvironment(awsconfig);

// Temporal: only add Logrocket to production
if (awsEnvironment === "main") {
  LogRocket.init("2ms24g/rosterlab", {
    release: process.env.REACT_APP_VERSION,
  });
}

setupLogRocketReact(LogRocket);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, testRedirectSignIn, productionRedirectSignIn] =
  awsconfig.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, testRedirectSignOut, productionRedirectSignOut] =
  awsconfig.oauth.redirectSignOut.split(",");

let redirectSignIn = testRedirectSignIn;
let redirectSignOut = testRedirectSignOut;

if (Capacitor.isNativePlatform()) {
  redirectSignIn = "rosterlab://callback";
  redirectSignOut = "rosterlab://callback";
} else if (window.location.hostname === "localhost") {
  redirectSignIn = localRedirectSignIn;
  redirectSignOut = localRedirectSignOut;
} else if (window.location.hostname === "app.rosterlab.com") {
  redirectSignIn = productionRedirectSignIn;
  redirectSignOut = productionRedirectSignOut;
}

if (awsEnvironment === "main") {
  awsconfig.oauth.domain = "oauth.rosterlab.com";
} else if (awsEnvironment === "test") {
  awsconfig.oauth.domain = "auth-test.rosterlab.com";
}

console.log("Initialising " + awsEnvironment);

if (process.env.NODE_ENV === "production") {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then(function (registration) {
        console.log(
          "SW registration succeeded with scope:",
          registration.scope
        );
      })
      .catch(function (e) {
        console.log("SW registration failed with error:", e);
      });
  }
}

//TODO: potential security flaw, should be using access token instead of id token,
// but this solution is currently required until amplify develops better multi-tenant support
Amplify.configure({
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: redirectSignIn,
    redirectSignOut: redirectSignOut,
  },
});

configAggrid();

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

const container = document.getElementById("root");
const root = createRoot(container);

configureAutoTrack({
  enable: true,
  type: "session",
});

configureAutoTrack({
  enable: true,
  type: "pageView",
  options: {
    attributes: {
      customizableField: "attr",
    },
    eventName: "pageView",
    appType: "singlePage",
    urlProvider: () => {
      return window.location.origin + window.location.pathname;
    },
  },
});

configureAutoTrack({
  enable: true,
  type: "event", // Tracks click events by default
  options: {
    events: ["click"], // Specify the events you want to track
    selectorPrefix: "data-amplify-analytics-", // Default prefix for tracking
  },
});

const queryClient = getQueryClient();
root.render(
  <QueryClientProvider client={queryClient}>
    <App />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
