import { KEYWORD_ALL, KEYWORD_NO_TASK } from "../../constants/keywords";
import { getNoTaskSubtasks } from "../../features/rosterProblems/service/preferencesAndFixedShifts";
import {
  addAreaToAllocation,
  allocationFulfilsArea,
  getAllocationsFulfillingArea,
  getCombinedShiftSubtasks,
} from "../../features/rosterProblems/service/rosterUtils";
import { sortStringArrayByPriority } from "../generalUtils/array";
import { cartesianProduct } from "../generalUtils/general";
import { convertAllocationInShortIdFormToNameForm } from "../modelUtils/allocation";
import { getShortIds } from "../modelUtils/generalModelUtil";
import {
  convertToAllocationShortIdNameOptionForm,
  convertToNameIdOptionForm,
  convertToOptionPropForm,
  convertToShortIdNameOptionForm,
} from "./dataToOptionsConverter";

const getShiftSkillOptions = (shiftShortIds, taskShortIds) => {
  const skillNamesWithBlank = ["", ...taskShortIds];
  const shiftSkillPairs = cartesianProduct(shiftShortIds, skillNamesWithBlank);
  const options = shiftSkillPairs.map((pair) => {
    const shiftName = pair[0];
    const skillName = pair[1];
    if (skillName) {
      return `${shiftName}-${skillName}`;
    }
    return shiftName;
  });
  return options;
};

export const getEnumeratedShiftTaskCombo = (shiftShortIds, taskShortIds) => {
  const enumeratedCombos = [];
  shiftShortIds.forEach((shiftShortId) => {
    taskShortIds.forEach((taskShortId) => {
      enumeratedCombos.push(shiftShortId + "-" + taskShortId);
    });
  });
  return enumeratedCombos;
};

const getShiftTaskShortIdNamesList = (
  shifts,
  tasks,
  enumeratedShiftTasks,
  shortIdsToEntityNamesDicts
) => {
  const shiftShortIds = getShortIds(shifts);
  const taskShortIds = getShortIds(tasks);

  if (enumeratedShiftTasks.length > 0) {
    return enumeratedShiftTasks;
  }

  return getEnumeratedShiftTaskCombo(shiftShortIds, taskShortIds).map(
    (shortId) => ({
      shortId,
      name: convertAllocationInShortIdFormToNameForm(
        shortId,
        shortIdsToEntityNamesDicts
      ),
    })
  );
};

export const getRequestOptions = (
  predefinedKeywords,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  areas,
  enumeratedTasks,
  enumeratedShiftTasks,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj
) => {
  const shiftTaskShortIdAndNames = getShiftTaskShortIdNamesList(
    shifts,
    tasks,
    enumeratedShiftTasks,
    shortIdsToEntityNamesDicts
  );

  const options = [
    {
      label: null,
      options: convertToOptionPropForm(predefinedKeywords),
    },
    {
      label: "Shifts",
      options: convertToShortIdNameOptionForm(shifts),
    },
    {
      label: "Shift groups",
      options: convertToShortIdNameOptionForm(shiftGroups),
    },
    {
      label: "Tasks",
      options: convertToShortIdNameOptionForm([...tasks, ...enumeratedTasks]),
    },
    {
      label: "Shift-tasks",
      options: convertToShortIdNameOptionForm(shiftTaskShortIdAndNames),
    },
    {
      label: "Sub tasks",
      options: convertToShortIdNameOptionForm(subTasks),
    },
  ];

  if (areas.length > 0) {
    const shiftShortIds = getShortIds(shifts);
    const shiftGroupShortIds = getShortIds(shiftGroups);
    const taskShortIds = getShortIds(tasks);
    const shiftTaskShortIds = getShortIds(shiftTaskShortIdAndNames);
    const subTaskShortIds = getShortIds(subTasks);

    for (const area of areas) {
      const areaShiftShortIds = shiftShortIds.map((shortId) =>
        addAreaToAllocation(area.shortId, shortId)
      );

      const areaShiftGroupShortIds = shiftGroupShortIds.map((shortId) =>
        addAreaToAllocation(area.shortId, shortId)
      );
      const areaTaskShortIds = taskShortIds.map((shortId) =>
        addAreaToAllocation(area.shortId, shortId)
      );
      const areaShiftTaskShortIds = shiftTaskShortIds.map((shortId) =>
        addAreaToAllocation(area.shortId, shortId)
      );
      const areaSubTaskShortIds = subTaskShortIds.map((shortId) =>
        addAreaToAllocation(area.shortId, shortId)
      );

      options.push(
        ...[
          {
            label: `${area.name}: Shifts`,
            options: convertToAllocationShortIdNameOptionForm(
              getAllocationsFulfillingArea(
                areaShiftShortIds,
                area,
                shiftGroups,
                shortIdsToEntityNamesDicts,
                customKeywordsUtilObj
              ),
              shortIdsToEntityNamesDicts
            ),
          },
          {
            label: `${area.name}: Shift Groups`,
            options: convertToAllocationShortIdNameOptionForm(
              getAllocationsFulfillingArea(
                areaShiftGroupShortIds,
                area,
                shiftGroups,
                shortIdsToEntityNamesDicts,
                customKeywordsUtilObj
              ),
              shortIdsToEntityNamesDicts
            ),
          },
          {
            label: `${area.name}: Tasks`,
            options: convertToAllocationShortIdNameOptionForm(
              getAllocationsFulfillingArea(
                areaTaskShortIds,
                area,
                shiftGroups,
                shortIdsToEntityNamesDicts,
                customKeywordsUtilObj
              ),
              shortIdsToEntityNamesDicts
            ),
          },
          {
            label: `${area.name}: Shift-Tasks`,
            options: convertToAllocationShortIdNameOptionForm(
              getAllocationsFulfillingArea(
                areaShiftTaskShortIds,
                area,
                shiftGroups,
                shortIdsToEntityNamesDicts,
                customKeywordsUtilObj
              ),
              shortIdsToEntityNamesDicts
            ),
          },
          {
            label: `${area.name}: Sub tasks`,
            options: convertToAllocationShortIdNameOptionForm(
              getAllocationsFulfillingArea(
                areaSubTaskShortIds,
                area,
                shiftGroups,
                shortIdsToEntityNamesDicts,
                customKeywordsUtilObj
              ),
              shortIdsToEntityNamesDicts
            ),
          },
        ]
      );
    }
  }

  return options;
};

export const getHistoryOptions = (
  predefinedKeywords,
  shifts,
  tasks,
  subTasks,
  areas,
  enumeratedTasks,
  shortIdsToEntityNamesDicts
) => {
  const shiftShortIds = getShortIds(shifts);
  const taskShortIds = getShortIds(tasks);
  const subTaskShortIds = getShortIds(subTasks);
  const enumeratedTaskShortIds = getShortIds(enumeratedTasks);

  const enumeratedCombos = getEnumeratedShiftTaskCombo(shiftShortIds, [
    ...taskShortIds,
    ...subTaskShortIds,
    ...enumeratedTaskShortIds,
  ]);

  const comboOptionForm = convertToAllocationShortIdNameOptionForm(
    enumeratedCombos,
    shortIdsToEntityNamesDicts
  );

  const options = [
    {
      label: null,
      options: convertToOptionPropForm(predefinedKeywords),
    },
  ];

  if (areas?.length === 0) {
    options.push(
      ...[
        {
          label: "Shifts",
          options: convertToShortIdNameOptionForm(shifts),
        },
        {
          label: "Shift-tasks",
          options: comboOptionForm,
        },
      ]
    );
  } else {
    for (const area of areas) {
      const shiftShortIdsWithArea = shiftShortIds.map(
        (shortId) => `${area.shortId}:${shortId}`
      );
      const enumeratedComboWithArea = enumeratedCombos.map(
        (shortId) => `${area.shortId}:${shortId}`
      );

      options.push(
        ...[
          {
            label: `${area.name}: Shifts`,
            options: convertToAllocationShortIdNameOptionForm(
              shiftShortIdsWithArea,
              shortIdsToEntityNamesDicts
            ),
          },
          {
            label: `${area.name}: Shift-tasks`,
            options: convertToAllocationShortIdNameOptionForm(
              enumeratedComboWithArea,
              shortIdsToEntityNamesDicts
            ),
          },
        ]
      );
    }
  }

  return options;
};

export function flatOptions(multiLevelOptions) {
  return multiLevelOptions.map(({ options }) => options).flat();
}

export function flatAnyLevelOptions(options) {
  const flattened = [];

  // Iterate through the options array
  options.forEach((optionGroup) => {
    // Check if the current option group has an 'options' property
    if (optionGroup.options && Array.isArray(optionGroup.options)) {
      // Flatten the inner options array
      optionGroup.options.forEach((option) => {
        flattened.push({
          label: option.label,
          value: option.value,
        });
      });
    } else {
      // If no 'options' property, assume it's already flattened
      flattened.push({
        label: optionGroup.label,
        value: optionGroup.value,
      });
    }
  });

  return flattened;
}

export const getAllocationOptions = (
  areas,
  shifts,
  taskBlocks,
  tasks,
  shiftGroups,
  predefinedShiftOptions,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
  areaFilter
) => {
  let sortedAreas = areas;
  let sortedAreaFilter = areaFilter;
  if (
    areaFilter &&
    areaFilter.length !== 0 &&
    !areaFilter.includes(KEYWORD_ALL)
  ) {
    const areaShortIds = getShortIds(areas);
    sortedAreaFilter = sortStringArrayByPriority(areaFilter, areaShortIds);
    const sortedAreaShortIds = sortStringArrayByPriority(
      areaShortIds,
      sortedAreaFilter
    );
    sortedAreas = sortedAreaShortIds.map((shortId) =>
      areas.find((area) => area.shortId === shortId)
    );
  }

  const shiftShortIds = getShortIds(shifts);
  const taskShortIds = getShortIds(tasks);

  let shiftTaskShortIds;
  if (taskBlocks.length === 0) {
    shiftTaskShortIds = getShiftSkillOptions(shiftShortIds, taskShortIds);
  } else {
    shiftTaskShortIds = [
      ...shiftShortIds,
      ...getCombinedShiftSubtasks(shifts, tasks, taskBlocks).map(
        (alloc) => alloc.shortId
      ),
    ];
  }

  let shortIdsWithAreas = [];
  if (areas?.length === 0) {
    shortIdsWithAreas = [...shiftTaskShortIds];
  } else {
    for (const area of sortedAreas) {
      const areaShortId = area.shortId;

      for (const shiftTaskShortId of shiftTaskShortIds) {
        const isAllocatable = allocationFulfilsArea(
          area,
          shiftGroups,
          shiftTaskShortId,
          null,
          shortIdsToEntityNamesDicts,
          customKeywordsUtilObj
        );

        if (isAllocatable) {
          shortIdsWithAreas.push(`${areaShortId}:${shiftTaskShortId}`);
        }
      }
    }
  }

  const allShortIDs = [...shortIdsWithAreas, ...predefinedShiftOptions];

  return allShortIDs.map((shortId) => {
    if (predefinedShiftOptions.includes(shortId)) {
      return {
        label: shortId,
        value: shortId,
      };
    }
    return {
      label: convertAllocationInShortIdFormToNameForm(
        shortId,
        shortIdsToEntityNamesDicts
      ),
      value: shortId,
    };
  });
};

export const getShiftAndShiftGroupOptions = (shifts, shiftGroups) => {
  const selectAllShiftsOption = { label: KEYWORD_ALL, value: KEYWORD_ALL };
  const selectNoneOptionShifts = { label: KEYWORD_ALL, value: "" };

  const shiftOptions = convertToShortIdNameOptionForm(shifts);
  const shiftGroupOptions = convertToShortIdNameOptionForm(shiftGroups);

  return {
    selectAllShiftsOption,
    selectNoneOptionShifts,
    shiftOptions,
    shiftGroupOptions,
  };
};

export const getAreaOptions = (areas) => {
  const areaOptions = convertToShortIdNameOptionForm(areas);
  const selectAllAreasOption = { label: KEYWORD_ALL, value: KEYWORD_ALL };
  const allOption = { label: "All areas", value: KEYWORD_ALL };
  return {
    areaOptions,
    allOption,
    selectAllAreasOption,
  };
};

export const getEmployeeOptions = (employees) => {
  const employeeOptions = convertToNameIdOptionForm(employees);
  const allOption = { label: "All employees", value: KEYWORD_ALL };
  return {
    employeeOptions,
    allOption,
  };
};

export const getTaskOptions = (tasks, subTasks, taskBlocks) => {
  const noTasksX = getNoTaskSubtasks(taskBlocks);

  const noTaskOption = { value: KEYWORD_NO_TASK, label: KEYWORD_NO_TASK };
  const taskOptions = convertToShortIdNameOptionForm(tasks);
  const noTasksXOptions = convertToShortIdNameOptionForm(noTasksX);
  const subTaskOptions = convertToShortIdNameOptionForm(subTasks);

  const allTaskOptions = [
    ...taskOptions,
    ...noTasksXOptions,
    ...subTaskOptions,
  ];

  const allXTaskBlocksInfo = taskBlocks.map(({ name, shortId }) => ({
    label: `all ${name} tasks`,
    value: `all ${shortId} tasks`,
    taskBlockName: name,
    taskBlockShortId: shortId,
    relatedOptions: [...noTasksXOptions, ...subTaskOptions].filter(
      ({ value }) => value.endsWith(shortId)
    ),
  }));

  const allXTaskBlocksOptions = allXTaskBlocksInfo.map(({ label, value }) => ({
    label,
    value,
  }));

  return {
    noTasksX,
    noTaskOption,
    taskOptions,
    noTasksXOptions,
    subTaskOptions,
    allTaskOptions,
    allXTaskBlocksInfo,
    allXTaskBlocksOptions,
  };
};

export const getFulfilledXTaskBlocksOptions = (
  selectedInputs,
  allXTaskBlocksInfo
) => {
  const selectedValues = getOptionValues(selectedInputs);
  const fulfilledXTaskBlocksOptions = allXTaskBlocksInfo.filter(
    ({ relatedOptions }) => {
      const relatedOptionsValues = relatedOptions.map(({ value }) => value);
      return relatedOptionsValues.every((value) =>
        selectedValues.includes(value)
      );
    }
  );
  return fulfilledXTaskBlocksOptions;
};

export const getOptionValues = (options) => options.map(({ value }) => value);
export const getOptionLabels = (options) => options.map(({ label }) => label);
