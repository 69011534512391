import styles from "./FixedShiftsOverviewGrid.module.css";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import { useMemo } from "react";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import DropdownSingleSelector from "../../../../grid/components/DropdownSingleSelector/DropdownSingleSelector";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import {
  allocationDropdownOptionsValueSetter,
  allocationValueFormatter,
  convertShortIdsCellValueToEntityNames,
  createBasicContextMenu,
  DateTime,
  defaultFilterValueGetter,
  flatOptions,
  getDatesColumnDefs,
  getDayNumFromColFieldName,
  getFilledMonthsArray,
  getRequestOptions,
  getGlobalDatesColumnDefs,
  onFilterTextBoxChanged,
  suppressEnterKey,
} from "../../../../../utils";
import { showWarningPopupIfNoOthersShowing } from "../../../../../utils/uiUtils/popup";
import {
  getColorCodedCells,
  getWeekendsCellStyle,
} from "../../../rosteredAllocations/service/styleGetters";
import { processAllocationsFromClipboard } from "../../../../../utils/agGridUtils/clipboard";
import { ColorCodingButton } from "../../../rosteredAllocations/components/RosterActionComponents/RosterActionComponents";

const ManageButton = ({ onClick }) => {
  return (
    <BasicButton
      color="#219ec9"
      hoverColor="#1f91b7"
      onClick={onClick}
      customStyle={{
        borderRadius: "10px",
      }}
    >
      Manage all
    </BasicButton>
  );
};

const NoVisibleDateOverlay = `<span style="padding: 10px; border: 2px solid red; color: red; font-size: 15px">
    Date range is out of your current roster model period, click "Clear filter"
    to go back
  </span>`;

const NoEmployeesOverlay = `<span style="padding: 10px; border: 2px solid red; color: red; font-size: 15px">
You have no employees set up for this roster
</span>`;

const FixedShiftsOverviewGrid = ({
  locationID,
  startDate,
  employeesData,
  enumeratedTasks,
  enumeratedShiftTasks,
  dayColumns,
  toggleRequestManager,
  setGridApiToParent,
  setColumnApiToParent,
  updateData,
  isSaving,
  overviewWarnings,
  longestStr,
  weekdayColWidth,
  weekendColWidth,
  isWDayWEndSeparate,
  setWeekdayColWidth,
  setWeekendColWidth,
  setIsWDayWEndSeparate,
  showManageBtn = true,
  showColAdjuster = true,
  showWarningBox = true,
  customStyle,
  title = null,
  gridApi,
  showActionBar = true,
  exportToCsv,
  exportToExcel,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  predefinedKeywords,
  customTopComponent,
  shouldInsertOffOnPasteBlank,
  getDataFromGrid,
  isScheduleView,
  shouldSpecifyWeeksInColHeader,
  areas,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  skills = null,
  showSkillsColumn = false,
  employeeNames,
  doesAreaFilterPass = null,
  isExternalFilterPresent = null,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
  toggleColorCodingModal,
  colorCodes,
  isGlobal = false,
}) => {
  const getContextMenuItems = () => {
    return createBasicContextMenu();
  };

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const isWarningPresent = overviewWarnings && overviewWarnings.length > 0;

  const allocationOptions = useMemo(
    () =>
      getRequestOptions(
        predefinedKeywords,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        areas,
        enumeratedTasks,
        enumeratedShiftTasks,
        shortIdsToEntityNamesDicts,
        customKeywordsUtilObj
      ),
    [
      predefinedKeywords,
      shifts,
      shiftGroups,
      tasks,
      subTasks,
      areas,
      enumeratedTasks,
      enumeratedShiftTasks,
      shortIdsToEntityNamesDicts,
      customKeywordsUtilObj,
    ]
  );

  const customActionBarComponents = useMemo(() => {
    const components = [];
    if (!isGlobal) {
      components.push(() => (
        <ColorCodingButton onClick={toggleColorCodingModal} />
      ));
    }
    return components;
  }, [toggleColorCodingModal, isGlobal]);

  const datesColumnDefs = useMemo(() => {
    if (shouldSpecifyWeeksInColHeader) {
      let datesColumnDefs = getDatesColumnDefs(dayColumns.length, startDate);
      const customDatesColumnDefs = datesColumnDefs.map(
        (weekColDefs, weekGroupIdx) => {
          const customWeekColDefs = weekColDefs.children.map(
            (dayColDefs, dayGroupIdx) => {
              const customDayColDefs = dayColDefs.children.map((colDef) => {
                const offset = getDayNumFromColFieldName(colDef.field);
                const DoW = new DateTime(startDate)
                  .addDays(offset)
                  .getDayOfWeek("dd");

                return {
                  ...colDef,
                  suppressMenu: true,
                  width:
                    DoW === "Sa" || DoW === "Su"
                      ? weekendColWidth
                      : weekdayColWidth,
                  cellStyle: (params) => ({
                    ...getWeekendsCellStyle(params),
                    ...getColorCodedCells(
                      params,
                      colorCodes,
                      shiftGroups,
                      shortIdsToEntityNamesDicts,
                      customKeywordsUtilObj
                    ),
                  }),
                  cellClassRules: {
                    "invalid-cell": (params) => {
                      if (overviewWarnings) {
                        const filteredOverviewWarnings =
                          overviewWarnings.filter(
                            ({ warningType }) =>
                              warningType === "invalid Allocations input"
                          );

                        for (const overviewWarning of filteredOverviewWarnings) {
                          if (
                            overviewWarning &&
                            overviewWarning.values.includes(params.value) &&
                            overviewWarning.extraInfo.employeeID ===
                              params.data.id
                          ) {
                            return true;
                          }
                        }
                      }
                    },
                  },
                  cellEditor: "dropdownSingleSelector",
                  cellEditorParams: {
                    width: 80,
                    options: allocationOptions,
                  },
                  cellEditorPopup: true,
                  valueSetter: (params) =>
                    allocationDropdownOptionsValueSetter(
                      params,
                      flatOptions(allocationOptions)
                    ),
                  valueFormatter: (params) =>
                    allocationValueFormatter(
                      params,
                      predefinedKeywords,
                      shortIdsToEntityNamesDicts
                    ),
                  suppressKeyboardEvent: suppressEnterKey,
                };
              });
              return {
                ...dayColDefs,
                groupId: `dayColGroup_${weekGroupIdx}_${dayGroupIdx}`,
                children: customDayColDefs,
              };
            }
          );
          return {
            ...weekColDefs,
            groupId: `weekColGroup${weekGroupIdx}`,
            children: customWeekColDefs,
          };
        }
      );
      return customDatesColumnDefs;
    } else {
      const monthsAndDays = getFilledMonthsArray(dayColumns, startDate);
      const noWeekColDefs = getGlobalDatesColumnDefs(monthsAndDays, startDate);

      const customColDefs = noWeekColDefs.map((monthColDefs) => {
        const customMonthColDefs = monthColDefs.children.map((dayColDefs) => {
          const customDayColDefs = dayColDefs.children.map((dowColDefs) => {
            const offset = getDayNumFromColFieldName(dowColDefs.field);
            const DoW = new DateTime(startDate)
              .addDays(offset)
              .getDayOfWeek("dd");
            return {
              ...dowColDefs,
              editable: true,
              suppressMenu: true,
              width:
                DoW === "Sa" || DoW === "Su"
                  ? weekendColWidth
                  : weekdayColWidth,
              cellStyle: (params) => ({
                ...getWeekendsCellStyle(params),
                ...getColorCodedCells(
                  params,
                  colorCodes,
                  shiftGroups,
                  shortIdsToEntityNamesDicts,
                  customKeywordsUtilObj
                ),
              }),
              cellClassRules: {
                "invalid-cell": (params) => {
                  if (overviewWarnings) {
                    const overviewWarning = overviewWarnings.find(
                      ({ warningType }) =>
                        warningType === "invalid Allocations input"
                    );
                    if (
                      overviewWarning &&
                      overviewWarning.values.includes(params.value)
                    ) {
                      return true;
                    }
                  }
                },
              },
              cellEditor: "dropdownSingleSelector",
              cellEditorParams: {
                width: 80,
                options: allocationOptions,
              },
              cellEditorPopup: true,
              valueSetter: (params) => {
                const dayIndex = parseInt(params.colDef.field.substring(1)) - 1;
                const columnDate = new DateTime(startDate).addDays(dayIndex);

                const employeeStartDate = params.data.startDate;
                const employeeFinishDate = params.data.finishDate;

                if (columnDate.isAfter(employeeFinishDate)) {
                  showWarningPopupIfNoOthersShowing(
                    "Cannot add after employee finish date",
                    `${columnDate.toFormat(
                      "day-month-year-readable"
                    )} is after employee finish date ${new DateTime(
                      employeeFinishDate
                    ).toFormat("day-month-year-readable")}`
                  );
                  return false;
                }

                if (columnDate.isBefore(employeeStartDate)) {
                  showWarningPopupIfNoOthersShowing(
                    "Cannot add before employee start date",
                    `${columnDate.toFormat(
                      "day-month-year-readable"
                    )} is before employee start date ${new DateTime(
                      employeeStartDate
                    ).toFormat("day-month-year-readable")}`
                  );
                  return false;
                }

                return allocationDropdownOptionsValueSetter(
                  params,
                  flatOptions(allocationOptions)
                );
              },
              valueFormatter: (params) =>
                allocationValueFormatter(
                  params,
                  predefinedKeywords,
                  shortIdsToEntityNamesDicts
                ),
              suppressKeyboardEvent: suppressEnterKey,
            };
          });
          return { ...dayColDefs, children: customDayColDefs };
        });
        return {
          ...monthColDefs,
          children: customMonthColDefs,
        };
      });
      return customColDefs;
    }
  }, [
    overviewWarnings,
    allocationOptions,
    startDate,
    weekdayColWidth,
    weekendColWidth,
    shouldSpecifyWeeksInColHeader,
    dayColumns,
    predefinedKeywords,
    shortIdsToEntityNamesDicts,
    colorCodes,
    customKeywordsUtilObj,
    shiftGroups,
  ]);

  const columnDefs = useMemo(() => {
    const employeesColDefs = [
      {
        field: "name",
        sortable: true,
        editable: false,
        width: 150,
        pinned: "left",
      },
    ];

    if (showSkillsColumn && skills) {
      employeesColDefs.push({
        field: "skills",
        sortable: true,
        editable: false,
        width: 100,
        pinned: "left",
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, skills),
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "skills", skills),
      });
    }

    return [
      {
        headerName: "Employees",
        groupId: "employeesGroup",
        children: employeesColDefs,
      },
      ...datesColumnDefs,
    ];
  }, [datesColumnDefs, skills, showSkillsColumn]);

  return (
    <Layout
      title={title ? title : "Overview"}
      isSubheading={true}
      headerRight={() => {
        if (showManageBtn && !isScheduleView) {
          return <ManageButton onClick={toggleRequestManager} />;
        }
        return null;
      }}
    >
      <div className={styles.container}>
        <div className={styles.topLine}>
          <div className={styles.left}>{customTopComponent}</div>
          <div className={styles.right}>
            {showManageBtn && isScheduleView && (
              <ManageButton onClick={toggleRequestManager} />
            )}
          </div>
        </div>
        {showActionBar && (
          <ActionBar
            locationID={locationID}
            searchBarSettings={{
              tableName: "fixedShiftsOverview",
              onFilterInputChanged,
            }}
            adjustWidthSettings={
              showColAdjuster
                ? {
                    longestStr,
                    weekdayColWidth,
                    weekendColWidth,
                    isWDayWEndSeparate,
                    setWeekdayColWidth,
                    setWeekendColWidth,
                    setIsWDayWEndSeparate,
                    tableName: isGlobal ? "globalFixedShifts" : "fixedShifts",
                  }
                : null
            }
            customComponents={customActionBarComponents}
            exportSettings={{
              exportToCsv,
              exportToExcel,
            }}
          />
        )}
        <DataEntryTable
          customStyle={customStyle ? customStyle.dataEntryTable : {}}
          columnHoverHighlight={true}
          columnDefs={columnDefs}
          rowData={employeesData}
          updateData={updateData}
          getContextMenuItems={getContextMenuItems}
          onCellKeyDown={(params) => {
            if (handleKeyDownForUndoRedo) {
              handleKeyDownForUndoRedo(params.event);
            }
          }}
          gridOptions={{
            onColumnVisible: (params) => {
              const allColumns = params.columnApi.getColumns();
              const visibleColumns = allColumns.filter(
                (item) => item.visible === true
              );
              if (visibleColumns.length === 1) {
                params.api.showNoRowsOverlay();
              } else {
                params.api.hideOverlay();
              }
            },
            onCellValueChanged: (params) => {
              if (triggerUndoRedoSnapshotCollection) {
                triggerUndoRedoSnapshotCollection(params);
              }
            },
          }}
          processDataFromClipboard={(params) =>
            processAllocationsFromClipboard(
              params,
              shouldInsertOffOnPasteBlank,
              employeeNames,
              true
            )
          }
          context={{
            options: flatOptions(allocationOptions),
          }}
          setGridApiToParent={setGridApiToParent}
          setColumnApiToParent={setColumnApiToParent}
          components={{
            dropdownSingleSelector: DropdownSingleSelector,
          }}
          overlayNoRowsTemplate={
            employeesData.length === 0
              ? NoEmployeesOverlay
              : NoVisibleDateOverlay
          }
          tableName="fixed-shifts-overview"
          getDataFromGrid={getDataFromGrid}
          doesExternalFilterPass={doesAreaFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
        />
      </div>
      <p className={styles["saving-line"]}>
        {isSaving ? "saving..." : "saved"}
      </p>
      {isWarningPresent && showWarningBox && (
        <div className={styles["warning-wrapper"]}>
          <WarningDisplay
            title="Following issues were found:"
            displayedWarnings={getDisplayedWarningsInfo(overviewWarnings)}
            bottomText={
              "Please change the allocated fixed shift/ tasks or if they are correct, change the restrictions on the employee page."
            }
          />
        </div>
      )}
    </Layout>
  );
};

export default FixedShiftsOverviewGrid;
