import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useMemo, useState } from "react";
import ModalViewer from "../../../../../../components/elements/Modal/ModalViewer";
import styles from "./AddShiftGroupModal.module.css";
import { getEntityNameByShortId, getNames } from "../../../../../../utils";
import Modal from "../../../../../../components/elements/Modal/Modal";

const AddShiftGroupModal = ({
  hide,
  isShowing,
  shifts,
  shiftGroups,
  addNewShiftGroup,
}) => {
  const [shiftGroupName, setShiftGroupName] = useState("");
  const [selectedShifts, setSelectedShifts] = useState([]);

  const shiftNames = useMemo(() => getNames(shifts), [shifts]);
  const shiftGroupNames = useMemo(() => getNames(shiftGroups), [shiftGroups]);

  const resetValues = () => {
    setShiftGroupName("");
    setSelectedShifts([]);
  };

  return (
    <Modal isOpen={isShowing}>
      <ModalViewer
        hide={() => {
          hide();
          resetValues();
        }}
        isShowing={true}
        title={"Add new shift group"}
        firstBtnLabel={"Add"}
        backgroundColor={"#F5F5F5"}
        onclickOkay={async () => {
          if (
            shiftGroupName &&
            !shiftGroupNames.includes(shiftGroupName) &&
            !shiftNames.includes(shiftGroupName)
          ) {
            addNewShiftGroup(shiftGroupName, selectedShifts);
          }
          resetValues();
        }}
      >
        <div className={styles.shiftGroupNameBox}>
          New shift group name:
          <input
            placeholder="Enter shift group name here"
            value={shiftGroupName}
            onChange={(event) => setShiftGroupName(event.target.value)}
            style={{
              paddingLeft: "5px",
            }}
          ></input>
        </div>
        <div className={styles.shiftSelectBox}>
          Add shifts into your shift group:
          <Select
            multiple
            value={selectedShifts}
            onChange={(event) => setSelectedShifts(event.target.value)}
            style={{ width: 400, backgroundColor: "white" }}
            MenuProps={{ autoFocus: false }}
            displayEmpty={true}
            renderValue={(selected) => {
              if (selected.length === 1) {
                return getEntityNameByShortId(shifts, selected[0]);
              } else if (selected.length === 0) return "No shifts selected";
              else {
                return selected
                  .map((shortId) => getEntityNameByShortId(shifts, shortId))
                  .join(", ");
              }
            }}
          >
            {shifts.map(({ shortId, name }) => (
              <MenuItem key={shortId} value={shortId}>
                <ListItemIcon>
                  <Checkbox checked={selectedShifts.indexOf(shortId) > -1} />
                </ListItemIcon>
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </div>
      </ModalViewer>
    </Modal>
  );
};

export default AddShiftGroupModal;
