export const checkRulesWithExceptionEnabled = (frontendSettings) => {
  if (!frontendSettings) {
    return false;
  }
  const settings = frontendSettings;
  const withExceptionsSetting = settings.filter(
    (s) => s.name === "rulesWithException"
  );
  if (withExceptionsSetting.length > 0) {
    return true;
  }
  return false;
};
