import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalViewer from "../../../../components/elements/Modal/ModalViewer";
import styles from "./ToggleColumnModal.module.css";
import {
  faThumbTack,
  faEye,
  faEyeSlash,
  faGrip,
  faCircleArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { camelToNormal } from "../../../../utils";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import {
  saveGlobalEmployeesColumnOrder,
  saveGlobalEmployeesColumnVisibility,
} from "../../../../localStorage/visibilityStorage";
import { useDisableBodyScrollOnMount } from "../../../rosterProblems/hooks/useDisableBodyScrollOnMount";
import Modal from "../../../../components/elements/Modal/Modal";

export const COLUMN_VISIBILITY_STATUS = Object.freeze({
  pinned: "pinned",
  displayed: "displayed",
  hidden: "hidden",
});

const getStatusIcon = (status) => {
  switch (status) {
    case COLUMN_VISIBILITY_STATUS.pinned:
      return faThumbTack;
    case COLUMN_VISIBILITY_STATUS.displayed:
      return faEye;
    case COLUMN_VISIBILITY_STATUS.hidden:
      return faEyeSlash;
    default:
      throw new Error(`invalid column visibility status ${status}`);
  }
};

const ToggleColumnModal = ({
  locationID,
  toggleIsColumnToggleModalOpen,
  columnApi,
  pinnedFields = [],
  reorderColumns,
  lockedColumnIds,
}) => {
  useDisableBodyScrollOnMount();

  const [columnStatus, setColumnStatus] = useState([]);

  const applyChanges = () => {
    const displayedColumns = columnStatus
      .filter((item) => item.status === COLUMN_VISIBILITY_STATUS.displayed)
      .map((item) => item.colId);
    const hiddenColumns = columnStatus
      .filter((item) => item.status === COLUMN_VISIBILITY_STATUS.hidden)
      .map((item) => item.colId);

    columnApi.setColumnsVisible(displayedColumns, true);
    columnApi.setColumnsVisible(hiddenColumns, false);

    const newColumnOrder = columnStatus.map((col) => col.colId);

    saveGlobalEmployeesColumnOrder(locationID, newColumnOrder);
    saveGlobalEmployeesColumnVisibility(locationID, columnStatus);

    reorderColumns(newColumnOrder);
  };

  const toggleColumn = (col) => {
    const { colId, status } = col;
    if (status === COLUMN_VISIBILITY_STATUS.pinned) {
      return;
    }
    const newStatus =
      status === COLUMN_VISIBILITY_STATUS.displayed
        ? COLUMN_VISIBILITY_STATUS.hidden
        : COLUMN_VISIBILITY_STATUS.displayed;

    const newColStatus = {
      ...col,
      status: newStatus,
    };

    setColumnStatus((prev) =>
      prev.map((item) => {
        if (item.colId === colId) {
          return newColStatus;
        }
        return item;
      })
    );
  };

  const getColumnVisibilityStatus = useCallback(() => {
    if (!columnApi) {
      return [];
    }
    const cols = columnApi.getColumns();
    const visibilityStatus = cols.map((col) => ({
      colId: col.colId,
      name: col.colDef.headerName
        ? col.colDef.headerName
        : camelToNormal(col.colDef.field),
      status: pinnedFields.includes(col.colDef.field)
        ? COLUMN_VISIBILITY_STATUS.pinned
        : col.visible
        ? COLUMN_VISIBILITY_STATUS.displayed
        : COLUMN_VISIBILITY_STATUS.hidden,
    }));

    return visibilityStatus;
  }, [columnApi, pinnedFields]);

  useEffect(() => {
    const status = getColumnVisibilityStatus();
    setColumnStatus(status);
  }, [getColumnVisibilityStatus]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    setColumnStatus((prev) => {
      return reorder(prev, result.source.index, result.destination.index);
    });
  };

  return (
    <Modal isOpen={true}>
      <ModalViewer
        isShowing={true}
        title="Show or Hide"
        hide={toggleIsColumnToggleModalOpen}
        onclickOkay={applyChanges}
        modalWidth="550px"
      >
        <div className={styles.grid}>
          <div className={`${styles.gridHeader} ${styles.row}`}>
            <span className={styles.status}>Show/Hide</span>
            <span className={styles.colName}>Column Names</span>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppableId">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {columnStatus.map((item, idx) => {
                    if (item.status !== COLUMN_VISIBILITY_STATUS.pinned) {
                      return (
                        <Draggable
                          key={item.colId}
                          draggableId={item.colId}
                          index={idx}
                        >
                          {(provided) => (
                            <div
                              key={item.colId}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`${styles.row} ${
                                idx === columnStatus.length - 1
                                  ? styles.noBorder
                                  : null
                              }`}
                            >
                              <div className={`${styles.status}`}>
                                <FontAwesomeIcon
                                  icon={faGrip}
                                  className={styles.gripIcon}
                                />
                                <button
                                  className={`${styles.statusBtn} ${
                                    item.status ===
                                    COLUMN_VISIBILITY_STATUS.pinned
                                      ? styles.pinned
                                      : null
                                  } ${
                                    lockedColumnIds.includes(item.colId)
                                      ? styles.locked
                                      : null
                                  }`}
                                  onClick={() => {
                                    if (!lockedColumnIds.includes(item.colId)) {
                                      toggleColumn(item);
                                    }
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={getStatusIcon(item.status)}
                                  />
                                </button>
                                {lockedColumnIds.includes(item.colId) && (
                                  <FontAwesomeIcon
                                    icon={faCircleArrowUp}
                                    className={styles.limitedIcon}
                                  />
                                )}
                              </div>
                              <span className={styles.colName}>
                                {item.name}
                              </span>
                            </div>
                          )}
                        </Draggable>
                      );
                    } else {
                      return (
                        <div
                          key={item.colId}
                          className={`${styles.row} ${
                            idx === columnStatus.length - 1
                              ? styles.noBorder
                              : null
                          }`}
                        >
                          <div className={`${styles.status}`}>
                            <button
                              className={`${styles.statusBtn} ${
                                item.status === COLUMN_VISIBILITY_STATUS.pinned
                                  ? styles.pinned
                                  : null
                              }`}
                              onClick={() => {
                                toggleColumn(item);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={getStatusIcon(item.status)}
                              />
                            </button>
                          </div>
                          <span className={styles.colName}>{item.name}</span>
                        </div>
                      );
                    }
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </ModalViewer>
    </Modal>
  );
};

export default ToggleColumnModal;
