import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getNames, getShiftHours } from "../../../../utils";
import { allocationFulfilsPreference } from "../../../rosterProblems/service/preferencesAndFixedShifts";
import { buildShortIdsToEntityNamesDicts } from "../../../rosterProblems/service/rosterUtils";
import { findMinMaxDemandsPairByShiftName } from "../../../statistics/service/statsValueGetter";
import { faNotesMedical } from "@fortawesome/free-solid-svg-icons";
import { getEmployeeNoteOnDay } from "../../../../utils/queryUtils/globalEmployeeDataGetters";
import { getShiftAndSkillShortIdFromCountStatsRowId } from "../../../rosterProblems/rosteredAllocations/service/statsColumns";
import { convertAllocationInShortIdFormToNameForm } from "../../../../utils/modelUtils/allocation";
import { KEYWORD_OFF } from "../../../../constants/keywords";

const STAFFING_LEVEL_STATUS_TYPE = Object.freeze({
  understaffed: "understaffed",
  overstaffed: "overstaffed",
  satisfied: "satisfied",
});

const getStaffingNumberToolTip = (
  rowId,
  cellValue,
  shiftDemands,
  dayIndex,
  shifts,
  skills
) => {
  const { shiftShortId, skillShortId } =
    getShiftAndSkillShortIdFromCountStatsRowId(rowId);
  const minMaxDemandsPair = findMinMaxDemandsPairByShiftName(
    shiftDemands,
    shiftShortId,
    skillShortId,
    "shiftShortId",
    "skillShortId"
  );

  const minDemandValue = minMaxDemandsPair.minDemandValues
    ? minMaxDemandsPair.minDemandValues[dayIndex]
    : null;
  const maxDemandValue = minMaxDemandsPair.maxDemandValues
    ? minMaxDemandsPair.maxDemandValues[dayIndex]
    : null;

  const targetShift = shifts.find(
    (s) => s.shortId === minMaxDemandsPair.shiftShortId
  );

  const targetShiftSkill = skills
    ? skills.find((s) => s.shortId === minMaxDemandsPair.skillShortId)
    : null;

  let shiftHours = null;
  if (targetShift) {
    shiftHours = getShiftHours(targetShift);
  }

  let status = {};

  if (minDemandValue !== null && cellValue < minDemandValue) {
    status = {
      type: STAFFING_LEVEL_STATUS_TYPE.understaffed,
      message: `understaffed (-${Math.abs(cellValue - minDemandValue)})`,
    };
  } else if (maxDemandValue !== null && cellValue > maxDemandValue) {
    status = {
      type: STAFFING_LEVEL_STATUS_TYPE.overstaffed,
      message: `overstaffed (+${cellValue - maxDemandValue})`,
    };
  } else {
    status = {
      type: STAFFING_LEVEL_STATUS_TYPE.satisfied,
    };
  }

  if (status.type === STAFFING_LEVEL_STATUS_TYPE.satisfied) {
    return null;
  }

  return () => (
    <div
      style={{
        padding: "7px 10px",
        backgroundColor: "white",
        fontSize: "1rem",
        overflow: "hidden",
        color: "#868686",
      }}
    >
      <span>
        You are{" "}
        <span
          style={{
            color:
              status.type === STAFFING_LEVEL_STATUS_TYPE.understaffed
                ? "#FF5050"
                : "#CBBE23",
            fontWeight: "bold",
          }}
        >
          {status.message}
        </span>{" "}
        {shiftHours && (
          <span>
            during the{" "}
            <b>
              {targetShift.name}
              {targetShiftSkill ? `(${targetShiftSkill.name})` : ""}
            </b>{" "}
            shift
          </span>
        )}
      </span>
    </div>
  );
};

const ToolTipDisplay = ({ sectionComponents }) => {
  if (sectionComponents.length === 0) {
    return <div></div>;
  }

  return (
    <div
      style={{
        padding: "10px",
        border: "1px solid black",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {sectionComponents.map((Component, idx) => (
        <Component key={idx} />
      ))}
    </div>
  );
};

function getNoteToolTipComponent(employeeID, date, employeesAllocationNotes) {
  const note = getEmployeeNoteOnDay(employeeID, employeesAllocationNotes, date);
  if (!note) {
    return null;
  }

  return () => (
    <div style={{ display: "flex", gap: "10px", color: "#3CAA84" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          gap: "2px",
        }}
      >
        <FontAwesomeIcon icon={faNotesMedical} style={{ fontSize: "22px" }} />
        <span style={{ fontSize: "10px" }}>Notes</span>
      </div>
      <p style={{ fontSize: "15px" }}>{note}</p>
    </div>
  );
}

function getPreferencesAndFixedShiftsToolTipComponent(
  employeeName,
  employeeID,
  employeeSkills,
  dayIndex,
  cellValue,
  preferencesInfo,
  fixedShiftsInfo,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  skills,
  subTasks,
  areas,
  customKeywordsUtilObj
) {
  const shortIdsToEntityNamesDicts = buildShortIdsToEntityNamesDicts(
    areas,
    shifts,
    shiftGroups,
    tasks,
    subTasks,
    skills
  );

  const getEmployeeAllocationOnADay = (
    allocationsInfo,
    employeeID,
    dayIndex
  ) => {
    const employeeAllocations = allocationsInfo.find(
      (info) => info.employeeID === employeeID
    );
    if (!employeeAllocations) {
      return "";
    }
    const allocationsType = employeeAllocations.preferences
      ? "preferences"
      : "fixedShifts";
    const allocation = employeeAllocations[allocationsType][dayIndex];
    return allocation;
  };

  const preferenceCellValue = getEmployeeAllocationOnADay(
    preferencesInfo,
    employeeID,
    dayIndex
  );
  const fixedShiftCellValue = getEmployeeAllocationOnADay(
    fixedShiftsInfo,
    employeeID,
    dayIndex
  );

  let isPreferenceMet = allocationFulfilsPreference(
    cellValue,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    preferenceCellValue,
    employeeSkills,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj
  );

  let isFixedShiftMet = allocationFulfilsPreference(
    cellValue,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    fixedShiftCellValue,
    employeeSkills,
    shortIdsToEntityNamesDicts,
    customKeywordsUtilObj
  );

  const getAllocationType = (allocationCellValue) => {
    let allocationType = "allocation";

    if (getNames(shifts).includes(allocationCellValue)) {
      allocationType = "shift";
    } else if (allocationCellValue === KEYWORD_OFF) {
      allocationType = KEYWORD_OFF;
    } else if (getNames(shiftGroups).includes(allocationCellValue)) {
      allocationType = "shift group";
    } else if (getNames(tasks).includes(allocationCellValue)) {
      allocationType = "task";
    }
    return allocationType;
  };

  const getAllocationInNameForm = (allocation) => {
    const matchingShiftGroup = shiftGroups.find(
      ({ shortId }) => shortId === allocation
    );
    if (matchingShiftGroup) {
      return matchingShiftGroup.name;
    }
    return convertAllocationInShortIdFormToNameForm(
      allocation,
      shortIdsToEntityNamesDicts
    );
  };

  let preferenceType = getAllocationType(preferenceCellValue);
  let fixedShiftType = getAllocationType(fixedShiftCellValue);

  if (!preferenceCellValue && !fixedShiftCellValue) {
    return null;
  }

  return () => (
    <div>
      {preferenceCellValue && (
        <p>
          {employeeName}&apos;s preference{" "}
          <b>{getAllocationInNameForm(preferenceCellValue)}</b>
          {" " + preferenceType} is <b>{isPreferenceMet ? "met" : "not met"}</b>
          .
        </p>
      )}
      {fixedShiftCellValue && (
        <p>
          {employeeName}&apos;s fixed shift{" "}
          <b>{getAllocationInNameForm(fixedShiftCellValue)}</b>
          {" " + fixedShiftType} is <b>{isFixedShiftMet ? "met" : "not met"}</b>
          .
        </p>
      )}
    </div>
  );
}

function getRuleConflictsToolTipComponent(
  employeeID,
  columnDay,
  invalidCellListWithReasons
) {
  const cellReasons = invalidCellListWithReasons.filter(
    (cell) =>
      employeeID === cell.employeeId && cell.coords[1] === parseInt(columnDay)
  );

  if (cellReasons.length === 0) {
    return null;
  }

  return () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {cellReasons.map(
        (reason, idx) => reason && <p key={idx}>{reason.description}</p>
      )}
    </div>
  );
}

/**
 * Tooltip for My Roster table
 */
export const CustomToolTip = ({
  data,
  date = null,
  employeesAllocationNotes = null,
  context,
  shifts,
  shiftGroups,
  tasks,
  taskBlocks,
  areas,
  customKeywordsUtilObj,
  ...props
}) => {
  const cellValue = data[props.colDef.field];
  const employeeID = data.id;
  const name = data.name;
  const columnHeader = props.colDef.field;
  const columnDay = columnHeader.substring(1);
  const dayIndex = Number(columnHeader.slice(1)) - 1;
  const isBottomPinnedRow = props.node.rowPinned === "bottom";

  const shouldHideTooltip = () => {
    if (data.id === "open-shifts" || data.id.startsWith("reserved")) {
      return true;
    }
    return false;
  };

  if (shouldHideTooltip(data)) {
    return <div></div>;
  }

  const getBottomPinnedToolTipComponents = () => {
    const components = [];

    if (!context.shiftDemands) {
      return components;
    }

    const rowId = data.id;
    if (rowId.startsWith("shift-counts") || rowId.match(/shift-.+-skill-.+/g)) {
      const staffingNumberToolTip = getStaffingNumberToolTip(
        rowId,
        props.value.value,
        context.shiftDemands,
        dayIndex,
        context.shifts,
        context.skills
      );

      if (staffingNumberToolTip) {
        components.push(staffingNumberToolTip);
      }
    }

    return components;
  };

  const getToolTipComponents = () => {
    const components = [];

    if (context.preferencesInfo && context.fixedShiftsInfo) {
      const preferencesInfo = context.preferencesInfo;
      const fixedShiftsInfo = context.fixedShiftsInfo;
      const preferencesAndFixedShiftsSectionComponent =
        getPreferencesAndFixedShiftsToolTipComponent(
          name,
          employeeID,
          data.skills,
          dayIndex,
          cellValue,
          preferencesInfo,
          fixedShiftsInfo,
          shifts,
          shiftGroups,
          tasks,
          taskBlocks,
          context.skills,
          context.subTasks,
          areas,
          customKeywordsUtilObj
        );

      if (preferencesAndFixedShiftsSectionComponent) {
        components.push(preferencesAndFixedShiftsSectionComponent);
      }
    }

    if (context.invalidCellListWithReasons) {
      const invalidCellListWithReasons = context.invalidCellListWithReasons;
      const ruleConflictsSectionComponent = getRuleConflictsToolTipComponent(
        employeeID,
        columnDay,
        invalidCellListWithReasons
      );
      if (ruleConflictsSectionComponent) {
        components.push(ruleConflictsSectionComponent);
      }
    }

    const noteSectionComponent = getNoteToolTipComponent(
      employeeID,
      date,
      employeesAllocationNotes
    );

    if (noteSectionComponent) {
      components.push(noteSectionComponent);
    }

    return components;
  };

  const sectionComponents = isBottomPinnedRow
    ? getBottomPinnedToolTipComponents()
    : getToolTipComponents();

  return <ToolTipDisplay sectionComponents={sectionComponents} />;
};
