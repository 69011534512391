import { useMemo } from "react";
import withHeader from "../../../../components/layouts/hoc/withHeader/withHeader";
import { useUserStore } from "../../../../globalStore/appStore";
import { PAGE_NAMES, useRedirect } from "../../../../utils/routeUtils/redirect";
import { PLAN, userIsAdmin, userIsInternal } from "../../../auth/service/auth";
import styles from "./Location.module.css";
import {
  getFieldsFromLocation,
  getUpdatedLocation,
} from "../../../../utils/queryUtils/locationDataGetters";
import { useLocationMutation } from "../../../../hooks/modelQueryHooks/useLocationMutation";
import { customConfirmAlert } from "../../../confirm/service/confirm";
import { useSettingsModelMutation } from "../../../../hooks/modelQueryHooks/useSettingsModelMutation";
import LocationSettings from "../LocationSettings/LocationSettings";

const Location = ({
  locations,
  location,
  deleteLocation,
  customKeywordsData,
}) => {
  const { deleteAreaFilterInSettings } = useSettingsModelMutation();
  const { user, plan } = useUserStore();
  const { updateLocationFields } = useLocationMutation(location);
  const redirect = useRedirect();
  const isAdmin = userIsAdmin(user);
  const isInternalUser = userIsInternal(user);

  const { locationID, name: locationName } = useMemo(
    () => getFieldsFromLocation(location),
    [location]
  );

  const updateLocationName = (name) => {
    const nameAlreadyExists = locations.find((loc) => loc.name === name.trim());
    if (name.trim() === "" || name === locationName || nameAlreadyExists) {
      return false;
    }

    const updatedLocation = getUpdatedLocation(location, { name: name.trim() });
    updateLocationFields(["name"], updatedLocation);
    return true;
  };

  const handleDeleteLocation = async () => {
    if (!locations || !locationID || locations.length <= 1) {
      return;
    }
    const response = await customConfirmAlert({
      title: "Please confirm",
      descriptions: ["Are you sure you want to delete this location?"],
    });
    if (!response) {
      return;
    }

    await Promise.all([
      deleteLocation(locationID),
      deleteAreaFilterInSettings(locationID),
    ]);

    redirect({
      pageName: PAGE_NAMES.main,
    });
  };

  const isLocationDeletable =
    locations.length > 1 &&
    (isAdmin || isInternalUser) &&
    plan !== PLAN.COORDINATOR &&
    plan !== PLAN.COLLABORATOR;

  return (
    <div className={styles.container}>
      <LocationSettings
        location={location}
        plan={plan}
        customKeywordsData={customKeywordsData}
        handleDeleteLocation={handleDeleteLocation}
        updateLocationName={updateLocationName}
        isLocationDeletable={isLocationDeletable}
      />
    </div>
  );
};

export default withHeader(Location);
