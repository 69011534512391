import { useMemo } from "react";
import { getLiveCalendarNoAuth } from "../service/liveCalendar";
import { orderGlobalEmployees } from "../../locations/service/locationsUtil";
import { useQuery } from "@tanstack/react-query";
import {
  buildNamesToEntityShortIdsDicts,
  buildShortIdsToEntityNamesDicts,
  getSubtasks,
} from "../../rosterProblems/service/rosterUtils";
import { addShortIdToEntities } from "../../../utils/modelUtils/shortId";
import { getDefaultDisplayedStatistics } from "../../../constants";
import { convertGlobalEmployeeToRosterInViewEmployee } from "../../../utils/queryUtils/globalEmployeeDataGetters";

async function loadLiveCalendarNoAuth(locationID) {
  const response = await getLiveCalendarNoAuth(locationID);
  if (response.location && response.employees) {
    return { location: response.location, employees: response.employees };
  }
  return null;
}

export function useLiveCalendarNoAuth(locationID) {
  const fetchLiveCalendarNoAuth = async ({ queryKey }) => {
    const [, locationID] = queryKey;
    const response = await loadLiveCalendarNoAuth(locationID);
    if (!response) {
      throw new Error("Fetching live calendar failed");
    }
    return response;
  };

  const { data, error, isLoading } = useQuery(
    ["liveCalendar", locationID],
    fetchLiveCalendarNoAuth,
    {
      enabled: !!locationID, // The query will not run until the locationID exists
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // Always longer than stale time
    }
  );

  const {
    locationName,
    orderedGlobalEmployees,
    skills,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    areas,
    colorCodes,
    order,
    frontendSettings,
    numDays,
    startDate,
    rules,
    demands,
    shiftViewHiddenRows,
    subTasks,
    statistics,
  } = useMemo(() => {
    if (!data) {
      return {
        orderedGlobalEmployees: [],
        skills: [],
        shifts: [],
        shiftGroups: [],
        tasks: [],
        taskBlocks: [],
        areas: [],
        colorCodes: [],
        frontendSettings: [],
        order: [],
        numDays: 0,
        startDate: null,
        locationName: "",
        rules: [],
        subTasks: [],
        statistics: getDefaultDisplayedStatistics(),
      };
    }

    const location = data.location;
    const numDays = location.defaultNumDays || 0;

    const employees = data.employees
      .filter((emp) => !emp._deleted)
      .map((employee) =>
        convertGlobalEmployeeToRosterInViewEmployee(numDays, employee)
      );

    const skills = location.Skills || [];
    const shifts = location.Shifts || [];
    const shiftGroups = location.ShiftGroups || [];
    const tasks = location.Tasks || [];
    const taskBlocks = location.TaskBlocks || [];
    const areas = location.Areas || [];
    const colorCodes = location.ColorCodes || [];
    const order = location.order || [];
    const frontendSettings = location.frontendSettings || [];
    const startDate = location.startDate || 0;
    const orderedGlobalEmployees = orderGlobalEmployees(employees, order);
    const locationName = location.name || "";
    const rules = location.CustomRules;
    const demands = location.Demands;
    const shiftViewHiddenRows = location.shiftViewHiddenRows;
    const statistics = location.Statistics;

    const subTasks = getSubtasks(tasks, taskBlocks);

    return {
      locationName,
      orderedGlobalEmployees,
      skills: addShortIdToEntities(skills),
      shifts: addShortIdToEntities(shifts),
      shiftGroups: addShortIdToEntities(shiftGroups),
      tasks: addShortIdToEntities(tasks),
      taskBlocks: addShortIdToEntities(taskBlocks),
      areas: areas,
      colorCodes,
      order,
      frontendSettings,
      numDays,
      startDate,
      rules,
      demands,
      shiftViewHiddenRows,
      subTasks,
      statistics,
    };
  }, [data]);

  const shortIdsToEntityNamesDicts = useMemo(() => {
    return buildShortIdsToEntityNamesDicts(
      areas,
      shifts,
      shiftGroups,
      tasks,
      subTasks,
      skills
    );
  }, [areas, shifts, shiftGroups, tasks, subTasks, skills]);

  const namesToEntityShortIdsDicts = useMemo(() => {
    return buildNamesToEntityShortIdsDicts(
      areas,
      shifts,
      shiftGroups,
      tasks,
      subTasks,
      skills
    );
  }, [areas, shifts, shiftGroups, tasks, subTasks, skills]);

  return {
    locationName,
    employees: orderedGlobalEmployees,
    order,
    frontendSettings,
    skills,
    shifts,
    shiftGroups,
    tasks,
    taskBlocks,
    subTasks,
    areas,
    colorCodes,
    isLoading,
    error,
    numDays,
    startDate,
    rules,
    demands,
    shiftViewHiddenRows,
    shortIdsToEntityNamesDicts,
    namesToEntityShortIdsDicts,
    statistics,
  };
}
