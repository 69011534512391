import styles from "./ShareRosterModal.module.css";
import Select from "react-select";
import { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import DragDropList from "../ShiftChooser/DragDropList";
import MultiSelectShifts from "../../../../components/elements/MultiSelectShifts/MultiSelectShifts";
import { MenuItem, Select as MuiSelect } from "@mui/material";
import { getFieldsFromRoster } from "../../../../utils/queryUtils/rosterDataGetters";
import {
  getCustomKeywordsDataFromLocation,
  interpretCustomKeywordsData,
} from "../../../../utils/queryUtils/locationDataGetters";
import { useRosterModelQuery } from "../../../../hooks/modelQueryHooks/useRosterModelQuery";
import { containsAllElements, getShortIds } from "../../../../utils";
import { KEYWORD_ALL } from "../../../../constants/keywords";

const SHARE_OPTIONS = Object.freeze({
  public: "public",
  private: "private",
});

const viewOptions = [
  { label: "Employee View", value: "employee" },
  { label: "Shift View", value: "shift-view" },
  { label: "Custom Shift View ", value: "shift" },
];

const key = "gtR,E80xn5yUveprPSWCDkLFds9M4bO1zuKB6VaQm2GYZAfiXHhwTlcoj7qIJN3"; // Check with Daniel if anyone is using the old key

export function obfuscateString(str) {
  let result = "";
  for (let i = 0; i < str.length; i++) {
    const char = str.charAt(i);
    const index = key.indexOf(char);
    if (index !== -1) {
      result += key.charAt((index + 1) % key.length);
    } else {
      result += char;
    }
  }
  return result;
}

export function deobfuscateString(str) {
  let result = "";
  for (let i = 0; i < str.length; i++) {
    const char = str.charAt(i);
    const index = key.indexOf(char);
    if (index !== -1) {
      const newIndex = (index - 1 + key.length) % key.length;
      result += key.charAt(newIndex);
    } else {
      result += char;
    }
  }
  return result;
}

const options = [
  {
    value: SHARE_OPTIONS.live,
    label: "Anyone with this link can view a live version of this schedule",
  },
];

const EmailInfo = ({
  shiftShortIds,
  shiftGroupShortIds,
  leaveKeywords,
  selectedShiftAndShiftGroupNames,
  setSelectedShiftAndShiftGroupNames,
  selectedOption,
  setSelectedOption,
  viewOptions,
  shifts,
  shiftGroups,
}) => {
  const emailInputRef = useRef(null);

  const isAllSelected =
    selectedShiftAndShiftGroupNames.length ===
    shiftShortIds.length + shiftGroupShortIds.length + leaveKeywords.length;

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (emailInputRef.current !== null) {
      emailInputRef.current.focus();
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        gap: "15px",
        margin: "20px 0px",
        flexDirection: "column",
      }}
    >
      <p className={styles.publicShareMsg}>
        THIS WILL BE A PUBLIC LINK THAT CAN BE VIEWED BY ANYONE
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <MultiSelectShifts
          shiftShortIds={shiftShortIds}
          shiftGroupShortIds={shiftGroupShortIds}
          leaveKeywords={leaveKeywords}
          selected={selectedShiftAndShiftGroupNames}
          setSelected={setSelectedShiftAndShiftGroupNames}
          isAllSelected={isAllSelected}
          width={"250px"}
          shifts={shifts}
          shiftGroups={shiftGroups}
        />
        <MuiSelect
          label="Schedule View"
          value={selectedOption}
          onChange={handleChange}
          style={{ width: "250px" }}
        >
          {viewOptions.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </MuiSelect>
      </div>

      {selectedOption === "shift" && (
        <DragDropList
          selected={selectedShiftAndShiftGroupNames}
          setSelected={setSelectedShiftAndShiftGroupNames}
          shifts={shifts}
          shiftGroups={shiftGroups}
          leaveKeywords={leaveKeywords}
        />
      )}
    </div>
  );
};

const Controllers = ({ handleOK, handleClose }) => {
  return (
    <div className={styles.constrollers}>
      <BasicButton
        color="#009EC4"
        onClick={handleOK}
        hoverColor="#1f91b7"
        customStyle={{
          borderRadius: "5px",
          width: "160px",
          fontSize: "1.2rem",
        }}
      >
        OK
      </BasicButton>
      <BasicButton
        hoverColor="#009EC4"
        borderColor="#009EC4"
        onClick={handleClose}
        customStyle={{
          borderRadius: "5px",
          width: "160px",
          fontSize: "1.2rem",
        }}
        dataTestId={"upload-roster-btn"}
      >
        Cancel
      </BasicButton>
    </div>
  );
};

const ShareRosterModal = ({
  closeShareRosterModal,
  selectedLocationID,
  location,
  rosterID,
}) => {
  const [selectedViewOption, setSelectedViewOption] = useState(
    viewOptions[0].value
  );
  const [
    shouldIncludeUnpublishedEmployees,
    setShouldIncludeUnpublishedEmployees,
  ] = useState(false);

  const urlInputRef = useRef(null);

  const [shareResult, setShareResult] = useState(null);

  const { roster } = useRosterModelQuery({
    isScheduleView: true,
    locationID: location.id,
    rosterID,
  });

  const { shifts, shiftGroups } = getFieldsFromRoster(roster);

  const shiftShortIds = useMemo(() => getShortIds(shifts), [shifts]);
  const shiftGroupShortIds = useMemo(
    () => getShortIds(shiftGroups),
    [shiftGroups]
  );

  const { leaveKeywords } = useMemo(() => {
    if (location) {
      return interpretCustomKeywordsData(
        getCustomKeywordsDataFromLocation(location)
      );
    }
    return { leaveKeywords: [], annualLeaveKeyword: null };
  }, [location]);

  const allShiftAndShiftGroupNames = [
    ...leaveKeywords,
    ...shiftShortIds,
    ...shiftGroupShortIds,
  ];

  const [selectedShiftAndShiftGroupNames, setSelectedShiftAndShiftGroupNames] =
    useState(allShiftAndShiftGroupNames);

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const copyToClipboard = () => {
    const url = urlInputRef.current.value;
    navigator.clipboard.writeText(url);
  };

  const getInfoMsg = () => {
    return "Anyone with this link can view the roster";
  };

  const getLinkDescription = () => {
    return "Public Link As Below";
  };

  const handleShareRoster = async () => {
    const originUrl = window.location.origin;
    const isAllSelected = containsAllElements(
      allShiftAndShiftGroupNames,
      selectedShiftAndShiftGroupNames
    );
    const s = isAllSelected
      ? KEYWORD_ALL
      : encodeURIComponent(
          obfuscateString(selectedShiftAndShiftGroupNames.join(","))
        );

    let liveURL = `${originUrl}/liveschedule?id=${selectedLocationID}&s=${s}&v=${selectedViewOption}`;

    if (
      selectedViewOption === viewOptions[0].value &&
      shouldIncludeUnpublishedEmployees
    ) {
      liveURL += "&u=true";
    }

    setShareResult({
      url: liveURL,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <button className={styles.closeBtn} onClick={closeShareRosterModal}>
          <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} />
        </button>
        <div className={`${styles.row}  ${styles.rowOne}`}>
          <span className={styles.title}>Share my roster</span>
        </div>
        <div className={`${styles.row} ${styles.rowTwo}`}>
          {shareResult ? (
            <p className={styles.infoText}>{getInfoMsg()}</p>
          ) : (
            <div className={styles.selectWrapper}>
              <Select
                options={options}
                value={options[0]}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #009ec6",
                    "&:hover": {
                      border: "1px solid #009ec6",
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    border: "1px solid #009ec6",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "#009ec6",
                  }),
                }}
              />
            </div>
          )}
        </div>
        <div className={`${styles.row} ${styles.rowThree}`}>
          {shareResult ? (
            <div className={styles.linkWrapper}>
              <p className={styles.linkDesc}>{getLinkDescription()}</p>
            </div>
          ) : (
            <EmailInfo
              shiftShortIds={shiftShortIds}
              shiftGroupShortIds={shiftGroupShortIds}
              leaveKeywords={leaveKeywords}
              selectedShiftAndShiftGroupNames={selectedShiftAndShiftGroupNames}
              setSelectedShiftAndShiftGroupNames={
                setSelectedShiftAndShiftGroupNames
              }
              selectedOption={selectedViewOption}
              setSelectedOption={setSelectedViewOption}
              viewOptions={viewOptions}
              shifts={shifts}
              shiftGroups={shiftGroups}
            />
          )}
        </div>
        {shareResult && (
          <div className={`${styles.row} ${styles.rowFour}`}>
            <input
              className={styles.linkInput}
              type="text"
              readOnly={true}
              value={shareResult.url}
              ref={urlInputRef}
            />
            <button className={styles.copyLinkBtn} onClick={copyToClipboard}>
              Copy Link
            </button>
          </div>
        )}
        {!shareResult && (
          <div className={`${styles.row} ${styles.rowFive}`}>
            {selectedViewOption === viewOptions[0].value && (
              <label className={styles.unpublishedEmployeesCheckbox}>
                Include unpublished employee rows
                <input
                  type="checkbox"
                  checked={shouldIncludeUnpublishedEmployees}
                  onChange={(e) =>
                    setShouldIncludeUnpublishedEmployees(e.target.checked)
                  }
                />
              </label>
            )}
            <Controllers
              handleOK={handleShareRoster}
              handleClose={closeShareRosterModal}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ShareRosterModal;
