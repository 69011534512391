/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  containsAllElements,
  convertToNameIdOptionForm,
  getAreaOptions,
  getEmployeeOptions,
  getIds,
  getOptionValues,
  getShortIds,
  hasCommonItems,
  strToArrCommaSeparated,
} from "../utils";
import { KEYWORD_ALL } from "../constants/keywords";

/**
 * This hook is bound to TWO <Select> (react-select) components.
 * It automatically handlers filtering Employees selector depending on the values in Areas selector
 */

export function useAreaEmployeesSelect(
  initialAreaFilterValue,
  areas,
  employees
) {
  const { areaOptions, selectAllAreasOption } = useMemo(
    () => getAreaOptions(areas),
    [areas]
  );

  const { employeeOptions, allOption: selectAllEmployeesOption } = useMemo(
    () => getEmployeeOptions(employees),
    [employees]
  );

  const [selectedAreaOptions, setSelectedAreaOptions] = useState(() => {
    if (
      !initialAreaFilterValue ||
      initialAreaFilterValue.length === 0 ||
      initialAreaFilterValue.includes(KEYWORD_ALL)
    ) {
      return [selectAllAreasOption];
    }

    return areaOptions.filter(({ value }) =>
      initialAreaFilterValue.includes(value)
    );
  });

  const [unSelectableEmployees, setUnSelectableEmployees] = useState(() => {
    const { unSelectableEmployees } = getSelectableEmployeesInfo(
      initialAreaFilterValue,
      employees,
      areas
    );
    return unSelectableEmployees;
  });

  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState(() => {
    const filteredEmployees = employees.filter(
      ({ id }) => !getIds(unSelectableEmployees).includes(id)
    );

    if (filteredEmployees.length === employees.length) {
      return [selectAllEmployeesOption];
    }

    return convertToNameIdOptionForm(filteredEmployees);
  });

  const areaShortIds = useMemo(() => getShortIds(areas), [areas]);

  const selectedAreaOptionValues = useMemo(
    () => getOptionValues(selectedAreaOptions),
    [selectedAreaOptions]
  );

  const selectedEmployeeOptionValues = useMemo(
    () => getOptionValues(selectedEmployeeOptions),
    [selectedEmployeeOptions]
  );

  // Resulting selected area entities
  const selectedAreaEntities = useMemo(() => {
    if (selectedAreaOptionValues.includes(KEYWORD_ALL)) {
      return areas;
    }
    return areas.filter(({ shortId }) =>
      selectedAreaOptionValues.includes(shortId)
    );
  }, [areas, selectedAreaOptionValues]);

  // Resulting selected employee entities
  const selectedEmployeeEntities = useMemo(() => {
    if (selectedEmployeeOptionValues.includes(KEYWORD_ALL)) {
      return employees;
    }

    return employees.filter(({ id }) =>
      selectedEmployeeOptionValues.includes(id)
    );
  }, [employees, selectedEmployeeOptionValues]);

  const updateSelectableEmployees = useCallback(
    (selectedAreaShortIds) => {
      const { unSelectableEmployees } = getSelectableEmployeesInfo(
        selectedAreaShortIds,
        employees,
        areaShortIds
      );
      setUnSelectableEmployees(unSelectableEmployees);

      const updatedSelectedEmployeeEntities = selectedEmployeeEntities.filter(
        ({ id }) => {
          return !unSelectableEmployees.includes(id);
        }
      );

      const updatedSelectedEmployeeOptions =
        updatedSelectedEmployeeEntities.length === employees.length
          ? [selectAllEmployeesOption]
          : convertToNameIdOptionForm(updatedSelectedEmployeeEntities);
      setSelectedEmployeeOptions(updatedSelectedEmployeeOptions);
    },
    [
      employees,
      areaShortIds,
      selectedEmployeeEntities,
      selectAllEmployeesOption,
    ]
  );

  const handleAreaSelect = (selectedInputs, actionMeta) => {
    const { action, option } = actionMeta;
    let selectedOptions = [...selectedInputs];

    switch (action) {
      case "select-option":
        if (option.value === selectAllAreasOption.value) {
          selectedOptions = [selectAllAreasOption];
          break;
        }
        break;
      case "deselect-option":
        if (option.value === selectAllAreasOption.value) {
          selectedOptions = [];
          break;
        }
        if (selectedAreaOptionValues.includes(KEYWORD_ALL)) {
          selectedOptions = areaOptions.filter(
            ({ value }) => option.value !== value
          );
          break;
        }
        break;
    }

    if (selectedOptions.length === areas.length) {
      selectedOptions = [selectAllAreasOption];
    }

    setSelectedAreaOptions(selectedOptions);

    let selectedAreaShortIds = getOptionValues(selectedOptions);
    if (
      selectedAreaShortIds.length === 0 ||
      selectedAreaShortIds.includes(KEYWORD_ALL)
    ) {
      selectedAreaShortIds = getShortIds(areas);
    }

    updateSelectableEmployees(selectedAreaShortIds);
  };

  const handleEmployeeSelect = (selectedInputs, actionMeta) => {
    const { action, option } = actionMeta;
    let selectedOptions = [...selectedInputs];

    switch (action) {
      case "select-option":
        if (option.value === selectAllEmployeesOption.value) {
          selectedOptions = [selectAllEmployeesOption];
          break;
        }
        break;
      case "deselect-option":
        if (option.value === selectAllEmployeesOption.value) {
          selectedOptions = [];
          break;
        }
        if (selectedEmployeeOptionValues.includes(KEYWORD_ALL)) {
          selectedOptions = employeeOptions.filter(
            ({ value }) => option.value !== value
          );
          break;
        }
        break;
    }

    if (selectedOptions.length === employees.length) {
      selectedOptions = [selectAllEmployeesOption];
    }

    setSelectedEmployeeOptions(selectedOptions);
  };

  return {
    areaSelectOptions: [selectAllAreasOption, ...areaOptions],
    employeeSelectOptions: [selectAllEmployeesOption, ...employeeOptions],
    selectedAreaOptions,
    selectedEmployeeOptions,
    selectedAreaEntities,
    selectedEmployeeEntities,
    unSelectableEmployees,
    handleAreaSelect,
    handleEmployeeSelect,
  };
}

function getSelectableEmployeesInfo(
  allowedAreaShortIds,
  employees,
  areaShortIds
) {
  if (
    containsAllElements(areaShortIds, allowedAreaShortIds) ||
    allowedAreaShortIds.length === 0 ||
    allowedAreaShortIds.includes(KEYWORD_ALL)
  ) {
    return {
      selectableEmployees: getIds(employees),
      unSelectableEmployees: [],
    };
  }

  const selectableEmployees = [];
  const unSelectableEmployees = [];

  for (const employee of employees) {
    const employeeAreaShortIds = strToArrCommaSeparated(employee.areas);

    if (
      employeeAreaShortIds.includes(KEYWORD_ALL) ||
      hasCommonItems(allowedAreaShortIds, employeeAreaShortIds)
    ) {
      selectableEmployees.push(employee.id);
    } else {
      unSelectableEmployees.push(employee.id);
    }
  }

  return { selectableEmployees, unSelectableEmployees };
}
