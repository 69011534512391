import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import CollapsibleDateRangePicker from "../../../../components/elements/CollapsibleDateRangePicker/CollapsibleDateRangePicker";
import withHeader from "../../../../components/layouts/hoc/withHeader/withHeader";
import { TABLE_NAMES } from "../../../../constants";
import { useGlobalPreferencesColumnWidthStore } from "../../../../globalStore/columnWidthStore";
import {
  changePrefImportanceLevel,
  DateTime,
  duplicateArr,
  getAllGlobalAllocationsFromGrid,
  getDayColumns,
  getLongestAllocationStringInRowData,
  getPreferenceSuffix,
  getRecurringColumns,
  getRecurringPeriodLengthSelection,
  parseGlobalEmployeeModelToGlobalPreferencesGridFormat,
  sortByDateField,
} from "../../../../utils";
import GridActionHandler from "../../../grid/components/GridActionHandler/GridActionHandler";
import PreferencesOverviewGrid from "../../../rosterProblems/components/Preferences/PreferencesOverviewGrid/PreferencesOverviewGrid";
import PreferencesRecurringGrid from "../../../rosterProblems/components/Preferences/PreferencesRecurringGrid/PreferencesRecurringGrid";
import PreferencesLocked from "../../../upgradePlan/components/PreferencesLocked/PreferencesLocked";
import { VIEW_RANGE } from "../../constants/calendarView";
import {
  onViewRangeChangeBuilder,
  handlePageChange,
  detectViewRange,
} from "../../service/calendar";
import { orderGlobalEmployees } from "../../service/locationsUtil";
import styles from "./GlobalPreferencesContainer.module.css";
import {
  getFieldsFromLocation,
  interpretCustomKeywordsData,
} from "../../../../utils/queryUtils/locationDataGetters";
import { useLocationMutation } from "../../../../hooks/modelQueryHooks/useLocationMutation";
import GetStartedButton from "../../../../components/elements/GetStartedButton/GetStartedButton";
import { useUserStore } from "../../../../globalStore/appStore";
import { getActualInitialStartDate } from "../../../../utils/queryUtils/monthViewUtils";
import { useGetPeriodFromUrl } from "../../../../hooks/useGetPeriodFromUrl";
import { useAreaFilter } from "../../../../hooks/useAreaFilter";
import AreaFilter from "../../../../components/elements/AreaFilter/AreaFilter";
import { buildShortIdsToEntityNamesDicts } from "../../../rosterProblems/service/rosterUtils";
import {
  subscribeGlobalEmployeeUpdate,
  subscribeLocationUpdate,
} from "../../../../utils/queryUtils/observers";
import {
  syncGlobalEmployee,
  syncLocation,
} from "../../../../utils/modelUtils/sync";

function getDefaultPrefLevelFromSettings(frontendSettings, settingName) {
  const globalFrontendSettingNames = frontendSettings.map((item) => item.name);
  if (globalFrontendSettingNames.includes(settingName)) {
    const defaultPrefImportance = frontendSettings.find(
      (setting) => setting.name === settingName
    ).values[0];
    return defaultPrefImportance;
  }

  return "";
}

const getPendingAlertMsg = (nearestNextPendingDate, recurringPendingExists) => {
  if (!nearestNextPendingDate && !recurringPendingExists) {
    return "";
  }
  if (nearestNextPendingDate) {
    return `Please review pending special preference request(s) - ${nearestNextPendingDate}`;
  }
  if (recurringPendingExists) {
    return "Please review pending recurring special request(s)";
  }
};

const getNearestNextOverviewPendingDate = (employees) => {
  for (const employee of employees) {
    const preferences = employee.Preferences;
    for (const day of preferences) {
      const today = new DateTime(null, false);
      const allocationDate = new DateTime(day.date);
      if (allocationDate.isAfter(today) && day.allocation.endsWith("*")) {
        return allocationDate.toFormat("displayed-full");
      }
    }
  }
  return null;
};

const checkRecurringPendingExists = (employees) => {
  for (const employee of employees) {
    const preferencesRecurring = employee.PreferencesRecurring;
    for (const day of preferencesRecurring) {
      if (day.endsWith("*")) {
        return true;
      }
    }
  }
  return false;
};

const GlobalPreferencesContainer = ({
  locationID,
  warnings,
  customKeywordsData,
  globalEmployees,
  location,
}) => {
  const { isPaidPlan } = useUserStore();

  const history = useHistory();
  const [overviewGridApi, setOverviewGridApi] = useState(null);
  const [recurringGridApi, setRecurringGridApi] = useState(null);

  const { updateGlobalEmployees, isMutationLoading: isSaving } =
    useLocationMutation(location);

  const {
    weekdayColWidth,
    weekendColWidth,
    isWDayWEndSeparate,
    setWeekdayColWidth,
    setWeekendColWidth,
    setIsWDayWEndSeparate,
  } = useGlobalPreferencesColumnWidthStore();

  const customKeywordsUtilObj = interpretCustomKeywordsData(customKeywordsData);

  const {
    onAreaFilterChanged,
    doesAreaFilterPass,
    isExternalFilterPresent,
    saveAreaFilter,
    initialAreaFilterValue,
  } = useAreaFilter([overviewGridApi, recurringGridApi], locationID);

  const {
    name,
    frontendSettings,
    globalShifts: shifts,
    globalShiftGroups: shiftGroups,
    globalTasks: tasks,
    globalSkills: skills,
    globalAreas: areas,
    colorCodes,
    order,
    subTasks,
    enumeratedTasks,
    enumeratedShiftTasks,
  } = useMemo(() => getFieldsFromLocation(location), [location]);

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const { startDate, finishDate } = useGetPeriodFromUrl(
    DateTime.getFirstDateOfCurrentMonth().toFormat("AWS"),
    DateTime.getLastDateOfCurrentMonth().toFormat("AWS")
  );

  const [viewRange, setViewRange] = useState(VIEW_RANGE.month);

  const nearestNextPendingDate =
    getNearestNextOverviewPendingDate(globalEmployees);
  const recurringPendingExists = checkRecurringPendingExists(globalEmployees);

  const updateDateQueryParam = (startDate, finishDate) => {
    history.push({
      pathname: "/locations/preferences",
      search: `?location-id=${locationID}&start-date=${startDate}&finish-date=${finishDate}`,
    });
  };

  useEffect(() => {
    setViewRange(detectViewRange(startDate, finishDate));
  }, [startDate, finishDate]);

  useEffect(() => {
    let locationUpdateSub;
    let globalEmployeeUpdateSub;

    if (locationID) {
      locationUpdateSub = subscribeLocationUpdate(
        (data) => syncLocation(locationID, data),
        locationID
      );

      globalEmployeeUpdateSub = subscribeGlobalEmployeeUpdate(
        (data) => syncGlobalEmployee(locationID, data),
        locationID
      );
    }

    return async () => {
      (await locationUpdateSub)?.unsubscribe();
      (await globalEmployeeUpdateSub)?.unsubscribe();
    };
  }, [locationID]);

  const numDays =
    DateTime.getDifferenceInDays(
      new DateTime(startDate),
      new DateTime(finishDate)
    ) + 1;

  const dayColumns = getDayColumns(numDays);

  const prefSuffix = useMemo(() => {
    const defaultPrefLevel = getDefaultPrefLevelFromSettings(
      frontendSettings,
      "defaultPreferenceImportance"
    );

    if (defaultPrefLevel === "critical") {
      return "!";
    }
    if (defaultPrefLevel === "high") {
      return "?";
    }
    if (defaultPrefLevel === "normal") {
      return "";
    }
    return "";
  }, [frontendSettings]);

  const autoApprovedLevel = useMemo(() => {
    const defaultApprovePrefLevel = getDefaultPrefLevelFromSettings(
      frontendSettings,
      "autoApprovedPreferenceLimit"
    );

    if (defaultApprovePrefLevel === "") {
      const defaultPrefLevel = getDefaultPrefLevelFromSettings(
        frontendSettings,
        "defaultPreferenceImportance"
      );

      if (defaultPrefLevel === "high" || defaultPrefLevel === "critical") {
        return "critical";
      } else {
        return "high";
      }
    }
    return defaultApprovePrefLevel;
  }, [frontendSettings]);

  // level: "normal", "high", "critical"
  const changePrefLevel = async (level, isOverview) => {
    if (isOverview) {
      changePrefImportanceLevel(overviewGridApi, level);
      const newEmployees = getPreferencesInGrid();
      await updatePreferences(newEmployees);
      return;
    } else {
      changePrefImportanceLevel(recurringGridApi, level);
      const newEmployees = getPreferencesInGrid();
      await updatePreferences(newEmployees);
    }
  };

  const overviewWarnings = useMemo(
    () => (warnings ? warnings.preferencesOverviewTable : null),
    [warnings]
  );
  const recurringWarnings = useMemo(
    () => (warnings ? warnings.preferencesRecurringTable : null),
    [warnings]
  );

  const employeesData = useMemo(() => {
    const unordered = parseGlobalEmployeeModelToGlobalPreferencesGridFormat(
      globalEmployees,
      numDays,
      startDate,
      finishDate
    );
    const ordered = orderGlobalEmployees(unordered, order);
    return ordered;
  }, [globalEmployees, order, numDays, startDate, finishDate]);

  const recurringPeriodSelection =
    getRecurringPeriodLengthSelection(employeesData);

  const recurringColumns = getRecurringColumns(
    recurringPeriodSelection === "week" ? 7 : 14
  );

  const applyGlobalPreferencesUpdate = (
    updatedGlobalEmployees,
    modifiedEmployeeIds
  ) => {
    const updatedLocation = {
      ...location,
      Employees: {
        items: updatedGlobalEmployees,
      },
    };
    updateGlobalEmployees(
      updatedGlobalEmployees.filter(
        (emp) => !modifiedEmployeeIds || modifiedEmployeeIds.includes(emp.id)
      ),
      ["Preferences", "PreferencesRecurring"],
      updatedLocation
    );
  };

  const updatePreferences = async (updatedEmployeesData) => {
    let modifiedEmployeeIds = [];

    const updatedGlobalEmployees = globalEmployees.map((employee) => {
      const targetEmployee = updatedEmployeesData.find(
        (emp) => emp.id === employee.id
      );

      if (!targetEmployee) {
        return employee;
      }

      const originalPreferences = employee.Preferences;
      const originalPreferencesRecurring = employee.PreferencesRecurring;

      const visiblePreferenceDates = targetEmployee.Preferences.map(
        (item) => item.date
      );

      const updatedPreferencesWithinDateRange =
        targetEmployee.Preferences.filter((item) => item.allocation !== null);

      const originalPreferencesOutsideDateRange = originalPreferences.filter(
        (item) => !visiblePreferenceDates.includes(item.date)
      );

      const updatedPreferences = [
        ...originalPreferencesOutsideDateRange,
        ...updatedPreferencesWithinDateRange,
      ];

      sortByDateField(updatedPreferences, "date", true);

      const newPreferencesRecurring = targetEmployee.PreferencesRecurring;

      // Check if Preferences or PreferencesRecurring are modified
      if (
        JSON.stringify(originalPreferences) !==
          JSON.stringify(updatedPreferences) ||
        JSON.stringify(originalPreferencesRecurring) !==
          JSON.stringify(newPreferencesRecurring)
      ) {
        modifiedEmployeeIds.push(employee.id);
      }

      return {
        ...employee,
        Preferences: updatedPreferences,
        PreferencesRecurring: newPreferencesRecurring,
      };
    });

    applyGlobalPreferencesUpdate(updatedGlobalEmployees, modifiedEmployeeIds);
  };

  const getPreferencesInGrid = () =>
    getAllGlobalAllocationsFromGrid(
      overviewGridApi,
      dayColumns,
      recurringColumns,
      startDate,
      true
    );

  const onViewRangeChange = (e) => {
    onViewRangeChangeBuilder(
      e.target.value,
      startDate,
      VIEW_RANGE,
      updateDateQueryParam
    );
  };

  const approveSpecialRequests = async (isOverview) => {
    let modifiedEmployeeIds = [];
    const targetSuffix = getPreferenceSuffix(autoApprovedLevel);

    const updatedGlobalEmployees = globalEmployees.map((employee) => {
      let isModified = false;

      const updatedPreferences = employee.Preferences.map((item) => {
        if (item.allocation.endsWith("*")) {
          isModified = true;
          return {
            ...item,
            allocation: item.allocation.slice(0, -1) + targetSuffix,
          };
        }
        return item;
      });

      const updatedPreferencesRecurring = employee.PreferencesRecurring.map(
        (item) => {
          if (item.endsWith("*")) {
            isModified = true;
            return item.slice(0, -1) + targetSuffix;
          }
          return item;
        }
      );

      if (isModified) {
        modifiedEmployeeIds.push(employee.id);
      }

      return {
        ...employee,
        ...(isOverview && { Preferences: updatedPreferences }),
        ...(!isOverview && {
          PreferencesRecurring: updatedPreferencesRecurring,
        }),
      };
    });

    applyGlobalPreferencesUpdate(updatedGlobalEmployees, modifiedEmployeeIds);
  };

  const updateRecurringSelection = (selection, prevSelection) => {
    const employeesDataFromGrid = getPreferencesInGrid();
    const updatedGlobalEmployees = globalEmployees.map((employee) => {
      const targetEmployee = employeesDataFromGrid.find(
        (emp) => emp.id === employee.id
      );
      if (!targetEmployee) {
        return employee;
      }

      const originalPreferencesRecurring = targetEmployee.PreferencesRecurring;
      let updatedPreferencesRecurring;
      if (selection === "week") {
        updatedPreferencesRecurring = originalPreferencesRecurring.slice(0, 7);
      } else if (prevSelection === "week" && selection === "fortnight") {
        updatedPreferencesRecurring = duplicateArr(
          originalPreferencesRecurring,
          2
        );
      }

      return {
        ...employee,
        PreferencesRecurring: updatedPreferencesRecurring,
      };
    });
    applyGlobalPreferencesUpdate(updatedGlobalEmployees);
  };

  const longestStr = getLongestAllocationStringInRowData(employeesData, [
    "id",
    "name",
  ]);

  if (!isPaidPlan) {
    return (
      <div className={styles.lockWrapper}>
        <PreferencesLocked />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles["inner-container"]}>
        <div className={styles.header}>
          <h1 className={styles.title} data-testid="heading">
            Preferences - {name}
          </h1>
          <GetStartedButton
            url={
              "https://help.rosterlab.com/fixed-shifts-leave-requests-and-preferences"
            }
          />
        </div>
        <div className={styles["top"]}>
          <div className={styles["top-left"]}>
            <div className={styles.areaFilterContainer}>
              <AreaFilter
                areas={areas}
                onAreaFilterChanged={onAreaFilterChanged}
                onMenuClose={saveAreaFilter}
                defaultValue={initialAreaFilterValue}
              />
            </div>
            <div className={styles["date-picker-wrapper"]}>
              <CollapsibleDateRangePicker
                startDate={startDate}
                finishDate={finishDate}
                urlUpdater={updateDateQueryParam}
              />
            </div>
            <span className={styles.viewLabel}>View: </span>
            <button
              className={styles["date-arrow"]}
              onClick={() => {
                handlePageChange(
                  "decrement",
                  startDate,
                  finishDate,
                  viewRange,
                  updateDateQueryParam
                );
              }}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </button>
            <select
              className={styles["range-selection"]}
              name="range"
              id="range"
              value={viewRange}
              onChange={onViewRangeChange}
            >
              <option value={VIEW_RANGE.month}>Month</option>
              <option value={VIEW_RANGE.fortnight}>2 Weeks</option>
              <option value={VIEW_RANGE.week}>Week</option>
              <option value={VIEW_RANGE.custom}>Custom</option>
            </select>
            <button
              className={styles["date-arrow"]}
              onClick={() => {
                handlePageChange(
                  "increment",
                  startDate,
                  finishDate,
                  viewRange,
                  updateDateQueryParam
                );
              }}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </button>
          </div>
          <div className={styles["top-right"]}>
            {(nearestNextPendingDate || recurringPendingExists) && (
              <p className={styles["pending-reminder-request-msg"]}>
                {getPendingAlertMsg(
                  nearestNextPendingDate,
                  recurringPendingExists
                )}
              </p>
            )}
          </div>
        </div>
        <GridActionHandler
          gridApi={overviewGridApi}
          addNewItemToDB={() => {}}
          updateItemsToDB={updatePreferences}
          duplicateItemsToDB={() => {}}
          removeItemsFromDB={() => {}}
          getDataFromGrid={getPreferencesInGrid}
          getToBeDeletedItems={() => {}}
          parseSelectedRowsToDuplicableInfo={() => {}}
          undoRedoParams={{
            tableName: TABLE_NAMES.LOCATION_PREFERENCES,
            getCustomGridSnapshot: null,
          }}
          disableUndoRedo={true}
        >
          <PreferencesOverviewGrid
            locationID={locationID}
            startDate={startDate}
            employeesData={employeesData}
            dayColumns={dayColumns}
            setGridApiToParent={setOverviewGridApi}
            setColumnApiToParent={() => {}}
            isSaving={isSaving}
            shouldSpecifyWeeksInColHeader={false}
            overviewWarnings={overviewWarnings}
            weekdayColWidth={weekdayColWidth}
            weekendColWidth={weekendColWidth}
            isWDayWEndSeparate={isWDayWEndSeparate}
            setWeekdayColWidth={setWeekdayColWidth}
            setWeekendColWidth={setWeekendColWidth}
            setIsWDayWEndSeparate={setIsWDayWEndSeparate}
            isGlobal={true}
            longestStr={longestStr}
            changePrefLevel={(level) => changePrefLevel(level, true)}
            suffix={prefSuffix}
            gridApi={overviewGridApi}
            getDateRangeSelector={() => {}}
            approveSpecialRequests={() => approveSpecialRequests(true)}
            enumeratedTasks={enumeratedTasks}
            enumeratedShiftTasks={enumeratedShiftTasks}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            subTasks={subTasks}
            showSkillsColumn={true}
            skills={skills}
            areas={areas}
            doesAreaFilterPass={doesAreaFilterPass}
            isExternalFilterPresent={isExternalFilterPresent}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            customKeywordsUtilObj={customKeywordsUtilObj}
            colorCodes={colorCodes}
          />
        </GridActionHandler>
        <GridActionHandler
          gridApi={recurringGridApi}
          addNewItemToDB={() => {}}
          updateItemsToDB={updatePreferences}
          duplicateItemsToDB={() => {}}
          removeItemsFromDB={() => {}}
          getDataFromGrid={getPreferencesInGrid}
          getToBeDeletedItems={() => {}}
          parseSelectedRowsToDuplicableInfo={() => {}}
          undoRedoParams={{
            tableName: TABLE_NAMES.LOCATION_PREFERENCES,
            getCustomGridSnapshot: null,
          }}
          disableUndoRedo={true}
        >
          <PreferencesRecurringGrid
            isSaving={isSaving}
            startDate={getActualInitialStartDate(
              location.isScheduleView ? location.startDate : startDate,
              location.isScheduleView ? location.defaultNumDays : numDays,
              location.isScheduleView
            )}
            employeesData={employeesData}
            recurringColumns={recurringColumns}
            setGridApiToParent={setRecurringGridApi}
            recurringWarnings={recurringWarnings}
            weekdayColWidth={weekdayColWidth}
            weekendColWidth={weekendColWidth}
            recurringPeriodSelection={recurringPeriodSelection}
            updateRecurringSelection={updateRecurringSelection}
            changePrefLevel={(level) => changePrefLevel(level, false)}
            gridApi={recurringGridApi}
            suffix={prefSuffix}
            isGlobal={true}
            approveSpecialRequests={() => approveSpecialRequests(false)}
            enumeratedTasks={enumeratedTasks}
            enumeratedShiftTasks={enumeratedShiftTasks}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            subTasks={subTasks}
            areas={areas}
            showSkillsColumn={true}
            skills={skills}
            doesAreaFilterPass={doesAreaFilterPass}
            isExternalFilterPresent={isExternalFilterPresent}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            customKeywordsUtilObj={customKeywordsUtilObj}
            colorCodes={colorCodes}
          />
        </GridActionHandler>
      </div>
    </div>
  );
};

export const GlobalPreferencesContainerNoHeader = GlobalPreferencesContainer;

export default withHeader(GlobalPreferencesContainer);
