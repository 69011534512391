import { Link, useHistory } from "react-router-dom";
import styles from "./SideBarItem.module.css";
import {
  generateClassicRoster,
  generateScheduleRoster,
} from "../../../features/rosterGenerator/service/generateRoster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";
import { PAGE_NAMES, useRedirect } from "../../../utils/routeUtils/redirect";
import { SIDEBAR_BUTTON_TYPE } from "../SideBar/sideBarItems";
import { getActiveWarningsNumForRoster } from "../../../features/warnings/service/displayHelper/warningNotification";
import { useRosterModelQuery } from "../../../hooks/modelQueryHooks/useRosterModelQuery";
import { useUserStore } from "../../../globalStore/appStore";
import { MID_TIER_RULE_NUMBER_LIMIT } from "../../../constants/generalConstants";
import { PLAN } from "../../../features/auth/service/auth";
import {
  customConfirmAlert,
  customWarningAlertWithDescriptionComponents,
} from "../../../features/confirm/service/confirm";
import { calculateWarnings } from "../../../features/warnings/service/validators/warningsCalculator";
import { checkFixedShiftsMatchCalendar } from "../../../utils/validationUtils/sharedValidations";
import { ALL_WARNING_FIELDS } from "../../../constants/warnings";

const GenerateNavItem = ({
  children,
  testName,
  rosterID,
  locationID,
  isScheduleView,
  periodStartDate,
  frontendSettings,
}) => {
  const { isPaidPlan, plan } = useUserStore();

  const history = useHistory();
  const globalSettings = frontendSettings || null;

  const redirectTo = useRedirect();

  const { roster, customKeywords } = useRosterModelQuery({
    isScheduleView,
    locationID,
    rosterID,
  });
  const isSnapshot = roster.isSnapshot;

  return (
    <span
      className={`${styles.link}`}
      data-testid={testName}
      data-tour={testName}
      data-amplify-analytics-name="generateRoster" // The event name sent to Pinpoint
      onClick={async () => {
        if (isScheduleView) {
          const employees = roster.Employees;

          const employeesWithSpecialPreferences = [];
          for (const employee of employees) {
            const preferences = employee.Days;
            const preferencesRecurring = employee.DaysRecurring;
            const allPreferences = [...preferences, ...preferencesRecurring];

            for (const pref of allPreferences) {
              if (pref.endsWith("*")) {
                employeesWithSpecialPreferences.push(employee.name);
                break;
              }
            }
          }

          if (employeesWithSpecialPreferences.length > 0) {
            const response = await customConfirmAlert({
              title: "Pending Special Requests",
              descriptions: [
                `There are pending special requests for ${employeesWithSpecialPreferences.join(
                  ", "
                )}, are you sure you want to generate a roster? If you do so all pending special requests will be treated as normal requests.`,
              ],
            });
            if (!response) {
              return;
            }
          }
        }

        if (!isPaidPlan) {
          redirectTo({
            pageName: isScheduleView
              ? PAGE_NAMES.scheduleGenerateLocked
              : PAGE_NAMES.generateLocked,
            query: { "roster-id": rosterID, "location-id": locationID },
          });
          return;
        }

        const onGenerate = async () => {
          const warnings = calculateWarnings(
            roster,
            ALL_WARNING_FIELDS,
            customKeywords
          );
          if (globalSettings.includes("checkLeave")) {
            const isApprovedRequestsMissing =
              await checkFixedShiftsMatchCalendar(locationID, rosterID);
            if (!isApprovedRequestsMissing) {
              const result = await customConfirmAlert({
                title: "Leave requests out of sync",
                descriptions: [
                  "You have Leave requests in the current roster that are out of sync with the approved leave requests.",
                  "Please click 'Continue' to generate the roster if this is correct. Please refresh the page if you would like to re-sync the leave requests.",
                ],
              });
              if (!result) {
                return;
              }
            }
          }

          if (isScheduleView) {
            if (
              plan === PLAN.MID &&
              roster.CustomRules.length > MID_TIER_RULE_NUMBER_LIMIT
            ) {
              const response =
                await customWarningAlertWithDescriptionComponents({
                  title: "Cannot Generate Roster",
                  DescriptionComponents: [
                    ({ ok }) => (
                      <div>
                        You can only add up to 8 rules for RosterLab Starter.{" "}
                        <span
                          onClick={ok}
                          style={{
                            color: "#E67530",
                            textDecoration: "underline",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          Modify in the rules page
                        </span>
                      </div>
                    ),
                    ({ cancel }) => (
                      <div>
                        Having a complex roster and need more rules?{" "}
                        <span
                          onClick={cancel}
                          style={{
                            color: "#E67530",
                            textDecoration: "underline",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          Chat with our team now!
                        </span>
                      </div>
                    ),
                  ],
                  okLabel: "Modify Rules",
                  cancelLabel: "Explore Premium",
                  showCancelBtn: true,
                });

              if (response) {
                redirectTo({
                  pageName: PAGE_NAMES.scheduleViewRules,
                  query: {
                    "location-id": locationID,
                    date: periodStartDate,
                  },
                });
              } else {
                redirectTo({
                  pageName: PAGE_NAMES.billing,
                  query: {
                    tab: "plan",
                  },
                });
              }
              return;
            }
            generateScheduleRoster(roster, locationID, warnings).then(
              (isSucceeded) => {
                if (isSucceeded) {
                  if (isSnapshot) {
                    history.push(
                      `/locations/schedule-view/solutions?location-id=${roster.locationID}&date=${roster.startDate}&snapshot-id=${rosterID}`
                    );
                  } else {
                    history.push(
                      `/locations/schedule-view/solutions?location-id=${roster.locationID}&date=${roster.startDate}`
                    );
                  }
                }
              }
            );
            return;
          }

          generateClassicRoster(rosterID, locationID, warnings).then(
            (isSucceeded) => {
              if (isSucceeded) {
                history.push("/roster/solutions?roster-id=" + rosterID);
              }
            }
          );
        };
        await onGenerate();
      }}
    >
      {children}
    </span>
  );
};

const DashboardNavItem = ({ children, locationID, testName }) => {
  return (
    <Link
      to={{
        pathname: "/locations/classic-view/dashboard",
        search: `?location-id=${locationID}`,
      }}
    >
      <div className={`${styles.link}`} data-testid={testName}>
        {children}
      </div>
    </Link>
  );
};

const CommonNavItem = ({ children, routeTo, urlQueryParam, testName }) => {
  return (
    <Link
      to={{
        pathname: routeTo,
        search: urlQueryParam,
      }}
    >
      <div className={`${styles.link}`} data-testid={testName}>
        {children}
      </div>
    </Link>
  );
};

const getNavItem = (
  buttonType,
  iconElement,
  label,
  rosterID,
  locationID,
  testName,
  urlQueryParam,
  routeTo,
  isScheduleView,
  periodStartDate,
  warnings,
  frontendSettings
) => {
  switch (buttonType) {
    case SIDEBAR_BUTTON_TYPE.generateButton:
      return (
        <GenerateNavItem
          testName={testName}
          rosterID={rosterID}
          locationID={locationID}
          isScheduleView={isScheduleView}
          periodStartDate={periodStartDate}
          warnings={warnings}
          frontendSettings={frontendSettings}
        >
          {iconElement}
          <span className={`${styles.displayedLabel} ${styles.whiteLabel}`}>
            Generate
          </span>
        </GenerateNavItem>
      );
    case SIDEBAR_BUTTON_TYPE.dashboardButton:
      return (
        <DashboardNavItem locationID={locationID} testName={testName}>
          {iconElement}
        </DashboardNavItem>
      );
    case SIDEBAR_BUTTON_TYPE.commonButtons:
      return (
        <CommonNavItem
          urlQueryParam={urlQueryParam}
          testName={testName}
          routeTo={routeTo}
        >
          {iconElement}
          <span className={`${styles.displayedLabel}`}>{label}</span>
        </CommonNavItem>
      );
    default:
      throw new Error(`[${buttonType}] is an invalid nav item button type`);
  }
};

const SideBarItem = ({
  iconElement,
  label,
  testName,
  routeTo,
  rosterID,
  locationID,
  active,
  isFullVersionMenu,
  urlQueryParam,
  buttonType,
  isScheduleView,
  periodStartDate,
  warnings,
  hideWarningCountForFreeUsers,
  frontendSettings,
}) => {
  const { isPaidPlan } = useUserStore();
  const shouldHideWarningCount = !isPaidPlan && hideWarningCountForFreeUsers;

  const warningCount = getActiveWarningsNumForRoster(warnings, label);
  return (
    <li
      className={`${styles.container} ${active && styles.active} ${
        buttonType === SIDEBAR_BUTTON_TYPE.generateButton && styles.generateBtn
      }`}
    >
      {warningCount > 0 && !shouldHideWarningCount && (
        <span className={`${styles.warningCount}`}>{warningCount}</span>
      )}
      <div className={styles.navItem}>
        {getNavItem(
          buttonType,
          iconElement,
          label,
          rosterID,
          locationID,
          testName,
          urlQueryParam,
          routeTo,
          isScheduleView,
          periodStartDate,
          warnings,
          frontendSettings
        )}
      </div>
      {isFullVersionMenu && !isPaidPlan && (
        <div className={styles.limitedContainer}>
          <FontAwesomeIcon
            icon={faCircleArrowUp}
            className={styles.limitedIcon}
            style={{
              color: label === "Generate Roster" ? "white" : "#00d4aa",
            }}
          />
        </div>
      )}
    </li>
  );
};

export default SideBarItem;
