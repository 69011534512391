/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_mobile_analytics_app_id": "d1e648808f364b70bca4cf2ae6aa4723",
    "aws_mobile_analytics_app_region": "ap-southeast-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "d1e648808f364b70bca4cf2ae6aa4723",
            "region": "ap-southeast-2"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://1dd81y6mc1.execute-api.ap-southeast-2.amazonaws.com/test",
            "region": "ap-southeast-2"
        },
        {
            "name": "RosterLabRestNoAuth",
            "endpoint": "https://onk7555p04.execute-api.ap-southeast-2.amazonaws.com/test",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://a72cwyf4fnaozdadp4h3wbk2nq.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-de2drpafqjcidmkl3bf53z4aty",
    "aws_cognito_identity_pool_id": "ap-southeast-2:b21dd507-38f2-4886-aebc-810c650541b2",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_Ax0nBnzQM",
    "aws_user_pools_web_client_id": "3evqrnt8akjh9subhk0dgkumh6",
    "oauth": {
        "domain": "rosterlabfrontenda552dd39-a552dd39-test.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://test.rosterlab.com/,https://app.rosterlab.com/",
        "redirectSignOut": "http://localhost:3000/,https://test.rosterlab.com/,https://app.rosterlab.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "GIVEN_NAME",
        "FAMILY_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
