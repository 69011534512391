import styles from "./LiveCalendarGrid.module.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import DataEntryTable from "../../../../rosterProblems/components/DataEntryTable/DataEntryTable";
import {
  DateTime,
  allocationValueFormatter,
  exportGridToExcel,
  getDatesColumnDefs,
  getLongestAllocationStringInRowData,
  getNames,
  getReservedHeaderRowStyle,
  getRowCountStatusBar,
  onFilterTextBoxChanged,
} from "../../../../../utils";
import { CustomToolTip } from "../../../../grid/components/toolTip/toolTip";
import { getDayColumnNameRegex } from "../../../../../utils/generalUtils/regex";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import { useLiveCalendarColumnWidthStore } from "../../../../../globalStore/columnWidthStore";
import AllocationCellRenderer from "../../../../grid/components/AllocationCellRenderer/AllocationCellRenderer";
import {
  getColorCodedCells,
  getWeekendsCellStyle,
} from "../../../../rosterProblems/rosteredAllocations/service/styleGetters";
import { useBottomPinnedGridStatisticsTemplate } from "../../../../../hooks/useBottomPinnedGridStatisticsTemplate";
import {
  getDayShiftCounts,
  getDayShiftHours,
  getShiftAndSkillCountsPerColumn,
  getShiftGroupHoursPerColumn,
} from "../../../../statistics/service/statsValueGetter";
import { getStatsColumns } from "../../../../rosterProblems/rosteredAllocations/service/statsColumns";
import { SwitchField } from "@aws-amplify/ui-react";

const initialAreaFilterValue = [];

const LiveCalendarGrid = ({
  locationID,
  employeesData,
  employees,
  areas,
  skills,
  shiftGroups,
  shifts,
  colorCodes,
  tasks,
  taskBlocks,
  shortNames,
  numDays,
  startDate,
  employeesAllocationNotes,
  gridApi,
  setGridApi,
  doesAreaFilterPass,
  isExternalFilterPresent,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
  statistics,
  namesToEntityShortIdsDicts,
  employeesShiftHours,
  ruleStatsMinMaxValues,
  isStatisticsEnabled,
  schedulePeriodNum,
}) => {
  const [showStatistics, setShowStatistics] = useState(false);
  const shiftNames = useMemo(() => getNames(shifts), [shifts]);
  const shiftGroupNames = useMemo(() => getNames(shiftGroups), [shiftGroups]);
  const taskNames = useMemo(() => getNames(tasks), [tasks]);

  const longestStr = getLongestAllocationStringInRowData(employeesData, [
    "id",
    "name",
  ]);

  const {
    weekdayColWidth,
    weekendColWidth,
    isWDayWEndSeparate,
    setWeekdayColWidth,
    setWeekendColWidth,
    setIsWDayWEndSeparate,
  } = useLiveCalendarColumnWidthStore();

  const pinnedBottomRowDataTemplate = useBottomPinnedGridStatisticsTemplate({
    shifts,
    shiftGroups,
    skills,
    numDays,
    statistics,
    initialAreaFilterValue: initialAreaFilterValue,
    hide: !showStatistics,
  });

  useEffect(() => {
    if (gridApi) {
      setTimeout(() => {
        gridApi.refreshCells();
      }, 200);
    }
  }, [schedulePeriodNum, gridApi]);

  const {
    totalShiftsColGroups,
    totalShiftHoursColGroups,
    weekendsOffCountColDefs,
    leaveCountColDefs,
  } = useMemo(
    () =>
      showStatistics
        ? getStatsColumns(
            startDate,
            numDays,
            shifts,
            shiftGroups,
            ruleStatsMinMaxValues,
            80,
            customKeywordsUtilObj.reservedShiftKeywords,
            employeesShiftHours,
            employees,
            tasks,
            taskBlocks,
            customKeywordsUtilObj,
            shortIdsToEntityNamesDicts,
            namesToEntityShortIdsDicts,
            true,
            statistics
          )
        : {
            totalShiftsColGroups: null,
            totalShiftHoursColGroups: null,
            weekendsOffCountColDefs: null,
            leaveCountColDefs: null,
          },
    [
      startDate,
      numDays,
      shiftGroups,
      ruleStatsMinMaxValues,
      employeesShiftHours,
      employees,
      shifts,
      tasks,
      taskBlocks,
      customKeywordsUtilObj,
      shortIdsToEntityNamesDicts,
      statistics,
      namesToEntityShortIdsDicts,
      showStatistics,
    ]
  );

  const customActionBarComponents = useMemo(() => {
    const components = [];
    if (isStatisticsEnabled) {
      components.push(() => (
        <div className={styles.container}>
          <SwitchField
            onChange={(e) => {
              if (!isStatisticsEnabled) {
                setShowStatistics(false);
                return;
              }
              setShowStatistics(e.target.checked);
            }}
            isChecked={isStatisticsEnabled ? showStatistics : null}
            label="Toggle statistics"
            isLabelHidden={true}
            trackCheckedColor="#009EC6"
            style={{
              display: "flex",
            }}
          />
          <span className={styles.label} style={{ color: "#219ec9" }}>
            Enable statistics
          </span>
        </div>
      ));
    }
    return components;
  }, [isStatisticsEnabled, showStatistics]);
  const datesColumnDefs = useMemo(() => {
    if (!employeesData) {
      return [];
    }

    let datesColumnDefs = getDatesColumnDefs(numDays, startDate);

    const customDatesColumnDefs = datesColumnDefs.map(
      (weekColDefs, weekColIdx) => {
        const customWeekColDefs = weekColDefs.children.map(
          (dayColDefs, dayColIdx) => {
            const customDayColDefs = dayColDefs.children.map((colDef) => {
              const date = new DateTime(startDate)
                .addDays(Number(colDef.field.substring(1)) - 1)
                .toFormat("AWS");

              return {
                ...colDef,
                tooltipComponent: "tooltipComponent",
                tooltipComponentParams: {
                  shiftNames,
                  shiftGroupNames,
                  taskNames,
                  shifts,
                  shiftGroups,
                  tasks,
                  taskBlocks,
                  areas,
                  ...(employeesAllocationNotes && { employeesAllocationNotes }),
                  customKeywordsUtilObj,
                  date,
                },
                cellRenderer: "allocationCellRenderer",
                cellRendererParams: {
                  ...(employeesAllocationNotes && {
                    employeesAllocationNotes,
                  }),
                  date,
                },
                tooltipValueGetter: (params) => ({ value: params.value }),
                editable: false,
                valueGetter: (params) => {
                  const dayColNameRegex = getDayColumnNameRegex();
                  const originalVal = params.data[params.colDef.field];

                  if (originalVal === null || originalVal === undefined) {
                    return originalVal;
                  }

                  if (
                    params.node.rowPinned === "bottom" &&
                    !params.data.id.startsWith("reserved")
                  ) {
                    if (params.data.id === "totalDayShiftCounts") {
                      return getDayShiftCounts(
                        params,
                        customKeywordsUtilObj.reservedShiftKeywords
                      );
                    }
                    if (params.data.id === "totalDayShiftHours") {
                      return getDayShiftHours(
                        params,
                        customKeywordsUtilObj.reservedShiftKeywords,
                        employeesShiftHours,
                        shortIdsToEntityNamesDicts,
                        namesToEntityShortIdsDicts,
                        true
                      );
                    }
                    if (params.data.id.startsWith("shift-hours-")) {
                      return getShiftGroupHoursPerColumn(
                        params,
                        shiftGroups,
                        customKeywordsUtilObj.reservedShiftKeywords,
                        employeesShiftHours,
                        shortIdsToEntityNamesDicts,
                        namesToEntityShortIdsDicts,
                        true,
                        customKeywordsUtilObj
                      );
                    }
                    //shift-counts- or skill-counts or shift-skill-counts
                    return getShiftAndSkillCountsPerColumn(
                      params,
                      shortIdsToEntityNamesDicts,
                      namesToEntityShortIdsDicts,
                      false,
                      customKeywordsUtilObj
                    );
                  } else if (
                    !params.data.id.startsWith("reserved") &&
                    params.colDef.field.match(dayColNameRegex)
                  ) {
                    const shiftSkillPair = originalVal.split("-");
                    const shift = shiftSkillPair[0].trim();
                    const skill = shiftSkillPair[1]
                      ? shiftSkillPair[1].trim()
                      : null;

                    if (skill) {
                      return [shift, skill].join("-");
                    }
                    return shift;
                  }
                  return originalVal;
                },
                valueFormatter: (params) => {
                  if (params.node.rowPinned) {
                    return params.value;
                  }

                  return allocationValueFormatter(
                    params,
                    shortNames,
                    shortIdsToEntityNamesDicts
                  );
                },
                width:
                  colDef.headerName === "Sa" || colDef.headerName === "Su"
                    ? weekendColWidth
                    : weekdayColWidth,
                cellStyle: (params) => {
                  let style = getWeekendsCellStyle(params);
                  if (shortNames.includes(params.value)) {
                    return {
                      ...style,
                      color: "#cb0bd2",
                    };
                  }

                  return {
                    ...style,
                    ...getColorCodedCells(
                      params,
                      colorCodes,
                      shiftGroups,
                      shortIdsToEntityNamesDicts,
                      customKeywordsUtilObj
                    ),
                  };
                },
                colSpan: (params) => {
                  if (
                    params.data.id === "reserved_header" &&
                    params.data["d1"] === "Total Counts of shifts by day"
                  ) {
                    return numDays;
                  }
                  return 1;
                },
              };
            });
            return {
              ...dayColDefs,
              groupId: `dayColGroup${dayColIdx}`,
              children: customDayColDefs,
            };
          }
        );
        return {
          ...weekColDefs,
          groupId: `weekColGroup${weekColIdx}`,
          children: customWeekColDefs,
        };
      }
    );
    return customDatesColumnDefs;
  }, [
    weekdayColWidth,
    weekendColWidth,
    startDate,
    colorCodes,
    employeesData,
    numDays,
    shiftGroups,
    shifts,
    shortNames,
    taskBlocks,
    tasks,
    employeesAllocationNotes,
    shiftGroupNames,
    shiftNames,
    taskNames,
    areas,
    shortIdsToEntityNamesDicts,
    namesToEntityShortIdsDicts,
    customKeywordsUtilObj,
    employeesShiftHours,
  ]);

  const basicColumns = [
    {
      field: "name",
      pinned: "left",
      editable: false,
    },
  ];

  const columnDefs = [
    {
      headerName: "Employees",
      children: basicColumns,
    },
    ...datesColumnDefs,
  ];

  if (totalShiftsColGroups) {
    columnDefs.push(totalShiftsColGroups);
  }
  if (totalShiftHoursColGroups) {
    columnDefs.push(totalShiftHoursColGroups);
  }
  if (weekendsOffCountColDefs) {
    columnDefs.push(weekendsOffCountColDefs);
  }
  if (leaveCountColDefs) {
    columnDefs.push(leaveCountColDefs);
  }

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const exportToExcel = useCallback(() => {
    exportGridToExcel(gridApi);
  }, [gridApi]);

  return (
    <div>
      <ActionBar
        locationID={locationID}
        searchBarSettings={{
          tableName: "calendar",
          onFilterInputChanged,
        }}
        adjustWidthSettings={{
          longestStr,
          weekdayColWidth,
          weekendColWidth,
          isWDayWEndSeparate,
          setWeekdayColWidth,
          setWeekendColWidth,
          setIsWDayWEndSeparate,
          tableName: "calendar",
          saveToDatabase: false,
          disableSetForAllPages: true,
        }}
        exportSettings={{
          exportToExcel,
        }}
        customComponents={customActionBarComponents}
      />
      <DataEntryTable
        customStyle={{
          height: "calc(100vh - 160px)",
        }}
        columnDefs={columnDefs}
        rowData={employeesData}
        setGridApiToParent={setGridApi}
        components={{
          tooltipComponent: CustomToolTip,
          allocationCellRenderer: AllocationCellRenderer,
        }}
        tooltipShowDelay={0}
        tooltipHideDelay={null}
        context={{
          shiftGroups,
          shifts,
          areas,
          tasks,
          taskBlocks,
        }}
        getRowStyle={getReservedHeaderRowStyle}
        statusBar={{
          statusPanels: [...getRowCountStatusBar().statusPanels],
        }}
        columnHoverHighlight={true}
        overlayNoRowsTemplate={
          '<span style="padding: 10px; font-weight: bold; font-size: 24px;">The schedule admin has not published the schedule for this period.</span>'
        }
        doesExternalFilterPass={doesAreaFilterPass}
        isExternalFilterPresent={isExternalFilterPresent}
        pinnedBottomRowData={pinnedBottomRowDataTemplate}
      />
    </div>
  );
};

export default LiveCalendarGrid;
