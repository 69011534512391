import styles from "./LocationSettings.module.css";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  checkCheckLeaveSettingEnabled,
  getFieldsFromLocation,
} from "../../../../utils/queryUtils/locationDataGetters";
import { getNames } from "../../../../utils";
import { useLocationMutation } from "../../../../hooks/modelQueryHooks/useLocationMutation";
import { LOCATION_SETTINGS_TYPE } from "../../../../constants/settings";
import { getLocationSettingsByPlan } from "../../../../utils/settingsUtils/settingsUtils";
import SettingsTabContent from "./SettingsTabContent";

function createSettingTabsInfo(plan) {
  const locationSettings = getLocationSettingsByPlan(plan);
  const settingTabsInfo = Object.values(LOCATION_SETTINGS_TYPE)
    .filter(
      (type) => type !== LOCATION_SETTINGS_TYPE.notDisplayedInSettingsPage
    )
    .map((type) => {
      const tabId = `tab-${type}`;
      const tabLabel = type;
      const typedSettings = locationSettings[type];
      const groups = Object.values(
        typedSettings.reduce((acc, item) => {
          const group = item.group;
          if (!acc[group]) {
            acc[group] = {
              groupId: `group-${group}`,
              groupLabel: group,
              settings: [],
            };
          }
          acc[group].settings.push(item);
          return acc;
        }, {})
      );

      return {
        tabId,
        tabLabel,
        groups,
      };
    });
  return settingTabsInfo;
}

export default function LocationSettings({
  location,
  plan,
  customKeywordsData,
  handleDeleteLocation,
  updateLocationName,
  isLocationDeletable,
}) {
  const { updateLocationFields } = useLocationMutation(location);

  const settingTabsInfo = createSettingTabsInfo(plan);

  const [activeTab, setActiveTab] = useState(settingTabsInfo[0].tabId);
  const bodyRef = useRef(null);
  const settingsTabPagesRef = useRef(null);

  const {
    frontendSettings,
    settings: backendSettings,
    defaultNumDays,
  } = useMemo(() => getFieldsFromLocation(location), [location]);

  const frontendSettingNames = getNames(frontendSettings);

  const leaveSettingValues = useMemo(
    () =>
      frontendSettings.find((setting) => setting.name === "annualLeaveKeyword")
        .values,
    [frontendSettings]
  );

  const publicHolidayTimezone = useMemo(() => {
    const setting = frontendSettings.find(
      (setting) => setting.name === "publicHolidayTimezone"
    );
    return setting ? setting.values[0] : "None";
  }, [frontendSettings]);

  const checkIsSettingEnabled = (setting) => {
    const isBackendSetting = setting.savedIn === "backend";
    if (isBackendSetting) {
      return backendSettings.includes(setting.name);
    }

    if (setting.name === "checkLeave") {
      const checkLeaveSetting = frontendSettings.find(
        (setting) => setting.name === "checkLeave"
      );

      if (checkLeaveSetting && checkLeaveSetting.values[0] === "false") {
        return false;
      }

      return true;
    }

    return frontendSettingNames.includes(setting.name);
  };

  const getTargetSetting = (setting) => {
    const isBackendSetting = setting.savedIn === "backend";

    if (isBackendSetting) {
      return null;
    }

    return frontendSettings.find((item) => item.name === setting.name) || null;
  };

  const toggleFrontendSetting = (settingName, values) => {
    let updatedFrontendSettings;
    if (frontendSettingNames.includes(settingName)) {
      updatedFrontendSettings = frontendSettings.filter(
        (item) => item.name !== settingName
      );
    } else {
      updatedFrontendSettings = [
        ...frontendSettings,
        {
          name: settingName,
          values: values ? values : [],
        },
      ];
    }

    const updatedLocation = {
      ...location,
      frontendSettings: updatedFrontendSettings,
    };
    updateLocationFields(["frontendSettings"], updatedLocation);
  };

  const toggleBackendSetting = (settingName) => {
    let updatedBackendSettings;
    if (backendSettings.includes(settingName)) {
      updatedBackendSettings = backendSettings.filter(
        (item) => item != settingName
      );
    } else {
      updatedBackendSettings = [...backendSettings, settingName];
    }

    const updatedLocation = {
      ...location,
      settings: updatedBackendSettings,
    };

    updateLocationFields(["settings"], updatedLocation);
  };

  const updateFrontendSettings = (settingName, values) => {
    // Check if the setting exists
    const settingIndex = frontendSettings.findIndex(
      (setting) => setting.name === settingName
    );

    let updatedFrontendSettings;

    if (settingIndex !== -1) {
      // Update existing setting
      updatedFrontendSettings = frontendSettings.map((setting, index) => {
        if (index === settingIndex) {
          return {
            name: settingName,
            values,
          };
        }
        return setting;
      });
    } else {
      // Add new setting
      updatedFrontendSettings = [
        ...frontendSettings,
        {
          name: settingName,
          values,
        },
      ];
    }

    const updatedLocation = {
      ...location,
      frontendSettings: updatedFrontendSettings,
    };

    updateLocationFields(["frontendSettings"], updatedLocation);
  };

  /**
   * ----- Code here can be improved -----
   * "checkLeave" setting toggle works differently
   * values: ["true] or ["false"]
   */
  const updateCheckLeaveSetting = () => {
    let updatedFrontendSettings;

    const checkLeaveSetting = frontendSettings.find(
      (setting) => setting.name === "checkLeave"
    );

    if (!checkLeaveSetting) {
      updatedFrontendSettings = [
        ...frontendSettings,
        {
          name: "checkLeave",
          values: ["true"],
        },
      ];
    } else {
      const isCheckLeaveSettingEnabled =
        checkCheckLeaveSettingEnabled(frontendSettings);

      updatedFrontendSettings = frontendSettings.map((setting) => {
        if (setting.name === "checkLeave") {
          if (isCheckLeaveSettingEnabled) {
            return {
              ...setting,
              values: ["false"],
            };
          }
          return {
            ...setting,
            values: ["true"],
          };
        }
        return setting;
      });
    }

    const updatedLocation = {
      ...location,
      frontendSettings: updatedFrontendSettings,
    };
    updateLocationFields(["frontendSettings"], updatedLocation);
  };

  const getSettingToggler = (setting) => {
    const isBackendSetting = setting.savedIn === "backend";
    return isBackendSetting ? toggleBackendSetting : toggleFrontendSetting;
  };

  function getTabDict() {
    if (!settingsTabPagesRef.current) {
      settingsTabPagesRef.current = {};
    }
    return settingsTabPagesRef.current;
  }

  const handleTabClick = (key) => {
    getTabDict()[key].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  };

  useEffect(() => {
    const refDict = getTabDict();
    const observer = new IntersectionObserver(
      (entries) => {
        const visibleEntries = entries
          .filter((entry) => entry.isIntersecting) // Ensure the entry is intersecting
          .sort((a, b) => a.boundingClientRect.top - b.boundingClientRect.top); // Sort by top position

        if (visibleEntries.length > 0) {
          const topMostEntry = visibleEntries[0]; // Get the top-most visible element
          // MAYBE GIVE THIS A BIT OF THROTTLE
          setActiveTab(topMostEntry.target.id);
        }
      },
      {
        root: bodyRef.current, // Use the container as the root
        rootMargin: "0px 0px 200px 0px", // Extend margin at the bottom
        threshold: [0.2], // Trigger at multiple visibility levels
      }
    );

    // Observe each node
    Object.values(refDict).forEach((node) => {
      if (node) observer.observe(node);
    });

    return () => {
      Object.values(refDict).forEach((node) => {
        if (node) observer.unobserve(node);
      });
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h1 className={styles.pageTitle}>
          Set up your location -{" "}
          <span className={styles.locationName}>{location.name}</span>
        </h1>
      </div>
      <div className={styles.body} ref={bodyRef}>
        <ul className={styles.sidebar}>
          {settingTabsInfo.map(({ tabId, tabLabel }, idx) => {
            const isActive = tabId === activeTab;
            return (
              <li
                key={idx}
                className={`${styles.settingItem} ${
                  isActive ? styles.activeSettingItem : ""
                }`}
                onClick={() => {
                  handleTabClick(tabId);
                }}
              >
                <div className={styles.settingItemTag}></div>
                <span className={styles.settingItemLabel}>{tabLabel}</span>
              </li>
            );
          })}
        </ul>
        <div className={styles.content}>
          {settingTabsInfo.map((tabInfo) => {
            return (
              <SettingsTabContent
                key={tabInfo.tabId}
                ref={(node) => {
                  const dict = getTabDict();
                  dict[tabInfo.tabId] = node;
                }}
                tabInfo={tabInfo}
                defaultNumDays={defaultNumDays}
                location={location}
                checkIsEnabled={checkIsSettingEnabled}
                getTargetSetting={getTargetSetting}
                getSettingToggler={getSettingToggler}
                updateFrontendSettings={updateFrontendSettings}
                customKeywordsData={customKeywordsData}
                leaveSettingValues={leaveSettingValues}
                publicHolidayTimezone={publicHolidayTimezone}
                updateCheckLeaveSetting={updateCheckLeaveSetting}
                handleDeleteLocation={handleDeleteLocation}
                updateLocationName={updateLocationName}
                isLocationDeletable={isLocationDeletable}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
