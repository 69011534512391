import { useMemo } from "react";
import styles from "./FixedShiftsRecurringGrid.module.css";
import Layout from "../../../../../components/layouts/Layout/Layout";
import DataEntryTable from "../../DataEntryTable/DataEntryTable";
import WarningDisplay from "../../../../warnings/components/WarningDisplay/WarningDisplay";
import RangeSelector from "../../RangeSelector/RangeSelector";
import { getDisplayedWarningsInfo } from "../../../../warnings/service/displayHelper/msgDisplayer";
import DropdownSingleSelector from "../../../../grid/components/DropdownSingleSelector/DropdownSingleSelector";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import {
  allocationDropdownOptionsValueSetter,
  allocationValueFormatter,
  convertShortIdsCellValueToEntityNames,
  createBasicContextMenu,
  DateTime,
  defaultFilterValueGetter,
  flatOptions,
  getDayNumFromColFieldName,
  getRequestOptions,
  onFilterTextBoxChanged,
  suppressEnterKey,
} from "../../../../../utils";
import {
  getColorCodedCells,
  getWeekendsCellStyle,
} from "../../../rosteredAllocations/service/styleGetters";
import { processAllocationsFromClipboard } from "../../../../../utils/agGridUtils/clipboard";
import { ColorCodingButton } from "../../../rosteredAllocations/components/RosterActionComponents/RosterActionComponents";

const NoEmployeesOverlay = `<span style="padding: 10px; border: 2px solid red; color: red; font-size: 15px">
You have no employees set up for this roster
</span>`;

const FixedShiftsRecurringGrid = ({
  locationID,
  startDate,
  employeesData,
  enumeratedTasks,
  enumeratedShiftTasks,
  isSaving,
  recurringColumns,
  setGridApiToParent,
  setColumnApiToParent,
  updateData,
  recurringWarnings,
  weekdayColWidth,
  weekendColWidth,
  recurringPeriodSelection,
  updateRecurringSelection,
  customStyle,
  title = null,
  showWeekRangeSelector = true,
  gridApi,
  showActionBar = true,
  exportToCsv,
  exportToExcel,
  handleKeyDownForUndoRedo,
  triggerUndoRedoSnapshotCollection,
  predefinedKeywords,
  shouldInsertOffOnPasteBlank,
  getDataFromGrid,
  areas,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  showSkillsColumn = false,
  skills = null,
  employeeNames,
  doesAreaFilterPass = null,
  isExternalFilterPresent = null,
  shortIdsToEntityNamesDicts,
  customKeywordsUtilObj,
  toggleColorCodingModal,
  colorCodes,
  isGlobal,
}) => {
  const getContextMenuItems = () => {
    return createBasicContextMenu();
  };

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const isWarningPresent = recurringWarnings && recurringWarnings.length > 0;

  const customActionBarComponents = useMemo(() => {
    const components = [];
    if (!isGlobal) {
      components.push(() => (
        <ColorCodingButton onClick={toggleColorCodingModal} />
      ));
    }
    return components;
  }, [toggleColorCodingModal, isGlobal]);

  const shiftOptions = useMemo(
    () =>
      getRequestOptions(
        predefinedKeywords,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        areas,
        enumeratedTasks,
        enumeratedShiftTasks,
        shortIdsToEntityNamesDicts,
        customKeywordsUtilObj
      ),
    [
      predefinedKeywords,
      shifts,
      shiftGroups,
      tasks,
      subTasks,
      areas,
      enumeratedTasks,
      enumeratedShiftTasks,
      shortIdsToEntityNamesDicts,
      customKeywordsUtilObj,
    ]
  );

  const columnDefs = useMemo(() => {
    const employeesColDefs = [
      {
        field: "name",
        sortable: true,
        editable: false,
        width: 150,
        pinned: "left",
      },
    ];

    if (showSkillsColumn && skills) {
      employeesColDefs.push({
        field: "skills",
        sortable: true,
        editable: false,
        width: 100,
        pinned: "left",
        valueFormatter: (params) =>
          convertShortIdsCellValueToEntityNames(params.value, skills),
        filterValueGetter: (params) =>
          defaultFilterValueGetter(params, "skills", skills),
      });
    }
    return [
      {
        headerName: "Employees",
        groupId: "employeesGroup",
        children: employeesColDefs,
      },
      {
        headerName: "Recurring",
        groupId: "dayGroup",
        children: recurringColumns.map((colName) => {
          const offset = getDayNumFromColFieldName(colName);
          const DoW = new DateTime(startDate)
            .addDays(offset)
            .getDayOfWeek("dd");

          return {
            headerName: DoW,
            headerClass: (params) => {
              const dayOfWeek = params.colDef.headerName;
              if (dayOfWeek === "Sa" || dayOfWeek === "Su") {
                return "header";
              }
            },
            cellStyle: (params) => ({
              ...getWeekendsCellStyle(params),
              ...getColorCodedCells(
                params,
                colorCodes,
                shiftGroups,
                shortIdsToEntityNamesDicts,
                customKeywordsUtilObj
              ),
            }),
            suppressMenu: true,
            width:
              DoW === "Sa" || DoW === "Su" ? weekendColWidth : weekdayColWidth,
            field: colName,
            cellClassRules: {
              "invalid-cell": (params) => {
                if (recurringWarnings) {
                  const filteredRecurringWarnings = recurringWarnings.filter(
                    ({ warningType }) =>
                      warningType === "invalid AllocationsRecurring input"
                  );
                  for (const recurringWarning of filteredRecurringWarnings) {
                    if (
                      recurringWarning &&
                      recurringWarning.values.includes(params.value) &&
                      recurringWarning.extraInfo.employeeID === params.data.id
                    ) {
                      return true;
                    }
                  }
                }
              },
            },
            cellEditor: "dropdownSingleSelector",
            cellEditorParams: {
              width: 100,
              options: shiftOptions,
            },
            cellEditorPopup: true,
            valueSetter: (params) =>
              allocationDropdownOptionsValueSetter(
                params,
                flatOptions(shiftOptions)
              ),
            valueFormatter: (params) =>
              allocationValueFormatter(
                params,
                predefinedKeywords,
                shortIdsToEntityNamesDicts
              ),
            suppressKeyboardEvent: suppressEnterKey,
          };
        }),
      },
    ];
  }, [
    recurringColumns,
    recurringWarnings,
    shiftOptions,
    startDate,
    weekdayColWidth,
    weekendColWidth,
    predefinedKeywords,
    skills,
    showSkillsColumn,
    shortIdsToEntityNamesDicts,
    colorCodes,
    customKeywordsUtilObj,
    shiftGroups,
  ]);

  return (
    <Layout
      title={title ? title : "Recurring Patterns"}
      isSubheading={true}
      headerRight={() => {
        if (!showWeekRangeSelector) {
          return null;
        }
        return (
          <RangeSelector
            updateSelection={updateRecurringSelection}
            selection={recurringPeriodSelection}
          />
        );
      }}
    >
      <div className={styles.container}>
        {showActionBar && (
          <ActionBar
            locationID={locationID}
            searchBarSettings={{
              tableName: "fixedShiftsRecurring",
              onFilterInputChanged,
            }}
            customComponents={customActionBarComponents}
            exportSettings={{
              exportToCsv,
              exportToExcel,
            }}
          />
        )}
        <DataEntryTable
          customStyle={customStyle ? customStyle.dataEntryTable : {}}
          columnHoverHighlight={true}
          columnDefs={columnDefs}
          rowData={employeesData}
          updateData={updateData}
          getContextMenuItems={getContextMenuItems}
          onCellKeyDown={(params) => {
            if (handleKeyDownForUndoRedo) {
              handleKeyDownForUndoRedo(params.event);
            }
          }}
          gridOptions={{
            onCellValueChanged: (params) => {
              if (triggerUndoRedoSnapshotCollection) {
                triggerUndoRedoSnapshotCollection(params);
              }
            },
          }}
          processDataFromClipboard={(params) =>
            processAllocationsFromClipboard(
              params,
              shouldInsertOffOnPasteBlank,
              employeeNames,
              true
            )
          }
          context={{
            options: flatOptions(shiftOptions),
          }}
          setGridApiToParent={setGridApiToParent}
          setColumnApiToParent={setColumnApiToParent}
          components={{
            dropdownSingleSelector: DropdownSingleSelector,
          }}
          overlayNoRowsTemplate={NoEmployeesOverlay}
          tableName="fixed-shifts-recurring"
          getDataFromGrid={getDataFromGrid}
          doesExternalFilterPass={doesAreaFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
        />
        <p className={styles["saving-line"]}>
          {isSaving ? "saving..." : "saved"}
        </p>
      </div>
      {isWarningPresent && (
        <div className={styles["warning-wrapper"]}>
          <WarningDisplay
            title="Following issues were found:"
            displayedWarnings={getDisplayedWarningsInfo(recurringWarnings)}
            bottomText={
              "Please change the allocated fixed shift/ tasks or if they are correct, change the restrictions on the employee page."
            }
          />
        </div>
      )}
    </Layout>
  );
};

export default FixedShiftsRecurringGrid;
