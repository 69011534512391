import { useCallback, useEffect, useMemo, useState } from "react";
import AllEmployeesDropdown from "../EmployeeSelectDropdown/EmployeeSelectDropdown";
import styles from "./IndividualViewWrapper.module.css";
import IndFixedShiftsWrapper from "../IndFixedShiftsWrapper/IndFixedShiftsWrapper";
import IndPreferencesWrapper from "../IndPreferencesWrapper/IndPreferencesWrapper";
import IndSkillsAndShiftsWrapper from "../IndSkillsAndShiftsWrapper/IndSkillsAndShiftsWrapper";
import { useRouteQuery } from "../../../../../hooks/useRouteQuery";
import NoEmployeesMsgDisplayer from "../NoEmployeesMsgDisplayer/NoEmployeesMsgDisplayer";
import { getNames } from "../../../../../utils";
import EmployeesViewSwitcher, {
  SWITCHABLE_PAGES,
} from "../../../components/EmployeesViewSwitcher/EmployeesViewSwitcher";
import {
  PAGE_NAMES,
  useRedirect,
} from "../../../../../utils/routeUtils/redirect";
import IndRulesContainer from "../IndRulesContainer/IndRulesContainer";
import useStandardDataContainer from "../../../../../hooks/modelQueryHooks/useStandardDataContainer";
import IndRosterContainer from "../IndRosterContainer/IndRosterContainer";
import {
  getCustomKeywordsDataFromLocation,
  interpretCustomKeywordsData,
} from "../../../../../utils/queryUtils/locationDataGetters";
import withSideBar from "../../../../../components/layouts/hoc/withSideBar/withSideBar";
import withHeader from "../../../../../components/layouts/hoc/withHeader/withHeader";
import { getNewSkillsTemplate } from "../../../../../utils/queryUtils/rosterDataGetters";
import { updateScheduleMyRoster } from "../../../../scheduleView/components/ScheduleRosterDataContainer/ScheduleRosterDataContainerUtils";
import { useWarningsStore } from "../../../../../globalStore/warningsStore";
import { buildShortIdsToEntityNamesDicts } from "../../../service/rosterUtils";
import {
  getLocationAreaFiltersSettings,
  useSettingsModelQuery,
} from "../../../../../hooks/modelQueryHooks/useSettingsModelQuery";
import { getMyRosterSettingValues } from "../../../../../utils/settingsUtils/settingsUtils";

const IndividualViewWrapper = ({
  periodStartDate,
  periodFinishDate,
  location,
  locationID,
  rosterID,
  isRoster,
  redirectToOverviewPage,
}) => {
  const { settings } = useSettingsModelQuery();

  const areaFilter = useMemo(
    () => getLocationAreaFiltersSettings(settings, locationID),
    [settings, locationID]
  );

  const startDate = periodStartDate;
  const customKeywordsData = useMemo(() => {
    if (location) {
      return getCustomKeywordsDataFromLocation(location);
    }
    return null;
  }, [location]);

  const customKeywordsUtilObj = useMemo(
    () => interpretCustomKeywordsData(customKeywordsData),
    [customKeywordsData]
  );

  const { warnings } = useWarningsStore();
  const isScheduleView = !isRoster;
  const { fields, roster, isQueryLoading, isSaving, updateFields } =
    useStandardDataContainer({
      isScheduleView,
      locationID,
      rosterID,
    });

  const {
    id,
    employees,
    skills,
    shifts,
    shiftGroups,
    demands,
    rules,
    tasks,
    taskBlocks,
    areas,
    colorCodes,
    numDays,
    isPublished,
    subTasks,
    enumeratedTasks,
    enumeratedShiftTasks,
    isSnapshot,
    frontendSettings,
  } = fields;

  const myRosterSettings = useMemo(
    () => getMyRosterSettingValues(frontendSettings),
    [frontendSettings]
  );

  const shortIdsToEntityNamesDicts = useMemo(
    () =>
      buildShortIdsToEntityNamesDicts(
        areas,
        shifts,
        shiftGroups,
        tasks,
        subTasks,
        skills
      ),
    [areas, shifts, shiftGroups, tasks, subTasks, skills]
  );

  const isMainStream = !isRoster && !isSnapshot;
  rosterID = id;

  const redirect = useRedirect();

  const redirectToSelectedEmployeeView = (employeeID, callback) => {
    if (isMainStream) {
      redirect({
        pageName: PAGE_NAMES.scheduleViewIndividualEmployee,
        query: {
          "location-id": locationID,
          date: periodStartDate,
          "employee-id": employeeID,
        },
        callback,
      });
    } else {
      redirect({
        pageName: PAGE_NAMES.individualEmployee,
        rosterID,
        employeeID,
      });
    }
  };

  const updateEmployeeWithFields = useCallback(
    (newEmployee, fields) => {
      const updatedRoster = {
        ...roster,
        Employees: roster.Employees.map((employee) => {
          if (employee.id === newEmployee.id) {
            return {
              ...employee,
              ...newEmployee,
            };
          }
          return employee;
        }),
      };
      updateFields(["Employees"], updatedRoster, roster, fields);
    },
    [roster, updateFields]
  );

  const updateMyRosterForRoster = useCallback(
    (updatedEmployee) => {
      const updatedEmployees = employees.map((employee) => {
        if (updatedEmployee.id === employee.id) {
          return {
            ...employee,
            RosteredAllocations: updatedEmployee.RosteredAllocations,
          };
        }
        return employee;
      });
      return updatedEmployees;
    },
    [employees]
  );

  const updateMyRosterForSchedule = useCallback(
    (updatedEmployee) => {
      const oldEmployee = employees.find(
        (employee) => updatedEmployee.id === employee.id
      );

      const newEmployee = updateScheduleMyRoster(
        [oldEmployee],
        [updatedEmployee],
        periodStartDate,
        periodFinishDate
      )[0];

      const updatedEmployees = employees.map((employee) => {
        if (newEmployee.id === employee.id) {
          return newEmployee;
        }
        return employee;
      });
      return updatedEmployees;
    },
    [employees, periodFinishDate, periodStartDate]
  );

  const updateMyRoster = useCallback(
    (updatedEmployee) => {
      let updatedEmployees;

      if (isMainStream) {
        updatedEmployees = updateMyRosterForSchedule(updatedEmployee);
      } else {
        updatedEmployees = updateMyRosterForRoster(updatedEmployee);
      }

      const updatedFields = ["Employees"];
      const updatedRoster = {
        ...roster,
        Employees: updatedEmployees,
      };

      updateFields(updatedFields, updatedRoster, roster, [
        "PublishedAllocations",
      ]);
    },
    [
      isMainStream,
      roster,
      updateFields,
      updateMyRosterForRoster,
      updateMyRosterForSchedule,
    ]
  );

  const addSkillFromEmployeesTable = useCallback(
    (skillName) => {
      const updatedSkills = [
        ...skills,
        ...getNewSkillsTemplate({
          nextNumberedSuffix: null,
          numItems: 1,
          name: skillName,
          existingSkills: skills,
        }),
      ];
      const updatedRoster = { ...roster, Skills: updatedSkills };
      updateFields(["Skills"], updatedRoster, roster, ["skills"]);
    },
    [roster, skills, updateFields]
  );

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const routeQuery = useRouteQuery();
  const employeeID = routeQuery.get("employee-id");

  useEffect(() => {
    const targetEmployee = employees.find((emp) => emp.id === employeeID);
    if (!targetEmployee) {
      setSelectedEmployee(employees[0]);
      return;
    }
    setSelectedEmployee(targetEmployee);
  }, [employees, employeeID]);

  const shiftNames = useMemo(() => getNames(shifts), [shifts]);
  const shiftGroupNames = useMemo(() => getNames(shiftGroups), [shiftGroups]);
  const taskNames = useMemo(() => getNames(tasks), [tasks]);

  const handleSelectEmployee = (employee) => {
    const setEmployee = () => {
      setSelectedEmployee(employee);
    };
    redirectToSelectedEmployeeView(employee.id, setEmployee);
  };

  if (isQueryLoading) {
    return <></>;
  }

  if (employees.length === 0) {
    return (
      <NoEmployeesMsgDisplayer
        redirectToEmployeesPage={redirectToOverviewPage}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      {selectedEmployee && (
        <div className={styles.top}>
          <div className={styles.header}>
            <AllEmployeesDropdown
              employees={employees}
              selectedEmployee={selectedEmployee}
              setSelectedEmployee={handleSelectEmployee}
            />
            <EmployeesViewSwitcher
              redirect={redirectToOverviewPage}
              currentPage={SWITCHABLE_PAGES.individual}
            />
          </div>
          <hr className={styles.divider} />
          <IndRosterContainer
            rosterID={rosterID}
            locationID={locationID}
            isSaving={isSaving}
            selectedEmployee={selectedEmployee}
            startDate={startDate}
            employees={employees}
            numDays={numDays}
            areas={areas}
            skills={skills}
            demands={demands}
            rules={rules}
            shifts={shifts}
            shiftNames={shiftNames}
            taskNames={taskNames}
            shiftGroups={shiftGroups}
            tasks={tasks}
            colorCodes={colorCodes}
            isMainStream={isMainStream}
            roster={roster}
            location={location}
            updateMyRoster={updateMyRoster}
            customKeywordsUtilObj={customKeywordsUtilObj}
            prevEmployees={employees}
            taskBlocks={taskBlocks}
            subTasks={subTasks}
            areaFilter={areaFilter}
            myRosterSettings={myRosterSettings}
          />
          <IndSkillsAndShiftsWrapper
            rosterID={rosterID}
            shiftNames={shiftNames}
            shiftGroupNames={shiftGroupNames}
            isSaving={isSaving}
            selectedEmployee={selectedEmployee}
            warnings={warnings}
            isMainStream={isMainStream}
            isPublished={isPublished}
            locationID={locationID}
            startDate={startDate}
            updateEmployeeWithFields={updateEmployeeWithFields}
            addSkillFromEmployeesTable={addSkillFromEmployeesTable}
            customKeywordsUtilObj={customKeywordsUtilObj}
            skills={skills}
            shifts={shifts}
            shiftGroups={shiftGroups}
            areas={areas}
            isRoster={isRoster}
          />
          <IndRulesContainer
            rosterID={rosterID}
            rules={rules}
            selectedEmployee={selectedEmployee}
            isSaving={isSaving}
            warnings={warnings}
            location={location}
            locationID={locationID}
            isMainStream={isMainStream}
            roster={roster}
            updateEmployeeWithFields={updateEmployeeWithFields}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            isScheduleView={isScheduleView}
          />
          <IndFixedShiftsWrapper
            rosterID={rosterID}
            selectedEmployee={selectedEmployee}
            location={location}
            startDate={startDate}
            numDays={numDays}
            isSaving={isSaving}
            warnings={warnings}
            enumeratedTasks={enumeratedTasks}
            enumeratedShiftTasks={enumeratedShiftTasks}
            isMainStream={isMainStream}
            roster={roster}
            locationID={locationID}
            updateEmployeeWithFields={updateEmployeeWithFields}
            customKeywordsUtilObj={customKeywordsUtilObj}
            areas={areas}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            subTasks={subTasks}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            colorCodes={colorCodes}
          />
          <IndPreferencesWrapper
            rosterID={rosterID}
            selectedEmployee={selectedEmployee}
            numDays={numDays}
            location={location}
            startDate={startDate}
            isSaving={isSaving}
            warnings={warnings}
            enumeratedTasks={enumeratedTasks}
            enumeratedShiftTasks={enumeratedShiftTasks}
            isMainStream={isMainStream}
            roster={roster}
            locationID={locationID}
            updateEmployeeWithFields={updateEmployeeWithFields}
            customKeywordsUtilObj={customKeywordsUtilObj}
            shifts={shifts}
            shiftGroups={shiftGroups}
            tasks={tasks}
            subTasks={subTasks}
            areas={areas}
            skills={skills}
            isRoster={isRoster}
            periodStartDate={periodStartDate}
            periodFinishDate={periodFinishDate}
            shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
            colorCodes={colorCodes}
          />
        </div>
      )}
    </div>
  );
};

export default withHeader(withSideBar(IndividualViewWrapper));
