import { useState } from "react";
import styles from "./LeaveSetting.module.css";
import { interpretCustomKeywordsData } from "../../../../utils/queryUtils/locationDataGetters";
import {
  KEYWORD_STUDY,
  LONG_NAME_ANNUAL_LEAVE,
  LONG_NAME_STUDY,
} from "../../../../constants/keywords";
import KeywordInputSettingItem from "./KeywordInputSettingItem";

const LeaveSetting = ({
  customKeywordsData,
  leaveSettingValues,
  updateFrontendSettings,
}) => {
  const { leaveKeywords } = interpretCustomKeywordsData(customKeywordsData);
  const [isStudyLeaveChecked, setIsStudyLeaveChecked] = useState(
    leaveKeywords.includes(KEYWORD_STUDY)
  );

  const addLeaveKeyword = (leaveLabel, customName) => {
    const leaveValues = [...leaveSettingValues];
    const leaveValueIndex = leaveValues.findIndex(
      (item) => item.split(";")[1] === leaveLabel
    );
    if (leaveValueIndex === -1) {
      leaveValues.push(`${customName};${leaveLabel}`);
    } else {
      leaveValues[leaveValueIndex] = `${customName};${leaveLabel}`;
    }

    updateFrontendSettings("annualLeaveKeyword", leaveValues);
  };

  const removeLeaveKeyword = (leaveLabel) => {
    const leaveValues = leaveSettingValues.filter(
      (item) => item.split(";")[1] !== leaveLabel
    );
    updateFrontendSettings("annualLeaveKeyword", leaveValues);
  };

  const updateStudyLeaveKeyword = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      addLeaveKeyword(LONG_NAME_STUDY, KEYWORD_STUDY);
    } else {
      removeLeaveKeyword(LONG_NAME_STUDY);
    }
    setIsStudyLeaveChecked(isChecked);
  };

  return (
    <div className={styles.container}>
      <div>
        <input
          id="studyLeaveCheckbox"
          type="checkbox"
          onChange={updateStudyLeaveKeyword}
          checked={isStudyLeaveChecked}
        />
        <label
          className={styles.studyLeaveCheckbox}
          htmlFor={"studyLeaveCheckbox"}
        >
          Use study leave
        </label>
      </div>
      <KeywordInputSettingItem
        keywordLongname={LONG_NAME_ANNUAL_LEAVE}
        label="Annual leave keyword"
        description=""
        note=""
        customKeywordsData={customKeywordsData}
        leaveSettingValues={leaveSettingValues}
        updateFrontendSettings={updateFrontendSettings}
      />
    </div>
  );
};

export default LeaveSetting;
