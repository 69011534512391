import { useRef, useState } from "react";
import styles from "./NameEditor.module.css";

export default function NameEditor({ label, defaultInputValue = "", save }) {
  const [input, setInput] = useState(defaultInputValue);
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef(null);

  const handleSaveClick = () => {
    setIsEditing(false);
    if (!input.trim()) {
      setInput(defaultInputValue);
      return;
    }

    const isSuccess = save(input);

    if (!isSuccess) {
      setInput(defaultInputValue);
    }
  };

  const handleEditClick = () => {
    inputRef.current.focus();
    inputRef.current.select();
    setIsEditing(true);
  };

  return (
    <div className={styles.container}>
      <label htmlFor="input" className={styles.label}>
        {label}
      </label>
      <input
        id="input"
        ref={inputRef}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        readOnly={!isEditing}
        className={`${styles.input} ${isEditing ? styles.active : ""}`}
      />
      <button
        className={styles.button}
        onClick={isEditing ? handleSaveClick : handleEditClick}
      >
        {isEditing ? "Save" : "Edit"}
      </button>
    </div>
  );
}
