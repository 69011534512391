import { useState } from "react";
import { DateTime, getIds } from "../../../../utils";
import styles from "./PublishCalendarModal.module.css";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import { customWarningAlert } from "../../../confirm/service/confirm";
import Select from "react-select";
import { reactSelectStyles } from "../../../../utils/reactSelectUtils/reactSelectStyles";
import { CheckboxOption } from "../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import {
  AreasValueContainer,
  EmployeesValueContainer,
} from "../../../../utils/reactSelectUtils/valueContainers";
import { useAreaEmployeesSelect } from "../../../../hooks/useAreaEmployeesSelect";
import { KEYWORD_ALL } from "../../../../constants/keywords";

const PublishCalendarModal = ({
  togglePublishModal,
  publishCalendar,
  defaultStartDate,
  defaultFinishDate,
  areas,
  initialAreaFilterValue = null,
  isAreaEnabled,
  employees,
}) => {
  const {
    areaSelectOptions,
    employeeSelectOptions,
    selectedAreaOptions,
    selectedEmployeeOptions,
    selectedAreaEntities,
    selectedEmployeeEntities,
    unSelectableEmployees,
    handleAreaSelect,
    handleEmployeeSelect,
  } = useAreaEmployeesSelect(initialAreaFilterValue, areas, employees);

  const [finishDate, setFinishDate] = useState(defaultFinishDate);

  const publish = () => {
    if (selectedEmployeeEntities.length === 0) {
      customWarningAlert({
        title: "Please select employee(s) to publish",
        descriptions: ["Please select employee(s) to publish"],
      });
      return;
    }

    if (
      new DateTime(defaultStartDate).getDate() >
      new DateTime(finishDate).getDate()
    ) {
      customWarningAlert({
        title: "Invalid start date",
        descriptions: ["Please make sure start date is before finish date"],
      });
      return;
    }

    const employeeIdsToPublish = getIds(selectedEmployeeEntities);
    publishCalendar(defaultStartDate, finishDate, employeeIdsToPublish);
    togglePublishModal();
  };

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <h2 className={styles.title}>Publish my roster</h2>
        <div className={styles.fields}>
          <div className={styles.field}>
            <span>Select the period to publish:</span>
            <div className={styles["dates-wrapper"]}>
              <p className={styles["start-date"]}>
                {new DateTime(defaultStartDate).toFormat("displayed-full")}
              </p>
              <input
                type="date"
                className={styles["finish-date"]}
                value={finishDate}
                onChange={(e) => {
                  setFinishDate(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.partialPublishSelectors}>
              {isAreaEnabled && areas.length > 0 && (
                <div className={styles.selectorWrapper}>
                  <p>Select area(s) to publish:</p>
                  <Select
                    isMulti
                    styles={reactSelectStyles}
                    options={areaSelectOptions}
                    value={selectedAreaOptions}
                    onChange={handleAreaSelect}
                    maxMenuHeight={150}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isSearchable={false}
                    isClearable={false}
                    isOptionSelected={(option) =>
                      selectedAreaEntities.length === areas.length ||
                      selectedAreaOptions.some(
                        ({ value }) => value === option.value
                      )
                    }
                    components={{
                      Option: CheckboxOption,
                      ValueContainer: AreasValueContainer,
                    }}
                  />
                </div>
              )}
              <div className={styles.selectorWrapper}>
                <p>Select Employee(s) to publish:</p>
                <Select
                  isMulti
                  styles={reactSelectStyles}
                  options={employeeSelectOptions}
                  value={selectedEmployeeOptions}
                  onChange={handleEmployeeSelect}
                  maxMenuHeight={150}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  isSearchable={false}
                  isClearable={false}
                  isOptionSelected={(option) =>
                    selectedEmployeeEntities.length === employees.length ||
                    selectedEmployeeOptions.some(
                      ({ value }) => value === option.value
                    )
                  }
                  components={{
                    Option: CheckboxOption,
                    ValueContainer: EmployeesValueContainer,
                  }}
                  isOptionDisabled={({ value }) => {
                    if (
                      unSelectableEmployees.length > 0 &&
                      value === KEYWORD_ALL
                    ) {
                      return true;
                    }
                    return unSelectableEmployees.includes(value);
                  }}
                />
              </div>
            </div>
          </div>
          <p className={styles.note}>
            All open shifts will be sent out for pick-up when published.
          </p>
        </div>
        <div className={styles.buttons}>
          <BasicButton
            color="#219ec9"
            hoverColor="#1f91b7"
            onClick={publish}
            customStyle={{
              borderRadius: "5px",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Publish
          </BasicButton>
          <BasicButton
            color="white"
            borderColor="#219ec9"
            labelColor="#219ec9"
            hoverColor="#ebebeb"
            hoverlabelColor="#219ec9"
            onClick={() => togglePublishModal()}
            customStyle={{
              borderRadius: "5px",
              fontSize: "18px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Cancel
          </BasicButton>
        </div>
      </div>
    </div>
  );
};

export default PublishCalendarModal;
