import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BasicButton from "../../../../../components/elements/BasicButton/BasicButton";
import {
  DateTime,
  checkEmployeesHaveRosteredAllocation,
} from "../../../../../utils";
import { hasEmployeesInfoChanged } from "../../../service/rosterUtils";
import {
  customConfirmAlert,
  customWarningAlert,
} from "../../../../confirm/service/confirm";

function checkNoteExistsWithinPeriod(
  employees,
  periodStartDate,
  periodFinishDate
) {
  const datesInPeriod = DateTime.getAllDateStringsBetween(
    periodStartDate,
    periodFinishDate,
    true,
    true
  );
  for (const employee of employees) {
    const publishedAllocations = employee.PublishedAllocations.filter(
      ({ date }) => datesInPeriod.includes(date)
    );
    for (const { note } of publishedAllocations) {
      if (note) {
        return true;
      }
    }
  }
  return false;
}

const GoToRosterButton = ({
  selectedSolutionData,
  employees,
  handleSendSolution,
  periodStartDate,
  periodFinishDate,
  isMainStream,
}) => {
  let hoverText = "";
  if (selectedSolutionData === null) {
    hoverText = "No Available Solution";
  }

  if (
    selectedSolutionData &&
    selectedSolutionData.status.startsWith("Solved")
  ) {
    return (
      <BasicButton
        color="#219ec9"
        hoverColor="#1f91b7"
        customStyle={{
          borderRadius: "10px",
        }}
        dataTestId="add-employees-btn"
        isBlocked={selectedSolutionData === null ? true : false}
        title={hoverText}
        onClick={async (e) => {
          e.preventDefault();
          const currentEmployeesInfo = employees.map((emp) => emp.name);
          const solutionEmployeesInfo = selectedSolutionData.employees.map(
            (emp) => emp.name
          );

          const isMismatchingSolution = hasEmployeesInfoChanged(
            solutionEmployeesInfo,
            currentEmployeesInfo
          );

          if (isMismatchingSolution) {
            customWarningAlert({
              title: "Mismatching solution",
              descriptions: [
                "Roster's employees does not match with the selected solution.",
              ],
            });
            return;
          }

          if (checkEmployeesHaveRosteredAllocation(employees)) {
            const response = await customConfirmAlert({
              title:
                "Shift allocations in the My Roster page will be overwritten.",
              descriptions: ["Are you sure you would like to do this?"],
            });
            if (!response) {
              return;
            }
          }

          if (
            isMainStream &&
            checkNoteExistsWithinPeriod(
              employees,
              periodStartDate,
              periodFinishDate
            )
          ) {
            const response = await customConfirmAlert({
              title:
                "You have notes for this period. Please ensure they make sense with the new roster.",
              descriptions: ["Are you sure you would like to do this?"],
            });
            if (!response) {
              return;
            }
          }
          handleSendSolution(selectedSolutionData);
        }}
      >
        Send To My Roster
        <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: "5px" }} />
      </BasicButton>
    );
  }

  return <></>;
};

export default GoToRosterButton;
