import styles from "./KeywordInputSettingItem.module.css";
import NameEditor from "../../../../components/elements/NameEditor/NameEditor";
import { interpretCustomKeywordsData } from "../../../../utils/queryUtils/locationDataGetters";
import { trimAndLowerCase } from "../../../../utils";

export default function KeywordInputSettingItem({
  keywordLongname,
  label,
  description,
  note,
  customKeywordsData,
  leaveSettingValues,
  updateFrontendSettings,
}) {
  const { leaveCodes, reservedKeywords } =
    interpretCustomKeywordsData(customKeywordsData);

  const allCustomKeywords = leaveCodes.map(({ shortname }) => shortname);

  const code = leaveCodes.find(({ longname }) => longname === keywordLongname);
  const keyword = code.shortname;

  const addLeaveKeyword = (customName) => {
    const leaveValues = [...leaveSettingValues];
    const leaveValueIndex = leaveValues.findIndex(
      (item) => item.split(";")[1] === keywordLongname
    );
    if (leaveValueIndex === -1) {
      leaveValues.push(`${customName};${keywordLongname}`);
    } else {
      leaveValues[leaveValueIndex] = `${customName};${keywordLongname}`;
    }

    updateFrontendSettings("annualLeaveKeyword", leaveValues);
  };

  const updateKeyword = (newValue) => {
    if (!keywordLongname || newValue === code.shortname) {
      return false;
    }

    if (allCustomKeywords.includes(newValue)) {
      alert("Keyword already exists");
      return false;
    }

    const lowerCaseValue = trimAndLowerCase(newValue);
    if (!lowerCaseValue) {
      return false;
    }

    const reservedKeywordsToLowerCase = reservedKeywords.map((word) =>
      word.toLowerCase()
    );

    if (reservedKeywordsToLowerCase.includes(lowerCaseValue)) {
      alert(`${reservedKeywords.join(", ")} are reserved names`);
      return false;
    }

    if (lowerCaseValue.includes("-") || lowerCaseValue.includes(";")) {
      alert("Invalid characters: '-', ';'");
      return false;
    }

    addLeaveKeyword(newValue);
    return true;
  };

  return (
    <div className={styles.container}>
      <div className={styles.field}>
        <NameEditor
          label={label}
          defaultInputValue={keyword}
          save={updateKeyword}
        />
        {description && <p className={styles.description}>{description}</p>}
      </div>
      {note && <p className={styles.note}>{note}</p>}
    </div>
  );
}
