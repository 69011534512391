import { components } from "react-select";
import { KEYWORD_ALL } from "../../constants/keywords";

const CustomValueContainer = ({ children, itemName, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    if (props.getValue().some(({ value }) => value === KEYWORD_ALL)) {
      values = `All ${itemName}`;
    } else if (values.length === 1) {
      values = values[0].props.children;
    } else if (values.length === props.options.length) {
      values = `All ${itemName}`;
    } else {
      values = `${values.length} ${itemName} selected`;
    }
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

/**
 * I made separate ValueContainers due to this issue:
 * https://stackoverflow.com/questions/58804769/react-select-losing-focus-for-custom-component-valuecontainer
 */

export const SkillsValueContainer = ({ children, ...props }) => (
  <CustomValueContainer {...props} itemName="skills">
    {children}
  </CustomValueContainer>
);

export const EmployeesValueContainer = ({ children, ...props }) => (
  <CustomValueContainer {...props} itemName="employees">
    {children}
  </CustomValueContainer>
);

export const AreasValueContainer = ({ children, ...props }) => (
  <CustomValueContainer {...props} itemName="areas">
    {children}
  </CustomValueContainer>
);
