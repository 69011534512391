import { getStatisticsTemplate } from "../../statistics/service/template";
import { updateLocation } from "../../../graphql/mutations";
import { getUserPlan } from "../../../globalStore/appStore";
import { graphqlErrorHandler } from "../../../utils/queryUtils/generalQueryUtil";
import { ONBOARDING_TASK_ID } from "../../onBoarding/service/onBoarding";
import {
  correctSpellingInRules,
  hasIncorrectSpelling,
} from "../../rosterProblems/service/fixRoster";
import { addShortIdToEntities } from "../../../utils/modelUtils/shortId";
import { checkGlobalEmployeesOrderIsValid } from "../../../utils/validationUtils/locationValidations";
import { checkAllShiftGroupsHasAdminUseOnlyInfo } from "../../../utils/validationUtils/sharedValidations";
import {
  DEFAULT_LEAVE_KEYWORD_SETTING,
  DEFAULT_CHECK_LEAVE_SETTING,
  DEFAULT_CUSTOM_KEYWORDS,
} from "../../../constants";
import { LONG_NAME_RDO } from "../../../constants/keywords";

function updateOnboardingTasks(newLocation, taskID, fieldsUpdated) {
  newLocation.completedOnboardingTasks.push(taskID);
  fieldsUpdated.add("completedOnboardingTasks");
}

export function fixColorCodes(previousColorCodes, shifts, shiftGroups) {
  let hasUpdated = false;
  const allHaveShortId = allEntitiesHaveShortId([...shifts, ...shiftGroups]);

  if (!previousColorCodes) {
    return { updatedColorCodes: previousColorCodes, hasUpdated };
  }

  if (!allHaveShortId) {
    return { updatedColorCodes: previousColorCodes, hasUpdated };
  }

  const updatedColorCodes = previousColorCodes.map((code) => {
    const { shift } = code;
    const targetShift = shifts.find(({ name }) => shift === name);
    const targetShiftGroup = shiftGroups.find(({ name }) => shift === name);

    if (targetShift) {
      if (shift !== targetShift.shortId) {
        hasUpdated = true;
      }
      return {
        ...code,
        shift: targetShift.shortId,
      };
    }

    if (targetShiftGroup) {
      if (shift !== targetShiftGroup.shortId) {
        hasUpdated = true;
      }
      return {
        ...code,
        shift: targetShiftGroup.shortId,
      };
    }

    return code;
  });

  return {
    updatedColorCodes,
    hasUpdated,
  };
}

export function addAutoAssignedToTasks(tasks) {
  let hasUpdated = false;

  for (const task of tasks) {
    if (task.autoAssigned === null) {
      task.autoAssigned = true;
      hasUpdated = true;
    }
  }

  return hasUpdated;
}

const updateFieldsForScheduleView = (location, fieldsUpdated) => {
  const newLocation = JSON.parse(JSON.stringify(location));

  if (newLocation.Tasks) {
    const hasUpdated = addAutoAssignedToTasks(newLocation.Tasks);
    if (hasUpdated) {
      fieldsUpdated.add("Tasks");
    }
  }

  if (!newLocation.OpenShifts) {
    newLocation.OpenShifts = [];
    fieldsUpdated.add("OpenShifts");
  }

  if (!newLocation.Areas) {
    newLocation.Areas = [];
    fieldsUpdated.add("Areas");
  }

  if (newLocation.ColorCodes) {
    const { updatedColorCodes, hasUpdated } = fixColorCodes(
      location.ColorCodes,
      location.Shifts,
      location.ShiftGroups
    );
    if (hasUpdated) {
      newLocation.ColorCodes = updatedColorCodes;
      fieldsUpdated.add("ColorCodes");
    }
  }

  if (newLocation.shiftViewHiddenRows === null) {
    newLocation.shiftViewHiddenRows = [];
    fieldsUpdated.add("shiftViewHiddenRows");
  }

  if (newLocation.ColorCodes === null) {
    newLocation.ColorCodes = [];
    fieldsUpdated.add("ColorCodes");
  }

  if (newLocation.Statistics === null) {
    newLocation.Statistics = getStatisticsTemplate();
    fieldsUpdated.add("Statistics");
  } else if (newLocation.Statistics.leaveCountsToShow === null) {
    newLocation.Statistics.leaveCountsToShow = [];
    fieldsUpdated.add("Statistics");
  }

  if (newLocation.TaskBlocks === null) {
    newLocation.TaskBlocks = [];
    fieldsUpdated.add("TaskBlocks");
  }

  if (!newLocation.completedOnboardingTasks) {
    newLocation.completedOnboardingTasks = [];
    fieldsUpdated.add("completedOnboardingTasks");
  }

  if (hasIncorrectSpelling(newLocation.CustomRules)) {
    newLocation.CustomRules = correctSpellingInRules(newLocation.CustomRules);
    fieldsUpdated.add("CustomRules");
  }

  if (
    newLocation.Employees.items.length > 0 &&
    !newLocation.completedOnboardingTasks.includes(
      ONBOARDING_TASK_ID.addEmployees
    )
  ) {
    updateOnboardingTasks(
      newLocation,
      ONBOARDING_TASK_ID.addEmployees,
      fieldsUpdated
    );
  }

  if (
    newLocation.Shifts.length > 0 &&
    !newLocation.completedOnboardingTasks.includes(
      ONBOARDING_TASK_ID.createShifts
    )
  ) {
    updateOnboardingTasks(
      newLocation,
      ONBOARDING_TASK_ID.createShifts,
      fieldsUpdated
    );
  }

  if (
    newLocation.CustomRules.length > 0 &&
    !newLocation.completedOnboardingTasks.includes(ONBOARDING_TASK_ID.addRules)
  ) {
    updateOnboardingTasks(
      newLocation,
      ONBOARDING_TASK_ID.addRules,
      fieldsUpdated
    );
  }

  return newLocation;
};

const arrayIfNull = (object, fieldsUpdated, field) => {
  if (object[field] === null) {
    object[field] = [];
    fieldsUpdated.add(field);
  }
};

const nullIfArray = (object, fieldsUpdated, field) => {
  if (object[field] !== null) {
    object[field] = null;
    fieldsUpdated.add(field);
  }
};

const updateFieldsForClassicView = (location, employees, fieldsUpdated) => {
  const newLocation = JSON.parse(JSON.stringify(location));

  if (newLocation.isScheduleView === null) {
    newLocation.isScheduleView = false;
    fieldsUpdated.add("isScheduleView");
  }

  if (!checkGlobalEmployeesOrderIsValid(employees, location.order)) {
    const globalEmployeeIDs = employees.map((emp) => emp.id);
    newLocation.order = globalEmployeeIDs;
    fieldsUpdated.add("order");
  }

  if (!checkAllShiftGroupsHasAdminUseOnlyInfo(newLocation.ShiftGroups)) {
    const shiftGroups = newLocation.ShiftGroups;
    const updatedShiftGroups = shiftGroups.map((group) => {
      if (group.adminUseOnly === null) {
        return {
          ...group,
          adminUseOnly: false,
        };
      }
      return group;
    });
    newLocation.ShiftGroups = updatedShiftGroups;
    fieldsUpdated.add("ShiftGroups");
  }

  arrayIfNull(newLocation, fieldsUpdated, "CustomRules");
  arrayIfNull(newLocation, fieldsUpdated, "TaskBlocks");
  arrayIfNull(newLocation, fieldsUpdated, "Demands");
  arrayIfNull(newLocation, fieldsUpdated, "Shifts");
  arrayIfNull(newLocation, fieldsUpdated, "ShiftGroups");
  arrayIfNull(newLocation, fieldsUpdated, "Skills");
  arrayIfNull(newLocation, fieldsUpdated, "Areas");
  arrayIfNull(newLocation, fieldsUpdated, "order");
  nullIfArray(newLocation, fieldsUpdated, "ColorCodes");
  nullIfArray(newLocation, fieldsUpdated, "startDate");
  nullIfArray(newLocation, fieldsUpdated, "defaultNumDays");

  return newLocation;
};

const allEntitiesHaveShortId = (entities) => {
  return entities.every((entity) => entity.shortId);
};

const updateFieldsInCommon = (location, employees) => {
  const newLocation = JSON.parse(JSON.stringify(location));
  const fieldsUpdated = new Set();

  arrayIfNull(newLocation, fieldsUpdated, "settings");

  if (location.Skills && !allEntitiesHaveShortId(location.Skills)) {
    newLocation.Skills = addShortIdToEntities(location.Skills);
    fieldsUpdated.add("Skills");
  }

  if (location.Tasks && !allEntitiesHaveShortId(location.Tasks)) {
    newLocation.Tasks = addShortIdToEntities(location.Tasks);
    fieldsUpdated.add("Tasks");
  }

  if (location.TaskBlocks && !allEntitiesHaveShortId(location.TaskBlocks)) {
    newLocation.TaskBlocks = addShortIdToEntities(location.TaskBlocks);
    fieldsUpdated.add("TaskBlocks");
  }

  if (newLocation.Demands.some((demand) => demand.areas == null)) {
    newLocation.Demands = newLocation.Demands.map((demand) => ({
      ...demand,
      areas: "",
    }));
    fieldsUpdated.add("Demands");
  }

  if (newLocation.ShiftGroups.some((group) => group.areas == null)) {
    newLocation.ShiftGroups = newLocation.ShiftGroups.map((group) => ({
      ...group,
      areas: "",
    }));
    fieldsUpdated.add("ShiftGroups");
  }

  if (location.Shifts && !allEntitiesHaveShortId(location.Shifts)) {
    newLocation.Shifts = addShortIdToEntities(location.Shifts);
    fieldsUpdated.add("Shifts");
  }

  if (location.ShiftGroups && !allEntitiesHaveShortId(location.ShiftGroups)) {
    newLocation.ShiftGroups = addShortIdToEntities(location.ShiftGroups);
    fieldsUpdated.add("ShiftGroups");
  }

  if (!location.order) {
    const globalEmployeeIDs = employees.map((emp) => emp.id);
    newLocation.order = globalEmployeeIDs;
    fieldsUpdated.add("order");
  }

  if (location.order && location.order.length !== employees.length) {
    const updatedOrder = [...location.order];
    const globalEmployeeIDs = employees.map((emp) => emp.id);
    globalEmployeeIDs.forEach((globalEmployeeID) => {
      if (!updatedOrder.includes(globalEmployeeID)) {
        updatedOrder.push(globalEmployeeID);
      }
    });
    newLocation.order = updatedOrder;
    fieldsUpdated.add("order");
  }

  if (newLocation.plan === null) {
    newLocation.plan = getUserPlan();
    fieldsUpdated.add("plan");
  }

  const annualLeaveKeywordSetting = location.frontendSettings.find(
    (setting) => setting.name === "annualLeaveKeyword"
  );

  // Fix keywords setting
  if (annualLeaveKeywordSetting) {
    let isUpdated = false;

    const updatedKeywordSettingValues = annualLeaveKeywordSetting.values.filter(
      (value) => {
        if (value.split(";")[1] === "Study leave") {
          isUpdated = true;
          return false;
        }
        return true;
      }
    );

    const rdoKeywordExists = updatedKeywordSettingValues.some(
      (value) => value.split(";")[1] === LONG_NAME_RDO
    );

    if (!rdoKeywordExists) {
      updatedKeywordSettingValues.push(
        `${DEFAULT_CUSTOM_KEYWORDS.rosteredDayOff};${LONG_NAME_RDO}`
      );
      isUpdated = true;
    }

    if (isUpdated) {
      newLocation.frontendSettings = newLocation.frontendSettings.map(
        (setting) => {
          if (setting.name === "annualLeaveKeyword") {
            return {
              ...setting,
              values: updatedKeywordSettingValues,
            };
          }
          return setting;
        }
      );
      fieldsUpdated.add("frontendSettings");
    }
  }

  if (!annualLeaveKeywordSetting) {
    newLocation.frontendSettings = [
      ...newLocation.frontendSettings,
      DEFAULT_LEAVE_KEYWORD_SETTING,
    ];
    fieldsUpdated.add("frontendSettings");
  }

  if (
    !location.frontendSettings.find((setting) => setting.name === "checkLeave")
  ) {
    newLocation.frontendSettings = [
      ...newLocation.frontendSettings,
      DEFAULT_CHECK_LEAVE_SETTING,
    ];
    fieldsUpdated.add("frontendSettings");
  }

  return [newLocation, fieldsUpdated];
};

export const fixLocation = async (location, employees) => {
  const { isScheduleView } = location;
  let [newLocation, fieldsUpdated] = updateFieldsInCommon(location, employees);

  if (isScheduleView) {
    newLocation = updateFieldsForScheduleView(newLocation, fieldsUpdated);
  } else {
    newLocation = updateFieldsForClassicView(
      newLocation,
      employees,
      fieldsUpdated
    );
  }

  const hasUpdated = fieldsUpdated.size > 0;
  if (hasUpdated) {
    console.log("fixLocation: location has been fixed");
    await updateLocationToDatabase(newLocation, fieldsUpdated);
  }

  if (hasUpdated) {
    return newLocation;
  }
  return null;
};

async function updateLocationToDatabase(location, fieldsUpdated) {
  // Check if fieldsUpdated is not empty
  let fieldsToUpdate = {};

  // Construct an object with only the fields to be updated
  for (let field of fieldsUpdated) {
    // eslint-disable-next-line no-prototype-builtins
    if (location.hasOwnProperty(field)) {
      fieldsToUpdate[field] = location[field];
    }
  }
  return graphqlErrorHandler(
    updateLocation,
    {
      input: {
        id: location.id,
        ...fieldsToUpdate,
        _version: location._version,
      },
    },
    (data) => data.updateLocation,
    null,
    "updateLocationToDatabase"
  );
}
