import { LOCATION_SETTINGS } from "../../constants/settings";
import { getNames } from "../modelUtils/generalModelUtil";

export function hasSetting(frontendSettings, settingName) {
  return frontendSettings.some((setting) => setting.name === settingName);
}

/**
 * Get all plan if plan === null.
 * If plan !== null, filter settings by plan
 */
export function getLocationSettingsByPlan(plan) {
  const filteredSettings = {};
  for (const [type, settings] of Object.entries(LOCATION_SETTINGS)) {
    filteredSettings[type] = settings.filter(({ plans }) => {
      return !plans || plans.includes(plan);
    });
  }
  return Object.freeze(filteredSettings);
}

export function getMyRosterSettingValues(frontendSettings) {
  const frontendSettingNames = getNames(frontendSettings);

  let displayAreaInAllocationCell = true;
  let showOffInsteadOfBlankInMyRoster = false;
  let showRDOInWeekends = false;
  let showRDOInWeekdays = false;
  for (const settingName of frontendSettingNames) {
    if (settingName === "hideAreaNameInAllocations") {
      displayAreaInAllocationCell = false;
    }

    if (settingName === "showOffInsteadOfBlankInMyRoster") {
      showOffInsteadOfBlankInMyRoster = true;
    }

    if (settingName === "showRDOInWeekends") {
      showRDOInWeekends = true;
    }

    if (settingName === "showRDOInWeekdays") {
      showRDOInWeekdays = true;
    }
  }
  return {
    displayAreaInAllocationCell,
    showOffInsteadOfBlankInMyRoster,
    showRDOInWeekends,
    showRDOInWeekdays,
  };
}
