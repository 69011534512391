import { useEffect, useState } from "react";
import {
  filterShiftGroupsByAreas,
  filterShiftsByAreas,
  getRosterTableBottomPinnedRowsPlaceholders,
  getShortIds,
} from "../utils";
import { KEYWORD_ALL } from "../constants/keywords";

export function useBottomPinnedGridStatisticsTemplate({
  shifts,
  shiftGroups,
  skills,
  numDays,
  statistics,
  initialAreaFilterValue,
  hide = false,
}) {
  const [pinnedBottomRowDataTemplate, setPinnedBottomRowDataTemplate] =
    useState([]);

  useEffect(() => {
    if (hide) {
      setPinnedBottomRowDataTemplate([]);
      return;
    }
    const rows = getRosterTableBottomPinnedRowsPlaceholders(
      shifts,
      shiftGroups,
      skills,
      numDays,
      statistics
    );

    const allShiftAndShiftGroupShortIds = getShortIds([
      ...shifts,
      ...shiftGroups,
    ]);

    const areaFilteredShiftGroups = filterShiftGroupsByAreas(
      initialAreaFilterValue,
      shiftGroups
    );
    const areaFilteredShifts = filterShiftsByAreas(
      initialAreaFilterValue,
      shifts,
      shiftGroups
    );

    const allAreaFilteredShiftAndShiftGroupShortIds = getShortIds([
      ...areaFilteredShiftGroups,
      ...areaFilteredShifts,
    ]);

    if (
      initialAreaFilterValue.length === 0 ||
      initialAreaFilterValue.includes(KEYWORD_ALL)
    ) {
      setPinnedBottomRowDataTemplate(rows);
      return;
    }

    const areaFilteredRows = rows.filter(
      (row) =>
        !allShiftAndShiftGroupShortIds.includes(row.shortId) ||
        allAreaFilteredShiftAndShiftGroupShortIds.includes(row.shortId)
    );

    setPinnedBottomRowDataTemplate(areaFilteredRows);
  }, [
    shifts,
    shiftGroups,
    skills,
    numDays,
    statistics,
    initialAreaFilterValue,
    hide,
  ]);

  return pinnedBottomRowDataTemplate;
}
