import { getRosterInView } from "../../../../hooks/modelQueryHooks/useScheduleQuery";
import { DateTime } from "../../../../utils";
import { getRosterIdByPeriodStartDate } from "../../../../utils/queryUtils/locationDataGetters";
import { getLocationAndEmployeesById } from "../../../../utils/queryUtils/locationQuery";
import { getRosterModelById } from "../../../../utils/queryUtils/rosterQuery";
import { downloadJSON } from "../../../rosterProblems/service/downloadRoster";
import { inferRosterStartDate } from "../../service/scheduleViewRoster";
import ScheduleFileInteractor from "../ScheduleFileInteractor/ScheduleFileInteractor";
import styles from "./ScheduleUploadDownload.module.css";

const ScheduleUploadDownload = ({ location }) => {
  const currentPeriodStartDate = inferRosterStartDate(
    location.startDate,
    new DateTime().toFormat("AWS"),
    location.defaultNumDays
  );

  const handleDownload = async (periodStartDate) => {
    const periodStartDateStr = periodStartDate.toFormat("AWS");
    const locationAndEmployees = await getLocationAndEmployeesById(location.id);
    let { employees: globalEmployees } = locationAndEmployees;
    const targetRosterId = getRosterIdByPeriodStartDate(
      location,
      periodStartDateStr
    );

    if (!targetRosterId) {
      return;
    }

    const roster = await getRosterModelById(targetRosterId);
    const rosterInView = getRosterInView(location, globalEmployees, roster);
    const locationName = location.name;

    await downloadJSON(
      JSON.stringify({ ...rosterInView, locationName }),
      `${location.name}_${periodStartDateStr}`
    );
  };

  return (
    <div className={styles.container}>
      <ScheduleFileInteractor
        buttonLabel="Download"
        buttonClickHandler={handleDownload}
        currentPeriodStartDate={currentPeriodStartDate}
        numDays={location.defaultNumDays}
      />
    </div>
  );
};

export default ScheduleUploadDownload;
