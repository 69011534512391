import { useEffect, useState } from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./EmployeeSeatsModal.module.css";
import { getCheckoutSession } from "../../../../utils/queryUtils/appQuery";
import { openSessionUrl } from "../../../../utils";
import Modal from "../../../../components/elements/Modal/Modal";

const EmployeeSeatsModal = ({
  children,
  totalGlobalEmployees,
  globalEmployeesNumPerLocation,
}) => {
  const [numEmployees, setNumEmployees] = useState(totalGlobalEmployees);
  const [isOpen, setIsOpen] = useState(false);

  const closeDialog = () => setIsOpen(false);
  const openDialog = () => setIsOpen(true);

  useEffect(() => {
    setNumEmployees(totalGlobalEmployees);
  }, [totalGlobalEmployees]);

  const handleCheckout = async () => {
    if (numEmployees <= 0) {
      return;
    }
    closeDialog();
    const result = await getCheckoutSession(numEmployees);

    if (result) {
      openSessionUrl(result.checkoutSession, false);
    }
  };

  const getNumEmployeesPerLocationLabel = () => {
    const labels = [];
    globalEmployeesNumPerLocation.forEach((employeesInLocation, idx) => {
      const isLastLabel = idx === globalEmployeesNumPerLocation.length - 1;
      const { locationName, numberOfEmployees } = employeesInLocation;
      labels.push(
        <span className={styles.analytics} key={locationName}>
          <b>{numberOfEmployees}</b> employees in {locationName}
          {!isLastLabel && ";"}
        </span>
      );
    });
    return labels;
  };

  if (totalGlobalEmployees === null || globalEmployeesNumPerLocation === null) {
    return null;
  }

  return (
    <>
      <div className={styles.buttonWrapper} onClick={openDialog}>
        {children}
      </div>
      {isOpen && (
        <Modal isOpen={isOpen}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              Set the number of people on the roster (seat) for your
              subscription!
            </h1>
            <div className={styles.group}>
              <p className={styles.plan}>
                RosterLab Starter @ $5AUD/seat/month
              </p>
              <p className={styles.currentPricing}>
                Your current total cost at ${numEmployees * 5}AUD/month
              </p>
            </div>
            <div className={styles.inputContainer}>
              <button
                className={styles.iconButton}
                onClick={() => setNumEmployees(Math.max(0, numEmployees - 1))}
                disabled={numEmployees === 0}
              >
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <input
                className={styles.input}
                type="number"
                value={numEmployees}
                onChange={(e) => {
                  if (!e.target.value) {
                    setNumEmployees(0);
                  }
                  const num = parseInt(e.target.value);
                  if (!isNaN(num) && num >= 0) {
                    setNumEmployees(num);
                  }
                }}
              />
              <button
                className={styles.iconButton}
                onClick={() => setNumEmployees(numEmployees + 1)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            <div>
              <span className={styles.analytics}>
                There are <b>{totalGlobalEmployees}</b> seats in total in your
                current account:
              </span>
              {getNumEmployeesPerLocationLabel()}
            </div>
            <div className={styles.bottomButtons}>
              <button
                disabled={numEmployees <= 0}
                className={`${styles.checkoutButton} ${styles.button} ${
                  numEmployees <= 0 && styles.disabled
                }`}
                onClick={handleCheckout}
              >
                Checkout
              </button>
              <button
                className={`${styles.cancelButton} ${styles.button}`}
                onClick={closeDialog}
              >
                Maybe later
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default EmployeeSeatsModal;
