import AppRoutes from "./routes/AppRoutes";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./utils/agGridUtils/css/headerStyle.css";
import "./utils/agGridUtils/css/customStyle.css";
import "./styles/cellStyles.css";
import "./styles/desktopCalendar.css";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import EndUserLicenseAgreement from "./features/auth/components/eula/EndUserLicenseAgreement";
import DataDeletionPolicy from "./features/auth/components/dataDeletionPolicy/DataDeletionPolicy";
import PrivacyPolicy from "./features/auth/components/privacyPolicy/PrivacyPolicy";
import ShareRosterAuthCheck from "./features/shareRostser/components/ShareRosterAuthCheck/ShareRosterAuthCheck";
import LoginPage from "./features/auth/components/LoginPage/LoginPage";
import { useUserStore } from "./globalStore/appStore";
import { useAuthStatusUpdate } from "./features/auth/service/useAuthStatusUpdate";
import LiveCalendarWrapper from "./features/liveCalendar/components/sharedMyRoster/LiveCalendarDataContainer/LiveCalendarDataContainer";
import { useReloadToFetchLatestBuild } from "./hooks/useReloadToFetchLatestBuild";
import { CompatRouter } from "react-router-dom-v5-compat";
import useOfflineNotification from "./utils/networkUtils/useOfflineNotifications";
import { Toaster } from "react-hot-toast";
import { setDefaultOptions } from "date-fns";
import SubscriptionAgreement from "./features/auth/components/subscriptionAgreement/SubscriptionAgreement";
import { LiveUpdate } from "@capawesome/capacitor-live-update";
import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import LoadingPage from "./features/loading/components/LoadingPage/LoadingPage";
import { awsEnvironment } from ".";

/**
 * This component DOES NOT gets re-rendered when route changes
 */
function App() {
  const { user } = useUserStore();

  setDefaultOptions({ weekStartsOn: 1 }); // This ensures that MUI DatePicker (which uses date-fns) calendar starts from Monday (Not Sunday)

  const { isLoadingUser } = useAuthStatusUpdate();
  useOfflineNotification();

  if (isLoadingUser) {
    return <LoadingPage />;
  }

  return (
    <BrowserRouter>
      <CompatRouter>
        <Toaster />
        <AppContent user={user} />
      </CompatRouter>
    </BrowserRouter>
  );
}

/**
 * This component gets re-rendered when route changes
 */
function AppContent({ user }) {
  const isNative = Capacitor.isNativePlatform();

  useEffect(() => {
    const setupLiveUpdate = async () => {
      if (isNative) {
        const channel = awsEnvironment !== "main" ? "test" : "main";
        console.log("Setting up live update for channel: ", channel);
        await LiveUpdate.setChannel(channel);
        await LiveUpdate.ready();
        await LiveUpdate.sync();
      }
    };

    setupLiveUpdate();
  }, [isNative]);

  useReloadToFetchLatestBuild();

  const isAuthenticated = user && user.session;

  return (
    <Switch>
      <Route exact path="/privacy">
        <PrivacyPolicy />
      </Route>
      <Route exact path="/eula">
        <EndUserLicenseAgreement />
      </Route>
      <Route exact path="/subscriptionagreement">
        <SubscriptionAgreement />
      </Route>
      <Route exact path="/datadeletion">
        <DataDeletionPolicy />
      </Route>
      <Route path="/liveschedule">
        <LiveCalendarWrapper />
      </Route>
      <Route path="/direct-share">
        <ShareRosterAuthCheck />
      </Route>
      <Route path="/">
        <LoginPage>{isAuthenticated && <AppRoutes user={user} />}</LoginPage>
      </Route>
    </Switch>
  );
}

export default App;
