import styles from "./TaskBlockExplainerModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import BasicButton from "../../../../components/elements/BasicButton/BasicButton";
import { openHubspotChat } from "../../../../utils";
import Modal from "../../../../components/elements/Modal/Modal";

const TaskBlockExplainerModal = ({ show, proceed }) => (
  <Modal isOpen={show}>
    <div className={styles.container}>
      <button className={styles.closeButton} onClick={() => proceed(false)}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <p className={styles.title}>What are task blocks?</p>
      <p className={styles.description}>
        Task blocks are designed for multiple tasks during one shift. If your
        team members can perform different tasks or having different duties
        during a shift, then tasks blocks are what you need!
      </p>
      <p className={styles.example}>
        For example, Day - Operations AM/Clinic PM.
      </p>
      <div className={styles.planDescription}>
        <FontAwesomeIcon
          icon={faCircleArrowUp}
          className={styles.upgradeIcon}
        />
        <p className={styles.planMessage}>
          If you have a complex schedule involves multiple tasks assignment,
          chat to our team and learn about how it works in RosterLab Premium.
        </p>
      </div>
      <BasicButton
        color="#219ec9"
        hoverColor="#1f91b7"
        onClick={() => {
          openHubspotChat();
          proceed(true);
        }}
        customStyle={{
          borderRadius: "5px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Chat with our team for a free trial in RosterLab Premium
      </BasicButton>
    </div>
  </Modal>
);

export default TaskBlockExplainerModal;
