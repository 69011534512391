import React, { useMemo, useState } from "react";
import Select from "react-select";
import ModalViewer from "../../../../../components/elements/Modal/ModalViewer";
import { generateReRoster } from "../../../../rosterGenerator/service/generateRoster";
import styles from "./RerosterModal.module.css";
import { useHistory } from "react-router-dom";
import { useRosterModelQuery } from "../../../../../hooks/modelQueryHooks/useRosterModelQuery";
import { DateTime, getAreaOptions, getNames } from "../../../../../utils";
import { reactSelectStyles } from "../../../../../utils/reactSelectUtils/reactSelectStyles";
import { CheckboxOption } from "../../../../grid/components/DropdownMultiSelector/DropdownMultiSelector";
import { KEYWORD_ALL } from "../../../../../constants/keywords";
import {
  AreasValueContainer,
  EmployeesValueContainer,
} from "../../../../../utils/reactSelectUtils/valueContainers";
import { useAreaEmployeesSelect } from "../../../../../hooks/useAreaEmployeesSelect";
import { customWarningAlert } from "../../../../confirm/service/confirm";
import Modal from "../../../../../components/elements/Modal/Modal";

interface RerosterModalProps {
  hide: () => void;
  isShowing: boolean;
  onClickOkay: () => void;
  initialStartDate: string;
  initialFinishDate: string;
  roster: any;
  nextLink: string;
  warnings: any;
  location: any;
  areas: any;
  employees: any;
  initialAreaFilterValue: string[];
}

export const ScheduleViewRerosterModal = (props) => {
  const { roster } = useRosterModelQuery({
    isScheduleView: true,
    locationID: props.locationID,
    rosterID: props.rosterID,
  });

  const isSnapshot = roster.isSnapshot;
  const nextLink = isSnapshot
    ? `/locations/schedule-view/solutions?location-id=${props.locationID}&snapshot-id=${roster.id}`
    : `/locations/schedule-view/solutions?location-id=${props.locationID}&date=${props.initialStartDate}`;

  return <RerosterModal nextLink={nextLink} roster={roster} {...props} />;
};

export const PrototypeRerosterModal = (props) => {
  const { roster } = useRosterModelQuery({
    isScheduleView: false,
    locationID: props.locationID,
    rosterID: props.rosterID,
  });

  const nextLink = "/roster/solutions?roster-id=" + props.rosterID;

  return <RerosterModal roster={roster} nextLink={nextLink} {...props} />;
};

const RerosterModal: React.FC<RerosterModalProps> = ({
  hide,
  isShowing,
  initialStartDate,
  initialFinishDate,
  roster,
  warnings,
  nextLink,
  location,
  areas,
  employees,
  initialAreaFilterValue = null,
}) => {
  const history = useHistory();
  const { areaOptions, selectAllAreasOption } = useMemo(
    () => getAreaOptions(areas),
    [areas]
  );

  const {
    employeeSelectOptions,
    selectedAreaOptions,
    selectedEmployeeOptions,
    selectedEmployeeEntities,
    unSelectableEmployees,
    handleAreaSelect,
    handleEmployeeSelect,
  } = useAreaEmployeesSelect(initialAreaFilterValue, areas, employees);

  const initialDate = getStartDate(initialStartDate, initialFinishDate);

  const [startDate, setStartDate] = useState(initialDate);
  const [finishDate, setFinishDate] = useState(initialFinishDate);
  const [isTasksOnly, setIsTasksOnly] = useState(false);

  const reroster = async () => {
    if (selectedEmployeeEntities.length === 0) {
      customWarningAlert({
        title: "Please select employee(s) to publish",
        descriptions: ["Please select employee(s) to publish"],
      });
      return;
    }

    const startDateObj = new DateTime(startDate);
    const finishDateObj = new DateTime(finishDate);
    if (
      startDateObj.isAfter(finishDate) ||
      startDateObj.isBefore(initialStartDate) ||
      finishDateObj.isAfter(initialFinishDate)
    ) {
      alert("Invalid date range");
      return;
    }

    const selectedEmployeeNames = getNames(selectedEmployeeEntities);
    const isSucceeded = await generateReRoster(
      roster,
      location,
      startDate,
      finishDate,
      selectedEmployeeNames,
      warnings,
      isTasksOnly
    );
    if (isSucceeded) {
      history.push(nextLink);
    }
  };

  const isSelectAllSelected = () => {
    return selectedAreaOptions.some(
      (option) => option.value === selectAllAreasOption.value
    );
  };

  return (
    <Modal isOpen={isShowing}>
      <ModalViewer
        isShowing={true}
        title="Re-generate"
        firstBtnLabel="Re-generate"
        onclickOkay={reroster}
        hide={hide}
        modalWidth="500px"
        showCloseIcon={false}
      >
        <div className={styles.content}>
          {areas.length > 0 && (
            <div className={styles.row}>
              <p className={styles.label}>Select area(s) to re-roster</p>
              <Select
                options={[selectAllAreasOption, ...areaOptions]}
                styles={reactSelectStyles}
                isMulti
                closeMenuOnSelect={false}
                onChange={handleAreaSelect}
                hideSelectedOptions={false}
                components={{
                  Option: CheckboxOption,
                  ValueContainer: AreasValueContainer,
                }}
                value={selectedAreaOptions}
                maxMenuHeight={150}
                isSearchable={false}
                isClearable={false}
                isOptionSelected={(option) => {
                  if (isSelectAllSelected()) {
                    return true;
                  }
                  return selectedAreaOptions.some(
                    ({ value }) => value === option.value
                  );
                }}
              />
            </div>
          )}
          <div className={styles.row}>
            <p className={styles.label}>Select the period to re-roster</p>
            <div className={styles.dateWrapper}>
              <input
                type="date"
                className={styles.dateInput}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                min={initialStartDate}
                max={initialFinishDate}
              />
              <input
                type="date"
                className={styles.dateInput}
                value={finishDate}
                onChange={(e) => setFinishDate(e.target.value)}
                min={initialStartDate}
                max={initialFinishDate}
              />
            </div>
          </div>
          <div className={styles.row}>
            <p className={styles.label}>Select the employees to re-roster:</p>
            <Select
              isMulti
              styles={{
                ...reactSelectStyles,
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              options={employeeSelectOptions}
              value={selectedEmployeeOptions}
              onChange={handleEmployeeSelect}
              maxMenuHeight={150}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isSearchable={false}
              isClearable={false}
              isOptionSelected={(option) =>
                selectedEmployeeEntities.length === employees.length ||
                selectedEmployeeOptions.some(
                  ({ value }) => value === option.value
                )
              }
              components={{
                Option: CheckboxOption,
                ValueContainer: EmployeesValueContainer,
              }}
              isOptionDisabled={({ value }) => {
                if (unSelectableEmployees.length > 0 && value === KEYWORD_ALL) {
                  return true;
                }
                return unSelectableEmployees.includes(value);
              }}
            />
          </div>

          <div className={styles.row}>
            <span className={styles.label}>Only reroster tasks: </span>
            <input
              type="checkbox"
              title="Tasks only"
              checked={isTasksOnly}
              onChange={() => setIsTasksOnly(!isTasksOnly)}
            />
          </div>
        </div>
      </ModalViewer>
    </Modal>
  );
};

function getStartDate(initialStartDate, initialFinishDate) {
  const today = new DateTime(null); // Today's date
  const startDate = new DateTime(initialStartDate);
  const finishDate = new DateTime(initialFinishDate);

  if (
    today.isDateBetweenTwoDates(
      startDate.toFormat("AWS"),
      finishDate.toFormat("AWS"),
      true,
      true
    )
  ) {
    return today.toFormat("AWS");
  } else {
    return startDate.toFormat("AWS");
  }
}
