import {
  generateRightPinnedStatsColumGroup,
  getRightColumnCellStyles,
  getShortIds,
} from "../../../../utils";
import {
  countShiftsInRowByName,
  countShiftsInShiftGroupInRowByName,
  getTotalShiftCountsForAnEmployee,
  getTotalShiftHoursByShiftName,
  getTotalShiftHoursByShiftGroup,
  getTotalShiftHoursForAnEmployee,
  getTotalWeekendsForAnEmployee,
  getTotalPreferencesMetForAnEmployee,
} from "../../../statistics/service/statsValueGetter";

export const getStatsColumns = (
  startDate,
  numDays,
  shifts,
  shiftGroups,
  ruleStatsMinMaxValues,
  colCustomWidthStore = null,
  uncountableShiftKeywords,
  employeesShiftHours,
  employees,
  tasks,
  taskBlocks,
  customKeywordsUtilObj,
  shortIdsToEntityNamesDicts,
  namesToEntityShortIdsDicts,
  isAllocationInShortIdForm = true,
  statistics
) => {
  const {
    employeeHoursToShow,
    employeeShiftCountsToShow,
    leaveCountsToShow,
    otherSettings,
  } = statistics;

  const colWidths = {
    overallShiftCounts:
      colCustomWidthStore && colCustomWidthStore["totalShiftCounts"]
        ? colCustomWidthStore["totalShiftCounts"]
        : 80,
    overallHours:
      colCustomWidthStore && colCustomWidthStore["totalShiftHours"]
        ? colCustomWidthStore["totalShiftHours"]
        : 80,
    weekendsOffCount:
      colCustomWidthStore && colCustomWidthStore["weekends"]
        ? colCustomWidthStore["weekends"]
        : 80,
    leaveCount:
      colCustomWidthStore && colCustomWidthStore["leaveCount"]
        ? colCustomWidthStore["leaveCount"]
        : 80, // Added new width for leave count
  };

  const minCounts = ruleStatsMinMaxValues.minCounts;
  const maxCounts = ruleStatsMinMaxValues.maxCounts;

  const shiftShortIds = getShortIds(shifts);
  const shiftGroupShortIds = getShortIds(shiftGroups);

  const overallShiftCountsColDefs = employeeShiftCountsToShow.includes(
    "overall"
  )
    ? {
        colId: "totalShiftCounts",
        headerName: "Total Counts",
        field: "totalShiftCounts",
        pinned: "right",
        sortable: true,
        width: colWidths.overallShiftCounts,
        valueGetter: (params) => {
          if (params.node.rowPinned || params.data.id.startsWith("reserved")) {
            return "";
          }
          const totalCounts = getTotalShiftCountsForAnEmployee(
            params.data,
            uncountableShiftKeywords,
            shortIdsToEntityNamesDicts,
            namesToEntityShortIdsDicts,
            isAllocationInShortIdForm
          );
          return totalCounts;
        },
      }
    : null;

  const shiftsAndGroupsToDisplayCounts = [...shifts, ...shiftGroups].filter(
    ({ shortId }) => employeeShiftCountsToShow.includes(shortId)
  );

  const totalShiftsColGroups = generateRightPinnedStatsColumGroup(
    "Total Shifts",
    "totalShifts",
    shiftsAndGroupsToDisplayCounts,
    "ShiftCount",
    overallShiftCountsColDefs,
    (params, shiftShortId) => {
      if (params.node.rowPinned || params.data.id.startsWith("reserved")) {
        return "";
      }

      let shiftCount = 0;
      if (shiftShortIds.includes(shiftShortId)) {
        shiftCount = countShiftsInRowByName(
          params.data,
          shiftShortId,
          shortIdsToEntityNamesDicts,
          namesToEntityShortIdsDicts,
          isAllocationInShortIdForm
        );
      } else if (shiftGroupShortIds.includes(shiftShortId)) {
        let shiftGroup = shiftGroups.filter(
          (shiftGroup) => shiftGroup.shortId === shiftShortId
        )[0];

        shiftCount = countShiftsInShiftGroupInRowByName(
          params.data,
          shiftGroup,
          shortIdsToEntityNamesDicts,
          namesToEntityShortIdsDicts,
          isAllocationInShortIdForm,
          customKeywordsUtilObj
        );
      }
      return shiftCount;
    },
    minCounts,
    maxCounts,
    colCustomWidthStore
  );

  const groupsToDisplayHours = shiftGroups.filter(({ shortId }) =>
    employeeHoursToShow.includes(shortId)
  );

  const overallHoursColDefs = employeeHoursToShow.includes("overall")
    ? {
        colId: "totalShiftHours",
        headerName: "total",
        field: "totalShiftHours",
        pinned: "right",
        sortable: true,
        width: colWidths.overallHours,
        valueGetter: (params) => {
          if (params.node.rowPinned || params.data.id.startsWith("reserved")) {
            return "";
          }
          const totalHours = getTotalShiftHoursForAnEmployee(
            params.data,
            employeesShiftHours,
            shortIdsToEntityNamesDicts,
            namesToEntityShortIdsDicts,
            isAllocationInShortIdForm
          );
          return totalHours;
        },
      }
    : null;

  const totalShiftHoursColGroups = generateRightPinnedStatsColumGroup(
    "Total Hours",
    "totalHours",
    groupsToDisplayHours,
    "ShiftHours",
    overallHoursColDefs,
    (params, shiftShortId) => {
      if (params.node.rowPinned || params.data.id.startsWith("reserved")) {
        return "";
      }
      let totalHours = 0;
      if (shiftShortIds.includes(shiftShortId)) {
        totalHours = getTotalShiftHoursByShiftName(
          params.data,
          shiftShortId,
          employeesShiftHours,
          shortIdsToEntityNamesDicts,
          namesToEntityShortIdsDicts,
          isAllocationInShortIdForm
        );
      } else if (shiftGroupShortIds.includes(shiftShortId)) {
        let shiftGroup = shiftGroups.filter(
          (shiftGroup) => shiftGroup.shortId === shiftShortId
        )[0];

        totalHours = getTotalShiftHoursByShiftGroup(
          params.data,
          shiftGroup,
          employeesShiftHours,
          customKeywordsUtilObj,
          shortIdsToEntityNamesDicts,
          namesToEntityShortIdsDicts,
          isAllocationInShortIdForm
        );
      }

      return totalHours;
    },
    minCounts,
    maxCounts,
    colCustomWidthStore,
    " hours"
  );

  const weekendsOffCountColDefs = otherSettings.includes("weekends")
    ? {
        headerName: "Weekends",
        groupId: "Weekends",
        children: [
          {
            colId: "weekends",
            field: "weekends",
            headerName: "Weekends off",
            pinned: "right",
            sortable: true,
            width: colWidths.weekendsOffCount,
            valueGetter: (params) => {
              if (
                params.node.rowPinned ||
                params.data.id.startsWith("reserved")
              ) {
                return "";
              }
              const totalWeekends = getTotalWeekendsForAnEmployee(
                params.data,
                startDate,
                numDays
              );
              return totalWeekends;
            },
            cellStyle: getRightColumnCellStyles(minCounts, maxCounts),
          },
        ],
      }
    : null;

  const preferencesColumnNamesToDisplay = [];
  if (otherSettings.includes("preferences")) {
    preferencesColumnNamesToDisplay.push("");
  }
  if (otherSettings.includes("preferencesCritical")) {
    preferencesColumnNamesToDisplay.push("Critical");
  }
  if (otherSettings.includes("preferencesHigh")) {
    preferencesColumnNamesToDisplay.push("High");
  }
  if (otherSettings.includes("preferencesLow")) {
    preferencesColumnNamesToDisplay.push("Low");
  }

  const preferencesCountColDefs =
    preferencesColumnNamesToDisplay.length === 0
      ? null
      : {
          headerName: "Preferences met",
          groupId: "Preferences",
          children: preferencesColumnNamesToDisplay.map((severity) => ({
            colId: "preferences" + severity,
            field: "preferences" + severity,
            headerName: severity ? severity : "All",
            pinned: "right",
            sortable: true,
            width: 100,
            valueGetter: (params) => {
              if (
                params.node.rowPinned ||
                params.data.id.startsWith("reserved")
              ) {
                return "";
              }
              const preferencesMet = getTotalPreferencesMetForAnEmployee(
                params.data,
                numDays,
                employees.filter((emp) => emp.id === params.node.id)[0],
                shifts,
                shiftGroups,
                tasks,
                taskBlocks,
                severity,
                shortIdsToEntityNamesDicts,
                namesToEntityShortIdsDicts,
                isAllocationInShortIdForm,
                customKeywordsUtilObj
              );
              return preferencesMet[0] + "/" + preferencesMet[1];
            },
            cellStyle: getRightColumnCellStyles(minCounts, maxCounts),
          })),
        };

  const { leaveCodes } = customKeywordsUtilObj;
  const leaveCountColDefs = generateRightPinnedStatsColumGroup(
    "Leave",
    "leave",
    leaveCodes
      .filter((leaveCode) => leaveCountsToShow.includes(leaveCode.shortname))
      .map((leaveCode) => ({
        shortId: leaveCode.shortname,
        name: leaveCode.longname,
      })),
    "LeaveCount",
    null,
    (params, leaveShortId) => {
      if (params.node.rowPinned || params.data.id.startsWith("reserved")) {
        return "";
      }
      const leaveCount = Object.values(params.data).filter(
        (value) => value === leaveShortId
      ).length;
      return leaveCount;
    },
    minCounts,
    maxCounts,
    colCustomWidthStore
  );

  return {
    totalShiftsColGroups,
    totalShiftHoursColGroups,
    weekendsOffCountColDefs,
    preferencesCountColDefs,
    leaveCountColDefs,
  };
};

export function getShiftAndSkillShortIdFromCountStatsRowId(rowId) {
  const shiftSkillRegex = /^shift-(.+)-skill-(.+)$/;
  const shiftSkillRegexMatch = rowId.match(shiftSkillRegex);

  if (shiftSkillRegexMatch) {
    return {
      shiftShortId: shiftSkillRegexMatch[1],
      skillShortId: shiftSkillRegexMatch[2],
    };
  }

  const shiftRegex = /^shift-counts-(.+)$/;
  const shiftRegexMatch = rowId.match(shiftRegex);

  if (shiftRegexMatch) {
    return {
      shiftShortId: shiftRegexMatch[1],
      skillShortId: shiftRegexMatch[2],
    };
  }

  return {
    shiftShortId: null,
    skillShortId: null,
  };
}
