import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ColumnWidthSetter from "../ColumnWidthSetter/ColumnWidthSetter";
import styles from "./AdjustColumnButton.module.css";
import { useLocationMutation } from "../../../hooks/modelQueryHooks/useLocationMutation";
import { useQueryClient } from "@tanstack/react-query";

const AdjustColumnButton = ({
  locationID,
  longestCellStr,
  weekdayColWidth,
  weekendColWidth,
  isWDayWEndSeparate,
  setWeekdayColWidth,
  setWeekendColWidth,
  setIsWDayWEndSeparate,
  tableName,
  btnStyle = {},
  isInActionBar,
  showWeekdayWeekendToggle = true,
  hideLabelOnSmallSize = true,
  disableSetForAllPages = false,
  saveToDatabase = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const location = queryClient.getQueryData(["location", locationID]);

  const { updateLocationFields } = useLocationMutation(location);

  const toggleSetter = () => {
    setIsOpen((prev) => !prev);
  };

  const saveColumnWidthForSingleTable = (widthsInfo) => {
    if (!saveToDatabase) return;

    const newLocationData = updateSingleColumnWidthSetting(
      location,
      tableName,
      widthsInfo
    );

    updateLocationFields(["frontendSettings"], newLocationData);
  };

  const saveColumnWidthForAllTables = (widthsInfo) => {
    const newLocationData = updateAllColumnWidthSetting(location, widthsInfo);

    updateLocationFields(["frontendSettings"], newLocationData);
  };

  return (
    <div className={styles.wholeWrapper}>
      <button
        className={`${styles.btn} ${
          isInActionBar ? styles.actionBarBtn : undefined
        }`}
        onClick={toggleSetter}
        style={btnStyle}
        title="Adjust column width"
      >
        <FontAwesomeIcon icon={faSliders} className={styles.icon} />
        <span className={hideLabelOnSmallSize ? styles.btnLabel : undefined}>
          Adjust column width
        </span>
      </button>
      <>
        <div
          style={
            isOpen
              ? {
                  opacity: "1",
                  transition: "all 0.5s",
                  transitionTimingFunction: "ease-out",
                }
              : { opacity: "0", height: "0px" }
          }
          className={`${styles.setterWrapper}`}
        >
          <ColumnWidthSetter
            weekdayColWidth={weekdayColWidth}
            weekendColWidth={weekendColWidth}
            setWeekdayColWidth={setWeekdayColWidth}
            setWeekendColWidth={setWeekendColWidth}
            isWDayWEndSeparate={isWDayWEndSeparate}
            setIsWDayWEndSeparate={setIsWDayWEndSeparate}
            longestCellStr={longestCellStr}
            toggleSetter={toggleSetter}
            saveColumnWidthForSingleTable={saveColumnWidthForSingleTable}
            saveColumnWidthForAllTables={saveColumnWidthForAllTables}
            location={location}
            showWeekdayWeekendToggle={showWeekdayWeekendToggle}
            disableSetForAllPages={disableSetForAllPages}
          />
        </div>
        {!isOpen ? null : <div className={styles.verticalGap}></div>}
      </>
    </div>
  );
};

export default AdjustColumnButton;

const updateSingleColumnWidthSetting = (location, tableName, widthsInfo) => {
  const colWidthsSetting = {
    name: `${tableName}ColWidths`,
    values: widthsInfo,
  };

  const settinsExcludingColWidths = location.frontendSettings.filter(
    (setting) => {
      return !(setting.name === `${tableName}ColWidths`);
    }
  );

  const updatedLocation = {
    ...location,
    frontendSettings: [...settinsExcludingColWidths, colWidthsSetting],
  };

  return updatedLocation;
};

const updateAllColumnWidthSetting = (location, widthsInfo) => {
  const colWidthsSettings = [
    {
      name: "solutionsColWidths",
      values: widthsInfo,
    },
    {
      name: "historyColWidths",
      values: widthsInfo,
    },
    {
      name: "fixedShiftsColWidths",
      values: widthsInfo,
    },
    {
      name: "preferencesColWidths",
      values: widthsInfo,
    },
    {
      name: "globalPreferencesColWidths",
      values: widthsInfo,
    },
    {
      name: "globalFixedShiftsColWidths",
      values: widthsInfo,
    },
    {
      name: "rosterColWidths",
      values: widthsInfo,
    },
  ];

  const settinsExcludingColWidths = location.frontendSettings.filter(
    (setting) => {
      if (setting.name.endsWith("ColWidths")) {
        return false;
      }
      return true;
    }
  );

  const updatedLocation = {
    ...location,
    frontendSettings: [...settinsExcludingColWidths, ...colWidthsSettings],
  };

  return updatedLocation;
};
