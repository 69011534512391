/**
 * Array-specific utility functions
 */

import { memoizeFunction } from "./cache";

export const strToArrCommaSeparated = memoizeFunction((stringWithComma) => {
  if (stringWithComma == null) {
    return [];
  }
  const strArrWithoutComma = stringWithComma.split(",");
  const strArrTrimmed = strArrWithoutComma.map((str) => str.trim());
  const strArrWithoutEmptyStr = strArrTrimmed.filter((str) => str !== "");
  return strArrWithoutEmptyStr;
});

export function getDuplicatedStringsInArr(arr) {
  const duplicates = arr.filter((item, index) => arr.indexOf(item) !== index);
  const uniqueDuplicatees = Array.from(new Set(duplicates));
  return uniqueDuplicatees;
}

export function removeDuplicateStrInArr(arr) {
  return Array.from(new Set(arr));
}

// Get an array with only items that are duplicates
// for example, if [1, 2, 2, 3, 3, 3] is passd in,
// it will return [2, 3]
export function getDuplicatedItemsInArray(arr) {
  const seen = [];
  const dups = [];
  for (const idx in arr) {
    if (!seen.includes(arr[idx])) {
      seen.push(arr[idx]);
    } else {
      dups.push(arr[idx]);
    }
  }
  const uniqueDuplicateItems = [...new Set(dups)];
  return uniqueDuplicateItems;
}

export const multiplyArray = (arr, length) => {
  return Array.from({ length }, () => arr).flat();
};

export const range = (size, startAt = 0) => {
  return [...Array(size).keys()].map((i) => i + startAt);
};

export const getMostCommonElementInArr = (array) => {
  if (array.length === 0) return null;
  var modeMap = {};
  var maxEl = array[0],
    maxCount = 1;
  for (var i = 0; i < array.length; i++) {
    var el = array[i];
    if (modeMap[el] == null) modeMap[el] = 1;
    else modeMap[el]++;
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }
  return maxEl;
};

export const duplicateArr = (arr, times) =>
  Array(times)
    .fill([...arr])
    .reduce((a, b) => a.concat(b));

export const emptyStrExistsInArray = (arr) => {
  const emptyItems = arr.filter((i) => i === "");
  return emptyItems.length > 0;
};

export const isPartialEmptyStringFilledArray = (arr) => {
  return emptyStrExistsInArray(arr) && !allElementsAreEmptyStr(arr);
};

export const allElementsAreEmptyStr = (arr) => {
  const nonEmptyItems = arr.filter((i) => i !== "");
  return nonEmptyItems.length === 0;
};

export const allElementsAreInteger = (arr) => {
  const nonIntegerItems = arr.filter((i) => !Number.isInteger(i));
  return nonIntegerItems.length === 0;
};

export function compareTwoObjectArraysByStringification(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
      return false;
    }
  }

  return true;
}

export function twoArraysAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  if (arr1.length === 0 && arr2.length === 0) return true;

  const isArrayOfNonNullableObjects =
    typeof arr1[0] === "object" && arr1[0] !== null;

  for (let i = 0; i < arr1.length; i++) {
    if (isArrayOfNonNullableObjects) {
      for (const key of Object.keys(arr1[i])) {
        if (
          (arr1[i][key] != null || arr2[i][key] != null) &&
          arr1[i][key] !== arr2[i][key]
        ) {
          return false;
        }
      }
    } else {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
  }
  return true;
}

export function hasCommonItems(array1, array2) {
  for (let i = 0; i < array1.length; i++) {
    if (array2.includes(array1[i])) {
      return true;
    }
  }
  return false;
}

// checks whether all elements in the first array, arr1, are present in the second array, arr2
export function containsAllElements(arr1, arr2) {
  return arr1.every((element) => arr2.includes(element));
}

export function resizeArray(array, n, filler) {
  const filling = filler ? filler : "";
  if (n <= array.length) {
    return array.slice(0, n);
  } else {
    const resizedArray = [...array];
    while (resizedArray.length < n) {
      resizedArray.push(filling);
    }
    return resizedArray;
  }
}

export function getMissingElements(arr1, arr2) {
  return arr2.filter((element) => !arr1.includes(element));
}

// Check first n elements of arr equals to value
export function checkFirstNElements(arr, n, value) {
  if (!Array.isArray(arr) || !Number.isInteger(n)) {
    return false;
  }

  for (let i = 0; i < n; i++) {
    if (arr[i] !== value) {
      return false;
    }
  }

  return true;
}

// Check last n elements of arr equals to value
export function checkLastNElements(arr, n, value) {
  if (!Array.isArray(arr) || !Number.isInteger(n)) {
    return false;
  }

  const startIndex = arr.length - n;
  for (let i = startIndex; i < arr.length; i++) {
    if (arr[i] !== value) {
      return false;
    }
  }

  return true;
}

export function findCommonElements(arr1, arr2) {
  // Create a Set from the first array to efficiently check for common element
  const set1 = new Set(arr1);

  // Use filter to find elements from the second array that are also in set1
  const commonElements = arr2.filter((item) => set1.has(item));
  return commonElements;
}

export function chunkArray(arr, chunkSize) {
  const chunks = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }

  return chunks;
}

export function getIndicesOfModifiedElements(arrBefore, arrAfter) {
  const modifiedIndices = [];

  for (let i = 0; i < arrBefore.length; i++) {
    if (arrBefore[i] !== arrAfter[i]) {
      modifiedIndices.push(i);
    }
  }

  return modifiedIndices;
}

export function getDeletedItemsInArray(arrBefore, arrAfter) {
  const deletedItems = [];
  arrBefore.forEach((item) => {
    if (!arrAfter.includes(item)) {
      deletedItems.push(item);
    }
  });
  return deletedItems;
}

export function removeItemInArrayAt(arr, indices) {
  indices.sort((a, b) => b - a);

  for (let i = 0; i < indices.length; i++) {
    const index = indices[i];
    if (index >= 0 && index < arr.length) {
      arr.splice(index, 1);
    }
  }

  return arr;
}

export function arraysHaveSameContent(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

export function createArrayFromInteger(n) {
  let array = [];
  for (let i = 1; i <= n; i++) {
    array.push(i);
  }
  return array;
}

// give count to first occurrence only
export function countConsecutive(arr) {
  return arr.map((current, i) => {
    // Check if it's the first of the consecutive sequence
    if (i === 0 || arr[i - 1] !== current) {
      let count = 0;
      for (let j = i + 1; j < arr.length; j++) {
        if (arr[j] === current) {
          count++;
        } else {
          break;
        }
      }
      return count;
    }
    return 0;
  });
}

export function pushIfNotExists(arr, item) {
  if (!arr.includes(item)) {
    arr.push(item);
  }
}

export function arraysIntersect(arr1, arr2) {
  for (const element of arr1) {
    if (arr2.includes(element)) {
      return true;
    }
  }
  return false;
}

export function arrayIsSuperset(arr1, arr2) {
  for (var i = 0; i < arr2.length; i++) {
    if (!arr1.includes(arr2[i])) {
      return false;
    }
  }
  return true;
}

/**
 * Sorts an array of strings based on the order defined in a priority array.
 *
 * @param {string[]} array - The array of strings to be sorted.
 * @param {string[]} sorterArray - The priority array that defines the desired order of elements.
 *
 * @returns {string[]} - A new array where elements appearing in the sorterArray
 *                       are prioritized and ordered as per the sorterArray.
 *                       Remaining elements are sorted lexicographically at the end.
 *
 * @example
 * const array = ["a3", "a3", "a2", "a2", "a2", "a0", "a0", "a1"];
 * const sorterArray = ["a1", "a2"];
 *
 * const result = sortByPriority(array, sorterArray);
 * console.log(result);
 * Output: ["a1", "a2", "a2", "a2", "a3", "a3", "a0", "a0"]
 */
export function sortStringArrayByPriority(array, sorterArray) {
  // Create a shallow copy of the array and sort it
  return [...array].sort((a, b) => {
    const indexA = sorterArray.indexOf(a);
    const indexB = sorterArray.indexOf(b);

    // If both elements are in sorterArray, sort based on their positions in sorterArray
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }
    // If only one element is in sorterArray, that element comes first
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;
    // If neither element is in sorterArray, maintain their original order
    return 0;
  });
}
