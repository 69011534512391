import { useMemo, useState } from "react";
import { TextField, List, ListItem, ListItemText } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  convertShortIDsToEntityNames,
  strToArrCommaSeparated,
} from "../../../../utils";
import styles from "./ShiftGroupExplainerModal.module.css"; // Import CSS module
import { getSubtasks } from "../../service/rosterUtils";
import {
  checkDemandAllocations,
  checkShiftGroupAllocations,
} from "../../../../utils/queryUtils/rosterQuery";
import { getRosteringProblemFromRoster } from "../../../rosterGenerator/service/generateRoster";
import { useQuery } from "@tanstack/react-query";
import { interpretCustomKeywordsData } from "../../../../utils/queryUtils/locationDataGetters";
import { KEYWORD_ALL, KEYWORD_ANY } from "../../../../constants/keywords";
import Modal from "../../../../components/elements/Modal/Modal";

function ShiftGroupExplainerModal({
  demandIndex,
  demand,
  shiftGroupIndex,
  shiftGroup,
  shifts,
  shiftGroups,
  tasks,
  skills,
  taskBlocks,
  customKeywordsData,
  locationSettings,
  roster,
  open,
  onClose,
}) {
  const [searchKeyword, setSearchKeyword] = useState("");

  const customKeywordsUtilObj = useMemo(
    () => interpretCustomKeywordsData(customKeywordsData),
    [customKeywordsData]
  );
  const { leaveKeywords } = customKeywordsUtilObj;

  const {
    data: allocations,
    isLoading,
    isError,
  } = useQuery(
    ["allocations", shiftGroupIndex, demandIndex, roster],
    async () => {
      if (shiftGroupIndex !== undefined && shiftGroupIndex >= 0 && roster) {
        const rosteringProblem = getRosteringProblemFromRoster(
          roster,
          locationSettings
        );
        const allocations2 = await checkShiftGroupAllocations(
          rosteringProblem,
          [...locationSettings, "leaveNames;" + leaveKeywords.join(";")],
          shiftGroupIndex
        );
        return JSON.parse(allocations2.data.checkRoster).allocations;
      } else if (demandIndex !== undefined && demandIndex >= 0 && roster) {
        const rosteringProblem = getRosteringProblemFromRoster(
          roster,
          locationSettings
        );
        const allocations2 = await checkDemandAllocations(
          rosteringProblem,
          ["leaveNames;" + leaveKeywords.join(";")],
          demandIndex
        );
        return JSON.parse(allocations2.data.checkRoster).allocations;
      }
    },
    {
      enabled: open && !!roster && (shiftGroupIndex >= 0 || demandIndex >= 0),
      select: (data) => data || [], // Ensure data is always an array
      retry: 1,
    }
  );

  const {
    chosenShifts,
    chosenTasks,
    chosenSkills,
    shiftsInversed,
    tasksInversed,
  } = useMemo(() => {
    if (!open) {
      return {};
    }

    if (demand) {
      return {
        chosenShifts: strToArrCommaSeparated(demand.shifts),
        chosenTasks: strToArrCommaSeparated(demand.tasks),
        chosenSkills: strToArrCommaSeparated(demand.skills),
        shiftsInversed: false,
        tasksInversed: false,
      };
    } else if (shiftGroup) {
      return {
        chosenShifts: strToArrCommaSeparated(shiftGroup.shifts),
        chosenTasks: strToArrCommaSeparated(shiftGroup.tasks),
        chosenSkills: strToArrCommaSeparated(shiftGroup.skills),
        shiftsInversed: shiftGroup.inversed,
        tasksInversed: shiftGroup.skillsInversed,
      };
    } else {
      console.error("Demand and shift group empty in shift group explainer");
      return {};
    }
  }, [demand, shiftGroup, open]);

  const subtasks = useMemo(
    () => getSubtasks(tasks, taskBlocks),
    [tasks, taskBlocks]
  );

  const taskBlocksContent = useMemo(() => {
    if (!chosenTasks) return [];
    return taskBlocks
      .map((taskBlock) => {
        const tasksInBlock = [];

        chosenTasks.forEach((taskShortId) => {
          const selectedSubtasks = subtasks.filter(
            (subtask) =>
              (subtask.shortId === taskShortId ||
                subtask.taskShortId === taskShortId) &&
              subtask.blockShortId === taskBlock.shortId
          );

          tasksInBlock.push(...selectedSubtasks.map((subtask) => subtask.name));
        });

        const tasksInBlockText = tasksInBlock.join(", ");

        if (tasksInBlock.length === 0) return null;

        const hasEmptySubtask = chosenTasks.includes(
          "no task " + taskBlock.shortId
        );

        return {
          key: taskBlock.name,
          content: `${
            !hasEmptySubtask ? "At least one" : "Either 0 or 1"
          } of the following ${taskBlock.name} tasks: ${tasksInBlockText}`,
        };
      })
      .filter(Boolean);
  }, [taskBlocks, chosenTasks, subtasks]);

  if (!open) {
    return <></>;
  }

  if (isLoading) {
    return (
      <Modal isOpen={open}>
        <div className={styles.container}>
          <p>Loading...</p>
        </div>
      </Modal>
    );
  }

  let innerInformation = null;

  if (isError) {
    innerInformation = (
      <>
        <p>
          I was not able to build a list of allocations for this roster. Please
          check for any warnings.
        </p>
        <br />
      </>
    );
  } else if (allocations.length === 0) {
    innerInformation = (
      <>
        <p>
          No allocations found (Note: for a shift or shift-task to be
          assignable, there needs to be a relevant staffing requirement.)
        </p>
        <br />
      </>
    );
  } else {
    const filteredAllocations = allocations.filter((allocation) =>
      allocation.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    innerInformation = (
      <>
        <p className={styles.description}>
          This includes all shifts or shift-tasks (if any) that the A.I. can
          assign:
        </p>
        <div className={styles.searchBarContainer}>
          <TextField
            fullWidth
            className={styles.textField}
            placeholder="Search keywords..."
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </div>

        <List className={styles.list}>
          {filteredAllocations.map((allocation, index) => (
            <ListItem className={styles.listItem} key={index}>
              <ListItemText primary={allocation} />
            </ListItem>
          ))}
        </List>
      </>
    );
  }

  const getShiftsExplanation = () => {
    const prefix = shiftsInversed
      ? "Any shifts except:"
      : "The following shifts:";

    const shiftsContent = convertShortIDsToEntityNames(chosenShifts, [
      ...shifts,
      ...shiftGroups,
    ]).join(", ");

    const content = chosenShifts.length > 0 ? shiftsContent : KEYWORD_ALL;
    return `${prefix} ${content}`;
  };

  const getSkillsExplanation = () => {
    const skillsContent = convertShortIDsToEntityNames(
      chosenSkills,
      skills
    ).join(", ");
    const content = chosenSkills.length > 0 ? skillsContent : "non required";
    return `Applies to employees with at least one of the following skills: ${content}`;
  };

  const getTasksExplanation = () => {
    const prefix = tasksInversed
      ? "Any task except: "
      : "The following tasks: ";
    const tasksContent = convertShortIDsToEntityNames(chosenTasks, tasks).join(
      ", "
    );
    const content = chosenTasks.length > 0 ? tasksContent : KEYWORD_ANY;
    return `${prefix} ${content}`;
  };

  return (
    <Modal isOpen={open}>
      <div className={styles.container}>
        <div className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <h2 className={styles.header}>Possible A.I. assignable allocations</h2>
        {innerInformation}
        <div>
          <p>
            <b>This includes allocations with: </b>
          </p>
          <p>{getShiftsExplanation()}</p>
          {taskBlocks.length > 0 ? (
            <>
              {taskBlocksContent.map(({ key, content }) => (
                <p key={key}>{content}</p>
              ))}
            </>
          ) : (
            <p>{getTasksExplanation()}</p>
          )}
          {chosenSkills && <p>{getSkillsExplanation()}</p>}
        </div>
      </div>
    </Modal>
  );
}

export default ShiftGroupExplainerModal;
