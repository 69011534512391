import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

function ModalDialog({ isOpen, children }) {
  const ref = useRef();

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const dialog = ref.current;
    dialog.showModal();
    return () => {
      dialog.close();
    };
  }, [isOpen]);

  return <dialog ref={ref}>{children}</dialog>;
}

function Modal({ isOpen, children }) {
  return (
    <>
      {createPortal(
        <ModalDialog isOpen={isOpen}>{children}</ModalDialog>,
        document.getElementById("modal")
      )}
    </>
  );
}

export default Modal;
