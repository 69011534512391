import { PLAN } from "../features/auth/service/auth";

export const LOCATION_SETTINGS_TYPE = Object.freeze({
  general: "General Settings",
  leaveManagement: "Leave Management",
  preferences: "Staff Preferences",
  interpretations: "Interpretations",
  rulesAndDemands: "Rules & Demands",
  notification: "Notifications",
  notDisplayedInSettingsPage: "Not displayed in settings page",
});

export const LOCATION_SETTINGS = Object.freeze({
  [LOCATION_SETTINGS_TYPE.general]: [
    {
      name: "pasting blank inserts off",
      title: "Copy/paste blank cell inserts off",
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: null,
      group: "Location behaviours",
    },
    {
      name: "locationAreas",
      title: "Show areas in this location",
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: null,
      group: "Location behaviours",
    },
    {
      name: "showCountStatsInSolutions",
      title: "Add my roster counts to the solution page",
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: null,
      group: "Location behaviours",
    },
    {
      name: "enableStatisticsInEmployeeApp",
      title: "Enable statistics in employee app",
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: null,
      group: "Location behaviours",
    },
    {
      name: "showOffInsteadOfBlankInMyRoster",
      title: 'Show "off" instead of blank in my roster page',
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: null,
      group: "My Roster page display",
    },
    {
      name: "showRDOInWeekdays",
      title: 'Show "RDO" instead of "off" during the weekdays',
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: null,
      group: "My Roster page display",
    },
    {
      name: "showRDOInWeekends",
      title: 'Show "RDO" instead of "off" during the weekends',
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: null,
      group: "My Roster page display",
    },
  ],
  [LOCATION_SETTINGS_TYPE.leaveManagement]: [
    {
      name: "showLeaveByDay",
      title: "Show leave hours assigned to each day",
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: null,
      group: "Leave management",
    },
    {
      name: "checkLeave",
      title: "Use RosterLab's leave management system",
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: null,
      group: "Leave management",
    },
  ],
  [LOCATION_SETTINGS_TYPE.preferences]: [
    {
      name: "defaultPreferenceImportance",
      title:
        "Default set all preferences from employees as % level (Except for special preferences)",
      savedIn: "frontend",
      advancedInputFormats: [
        {
          type: "select",
          options: ["normal", "high", "critical"],
          default: "normal",
        },
      ],
      plans: null,
      group: "Staff preferences",
    },
    {
      name: "autoApprovedPreferenceLimit",
      title:
        "Approve all pending special requests button sets pending preferences as % level preferences",
      savedIn: "frontend",
      advancedInputFormats: [
        {
          type: "select",
          options: ["high", "critical"],
          default: "high",
        },
      ],
      plans: null,
      group: "Staff preferences",
    },
  ],
  [LOCATION_SETTINGS_TYPE.interpretations]: [
    {
      name: "shiftHoursMajorityDay",
      title:
        "Shift hours count on day on which majority of the hours lie (as opposed to start day)",
      savedIn: "backend",
      advancedInputFormats: null,
      plans: [PLAN.AI, PLAN.COLLABORATOR],
      group: "App interpretations",
    },
    {
      name: "nightsNextDayDayOn",
      title:
        "The end day of a night shift also counts as a day on (as opposed to a day off)",
      savedIn: "backend",
      advancedInputFormats: null,
      plans: [PLAN.AI, PLAN.COLLABORATOR],
      group: "App interpretations",
    },
    {
      name: "callRosterSmartLeave",
      title: "Call roster version of smart leave",
      savedIn: "backend",
      advancedInputFormats: null,
      plans: [PLAN.AI, PLAN.COLLABORATOR],
      group: "App interpretations",
    },
    {
      name: "noMinimumHoursLeave",
      title: "No minimum hours per leave period",
      savedIn: "backend",
      advancedInputFormats: null,
      plans: null,
      group: "App interpretations",
    },
    {
      name: "weekendFriday",
      title: "Weekend starts at 6pm on Friday",
      savedIn: "backend",
      advancedInputFormats: null,
      plans: null,
      group: "App interpretations",
    },
    {
      name: "failOnFixedShifts",
      title: "Fail when rules conflict with fixed shifts (advanced)",
      savedIn: "backend",
      advancedInputFormats: null,
      plans: [PLAN.AI, PLAN.COLLABORATOR],
      group: "Advanced Settings",
    },
    {
      name: "cyclicRoster",
      title: "Make the roster cyclic (advanced)",
      savedIn: "backend",
      advancedInputFormats: null,
      plans: [PLAN.AI, PLAN.COLLABORATOR],
      group: "Advanced Settings",
    },
    {
      name: "overGenerate",
      title: "Roster likely to end in undesirable patterns (advanced)",
      savedIn: "backend",
      advancedInputFormats: null,
      plans: null,
      group: "Advanced Settings",
    },
    {
      name: "nightsPatternCustom",
      title: "Custom nights pattern (advanced)",
      savedIn: "backend",
      advancedInputFormats: null,
      plans: null,
      group: "Advanced Settings",
    },
    {
      name: "fastSolve",
      title: "Solve faster with a worse solution for validation",
      savedIn: "backend",
      advancedInputFormats: null,
      plans: null,
      group: "Advanced Settings",
    },
    {
      name: "experimentalGenerate",
      title: "Enable experimental AI rostering techniques",
      savedIn: "backend",
      advancedInputFormats: null,
      plans: null,
      group: "Advanced Settings",
    },
  ],
  [LOCATION_SETTINGS_TYPE.rulesAndDemands]: [
    {
      name: "rulesWithException",
      title: 'Enable the "with exception" option for my rules',
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: [PLAN.AI, PLAN.COLLABORATOR],
      group: "Rules management",
    },
    {
      name: "shiftTaskOnlyDemands",
      title: "Do not consider start time and end time in staffing requirements",
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: null,
      group: "Demands management",
    },
  ],
  [LOCATION_SETTINGS_TYPE.notification]: [
    {
      name: "emailAdminOnOpenShiftAccept",
      title: "Email admin when someone accepts an open shift",
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: null,
      group: "Email notifications",
    },
  ],
  [LOCATION_SETTINGS_TYPE.notDisplayedInSettingsPage]: [
    {
      name: "hideAreaNameInAllocations",
      title: "Hide area name in my roster table cells",
      savedIn: "frontend",
      advancedInputFormats: null,
      plans: null,
      group: null,
    },
  ],
});

/**
 * Stores:
 *  - Whether the roster sidebar is default expanded/collapsed
 *  - Whether the default employees page is overview or individual
 */
export const uiStateSettings = [
  {
    name: "sideBarStatus",
    possibleValues: {
      expanded: "expanded",
      collapsed: "collapsed",
    },
  },
  {
    name: "defaultEmployeesPageView",
    possibleValues: {
      overview: "overview",
      individual: "individual",
    },
  },
  {
    name: "defaultMyRosterPageView",
    possibleValues: {
      employeeView: "employeeView",
      shiftView: "shiftView",
    },
  },
];
