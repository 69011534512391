import { useEffect, useMemo, useState } from "react";
import {
  DateTime,
  getAllFixedShiftsFromGrid,
  getDayColumns,
  getRecurringColumns,
  getRecurringPeriodLengthSelection,
  parseEmployeeModelToFixedShiftsGridFormat,
} from "../../../../../utils";
import { hideColumnInGrid } from "../../../service/singleEmployeeViewUtils";
import IndFixedShiftsOverviewContainer from "../IndFixedShiftsOverviewContainer/IndFixedShiftsOverviewContainer";
import IndFixedShiftsRecurringContainer from "../IndFixedShiftsRecurringContainer/IndFixedShiftsRecurringContainer";

const IndFixedShiftsWrapper = ({
  selectedEmployee,
  startDate,
  location,
  numDays,
  isSaving,
  warnings,
  enumeratedTasks,
  isMainStream,
  enumeratedShiftTasks,
  updateEmployeeWithFields,
  customKeywordsUtilObj,
  locationID,
  areas,
  shifts,
  shiftGroups,
  tasks,
  subTasks,
  shortIdsToEntityNamesDicts,
  colorCodes,
}) => {
  const [overviewColumnApi, setOverviewColumnApi] = useState(null);
  const [recurringColumnApi, setRecurringColumnApi] = useState(null);
  const { predefinedShiftOptions } = customKeywordsUtilObj;

  useEffect(() => {
    if (overviewColumnApi && recurringColumnApi) {
      hideColumnInGrid([overviewColumnApi, recurringColumnApi], ["name"]);
    }
  }, [overviewColumnApi, recurringColumnApi]);

  const employeesData = useMemo(() => {
    return parseEmployeeModelToFixedShiftsGridFormat(
      [selectedEmployee],
      numDays,
      isMainStream
    );
  }, [selectedEmployee, numDays, isMainStream]);

  const dayColumns = getDayColumns(numDays);
  const recurringPeriodSelection =
    getRecurringPeriodLengthSelection(employeesData);
  const recurringColumns = getRecurringColumns(
    recurringPeriodSelection === "week" ? 7 : 14
  );

  const finishDateDate = new DateTime(startDate).getDate();
  finishDateDate.setDate(finishDateDate.getDate() + numDays - 1);

  const getDataFromGrid = (gridApi) => {
    const employeesInGrid = getAllFixedShiftsFromGrid(
      gridApi,
      dayColumns,
      recurringColumns
    );
    return employeesInGrid;
  };

  const updateData = async (newEmployees) => {
    updateEmployeeWithFields(newEmployees[0], [
      "Allocations",
      "AllocationsRecurring",
    ]);
  };

  return (
    <>
      <IndFixedShiftsOverviewContainer
        startDate={startDate}
        warnings={warnings}
        employeesData={employeesData}
        enumeratedTasks={enumeratedTasks}
        dayColumns={dayColumns}
        setGridApiToParent={() => {}}
        setColumnApiToParent={setOverviewColumnApi}
        isSaving={isSaving}
        updateData={updateData}
        predefinedKeywords={predefinedShiftOptions}
        enumeratedShiftTasks={enumeratedShiftTasks}
        locationID={locationID}
        getDataFromGrid={getDataFromGrid}
        areas={areas}
        shifts={shifts}
        shiftGroups={shiftGroups}
        tasks={tasks}
        subTasks={subTasks}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        customKeywordsUtilObj={customKeywordsUtilObj}
        colorCodes={colorCodes}
      />
      <IndFixedShiftsRecurringContainer
        location={location}
        startDate={startDate}
        numDays={numDays}
        warnings={warnings}
        isSaving={isSaving}
        employeesData={employeesData}
        enumeratedTasks={enumeratedTasks}
        recurringColumns={recurringColumns}
        setGridApiToParent={() => {}}
        setColumnApiToParent={setRecurringColumnApi}
        updateData={updateData}
        predefinedKeywords={predefinedShiftOptions}
        enumeratedShiftTasks={enumeratedShiftTasks}
        locationID={locationID}
        getDataFromGrid={getDataFromGrid}
        areas={areas}
        shifts={shifts}
        shiftGroups={shiftGroups}
        tasks={tasks}
        subTasks={subTasks}
        shortIdsToEntityNamesDicts={shortIdsToEntityNamesDicts}
        customKeywordsUtilObj={customKeywordsUtilObj}
        colorCodes={colorCodes}
      />
    </>
  );
};

export default IndFixedShiftsWrapper;
