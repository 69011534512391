import { getUserUsername } from "../../globalStore/appStore";
import { IdentifyUser } from "../../features/auth/service/useAuthStatusUpdate";

export function openHubspotChat() {
  if (window._hsq) {
    window._hsq.push(["identify", { requested_free_trial: true }]);
    IdentifyUser(getUserUsername(), { requested_free_trial: true });
  }

  if (window.HubSpotConversations && window.HubSpotConversations.widget) {
    window.HubSpotConversations.widget.open();
  }
}

export function openDemoBookingWindow() {
  if (window._hsq) {
    window._hsq.push(["identify", { requested_free_trial: true }]);
    IdentifyUser(getUserUsername(), { requested_free_trial: true });
  }

  window.open(
    "https://meetings.rosterlab.com/meetings/daniel-ge/demo",
    "_blank"
  );
}
