import { USER_STATUS } from "../../../features/locations/service/employeeAuthInfo";
import { DateTime } from "../../dataTypesUtils/DateTime";
import { getNames, getShiftTask } from "../../modelUtils/generalModelUtil";
import { allocationFulfilsShiftGroup } from "../../../features/rosterProblems/service/preferencesAndFixedShifts";
import { getEmployeeRosteredAllocations } from "../../../features/scheduleView/service/scheduleViewRoster";
import { separateAcceptedOpenShiftsAsEntity } from "../../queryUtils/locationDataGetters";
import { prototypeRosterIsMonthView } from "../../queryUtils/monthViewUtils";
import { extractEntityShortIdsFromAllocation } from "../../modelUtils/allocation";

export const parseEmployeeModelToEmployeesGridFormat = (employees) => {
  const employeesData = [];
  for (let i = 0; i < employees.length; i++) {
    employeesData.push({
      id: employees[i].id,
      externalID: employees[i].externalID ? employees[i].externalID : "",
      name: employees[i].name,
      skills: employees[i].skills,
      shifts: employees[i].shifts ? employees[i].shifts : "",
      areas: employees[i].areas ? employees[i].areas : "",
    });
  }
  return employeesData;
};

export const parseScheduleEmployeeObjectsToEmployeesGridFormat = (
  employees,
  employeesAuthInfo,
  authProcessingUsers
) => {
  const employeesData = employees.map((employee) => {
    return {
      name: employee.name,
      externalID: employee.externalID ? employee.externalID : "",
      email: employee.email,
      id: employee.id,
      skills: employee.skills ? employee.skills : "",
      shifts: employee.shifts ? employee.shifts : "",
      FTE: employee.FTE ? employee.FTE : 0.0,
      salary: employee.salary ? employee.salary : 0.0,
      startDate: employee.startDate ? employee.startDate : "1970-01-01",
      finishDate: employee.finishDate ? employee.finishDate : "2038-01-01",
      areas: employee.areas ? employee.areas : "",
    };
  });

  const employeesWithAuthStatus = employeesData.map((emp) => {
    if (authProcessingUsers.includes(emp.id)) {
      return {
        ...emp,
        status: USER_STATUS.PROCESSING,
      };
    }

    const info = employeesAuthInfo.find(
      (empInfo) => empInfo.email.toLowerCase() === emp.email.toLowerCase()
    );

    if (info) {
      return {
        ...emp,
        status: info.userStatus,
      };
    }
    return {
      ...emp,
      status: USER_STATUS.NOT_INVITED,
    };
  });
  return employeesWithAuthStatus;
};

export const parseSkillModelToSkillsGridFormat = (skills) => {
  const skillsData = [];
  for (let i = 0; i < skills.length; i++) {
    skillsData.push({
      id: skills[i].id,
      name: skills[i].name,
      description: skills[i].description,
      shortId: skills[i].shortId || skills[i].name,
    });
  }
  return skillsData;
};

export const parseTaskModelToTasksGridFormat = (tasks) => {
  const tasksData = [];
  for (let i = 0; i < tasks.length; i++) {
    tasksData.push({
      id: tasks[i].id,
      name: tasks[i].name,
      description: tasks[i].description,
      includeGeneralStaffing: tasks[i].includeGeneralStaffing,
      skills: tasks[i].skills,
      shortId: tasks[i].shortId || tasks[i].name,
      autoAssigned: tasks[i].autoAssigned,
    });
  }
  return tasksData;
};

export const parseAreaModelToAreasGridFormat = (areas) => {
  const areasData = [];
  for (let i = 0; i < areas.length; i++) {
    areasData.push({
      id: areas[i].id,
      name: areas[i].name,
      shifts: areas[i].shifts,
      skills: areas[i].skills,
      tasks: areas[i].tasks,
      autoAssigned: areas[i].autoAssigned,
      shortId: areas[i].shortId,
    });
  }
  return areasData;
};

//TODO: generalise other functions, also does this just duplicate a dictionary?
export const parseModelToGridFormat = (items) => {
  const itemsData = [];
  for (let i = 0; i < items.length; i++) {
    const itemData = {};
    for (const [prop, value] of Object.entries(items[i])) {
      itemData[prop] = value;
    }
    itemsData.push(itemData);
  }
  return itemsData;
};

export const parseShiftModelToShiftsGridFormat = (shifts) => {
  let shiftsData = [];
  for (let i = 0; i < shifts.length; i++) {
    shiftsData.push({
      id: shifts[i].id,
      name: shifts[i].name,
      startTime: shifts[i].startTime,
      finishTime: shifts[i].finishTime,
      fulfilsDemand: shifts[i].fulfilsDemand,
      autoAssigned: shifts[i].autoAssigned,
      skill: shifts[i].skill,
      adminUseOnly: shifts[i].adminUseOnly ? shifts[i].adminUseOnly : false,
      shortId: shifts[i].shortId || shifts[i].name,
    });
  }
  return shiftsData;
};

export const parseShiftGroupModelToShiftGroupsGridFormat = (shiftGroups) => {
  const shiftGroupsData = [];
  for (let i = 0; i < shiftGroups.length; i++) {
    shiftGroupsData.push({
      id: shiftGroups[i].id,
      name: shiftGroups[i].name,
      shifts: shiftGroups[i].shifts,
      inversed: shiftGroups[i].inversed,
      tasks: shiftGroups[i].tasks,
      skills: shiftGroups[i].skills,
      skillsInversed: shiftGroups[i].skillsInversed,
      adminUseOnly: shiftGroups[i].adminUseOnly,
      shortId: shiftGroups[i].shortId || shiftGroups[i].name,
      areas: shiftGroups[i].areas,
    });
  }
  return shiftGroupsData;
};

export const parseRuleModelToRulesGridFormat = (employees, rules, isGlobal) => {
  const rulesData = [];
  for (let i = 0; i < employees.length; i++) {
    rulesData.push({
      id: employees[i].id,
      name: employees[i].name,
    });
    rules.forEach((rule, ruleInd) => {
      if (isGlobal) {
        rulesData[i][rule.name] = employees[i].ruleValues[ruleInd];
      } else {
        rulesData[i][rule.name] = employees[i].RuleValues[ruleInd];
      }
    });
  }
  return rulesData;
};

export const parseDemandModelToDemandsGridFormat = (demands) => {
  const demandsData = [];
  for (let i = 0; i < demands.length; i++) {
    demandsData.push({
      id: demands[i].id,
      startTime: demands[i].startTime,
      finishTime: demands[i].finishTime,
      skills: demands[i].skills,
      tasks: demands[i].tasks,
      type: demands[i].type,
      importance: demands[i].importance,
      shifts: demands[i].shifts,
      publicHoliday: demands[i].defaultPHValue,
      areas: demands[i].areas || "",
      conditionalGroup: demands[i].conditionalGroup || "",
    });
    demands[i].values.forEach((val, valInd) => {
      demandsData[i][valInd + 1] = val;
    });
  }
  return demandsData;
};

export const parseEmployeeModelToRosterGridFormat = (
  employees,
  isMainStream
) => {
  let employeesData = [];
  for (let i = 0; i < employees.length; i++) {
    employeesData.push({
      id: employees[i].id,
      name: employees[i].name,
      skills: employees[i].skills,
      ...(isMainStream && { rosterEmployeeID: employees[i].id }),
      areas: employees[i].areas || "",
    });
    if (employees[i].RosteredAllocations) {
      employees[i].RosteredAllocations.forEach((day, dayInd) => {
        const colName = "d" + (dayInd + 1);
        employeesData[i][colName] = day;
      });
    }
  }
  return employeesData;
};

export const parseOpenShiftsToRosterGridFormat = (
  openShifts,
  numDays,
  periodStartDate
) => {
  const openShiftsData = {
    id: "open-shifts",
    name: "Open Shifts",
    skills: "",
  };
  for (let i = 1; i <= numDays; i++) {
    const date = new DateTime(periodStartDate).addDays(i - 1).toFormat("AWS");
    const targetOpenShifts = openShifts.filter(
      (openShift) => openShift.date === date
    );
    const separatedOpenShifts =
      separateAcceptedOpenShiftsAsEntity(targetOpenShifts);

    const colName = "d" + i;

    if (separatedOpenShifts.length > 0) {
      let total = 0;
      separatedOpenShifts.forEach((openShift) => {
        const { displayedInfo } = openShift;
        if (!displayedInfo.isAccepted) {
          total = total + displayedInfo.number;
        }
      });
      openShiftsData[colName] = total.toString();
    } else {
      openShiftsData[colName] = "0";
    }
  }
  return [openShiftsData];
};

export const parseEmployeeModelToLiveCalendarFormat = (
  employees,
  startDate,
  numDays,
  filteredShifts,
  shiftGroups,
  customKeywordsUtilObj,
  shortIdsToEntityNamesDicts,
  shouldIncludeUnpublishedEmployees = false
) => {
  const { leaveCodes } = customKeywordsUtilObj;
  const relevantShiftGroups = shiftGroups.filter((shiftGroup) =>
    filteredShifts.includes(shiftGroup.shortId)
  );

  const allDatesInSchedule = DateTime.getAllDatesBetween(
    startDate,
    new DateTime(startDate).addDays(numDays).toFormat("AWS"),
    true,
    true
  ).map((date) => date.toFormat("AWS"));

  let employeesData = [];

  for (let i = 0; i < employees.length; i++) {
    const employee = {
      id: employees[i].id || (i + 1).toString(),
      index: i,
      name: employees[i].name,
      skills: employees[i].skills,
      areas: employees[i].areas || "",
    };
    const rosteredAllocations = getEmployeeRosteredAllocations(
      employees[i],
      leaveCodes,
      allDatesInSchedule
    );

    const nonNullPublishedAllocations = employees[
      i
    ].PublishedAllocations.filter(
      (alloc) =>
        alloc.publishedAllocation !== null &&
        allDatesInSchedule.includes(alloc.date)
    );

    if (
      nonNullPublishedAllocations.length === 0 &&
      !shouldIncludeUnpublishedEmployees
    )
      continue;

    for (let d = 0; d < numDays; d++) {
      const colName = "d" + (d + 1);
      const alloc = rosteredAllocations[d];

      const [shift] = getShiftTask(alloc);
      const { areaShortId, shiftShortId, taskShortId, enumeratedTaskShortId } =
        extractEntityShortIdsFromAllocation(alloc, shortIdsToEntityNamesDicts);

      if (
        filteredShifts.includes(shift) ||
        relevantShiftGroups.filter((shiftGroup) =>
          allocationFulfilsShiftGroup(
            shiftGroup,
            areaShortId,
            shiftShortId,
            taskShortId || enumeratedTaskShortId,
            null,
            customKeywordsUtilObj
          )
        ).length > 0
      ) {
        employee[colName] = alloc ? alloc : "";
      }
    }

    for (let d = 0; d < numDays; d++) {
      const colName = "d" + (d + 1);
      if (!(colName in employee)) {
        employee[colName] = "";
      }
    }
    employeesData.push(employee);
  }
  return employeesData;
};

export const parseEmployeeModelToLiveShiftCalendarFormat = (
  employees,
  startDate,
  numDays,
  filteredShifts,
  shiftGroups,
  customKeywordsUtilObj,
  shortIdsToEntityNamesDicts
) => {
  const employeesData = parseEmployeeModelToLiveCalendarFormat(
    employees,
    startDate,
    numDays,
    filteredShifts,
    shiftGroups,
    customKeywordsUtilObj,
    shortIdsToEntityNamesDicts,
    false
  );

  const shiftTaskDataUnordered = [];

  for (let d = 0; d < numDays; d++) {
    employeesData.forEach((employee) => {
      const dayKey = "d" + (d + 1);

      const allocation = employee[dayKey];
      if (allocation) {
        const {
          areaShortId,
          shiftShortId,
          taskShortId,
          enumeratedTaskShortId,
        } = extractEntityShortIdsFromAllocation(
          allocation,
          shortIdsToEntityNamesDicts
        );

        const relevantShiftShiftGroups = filteredShifts.filter(
          (filteredShift) => {
            const relevantShiftGroup = shiftGroups.find(
              (shiftGroup) => shiftGroup.shortId === filteredShift
            );

            return (
              filteredShift === shiftShortId ||
              (relevantShiftGroup &&
                allocationFulfilsShiftGroup(
                  relevantShiftGroup,
                  areaShortId,
                  shiftShortId,
                  taskShortId || enumeratedTaskShortId,
                  null,
                  customKeywordsUtilObj
                ))
            );
          }
        );

        relevantShiftShiftGroups.forEach((shiftShiftGroupShortId) => {
          const isShiftGroup = getNames(shiftGroups).includes(
            shiftShiftGroupShortId
          );
          const rowShift = shiftShiftGroupShortId;
          const rowTask = taskShortId && !isShiftGroup ? taskShortId : "";
          const relevantRows = shiftTaskDataUnordered.filter(
            (dat) =>
              dat.shift === rowShift && dat.task === rowTask && !dat[dayKey]
          );
          const rowNumber = shiftTaskDataUnordered.filter(
            (dat) => dat.shift === rowShift && dat.task === rowTask
          ).length;

          if (relevantRows.length === 0) {
            shiftTaskDataUnordered.push({
              id:
                shiftShiftGroupShortId +
                `${taskShortId ? "_" + taskShortId : ""}` +
                `_${rowNumber}`,
              shift: rowShift,
              task: rowTask,
              [dayKey]: employee.name,
            });
          } else {
            relevantRows[0][dayKey] = employee.name;
          }
        });
      }
    });
  }

  shiftTaskDataUnordered.sort((a, b) => {
    const aIndex = filteredShifts.indexOf(a.shift);
    const bIndex = filteredShifts.indexOf(b.shift);

    if (aIndex < bIndex) {
      return -1;
    } else if (aIndex > bIndex) {
      return 1;
    } else {
      const splitByUnderscoreA = a.id.split("_");
      const rowNumberA = splitByUnderscoreA[splitByUnderscoreA.length - 1];
      const splitByUnderscoreB = b.id.split("_");
      const rowNumberB = splitByUnderscoreB[splitByUnderscoreB.length - 1];
      return Number(rowNumberA) - Number(rowNumberB);
    }
  });
  return shiftTaskDataUnordered;
};

export const parseEmployeeModelToHistoryGridFormat = (
  employees,
  HISTORY_LENGTH
) => {
  let employeesData = [];
  for (let i = 0; i < employees.length; i++) {
    employeesData.push({
      id: employees[i].id,
      name: employees[i].name,
      areas: employees[i].areas || "",
    });
    if (employees[i].History !== null) {
      employees[i].History.forEach((day, dayInd) => {
        const colName = "d-" + (HISTORY_LENGTH - dayInd);
        employeesData[i][colName] = day;
      });
    }
  }

  return employeesData;
};

export const parseEmployeeModelToFixedShiftsGridFormat = (
  employees,
  numDays
) => {
  let employeesData = [];
  for (let i = 0; i < employees.length; i++) {
    employeesData.push({
      id: employees[i].id,
      name: employees[i].name,
      areas: employees[i].areas || "",
    });
    if (employees[i].Allocations !== null) {
      employees[i].Allocations.forEach((day, dayInd) => {
        const colName = "d" + (dayInd + 1);
        employeesData[i][colName] = day;
      });
    } else {
      for (let j = 0; j < numDays; j++) {
        const colName = "d" + (j + 1);
        employeesData[i][colName] = "";
      }
    }
    if (employees[i].AllocationsRecurring !== null) {
      employees[i].AllocationsRecurring.forEach((day, dayInd) => {
        const colName = "r" + (dayInd + 1);
        employeesData[i][colName] = day;
      });
    } else {
      for (let j = 0; j < numDays; j++) {
        const colName = "r" + (j + 1);
        employeesData[i][colName] = "";
      }
    }
  }
  return employeesData;
};

export const parseEmployeeModelToPreferencesGridFormat = (
  employees,
  numDays
) => {
  let employeesData = [];
  for (let i = 0; i < employees.length; i++) {
    employeesData.push({
      id: employees[i].id,
      name: employees[i].name,
      areas: employees[i].areas || "",
    });
    if (employees[i].Days !== null) {
      employees[i].Days.forEach((day, dayInd) => {
        const colName = "d" + (dayInd + 1);
        employeesData[i][colName] = day;
      });
    } else {
      for (let j = 0; j < numDays; j++) {
        const colName = "d" + (j + 1);
        employeesData[i][colName] = "";
      }
    }
    //
    if (employees[i].DaysRecurring !== null) {
      employees[i].DaysRecurring.forEach((day, dayInd) => {
        const colName = "r" + (dayInd + 1);
        employeesData[i][colName] = day;
      });
    } else {
      for (let j = 0; j < numDays; j++) {
        const colName = "r" + (j + 1);
        employeesData[i][colName] = "";
      }
    }
  }
  return employeesData;
};

export const parseSolutionModelToSolutionsGridFormat = (
  solution,
  checkLeave,
  showLeaveByDay,
  employees
) => {
  let solutionData = [];
  if (solution.employees) {
    for (let i = 0; i < solution.employees.length; i++) {
      const employeeID = solution.employees[i].employeeID;
      const employee = employees.find((emp) => emp.id === employeeID);
      const rowSolution = {
        id: employeeID,
        name: solution.employees[i].name,
        skills: solution.employees[i].skills,
        areas: employee?.areas || "", // employee can be null in certain circumstances
      };

      if (rowSolution.id.endsWith("_rules")) {
        rowSolution.name += " (without failing rules)";
      } else if (rowSolution.id.endsWith("_fixed")) {
        rowSolution.name += " (fixed shifts highlighted)";
      }

      for (let d = 0; d < solution.employees[i].days.length; d++) {
        const colName = "d" + (d + 1).toString();
        rowSolution[colName] = solution.employees[i].days[d]
          ? solution.employees[i].days[d]
          : "";
        if (rowSolution[colName] === "-") {
          rowSolution[colName] = "";
        } else if (checkLeave(rowSolution[colName])) {
          if (!showLeaveByDay) {
            rowSolution[colName] = checkLeave(rowSolution[colName]);
          } else {
            const regex = /[A-Za-z0-9]+: ([0-9]+)/;
            const match = rowSolution[colName].match(regex);
            if (match === null) {
              rowSolution[colName] += ": 0";
            }
          }
        }
      }

      solutionData.push(rowSolution);
    }
  }
  return solutionData;
};

export const parseGlobalEmployeeModelToGlobalPreferencesGridFormat = (
  employees,
  numDays,
  startDate,
  finishDate
) => {
  let employeesData = [];
  for (let i = 0; i < employees.length; i++) {
    employeesData.push({
      id: employees[i].id,
      name: employees[i].name,
      skills: employees[i].skills,
      areas: employees[i].areas || "",
    });
    if (employees[i].Preferences) {
      const formattedPreferences = new Array(numDays).fill("");

      employees[i].Preferences.filter((pref) => {
        return (
          new DateTime(pref.date).date >= new DateTime(startDate).date &&
          new DateTime(pref.date).date <= new DateTime(finishDate).date
        );
      }).forEach((pref) => {
        formattedPreferences[
          DateTime.getDifferenceInDays(
            new DateTime(startDate),
            new DateTime(pref.date)
          )
        ] = pref.allocation;
      });

      formattedPreferences.forEach((day, dayInd) => {
        const colName = "d" + (dayInd + 1);
        employeesData[i][colName] = day;
      });
    } else {
      for (let j = 0; j < numDays; j++) {
        const colName = "d" + (j + 1);
        employeesData[i][colName] = "";
      }
    }
    if (employees[i].PreferencesRecurring) {
      employees[i].PreferencesRecurring.forEach((day, dayInd) => {
        const colName = "r" + (dayInd + 1);
        employeesData[i][colName] = day;
      });
    } else {
      for (let j = 0; j < numDays; j++) {
        const colName = "r" + (j + 1);
        employeesData[i][colName] = "";
      }
    }
  }
  return employeesData;
};

export const parseMinMaxShiftDemandsToGridFormat = (
  shiftShortId,
  minDemandValues,
  maxDemandValues
) => {
  const gridData = Array(2);
  const maxDemandsData = { minOrMax: "max", shiftShortId };
  const minDemandsData = { minOrMax: "min", shiftShortId };

  maxDemandValues.forEach((val, idx) => {
    maxDemandsData[idx + 1] = val;
  });
  minDemandValues.forEach((val, idx) => {
    minDemandsData[idx + 1] = val;
  });

  gridData[0] = maxDemandsData;
  gridData[1] = minDemandsData;
  return gridData;
};

export const parseRosterListToGridFormat = (rosterList) => {
  const rowData = rosterList.map((roster) => {
    const createdAt =
      roster.createdAt === undefined
        ? new Date()
        : new DateTime(roster.createdAt, true).getDate();

    return {
      id: roster.id,
      name: roster.name,
      numWeeks: !prototypeRosterIsMonthView(roster.numDays)
        ? roster.numDays / 7
        : "one month",
      startDate: roster.startDate,
      createdAt: new DateTime(createdAt).toFormat("displayed-full"),
      createdAtFull: createdAt,
    };
  });

  return rowData;
};

export const parseGlobalEmployeeModelToScheduleRosterGridFormat = (
  employees
) => {
  const parsedEmployeesData = employees.map((employee) => {
    const data = {
      id: employee.id,
      name: employee.name,
      skills: employee.skills,
    };
    for (const allocationIndex in employee.RosteredAllocations) {
      const allocation = employee.RosteredAllocations[allocationIndex];
      const dayFieldName = `d${Number(allocationIndex) + 1}`;
      data[dayFieldName] = allocation;
    }
    return data;
  });

  return parsedEmployeesData;
};
