import { DateTime } from "../dataTypesUtils/DateTime";

export function sortByDateField(
  arr,
  fieldName,
  isAscending,
  mutateOriginal = true
) {
  const targetArray = mutateOriginal ? arr : [...arr];
  targetArray.sort((a, b) => {
    const dateA = new DateTime(a[fieldName], true).getDate();
    const dateB = new DateTime(b[fieldName], true).getDate();
    return isAscending ? dateA - dateB : dateB - dateA;
  });
  return targetArray;
}

export function sortByAlphanumericField(arr, fieldName) {
  arr.sort(function (a, b) {
    return a[fieldName].localeCompare(b[fieldName]);
  });
}

export function sortByNumericField(array, fieldName) {
  array.sort((a, b) => a[fieldName] - b[fieldName]);
}

/**
 * @param {*} employees
 * @param {*} sortedIds - array of ids in sorted order
 * The employees whos id are not in the sortedIds will be appended last
 * @returns
 */
export function sortEmployeesById(employees, sortedIds) {
  return [...employees].sort((a, b) => {
    const indexA = sortedIds.indexOf(a.id);
    const indexB = sortedIds.indexOf(b.id);

    if (indexA === -1 && indexB === -1) {
      return 0;
    }

    if (indexA === -1) {
      return 1;
    }

    if (indexB === -1) {
      return -1;
    }

    return indexA - indexB;
  });
}
