import { useMemo } from "react";
import DataEntryTable from "../../../components/DataEntryTable/DataEntryTable";
import {
  getColorCodedCells,
  getDemandMinMaxCellStyles,
  getFixedShiftsAllocationColor,
  getHardFailCellColor,
  getPrefMatchingCellColor,
  getSoftFailCellColor,
  isWeekendsCellHeader,
} from "../../service/styleGetters";
import AllocationSelector from "../AllocationSelector/AllocationSelector";
import RosterStatusPanel from "../RosterStatusPanel/RosterStatusPanel";
import {
  ScheduleToolbar,
  PrototypeToolbar,
} from "../RosterToolbar/RosterToolbar";
import {
  DateTime,
  convertShortIdsCellValueToEntityNames,
  defaultFilterValueGetter,
  getDatesColumnDefs,
  getIds,
  getMyRosterRowStyle,
  getNames,
  getRowCountStatusBar,
  insertEmptyStrOnPressBackspace,
  isWeekendsHeaderName,
  rosterGridValueSetter,
  sideBar,
  suppressEnterKey,
  toolIcons,
} from "../../../../../utils";
import { CustomToolTip } from "../../../../grid/components/toolTip/toolTip";
import {
  getDayShiftCounts,
  getDayShiftHours,
  getShiftAndSkillCountsPerColumn,
  getShiftGroupHoursPerColumn,
} from "../../../../statistics/service/statsValueGetter";
import { getDayColumnNameRegex } from "../../../../../utils/generalUtils/regex";
import OpenShiftsCellEditor, {
  OpenShiftsCellRenderer,
  getSelectedDate,
} from "../../../../grid/components/OpenShiftsCellEditor/OpenShiftsCellEditor";
import OpenShiftsLabelCellRenderer from "../../../../grid/components/OpenShiftsLabelCellRenderer/OpenShiftsLabelCellRenderer";
import { customWarningAlert } from "../../../../confirm/service/confirm";
import LogRocket from "logrocket";
import { defaultWarningHighlighterCellClassRules } from "../../../../../utils/agGridUtils/customCellStyles";
import AllocationCellRenderer from "../../../../grid/components/AllocationCellRenderer/AllocationCellRenderer";
import {
  convertAllocationInShortIdFormToNameForm,
  extractEntityShortIdsFromAllocation,
  removeAreaInAllocation,
} from "../../../../../utils/modelUtils/allocation";
import { KEYWORD_OFF, KEYWORD_STUDY } from "../../../../../constants/keywords";
import { processRosteredAllocationForClipboard } from "../../../../../utils/agGridUtils/clipboard";

const RosterGrid = ({
  numDays,
  employeesData,
  updateData,
  handleKeyDownForUndoRedo = () => {},
  triggerUndoRedoSnapshotCollection = () => {},
  setSelectedRows = () => {},
  onShiftCountCellClick = () => {},
  toggleRestrictionModal = () => {},
  setCellClicked = () => {},
  setGridApiToParent,
  toggleExpandGrid = () => {},
  pinnedBottomRowDataTemplate = [],
  isSaving,
  context,
  setColumnApiToParent,
  startDate,
  invalidCellListWithReasons,
  preferencesInfo,
  fixedShiftsInfo,
  shiftGroups,
  shiftNames,
  allocationOptions,
  totalShiftsColGroups = null,
  totalShiftHoursColGroups = null,
  weekendsOffCountColDefs = null,
  preferencesCountColDefs = null,
  leaveCountColDefs = null,
  getContextMenuItems,
  includeEmployeesInfoColumnDefs = true,
  showStatusBar = true,
  showSideBar = true,
  customGridHeight = null,
  colWidths = {},
  handleUIColDrag = null,
  handleRangeSelectionChanged = null,
  predefinedShiftOptions,
  uncountableShiftKeywords,
  areas,
  skills,
  shifts,
  tasks,
  taskBlocks,
  colorCodes,
  employeesShiftHours,
  publishedOnlyAllocations,
  requestAllocations,
  isMainStream,
  annualLeaveKeyword,
  showPublishedCellColor,
  openShiftsData,
  openOpenShiftsModal,
  openShifts,
  deleteOpenShift,
  getDataFromGrid,
  employeeDatesWithAcceptedOpenShiftAllocations = null,
  onToolPanelVisibleChanged = null,
  customGridMinHeight,
  rosterWarnings,
  updateNote,
  employeesAllocationNotes = null,
  isExternalFilterPresent = null,
  doesExternalFilterPass = null,
  shortIdsToEntityNamesDicts,
  namesToEntityShortIdsDicts,
  customKeywordsUtilObj,
  myRosterSettings,
}) => {
  const { rdoKeyword } = customKeywordsUtilObj;

  const {
    displayAreaInAllocationCell,
    showOffInsteadOfBlankInMyRoster,
    showRDOInWeekends,
    showRDOInWeekdays,
  } = useMemo(() => myRosterSettings, [myRosterSettings]);
  const employeeIDs = useMemo(() => getIds(employeesData), [employeesData]);

  const datesColumnDefs = useMemo(() => {
    let datesColumnDefs = getDatesColumnDefs(numDays, startDate);
    const uncoloredCellContents = [predefinedShiftOptions[0]];

    const customDatesColumnDefs = datesColumnDefs.map(
      (weekColDefs, weekGroupIdx) => {
        const customWeekColDefs = weekColDefs.children.map(
          (dayColDefs, dayGroupIdx) => {
            const customDayColDefs = dayColDefs.children.map((colDef) => {
              const date = new DateTime(startDate)
                .addDays(Number(colDef.field.substring(1)) - 1)
                .toFormat("AWS");

              return {
                ...colDef,
                valueGetter: (params) => {
                  const dayColNameRegex = getDayColumnNameRegex();
                  const originalVal = params.data[params.colDef.field];
                  if (originalVal === null || originalVal === undefined) {
                    return originalVal;
                  }
                  if (
                    params.node.rowPinned === "bottom" &&
                    !params.data.id.startsWith("reserved")
                  ) {
                    if (params.data.id === "totalDayShiftCounts") {
                      return getDayShiftCounts(
                        params,
                        uncountableShiftKeywords
                      );
                    }
                    if (params.data.id === "totalDayShiftHours") {
                      return getDayShiftHours(
                        params,
                        uncountableShiftKeywords,
                        employeesShiftHours,
                        shortIdsToEntityNamesDicts,
                        namesToEntityShortIdsDicts,
                        true
                      );
                    }
                    if (params.data.id.startsWith("shift-hours-")) {
                      return getShiftGroupHoursPerColumn(
                        params,
                        shiftGroups,
                        uncountableShiftKeywords,
                        employeesShiftHours,
                        shortIdsToEntityNamesDicts,
                        namesToEntityShortIdsDicts,
                        true,
                        customKeywordsUtilObj
                      );
                    }
                    //shift-counts- or skill-counts or shift-skill-counts
                    return getShiftAndSkillCountsPerColumn(
                      params,
                      shortIdsToEntityNamesDicts,
                      namesToEntityShortIdsDicts,
                      false,
                      customKeywordsUtilObj
                    );
                  } else if (
                    !params.data.id.startsWith("reserved") &&
                    params.colDef.field.match(dayColNameRegex)
                  ) {
                    const shiftSkillPair = originalVal.split("-");
                    const shift = shiftSkillPair[0].trim();
                    const skill = shiftSkillPair[1]
                      ? shiftSkillPair[1].trim()
                      : null;
                    if (skill) {
                      return [shift, skill].join("-");
                    }
                    return shift;
                  }
                  return originalVal;
                },
                valueSetter: (params) => {
                  if (params.data.id === "open-shifts") {
                    return false;
                  }
                  if (employeeDatesWithAcceptedOpenShiftAllocations) {
                    const employeeID = params.data.id;
                    const employeeAcceptedOpenShiftInfo =
                      employeeDatesWithAcceptedOpenShiftAllocations.find(
                        (info) => info.employeeID === employeeID
                      );
                    const colId = params.colDef.field;
                    if (
                      employeeAcceptedOpenShiftInfo.acceptedOpenShiftColIDs.includes(
                        colId
                      )
                    ) {
                      customWarningAlert({
                        title: "Open Shift Alert",
                        descriptions: [
                          "This is an open shift that you're trying to overwrite, if it is on purpose, please delete the open shift properly in the open shift modal above",
                        ],
                      });
                      return false;
                    }
                  }
                  if (insertEmptyStrOnPressBackspace(params)) {
                    return true;
                  }

                  return rosterGridValueSetter(
                    params,
                    namesToEntityShortIdsDicts
                  );
                },
                valueFormatter: (params) => {
                  // Convert to short id -> name only if involves shifts in the allocation
                  const dayColNameRegex = getDayColumnNameRegex();
                  let value = params.value;
                  if (
                    params.column.colId.match(dayColNameRegex) &&
                    !params.node.rowPinned
                  ) {
                    if (value === "" && showOffInsteadOfBlankInMyRoster) {
                      value = KEYWORD_OFF;
                    }

                    if (
                      isWeekendsHeaderName(params.colDef?.headerName) &&
                      showRDOInWeekends &&
                      value === KEYWORD_OFF
                    ) {
                      return rdoKeyword;
                    }

                    if (
                      !isWeekendsHeaderName(params.colDef?.headerName) &&
                      showRDOInWeekdays &&
                      value === KEYWORD_OFF
                    ) {
                      return rdoKeyword;
                    }

                    const { shiftShortIdMapsToEntity } =
                      extractEntityShortIdsFromAllocation(
                        value,
                        shortIdsToEntityNamesDicts
                      );

                    if (!displayAreaInAllocationCell) {
                      value = removeAreaInAllocation(
                        value,
                        shortIdsToEntityNamesDicts,
                        false
                      );
                    }

                    let nameForm = value;

                    if (shiftShortIdMapsToEntity) {
                      nameForm = convertAllocationInShortIdFormToNameForm(
                        value,
                        shortIdsToEntityNamesDicts
                      );
                    }

                    if (nameForm) {
                      return nameForm;
                    }
                  }
                  return value;
                },
                cellClassRules: {
                  weekends: isWeekendsCellHeader,
                  "roster-allocation-soft-fail": (params) =>
                    getSoftFailCellColor(
                      params,
                      uncoloredCellContents,
                      invalidCellListWithReasons,
                      preferencesInfo,
                      shifts,
                      shiftGroups,
                      tasks,
                      taskBlocks,
                      shortIdsToEntityNamesDicts,
                      customKeywordsUtilObj
                    ),

                  "roster-allocation-hard-fail": (params) =>
                    getHardFailCellColor(
                      params,
                      uncoloredCellContents,
                      invalidCellListWithReasons,
                      preferencesInfo,
                      shifts,
                      shiftGroups,
                      tasks,
                      taskBlocks,
                      shortIdsToEntityNamesDicts,
                      customKeywordsUtilObj
                    ),
                  "roster-allocation-pref-matching": (params) => {
                    let result = false;
                    try {
                      result = getPrefMatchingCellColor(
                        params,
                        preferencesInfo,
                        shifts,
                        shiftGroups,
                        tasks,
                        taskBlocks,
                        shortIdsToEntityNamesDicts,
                        customKeywordsUtilObj
                      );
                    } catch (error) {
                      console.error(error);
                      LogRocket.captureException(error, {
                        extra: employeesData,
                      });
                      return false;
                    }
                    return result;
                  },
                  "roster-allocation-fixed-shift-not-matching": (params) =>
                    getFixedShiftsAllocationColor(
                      params,
                      fixedShiftsInfo,
                      shifts,
                      shiftGroups,
                      tasks,
                      taskBlocks,
                      false,
                      shortIdsToEntityNamesDicts,
                      customKeywordsUtilObj
                    ),
                  "roster-allocation-fixed-shift-matching": (params) =>
                    getFixedShiftsAllocationColor(
                      params,
                      fixedShiftsInfo,
                      shifts,
                      shiftGroups,
                      tasks,
                      taskBlocks,
                      true,
                      shortIdsToEntityNamesDicts,
                      customKeywordsUtilObj
                    ),
                  "invalid-cell": (params) => {
                    if (rosterWarnings) {
                      const invalidStudyLeaveWarning = rosterWarnings.find(
                        ({ warningType }) =>
                          warningType === "invalid Study leave"
                      );

                      if (
                        invalidStudyLeaveWarning &&
                        params.value === KEYWORD_STUDY
                      ) {
                        return true;
                      }
                    }
                    return false;
                  },
                  "calendar-cell-published": (params) => {
                    if (params.data.id === "open-shifts") {
                      const cellDate = getSelectedDate(
                        startDate,
                        params.colDef.field
                      );

                      const openShiftsOnDay = openShifts.filter(
                        (openShift) => openShift.date === cellDate
                      );

                      const allOpenShiftsArePublished = openShiftsOnDay.every(
                        ({ isPublished }) => isPublished === true
                      );

                      if (openShiftsOnDay.length) {
                        return allOpenShiftsArePublished;
                      }

                      if (
                        publishedOnlyAllocations &&
                        publishedOnlyAllocations.length > 0
                      ) {
                        const fieldName = params.colDef.field;
                        const publishedCell =
                          publishedOnlyAllocations[0].publishedOnlyAllocations.find(
                            ({ header }) => header === fieldName
                          );
                        if (publishedCell) {
                          return true;
                        }
                      }

                      return false;
                    }

                    if (!showPublishedCellColor) {
                      return false;
                    }
                    if (!publishedOnlyAllocations) {
                      return false;
                    }
                    const targetEmployeeAllocations =
                      publishedOnlyAllocations.find(
                        (data) => data.employeeID === params.data.id
                      );

                    if (!targetEmployeeAllocations) {
                      return false;
                    }
                    const cellHeader = params.column.colDef.field;

                    const targetPublishedAllocation =
                      targetEmployeeAllocations.publishedOnlyAllocations.find(
                        (allo) => allo.header === cellHeader
                      );
                    if (targetPublishedAllocation) {
                      return true;
                    }
                    return false;
                  },
                  "calendar-cell-approved": (params) => {
                    if (requestAllocations) {
                      const rowID = params.data.id;
                      const requests = requestAllocations.find(
                        (data) => data.employeeID === rowID
                      )?.requests;

                      if (!requests) {
                        return false;
                      }

                      const fieldName = params.colDef.field;
                      const request = requests.find(
                        (req) => req.header === fieldName
                      );
                      if (request) {
                        if (request.state === "Approved") {
                          return true;
                        }
                      }
                    }
                    return false;
                  },
                  "annual-leave": (params) => {
                    if (params.value === annualLeaveKeyword) {
                      return true;
                    }
                    return false;
                  },
                },
                cellStyle: (params) => {
                  let style = {};
                  if (params.data.id === "open-shifts") {
                    style.borderRight = "1px solid #bdc3c7";
                  } else {
                    style = { ...getDemandMinMaxCellStyles(params) };
                    style = {
                      ...style,
                      ...getColorCodedCells(
                        params,
                        colorCodes,
                        shiftGroups,
                        shortIdsToEntityNamesDicts,
                        customKeywordsUtilObj
                      ),
                    };
                  }
                  return style;
                },
                tooltipComponent: "tooltipComponent",
                tooltipComponentParams: {
                  shiftNames,
                  shiftGroupNames: getNames(shiftGroups),
                  taskNames: getNames(tasks),
                  shifts,
                  shiftGroups,
                  tasks,
                  taskBlocks,
                  areas,
                  ...(employeesAllocationNotes && { employeesAllocationNotes }),
                  customKeywordsUtilObj,
                  date,
                },
                tooltipValueGetter: (params) => ({ value: params.value }),
                suppressMenu: true,
                width: colWidths[colDef.field] ? colWidths[colDef.field] : 80,
                cellRendererSelector: (params) => {
                  const rowId = params.data.id;
                  if (rowId === "open-shifts") {
                    return {
                      component: "openShiftsCellButton",
                    };
                  }

                  if (employeeIDs.includes(rowId)) {
                    return {
                      component: "allocationCellRenderer",
                      params: {
                        ...(employeesAllocationNotes && {
                          employeesAllocationNotes,
                        }),
                        date,
                      },
                    };
                  }
                },
                cellEditorSelector: (params) => {
                  const rowId = params.data.id;
                  if (rowId === "open-shifts") {
                    return {
                      component: "openShiftsSelector",
                      params: {
                        openOpenShiftsModal,
                        startDate,
                        openShifts,
                        deleteOpenShift,
                        employeesData,
                      },
                      popup: true,
                    };
                  }
                  return {
                    component: "allocationSelector",
                    params: {
                      options: allocationOptions,
                      allowNote: isMainStream,
                      startDate,
                      updateNote,
                      shortIdsToEntityNamesDicts,
                      namesToEntityShortIdsDicts,
                      ...(employeesAllocationNotes && {
                        employeesAllocationNotes,
                      }),
                    },
                    popup: true,
                  };
                },
              };
            });
            return {
              ...dayColDefs,
              groupId: `dayColGroup_${weekGroupIdx}_${dayGroupIdx}`,
              children: customDayColDefs,
            };
          }
        );
        return {
          ...weekColDefs,
          groupId: `weekColGroup${weekGroupIdx}`,
          children: customWeekColDefs,
        };
      }
    );
    return customDatesColumnDefs;
  }, [
    numDays,
    startDate,
    invalidCellListWithReasons,
    preferencesInfo,
    fixedShiftsInfo,
    shiftGroups,
    tasks,
    shiftNames,
    allocationOptions,
    colWidths,
    predefinedShiftOptions,
    uncountableShiftKeywords,
    shifts,
    colorCodes,
    employeesShiftHours,
    publishedOnlyAllocations,
    requestAllocations,
    annualLeaveKeyword,
    showPublishedCellColor,
    taskBlocks,
    openOpenShiftsModal,
    openShifts,
    deleteOpenShift,
    employeesData,
    employeeDatesWithAcceptedOpenShiftAllocations,
    shortIdsToEntityNamesDicts,
    isMainStream,
    updateNote,
    employeesAllocationNotes,
    employeeIDs,
    rosterWarnings,
    areas,
    namesToEntityShortIdsDicts,
    customKeywordsUtilObj,
    displayAreaInAllocationCell,
    showOffInsteadOfBlankInMyRoster,
    showRDOInWeekdays,
    showRDOInWeekends,
    rdoKeyword,
  ]);

  const employeesInfoColumnDefs = useMemo(() => {
    return {
      headerName: "Employees",
      groupId: "employeesColGroup",
      children: [
        {
          field: "name",
          pinned: "left",
          width: colWidths["name"] ? colWidths["name"] : 190,
          rowDrag: true,
          cellStyle: (params) => {
            if (params.data.id === "open-shifts") {
              return {
                textAlign: "center",
                borderRight: "1px solid #bdc3c7",
              };
            }
          },
          sortable: true,
          colSpan: (params) => {
            if (params.data.id === "open-shifts") {
              return 2;
            }
            return 1;
          },
          cellRendererSelector: (params) => {
            const rowId = params.data.id;
            if (rowId === "open-shifts") {
              return {
                component: "openShiftsLabelCellRenderer",
              };
            }
          },
          valueSetter: () => {
            return false;
          },
          cellClassRules: {
            "invalid-cell": (params) => {
              if (params.data.id === "reserved_header") {
                return false;
              }
              if (!params.value) {
                return true;
              }
              if (rosterWarnings) {
                return defaultWarningHighlighterCellClassRules(
                  params,
                  rosterWarnings
                );
              }
            },
          },
          editable: false,
        },
        {
          field: "skills",
          pinned: "left",
          width: colWidths["skills"] ? colWidths["skills"] : 90,
          suppressKeyboardEvent: suppressEnterKey,
          valueFormatter: (params) =>
            convertShortIdsCellValueToEntityNames(params.value, skills),
          valueSetter: () => {
            return false;
          },
          editable: false,
          cellClassRules: {
            "invalid-cell": (params) => {
              if (rosterWarnings) {
                return defaultWarningHighlighterCellClassRules(
                  params,
                  rosterWarnings
                );
              }
            },
          },
          filterValueGetter: (params) =>
            defaultFilterValueGetter(params, "skills", skills),
        },
      ],
    };
  }, [colWidths, skills, rosterWarnings]);

  const statisticsColumnDefs = useMemo(() => {
    const colDefs = [];

    if (totalShiftsColGroups) {
      colDefs.push(totalShiftsColGroups);
    }

    if (totalShiftHoursColGroups) {
      colDefs.push(totalShiftHoursColGroups);
    }

    if (leaveCountColDefs) {
      colDefs.push(leaveCountColDefs);
    }

    if (weekendsOffCountColDefs) {
      colDefs.push(weekendsOffCountColDefs);
    }

    if (preferencesCountColDefs) {
      colDefs.push(preferencesCountColDefs);
    }

    return colDefs;
  }, [
    totalShiftsColGroups,
    totalShiftHoursColGroups,
    leaveCountColDefs,
    weekendsOffCountColDefs,
    preferencesCountColDefs,
  ]);

  const columnDefs = useMemo(() => {
    const columns = [...datesColumnDefs];

    if (includeEmployeesInfoColumnDefs) {
      columns.unshift(employeesInfoColumnDefs);
    }

    return [...columns, ...statisticsColumnDefs];
  }, [
    datesColumnDefs,
    employeesInfoColumnDefs,
    statisticsColumnDefs,
    includeEmployeesInfoColumnDefs,
  ]);

  return (
    <DataEntryTable
      customStyle={{
        height: customGridHeight ? customGridHeight : null,
        minHeight: customGridMinHeight ? customGridMinHeight : "400px",
      }}
      columnDefs={columnDefs}
      defaultColDef={{
        editable: (params) => {
          if (params.data.id === "open-shifts") {
            return true;
          }
          return !params.node.isRowPinned();
        },
        colSpan: (params) => {
          if (
            params.data.id === "reserved_header" &&
            params.data["d1"] === "Total Counts of shifts by day"
          ) {
            return numDays;
          }
          return 1;
        },
      }}
      rowData={employeesData}
      updateData={updateData}
      getContextMenuItems={getContextMenuItems}
      onCellKeyDown={(params) => {
        if (handleKeyDownForUndoRedo) {
          handleKeyDownForUndoRedo(params.event);
        }
      }}
      gridOptions={{
        getRowHeight: (params) => {
          if (params.data.id === "open-shifts") {
            return 30;
          }
          return 25;
        },
        pinnedTopRowData: openShiftsData,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        onCellValueChanged: (params) => {
          if (triggerUndoRedoSnapshotCollection) {
            triggerUndoRedoSnapshotCollection(params);
          }
        },
        onRowDragEnd: (params) => {
          params.context.reorderEmployeesData(params);
        },
        suppressMoveWhenRowDragging: true,
        onSelectionChanged: (params) => {
          setSelectedRows(params.api.getSelectedNodes());
        },
        onCellClicked: (params) => {
          if (params.data.id === "reserved_header") {
            return;
          }
          const isShiftCountByEmployeeCol =
            params.column.colId.endsWith("ShiftCount");
          const isTotalShiftHoursCol =
            params.column.colId === "totalShiftHours";
          const isShiftHoursCol =
            params.column.colId.endsWith("ShiftHours") && !isTotalShiftHoursCol;
          const isWeekendsCol = params.column.colId.endsWith("weekends");

          if (
            params.data.id.startsWith("shift") &&
            !params.data.id.startsWith("shift-hours")
          ) {
            onShiftCountCellClick(params);
          }

          if (
            isShiftCountByEmployeeCol ||
            isShiftHoursCol ||
            isTotalShiftHoursCol ||
            isWeekendsCol
          ) {
            toggleRestrictionModal(params);
            setCellClicked([params.data.name, params.colDef.headerName]);
          }
        },
        onRangeSelectionChanged: (params) => {
          if (handleRangeSelectionChanged) {
            handleRangeSelectionChanged(params);
          }
        },
      }}
      setGridApiToParent={setGridApiToParent}
      components={{
        allocationSelector: AllocationSelector,
        openShiftsSelector: OpenShiftsCellEditor,
        openShiftsCellButton: OpenShiftsCellRenderer,
        allocationCellRenderer: AllocationCellRenderer,
        openShiftsLabelCellRenderer: OpenShiftsLabelCellRenderer,
        rosterToolbar: isMainStream ? ScheduleToolbar : PrototypeToolbar,
        tooltipComponent: CustomToolTip,
        customPanel: (props) => (
          <RosterStatusPanel toggleExpandGrid={toggleExpandGrid} {...props} />
        ),
      }}
      icons={toolIcons}
      sideBar={showSideBar ? sideBar : null}
      pinnedBottomRowData={pinnedBottomRowDataTemplate}
      enableRowDragAnimation={!isSaving}
      context={{ ...context, displayAreaInAllocationCell }}
      setColumnApiToParent={setColumnApiToParent}
      getRowStyle={getMyRosterRowStyle}
      statusBar={
        showStatusBar
          ? {
              statusPanels: [
                ...getRowCountStatusBar().statusPanels,
                {
                  statusPanel: "customPanel",
                  align: "right",
                },
              ],
            }
          : {}
      }
      tooltipShowDelay={0}
      tooltipHideDelay={null}
      handleUIColDrag={handleUIColDrag}
      tableName="my-roster"
      columnHoverHighlight={true}
      shouldHaveCheckBoxLeftMargin={!isMainStream}
      getDataFromGrid={getDataFromGrid}
      onToolPanelVisibleChanged={onToolPanelVisibleChanged}
      isExternalFilterPresent={isExternalFilterPresent}
      doesExternalFilterPass={doesExternalFilterPass}
      processCellForClipboard={(params) =>
        processRosteredAllocationForClipboard(
          params,
          shortIdsToEntityNamesDicts
        )
      }
    />
  );
};

export default RosterGrid;
