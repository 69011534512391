import styles from "./ShiftViewRowFilter.module.css";
import { useEffect, useState } from "react";
import ModalViewer from "../../../../../components/elements/Modal/ModalViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { getDisplayedRowName } from "../RosterTableShiftView/RosterTableShiftView";
import { toFullAllocationString } from "../../../../../utils/modelUtils/allocation";
import Modal from "../../../../../components/elements/Modal/Modal";

const ShiftViewRowFilter = ({
  rowNames,
  isShowing,
  toggleModal,
  updateShiftViewHiddenRows,
  hiddenRows,
  rosterID,
  areas,
  shifts,
  tasks,
  shiftGroups,
}) => {
  const [selected, setSelected] = useState(() =>
    rowNames.filter((name) => hiddenRows.includes(name))
  );

  const handleCheck = (name) => {
    selected.includes(name)
      ? setSelected((prev) => prev.filter((prevName) => prevName !== name))
      : setSelected((prev) => [...prev, name]);
  };

  useEffect(() => {
    setSelected(rowNames.filter((name) => hiddenRows.includes(name)));
  }, [isShowing, rowNames, hiddenRows]);

  const handleSave = () => {
    updateShiftViewHiddenRows(selected, rosterID);
  };

  return (
    <Modal isOpen={isShowing}>
      <ModalViewer
        isShowing={true}
        hide={toggleModal}
        onclickOkay={handleSave}
        modalWidth="550px"
        title="Show or Hide"
      >
        <div className={styles.grid}>
          <div className={`${styles.gridHeader} ${styles.row}`}>
            <span className={styles.status}>Show/Hide</span>
            <span className={styles.rowName}>Row Names</span>
          </div>
          <ul className={styles.list}>
            {rowNames.map((name, idx) => {
              const { areaLabel, allocationLabel } = getDisplayedRowName(
                name,
                areas,
                shifts,
                tasks,
                shiftGroups
              );

              return (
                <li
                  key={idx}
                  className={`${styles.row} ${
                    idx === rowNames.length - 1 ? styles.noBorder : null
                  }`}
                >
                  <div className={styles.status}>
                    <button
                      className={styles.statusBtn}
                      onClick={() => handleCheck(name)}
                    >
                      <FontAwesomeIcon
                        icon={selected.includes(name) ? faEyeSlash : faEye}
                      />
                    </button>
                  </div>
                  <span className={styles.rowName}>
                    {toFullAllocationString(areaLabel, allocationLabel)}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </ModalViewer>
    </Modal>
  );
};

export default ShiftViewRowFilter;
