import { useCallback, useMemo } from "react";
import DataEntryTable from "../../../../rosterProblems/components/DataEntryTable/DataEntryTable";
import {
  allocationValueFormatter,
  exportGridToExcel,
  getDatesColumnDefs,
  getLongestAllocationStringInRowData,
  getReservedHeaderRowStyle,
  getRowCountStatusBar,
  onFilterTextBoxChanged,
} from "../../../../../utils";
import ActionBar from "../../../../../components/elements/ActionBar/ActionBar";
import { useLiveCalendarColumnWidthStore } from "../../../../../globalStore/columnWidthStore";
import { getWeekendsCellStyle } from "../../../../rosterProblems/rosteredAllocations/service/styleGetters";

const LiveShiftCalendarGrid = ({
  shiftsData,
  numDays,
  startDate,
  gridApi,
  setGridApi,
  doesAreaFilterPass,
  isExternalFilterPresent,
  shortIdsToEntityNamesDicts,
}) => {
  const longestStr = getLongestAllocationStringInRowData(shiftsData, [
    "id",
    "shiftTask",
  ]);

  const {
    weekdayColWidth,
    weekendColWidth,
    isWDayWEndSeparate,
    setWeekdayColWidth,
    setWeekendColWidth,
    setIsWDayWEndSeparate,
  } = useLiveCalendarColumnWidthStore();

  const datesColumnDefs = useMemo(() => {
    if (!shiftsData) {
      return [];
    }

    let datesColumnDefs = getDatesColumnDefs(numDays, startDate);

    const customDatesColumnDefs = datesColumnDefs.map(
      (weekColDefs, weekColIdx) => {
        const customWeekColDefs = weekColDefs.children.map(
          (dayColDefs, dayColIdx) => {
            const customDayColDefs = dayColDefs.children.map((colDef) => {
              return {
                ...colDef,
                editable: false,
                width:
                  colDef.headerName === "Sa" || colDef.headerName === "Su"
                    ? weekendColWidth
                    : weekdayColWidth,
                cellStyle: (params) => getWeekendsCellStyle(params),
              };
            });
            return {
              ...dayColDefs,
              groupId: `dayColGroup${dayColIdx}`,
              children: customDayColDefs,
            };
          }
        );
        return {
          ...weekColDefs,
          groupId: `weekColGroup${weekColIdx}`,
          children: customWeekColDefs,
        };
      }
    );

    return customDatesColumnDefs;
  }, [shiftsData, numDays, weekdayColWidth, weekendColWidth, startDate]);

  const columnDefs = [
    {
      headerName: "Allocation",
      children: [
        {
          field: "shift",
          pinned: "left",
          editable: false,
          valueFormatter: (params) =>
            allocationValueFormatter(params, [], shortIdsToEntityNamesDicts),
          minWidth: 100,
        },
        {
          field: "task",
          pinned: "left",
          editable: false,
          valueFormatter: (params) =>
            allocationValueFormatter(params, [], shortIdsToEntityNamesDicts),
          minWidth: 100,
        },
      ],
    },
    ...datesColumnDefs,
  ];

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const exportToExcel = useCallback(() => {
    exportGridToExcel(gridApi);
  }, [gridApi]);

  return (
    <div>
      <ActionBar
        searchBarSettings={{
          tableName: "calendar",
          onFilterInputChanged,
        }}
        adjustWidthSettings={{
          longestStr,
          weekdayColWidth,
          weekendColWidth,
          isWDayWEndSeparate,
          setWeekdayColWidth,
          setWeekendColWidth,
          setIsWDayWEndSeparate,
          tableName: "calendar",
          saveToDatabase: false,
          disableSetForAllPages: true,
        }}
        exportSettings={{
          exportToExcel,
        }}
      />
      <DataEntryTable
        customStyle={{
          height: "calc(100vh - 160px)",
        }}
        gridOptions={{
          onFirstDataRendered: (params) => {
            params.columnApi.autoSizeColumns(["shift", "task"]);
          },
        }}
        columnDefs={columnDefs}
        rowData={shiftsData}
        setGridApiToParent={setGridApi}
        tooltipShowDelay={0}
        tooltipHideDelay={null}
        getRowStyle={getReservedHeaderRowStyle}
        statusBar={{
          statusPanels: [...getRowCountStatusBar().statusPanels],
        }}
        columnHoverHighlight={true}
        overlayNoRowsTemplate={
          '<span style="padding: 10px; font-weight: bold; font-size: 24px;">The schedule admin has not published the schedule for this period.</span>'
        }
        doesExternalFilterPass={doesAreaFilterPass}
        isExternalFilterPresent={isExternalFilterPresent}
      />
    </div>
  );
};

export default LiveShiftCalendarGrid;
