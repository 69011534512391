import { faPalette } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./RosterActionComponents.module.css";

export const ColorCodingButton = ({
  onClick,
  buttonStyle = {},
  iconStyle = {},
}) => {
  return (
    <button
      className={`${styles.btn} ${styles.blueBtn}`}
      onClick={onClick ? onClick : () => {}}
      data-intercom-target="add-warning-btn"
      style={buttonStyle}
    >
      <FontAwesomeIcon
        icon={faPalette}
        className={styles.icon}
        style={iconStyle}
      />
      Color Coding
    </button>
  );
};
