import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ActionBar from "../../../../components/elements/ActionBar/ActionBar";
import AddRowButton from "../../../../components/elements/AddRowButton/AddRowButton";
import ModalReactButtons from "../../../../components/elements/modalReactButtons/ModalReactButtons";
import DataEntryTable from "../../../rosterProblems/components/DataEntryTable/DataEntryTable";
import styles from "./ColorCodingModal.module.css";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ColorPickerRenderer from "../colorPickerCells/ColorPickerRenderer";
import ColorPickerEditor from "../colorPickerCells/ColorPickerEditor";
import DropdownSingleSelector from "../../../grid/components/DropdownSingleSelector/DropdownSingleSelector";
import {
  convertToOptionPropForm,
  convertToShortIdNameOptionForm,
  createContextMenuWithDelete,
  defaultValueSetter,
  exportGridToExcel,
  getAllColorCodesFromGrid,
  getRowId,
  getShortIds,
  getToBeDeletedRows,
  onFilterTextBoxChanged,
  removeDuplicateStrInArr,
  shiftAndShiftGroupShortIdValueFormatter,
  suppressDeleteAndBackspaceKey,
} from "../../../../utils";
import { customWarningAlert } from "../../../confirm/service/confirm";
import {
  convertAllocationInNameFormToShortIdForm,
  extractEntityNamesFromAllocation,
} from "../../../../utils/modelUtils/allocation";
import {
  KEYWORD_NA,
  KEYWORD_OFF,
  LONG_NAME_RDO,
} from "../../../../constants/keywords";

const DeleteButtonRenderer = ({ onClick, ...props }) => {
  const rowInfo = props.data;
  return (
    <button className={styles.deleteBtn} onClick={() => onClick(rowInfo)}>
      <FontAwesomeIcon icon={faTrashCan} />
    </button>
  );
};

const ColorCodingModal = ({
  handleClose,
  shifts,
  shiftGroups,
  colorCodes,
  updateColorCodes,
  customKeywordsUtilObj,
  namesToEntityShortIdsDicts,
}) => {
  const { leaveKeywords, leaveCodes } = customKeywordsUtilObj;

  const otherKeywords = useMemo(() => {
    const keywords = [KEYWORD_OFF, KEYWORD_NA];
    const rdoCode = leaveCodes.find(
      ({ longname }) => longname === LONG_NAME_RDO
    );
    if (rdoCode) {
      keywords.push(rdoCode.shortname);
    }

    return keywords;
  }, [leaveCodes]);

  const [gridApi, setGridApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const componentRef = useRef(null);

  const [warnings, setWarnings] = useState({
    invalidColorExists: false,
    invalidShifts: [],
  });

  const shiftShortIds = useMemo(() => getShortIds(shifts), [shifts]);
  const shiftGroupShortIds = useMemo(
    () => getShortIds(shiftGroups),
    [shiftGroups]
  );

  const possibleIds = useMemo(
    () => [
      ...shiftShortIds,
      ...shiftGroupShortIds,
      ...leaveKeywords,
      ...otherKeywords,
    ],
    [shiftShortIds, shiftGroupShortIds, leaveKeywords, otherKeywords]
  );

  const exportToExcel = useCallback(() => {
    exportGridToExcel(gridApi);
  }, [gridApi]);

  const getWarningMessages = () => {
    const messages = [];
    if (warnings.invalidColorExists) {
      messages.push("Please select a color");
    }
    if (warnings.invalidShifts.length > 0) {
      messages.push("Please select a shift or shift group");
    }
    return messages;
  };

  const rowData = useMemo(() => {
    return colorCodes.map((row) => ({ ...row }));
  }, [colorCodes]);

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  const getColorCodesWarnings = useCallback(
    (gridData) => {
      const invalidShifts = [];
      let invalidColorExists = false;
      for (const data of gridData) {
        if (!possibleIds.includes(data.shift)) {
          invalidShifts.push(data.shift);
        }
        if (!data.color) {
          invalidColorExists = true;
        }
      }

      return {
        invalidShifts: removeDuplicateStrInArr(invalidShifts),
        invalidColorExists,
      };
    },
    [possibleIds]
  );

  const refreshColorCodingWarnings = useCallback(() => {
    if (!gridApi) {
      return;
    }
    const gridData = getAllColorCodesFromGrid(gridApi);
    setWarnings(getColorCodesWarnings(gridData));
  }, [gridApi, getColorCodesWarnings]);

  useEffect(() => {
    if (gridApi) {
      refreshColorCodingWarnings();
    }
  }, [gridApi, refreshColorCodingWarnings]);

  const getContextMenuItems = () => {
    return createContextMenuWithDelete(() => {
      const toBeDeleted = getToBeDeletedRows(null, gridApi);
      removeColorCode(toBeDeleted);
    });
  };

  const duplicateColorCode = useCallback(() => {
    const toBeAdded = selectedRows.map((row) => ({
      ...row.data,
    }));
    gridApi.applyTransaction({
      add: [...toBeAdded],
    });
    refreshColorCodingWarnings();
  }, [gridApi, selectedRows, refreshColorCodingWarnings]);

  const addColorCode = useCallback(() => {
    gridApi.applyTransaction({
      add: [
        {
          shift: "",
          color: "",
        },
      ],
    });
    refreshColorCodingWarnings();
  }, [gridApi, refreshColorCodingWarnings]);

  const removeColorCode = useCallback(
    (toBeDeleted) => {
      gridApi.applyTransaction({ remove: toBeDeleted });
      refreshColorCodingWarnings();
    },
    [gridApi, refreshColorCodingWarnings]
  );

  const options = useMemo(() => {
    return [
      {
        label: "Shifts",
        options: convertToShortIdNameOptionForm(shifts),
      },
      {
        label: "Shift Groups",
        options: convertToShortIdNameOptionForm(shiftGroups),
      },
      {
        label: "Leave",
        options: convertToOptionPropForm(leaveKeywords),
      },
      {
        label: "Other",
        options: convertToOptionPropForm(otherKeywords),
      },
    ];
  }, [shifts, shiftGroups, leaveKeywords, otherKeywords]);

  const columnDefs = useMemo(
    () => [
      {
        field: "shift",
        headerName: "Shift/Shift Group",
        rowDrag: true,
        cellEditor: DropdownSingleSelector,
        cellEditorParams: {
          width: 200,
          options: options,
        },
        cellEditorPopup: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        valueSetter: (params) => {
          const inputAllocation = params.newValue.trim();

          const { shiftNameMapsToEntity, shiftGroupNameMapsToEntity } =
            extractEntityNamesFromAllocation(
              inputAllocation,
              namesToEntityShortIdsDicts
            );

          if (shiftNameMapsToEntity || shiftGroupNameMapsToEntity) {
            const shortIdForm = convertAllocationInNameFormToShortIdForm(
              inputAllocation,
              namesToEntityShortIdsDicts
            );
            params.data[params.column.colId] = shortIdForm;
            return true;
          }

          return defaultValueSetter(params);
        },
        valueFormatter: (params) =>
          shiftAndShiftGroupShortIdValueFormatter(params, shifts, shiftGroups),
        cellClassRules: {
          "invalid-cell": (params) => {
            if (!params.value) {
              return true;
            }

            if (!possibleIds.includes(params.value)) {
              return true;
            }
          },
        },
      },
      {
        field: "color",
        width: 130,
        suppressSizeToFit: true,
        cellRenderer: ColorPickerRenderer,
        cellEditor: ColorPickerEditor,
        cellEditorPopup: true,
        cellEditorPopupPosition: "under",
        valueSetter: defaultValueSetter,
        suppressKeyboardEvent: suppressDeleteAndBackspaceKey,
        cellClassRules: {
          "invalid-cell": (params) => {
            if (!params.value) {
              return true;
            }
          },
        },
      },
      {
        field: "remove",
        width: 120,
        suppressSizeToFit: true,
        cellRenderer: DeleteButtonRenderer,
        cellRendererParams: {
          onClick: (rowInfo) => {
            removeColorCode([rowInfo]);
          },
        },
        editable: false,
      },
    ],
    [
      options,
      removeColorCode,
      possibleIds,
      shifts,
      shiftGroups,
      namesToEntityShortIdsDicts,
    ]
  );

  const onFilterInputChanged = (inputTagID) => {
    onFilterTextBoxChanged(gridApi, inputTagID);
  };

  const saveColorCode = useCallback(async () => {
    if (!gridApi) {
      return;
    }
    const gridData = getAllColorCodesFromGrid(gridApi);

    if (warnings.invalidShifts.length > 0) {
      customWarningAlert({
        title: "Warning",
        descriptions: [
          `Invalid shift(s)/shift group(s): ${warnings.invalidShifts.join(
            ", "
          )}`,
        ],
      });
      return;
    } else if (warnings.invalidColorExists) {
      customWarningAlert({
        title: "Warning",
        descriptions: [`Invalid color(s)`],
      });
      return;
    }

    updateColorCodes(gridData);
    handleClose();
  }, [gridApi, handleClose, warnings, updateColorCodes]);

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.upper}>
          <h2 className={styles.title}>Color Coding</h2>
          <AddRowButton
            rowName={"Colour for shifts"}
            addSingle={addColorCode}
            showDropdown={false}
          />
        </div>
        <div>
          <p className={styles.descTitle}>How does it work:</p>
          <p className={styles.desc}>
            How does it work: If there&apos;s a shift group is assigned a
            colour, where one of the shifts is also assigned. A ranking system
            will apply.
          </p>
        </div>
        <div className={styles.gridContainer} ref={componentRef}>
          <ActionBar
            searchBarSettings={{
              tableName: "colorCoding",
              onFilterInputChanged,
            }}
            duplicateSelectedSettings={{
              selectedRows,
              duplicateSelectedRows: duplicateColorCode,
            }}
            deleteSelectedSettings={{
              selectedRows,
              removeSelectedRows: () => {
                const toBeDeleted = selectedRows.map((row) => row.data);
                removeColorCode(toBeDeleted);
              },
            }}
            exportSettings={{
              exportToExcel,
            }}
            showMoreOptionLabel={false}
          />
          <DataEntryTable
            getRowId={getRowId}
            customStyle={{
              height: "400px",
            }}
            getContextMenuItems={getContextMenuItems}
            setGridApiToParent={setGridApi}
            columnDefs={columnDefs}
            rowData={rowData}
            gridOptions={{
              rowSelection: "multiple",
              suppressRowClickSelection: true,
              onSelectionChanged: (params) => {
                setSelectedRows(params.api.getSelectedNodes());
              },
              onCellValueChanged: () => refreshColorCodingWarnings(),
            }}
            enableRowDragAnimation={true}
            useIndexAsRowId={true}
          />
        </div>
        <div className={styles.warningMsgContainer}>
          {getWarningMessages().map((msg, idx) => (
            <p key={idx}>{msg}</p>
          ))}
        </div>
        <div className={styles.btns}>
          <ModalReactButtons
            positiveSettings={{
              label: "Save",
              color: "#009EC4",
              hoverColor: "#1f91b7",
              onClick: saveColorCode,
              customStyle: {
                borderRadius: "5px",
                width: "230px",
                fontSize: "1.2rem",
              },
            }}
            negativeSettings={{
              label: "Close",
              color: "#009EC4",
              hoverColor: "#009EC4",
              onClick: handleClose,
              customStyle: {
                borderRadius: "5px",
                width: "230px",
                fontSize: "1.2rem",
              },
            }}
            btnsWrapperCustomStyle={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ColorCodingModal;
