import { useEffect, useState } from "react";
import ModalViewer from "../../../../../../components/elements/Modal/ModalViewer";
import styles from "./AddSkillMixModal.module.css";
import { getEntityNameByShortId } from "../../../../../../utils";
import Modal from "../../../../../../components/elements/Modal/Modal";

const AddSkillMixModal = ({
  hide,
  isShowing,
  shiftShortIds,
  shifts,
  skillShortIds,
  skills,
  addNewSkillMix,
}) => {
  const [selectedShift, setSelectedShift] = useState(shiftShortIds[0]);
  const [selectedSkill, setSelectedSkill] = useState(skillShortIds[0]);

  useEffect(() => {
    setSelectedShift(shiftShortIds[0]);
    setSelectedSkill(skillShortIds[0]);
  }, [shiftShortIds, skillShortIds]);

  return (
    <Modal isOpen={isShowing}>
      <ModalViewer
        hide={hide}
        isShowing={true}
        title={"Add new skill mix"}
        firstBtnLabel={"Add"}
        backgroundColor={"#F5F5F5"}
        onclickOkay={() => {
          if (selectedShift && selectedSkill) {
            addNewSkillMix(selectedShift, selectedSkill);
          }
        }}
      >
        <div className={styles.shiftSkillBox}>
          <div className={styles.shiftGroupNameBox}>
            Select a shift name:
            <select
              value={selectedShift}
              onChange={(event) => setSelectedShift(event.target.value)}
            >
              {shifts.map(({ name, shortId }, idx) => (
                <option key={idx} value={shortId}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.shiftGroupNameBox}>
            Select a skill name:
            <select
              value={selectedSkill}
              onChange={(event) => setSelectedSkill(event.target.value)}
            >
              {skills.map(({ name, shortId }, idx) => (
                <option key={idx} value={shortId}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={styles.dynamicSentence}>
          Number of employees with{" "}
          <b style={{ color: "rgb(33, 158, 201)" }}>
            {getEntityNameByShortId(skills, selectedSkill)}
          </b>{" "}
          skill working during{" "}
          <b style={{ color: "rgb(33, 158, 201)" }}>
            {getEntityNameByShortId(shifts, selectedShift)}
          </b>{" "}
          shift
        </div>
      </ModalViewer>
    </Modal>
  );
};

export default AddSkillMixModal;
