import styles from "./SnapshotsDropdown.module.css";
import { useCallback, useEffect, useState } from "react";
import { useToggleShow } from "../../../../hooks/useToggleShow";
import ModalViewer from "../../../../components/elements/Modal/ModalViewer";
import { DateTime, getIds, getNames, sortByDateField } from "../../../../utils";
import ManageSnapshotsModal from "../ManageSnapshotsModal/ManageSnapshotsModal";
import useStandardDataContainer from "../../../../hooks/modelQueryHooks/useStandardDataContainer";
import SaveSnapshotModal from "../SaveSnapshotModal/SaveSnapshotModal";
import { useHistory } from "react-router-dom";
import { downloadJSON } from "../../../rosterProblems/service/downloadRoster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { useScheduleRouteQuery } from "../../../../hooks/useScheduleRouteQuery";
import { useSnapshotsStore } from "../../../../globalStore/snapshotsStore";
import { customConfirmAlert } from "../../../confirm/service/confirm";
import Modal from "../../../../components/elements/Modal/Modal";

export default function SnapshotsDropdown({
  location,
  rosterID,
  isMainStream,
  periodStartDate,
}) {
  const { snapshotId: currentSnapshotId } = useScheduleRouteQuery();
  const { snapshots: snapshotRosters, refreshSnapshots } = useSnapshotsStore();

  const sortedSnapshotRosters = sortByDateField(
    snapshotRosters,
    "createdAt",
    false,
    false
  );

  useEffect(() => {
    refreshSnapshots(location.id, periodStartDate);
  }, [refreshSnapshots, location.id, periodStartDate]);

  const history = useHistory();
  const {
    roster,
    createSnapshotRoster,
    updateSnapshots,
    duplicateSnapshot,
    deleteSnapshots,
  } = useStandardDataContainer({
    isScheduleView: true,
    locationID: location.id,
    rosterID,
  });

  const duplicateSnapshotAndRefresh = useCallback(
    async (locationID, id, neName) => {
      await duplicateSnapshot(locationID, id, neName);
      await refreshSnapshots(location.id, periodStartDate);
    },
    [duplicateSnapshot, refreshSnapshots, location.id, periodStartDate]
  );

  const deleteSnapshotAndRefresh = useCallback(
    async (deletedSnapshotIDs) => {
      const response = await customConfirmAlert({
        title: "Please confirm",
        descriptions: [
          `Are you sure you want to delete ${deletedSnapshotIDs.length} version(s)?`,
        ],
      });
      if (response) {
        await deleteSnapshots(deletedSnapshotIDs);
        await refreshSnapshots(location.id, periodStartDate);
      }
    },
    [deleteSnapshots, location, periodStartDate, refreshSnapshots]
  );

  const [newSnapshotDefaultName, setNewSnapshotDefaultName] = useState("");
  const [newSnapshotName, setNewSnapshotName] = useState("");

  const [isNewSnapshotModalOpen, setIsNewSnapshotModalOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const [isManageSnapshotsModalOpen, setIsManageSnapshotsModalOpen] =
    useState(false);

  const {
    isOpen: isDropdownOpen,
    toggleDropdown,
    selectorRef: dropdownTriggerRef,
    selectorTriggerRef: dropdownRef,
  } = useToggleShow();

  const openNewSnapshotModal = () => {
    setIsManageSnapshotsModalOpen(false);
    const currentDateFormatted = new DateTime().toFormat("version-date");
    const currentTimeFormatted = DateTime.getCurrentTimeFormatted();
    setNewSnapshotDefaultName(
      `Version: ${currentDateFormatted} ${currentTimeFormatted}`
    );

    setIsNewSnapshotModalOpen(true);
  };

  const openManageSnapshotsModal = () => {
    setIsNewSnapshotModalOpen(false);
    setIsManageSnapshotsModalOpen(true);
  };

  const brieflyShowWarningMessage = (message) => {
    setWarningMessage(message);
    setTimeout(() => {
      setWarningMessage("");
    }, 3000);
  };

  const takeSnapshot = async () => {
    let newName = newSnapshotName.trim();
    const existingSnapshotNames = getNames(sortedSnapshotRosters);

    if (!newName) {
      if (!newSnapshotDefaultName) {
        brieflyShowWarningMessage("Missing name!");
        return;
      }
      newName = newSnapshotDefaultName;
    }

    if (existingSnapshotNames.includes(newName)) {
      brieflyShowWarningMessage("Name already exists!");
      return;
    }

    await generateSnapshot(newName);
    setIsNewSnapshotModalOpen(false);
    setNewSnapshotName("");
    await refreshSnapshots(location.id, periodStartDate);
    return;
  };

  const openSnapshotPage = (snapshotId) => {
    let query = `?location-id=${location.id}`;
    if (getIds(sortedSnapshotRosters).includes(snapshotId)) {
      query = `${query}&snapshot-id=${snapshotId}`;
    }
    history.push({
      search: query,
    });
  };

  const goBackToCurrentVersion = () => {
    history.push({
      search: `?location-id=${location.id}&date=${periodStartDate}`,
    });
  };

  const generateSnapshot = useCallback(
    async (snapshotName) => {
      return createSnapshotRoster(snapshotName, roster, location);
    },
    [createSnapshotRoster, roster, location]
  );

  const downloadSnapshot = (snapshotId) => {
    const snapshotRoster = sortedSnapshotRosters.find(
      ({ id }) => id === snapshotId
    );
    const locationName = location.name;
    if (snapshotRoster) {
      const json = JSON.stringify({ ...snapshotRoster, locationName });
      downloadJSON(json, snapshotRoster.name);
    }
  };

  const snapshotRosterExists = sortedSnapshotRosters.length > 0;

  return (
    <div
      className={styles.container}
      style={{
        ...(isNewSnapshotModalOpen && { zIndex: 2 }),
        ...(isManageSnapshotsModalOpen && { zIndex: 2 }),
      }}
    >
      <Modal isOpen={isNewSnapshotModalOpen}>
        <ModalViewer
          isShowing={true}
          title="Save the current version"
          firstBtnLabel="Save"
          secondBtnLabel="Cancel"
          themeColor="#0A9887"
          onFirstBtnClick={takeSnapshot}
          onSecondBtnClick={() => setIsNewSnapshotModalOpen(false)}
          hide={() => setIsNewSnapshotModalOpen(false)}
          invokeHideAfterButtonClick={false}
        >
          <SaveSnapshotModal
            newSnapshotName={newSnapshotName}
            setNewSnapshotName={setNewSnapshotName}
            warningMessage={warningMessage}
            newSnapshotDefaultName={newSnapshotDefaultName}
          />
        </ModalViewer>
      </Modal>
      <Modal isOpen={isManageSnapshotsModalOpen}>
        <ModalViewer
          isShowing={true}
          title="Version Histories"
          firstBtnLabel="Go back to my current version"
          themeColor="#0A9887"
          onFirstBtnClick={goBackToCurrentVersion}
          noSecondButton={true}
          hide={() => setIsManageSnapshotsModalOpen(false)}
          modalWidth="1000px"
          buttonWidth="400px"
        >
          <ManageSnapshotsModal
            locationID={location.id}
            snapshotRosters={sortedSnapshotRosters}
            openSnapshotPage={openSnapshotPage}
            downloadSnapshot={downloadSnapshot}
            duplicateSnapshot={duplicateSnapshotAndRefresh}
            deleteSnapshots={deleteSnapshotAndRefresh}
            updateSnapshots={updateSnapshots}
            currentSnapshotId={currentSnapshotId}
          />
        </ModalViewer>
      </Modal>
      <div className={styles.buttonsWrapper}>
        {!isMainStream && (
          <button
            className={`${styles.button} ${styles.currentVersionButton}`}
            onClick={goBackToCurrentVersion}
          >
            Back to current version
          </button>
        )}
        {snapshotRosterExists && (
          <button
            className={`${styles.button} ${styles.versionControlButton}`}
            onClick={() => toggleDropdown()}
            ref={dropdownRef}
          >
            Version Control
          </button>
        )}
        {!snapshotRosterExists && (
          <button
            className={`${styles.button} ${styles.versionControlButton}`}
            onClick={openNewSnapshotModal}
            ref={dropdownRef}
          >
            Save as a new version
          </button>
        )}
      </div>
      {isDropdownOpen && (
        <ul className={styles.dropdown} ref={dropdownTriggerRef}>
          <li className={styles.item}>
            <button
              className={styles.takeSnapshotButton}
              onClick={openNewSnapshotModal}
            >
              Save as a new version
            </button>
          </li>
          <li className={styles.item}>
            {!isMainStream && (
              <div className={styles.itemLabelContainer}>
                <button
                  className={styles.backToCurrentVersionButton}
                  onClick={goBackToCurrentVersion}
                >
                  <FontAwesomeIcon icon={faBackward} />
                  Back to current version
                </button>
              </div>
            )}
            <div className={styles.itemLabelContainer}>
              <p className={styles.itemLabel}>VERSION HISTORIES</p>
            </div>
            <div className={styles.versions}>
              {sortedSnapshotRosters.map(({ name, id }) => (
                <button
                  className={`${styles.versionButton} ${
                    currentSnapshotId === id
                      ? styles.currentActiveSnapshot
                      : undefined
                  }`}
                  key={id}
                  onClick={() => openSnapshotPage(id)}
                >
                  {name}
                </button>
              ))}
            </div>
            <button
              className={styles.manageVersionsButton}
              onClick={openManageSnapshotsModal}
            >
              Manage versions
            </button>
          </li>
        </ul>
      )}
    </div>
  );
}
